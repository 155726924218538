import { Button } from '@cbhq/cds-web/buttons/Button';
import React, { useState } from 'react';
import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import {
  CoinbaseAnalyticsTransactionDirection,
  CoinbaseAnalyticsTransactionOrder,
  CoinbaseAnalyticsTransactionTypes,
} from '../pages/addresses/__generated__/AddressTransactions_AddressQuery.graphql';
import { Moment } from 'moment';
import { TextInput } from '@cbhq/cds-web/controls';
import { TextLabel1 } from '@cbhq/cds-web/typography';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { TransactionsFiltersType } from './TransactionTabData';
import { MultiselectDropdown } from './filters/MultiselectDropdown';

const TransactionFilters: React.FC<{
  tickerSymbol: string;
  setTransactionFilters: (value: TransactionsFiltersType) => void;
}> = (props) => {
  const [direction, setDirection] = useState('all');
  const [order, setOrder] = useState('desc');
  const [types, setTypes] = useState(null);
  const [periodStart, setPeriodStart] = useState<Moment>(null);
  const [periodEnd, setPeriodEnd] = useState<Moment>(null);
  const [minAssetAmount, setMinAssetAmount] = useState(null);
  const [maxAssetAmount, setMaxAssetAmount] = useState(null);
  const [addressHashOrWalletId, setAddressHashOrWalletId] = useState(null);
  const [filterButtonText, setFilterButtonText] = useState('All');

  const onResetClick = () => {
    setDirection('all');
    setOrder('desc');
    setTypes(null);
    setPeriodStart(null);
    setPeriodEnd(null);
    setMinAssetAmount('');
    setMaxAssetAmount('');
    setAddressHashOrWalletId('');
  };

  const onFilterClick = () => {
    isFiltered();
    props.setTransactionFilters({
      direction:
        direction?.toUpperCase() as CoinbaseAnalyticsTransactionDirection,
      order: order?.toUpperCase() as CoinbaseAnalyticsTransactionOrder,
      types:
        types && types.length > 0
          ? types.map((t) => {
              return t.toUpperCase() as CoinbaseAnalyticsTransactionTypes;
            })
          : null,
      addressHashOrWalletId: addressHashOrWalletId?.trim(),
      periodStart: periodStart?.format('YYYY-MM-DDTHH:mm:ss[Z]') ?? null,
      periodEnd: periodEnd?.format('YYYY-MM-DDTHH:mm:ss[Z]') ?? null,
      minAssetAmount: minAssetAmount?.trim(),
      maxAssetAmount: maxAssetAmount?.trim(),
    });
  };

  const isFiltered = () => {
    if (
      direction.toUpperCase() != 'ALL' ||
      order.toUpperCase() != 'DESC' ||
      types ||
      periodStart ||
      periodEnd ||
      (minAssetAmount && minAssetAmount?.trim() != '') ||
      (maxAssetAmount && maxAssetAmount?.trim() != '') ||
      (addressHashOrWalletId && addressHashOrWalletId?.trim() != '')
    ) {
      setFilterButtonText('Filtered');
    } else setFilterButtonText('All');
  };

  const onChangeDate = (setPeriodFn) => (time) => {
    setPeriodFn(time);
  };

  const attributeOptions = [
    'utxo',
    'zero',
    'op_return',
    'coinbase',
    'escrow',
    'selfchange',
    'segwit',
    'segwit_native',
    'selfsend',
    'high_fee',
    'invalid_addresses',
    'mixer',
    'shared',
    'joinmarket',
    'wasabi',
    'shielded',
  ];

  return (
    <div className="dropdown pull-right">
      <button
        className="btn btn-primary btn-xs dropdown-toggle"
        type="button"
        data-toggle="dropdown"
      >
        <span className="glyphicon glyphicon-filter"></span>
        {filterButtonText}
        <span className="caret"></span>
      </button>
      <ul className="dropdown-menu" style={{ width: 250 + 'px' }}>
        <form id="filters" className="form container-fluid">
          {/* Direction */}
          <fieldset style={fieldsetStyle}>
            <legend style={filterHeaderStyle}>Direction</legend>
            <table>
              <thead>
                <tr>
                  <th style={{ width: '62%' }}></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="small">
                    <input
                      type="radio"
                      id="radio_all"
                      value="all"
                      checked={direction == 'all'}
                      onChange={() => {
                        setDirection('all');
                      }}
                    />
                    &nbsp;&nbsp;All
                  </td>
                  <td className="small">
                    <input
                      type="radio"
                      id="radio_unspent"
                      value="unspent"
                      checked={direction == 'unspent'}
                      onChange={() => {
                        setDirection('unspent');
                      }}
                    />
                    &nbsp;&nbsp;Unspent
                  </td>
                </tr>
                <tr>
                  <td className="small">
                    <input
                      type="radio"
                      id="radio_sent"
                      value="sent"
                      checked={direction == 'sent'}
                      onChange={() => {
                        setDirection('sent');
                      }}
                    />
                    &nbsp;&nbsp;Sent
                  </td>
                  <td className="small">
                    <input
                      type="radio"
                      id="radio_received"
                      value="received"
                      checked={direction == 'received'}
                      onChange={() => {
                        setDirection('received');
                      }}
                    />
                    &nbsp;&nbsp;Received
                  </td>
                </tr>
              </tbody>
            </table>
          </fieldset>

          {/* Sort */}
          <fieldset style={fieldsetStyle}>
            <legend style={filterHeaderStyle}>Sort</legend>
            <div></div>
            <VStack gap={1}>
              <HStack gap={1} alignItems="center">
                <Box>
                  <input
                    type="radio"
                    id="radio_new_to_old"
                    value="desc"
                    checked={order == 'desc'}
                    onChange={() => {
                      setOrder('desc');
                    }}
                  />
                  &nbsp;&nbsp;Newest to Oldest
                </Box>
              </HStack>
              <HStack gap={1} alignItems="center">
                <Box width="80%">
                  <input
                    type="radio"
                    id="radio_old_to_new"
                    value="asc"
                    checked={order == 'asc'}
                    onChange={() => {
                      setOrder('asc');
                    }}
                  />
                  &nbsp;&nbsp;Oldest to Newest
                </Box>
              </HStack>
            </VStack>
          </fieldset>

          {/* Date Picker */}
          <fieldset style={fieldsetStyle}>
            <legend style={filterHeaderStyle}>Date</legend>
            <VStack gap={1}>
              <HStack gap={1} alignItems="center">
                <Box width="20%" justifyContent="flex-end">
                  <TextLabel1 as="p">From</TextLabel1>
                </Box>
                <Box width="80%">
                  <DateTime
                    key="periodStart"
                    value={periodStart}
                    utc
                    onChange={onChangeDate(setPeriodStart)}
                    renderInput={renderDateTimeInput}
                  />
                </Box>
              </HStack>
              <HStack gap={1} alignItems="center">
                <Box width="20%" justifyContent="flex-end">
                  <TextLabel1 as="p">To</TextLabel1>
                </Box>
                <Box width="80%">
                  <DateTime
                    key="periodEnd"
                    value={periodEnd}
                    utc
                    onChange={onChangeDate(setPeriodEnd)}
                    renderInput={renderDateTimeInput}
                  />
                </Box>
              </HStack>
            </VStack>
          </fieldset>

          {/* Value */}
          <fieldset style={fieldsetStyle}>
            <legend style={filterHeaderStyle}>Value</legend>
            <VStack gap={1}>
              <HStack gap={1} alignItems="center">
                <Box width="20%" justifyContent="flex-end">
                  <TextLabel1 as="p">Min</TextLabel1>
                </Box>
                <TextInput
                  height={textInputHeight}
                  width="80%"
                  type="number"
                  compact
                  value={minAssetAmount}
                  onChange={(evt) => {
                    setMinAssetAmount(evt.target.value);
                  }}
                  suffix={props.tickerSymbol.toUpperCase()}
                ></TextInput>
              </HStack>
              <HStack gap={1} alignItems="center">
                <Box width="80%" justifyContent="flex-end">
                  <TextLabel1 as="p">Max</TextLabel1>
                </Box>
                <TextInput
                  height={textInputHeight}
                  width="80%"
                  type="number"
                  compact
                  value={maxAssetAmount}
                  onChange={(evt) => {
                    setMaxAssetAmount(evt.target.value);
                  }}
                  suffix={props.tickerSymbol.toUpperCase()}
                ></TextInput>
              </HStack>
            </VStack>
          </fieldset>

          {/* Attributes */}
          <fieldset style={fieldsetStyle}>
            <legend style={filterHeaderStyle}>Attributes</legend>
            <MultiselectDropdown
              allowMultiple
              selectAllOptions={false}
              itemList={attributeOptions}
              onChange={setTypes}
              allOptionsText="Select Attributes"
              defaultSelection={types}
            />
          </fieldset>

          {/* Address or WalletID */}
          <fieldset style={fieldsetStyle}>
            <legend style={filterHeaderStyle}>Address or Wallet ID</legend>
            <TextInput
              id="addressHashOrWalletId"
              compact
              height={textInputHeight}
              value={addressHashOrWalletId}
              onChange={(evt) => {
                setAddressHashOrWalletId(evt.target.value);
              }}
            />
          </fieldset>

          {/* Submit Button */}
          <div style={{ paddingBottom: 15 + 'px' }}>
            <HStack gap={1}>
              <Button compact block onPress={onResetClick} variant="secondary">
                Reset
              </Button>
              <Button compact block onPress={onFilterClick}>
                Filter
              </Button>
            </HStack>
          </div>
        </form>
      </ul>
    </div>
  );
};

const renderDateTimeInput = (props, openCalendar) => {
  return (
    <TextInput
      width="90%"
      height={textInputHeight}
      compact
      value={props.value}
      onClick={openCalendar}
    />
  );
};

const fieldsetStyle = {
  paddingBottom: '20px',
};
const filterHeaderStyle = {
  fontSize: '15px',
};
const textInputHeight = 40;

export default TransactionFilters;
