import React from 'react';
import { abbreviateHash } from '../util/StringUtils';
import { RightChevron } from './Icons';
import { HStack } from '@cbhq/cds-web/layout';
import { IconButton } from '@cbhq/cds-web/buttons';
import { Tooltip } from '@cbhq/cds-web/overlays';
import { useToast } from '@cbhq/cds-web/overlays/useToast';

export const HeaderBreadcrumbBlock: React.FC<{
  hash: string;
  label: string;
  link?: string | React.ReactNode;
  customTooltip?: string;
  riskLabel;
}> = (props) => {
  const { hash, label, link, riskLabel } = props;
  const toast = useToast();

  return (
    <div className="page-header">
      <HStack alignItems="center" gap={1}>
        {riskLabel}
        {label}
      </HStack>

      <HStack alignItems="center" gap={1}>
        <h2
          className="breadcrumb-header"
          data-effect="solid"
          data-tip="necessary for react-tooltip"
          data-for={`item-${hash}`}
          data-place="right"
          data-clickable={true}
          data-delay-hide="1000"
          data-background-color="white"
          data-border="true"
          data-border-color="#CCC"
          data-text-color="#333"
          data-class="tooltip-container"
        >
          {link || abbreviateHash(hash)}
        </h2>
        <Tooltip content={'Copy'} placement="top">
          <IconButton
            transparent
            variant="foregroundMuted"
            name="copy"
            onPress={() => {
              navigator.clipboard.writeText(hash);
              toast.show('Copied to clipboard!');
            }}
          />
        </Tooltip>
      </HStack>
    </div>
  );
};

export const HeaderBreadcrumbs: React.FC<{ blocks }> = (props) => {
  const crumbs = [];
  for (let i = 0; i < props.blocks.length; i++) {
    crumbs.push(<td key={`crumb-${i}`}>{props.blocks[i]}</td>);
    if (i == props.blocks.length - 1) {
      break;
    }
    crumbs.push(
      <td
        width={34}
        key={`chevron-${i}`}
        align={'center'}
        style={{ verticalAlign: 'bottom', paddingBottom: '45px' }}
      >
        <div style={{ color: '#E3E2E3' }}>
          <RightChevron />
        </div>
      </td>
    );
  }

  return (
    <table>
      <tbody>
        <tr>{crumbs}</tr>
      </tbody>
    </table>
  );
};
