import React, { useContext } from 'react';
import ReactTooltip from 'react-tooltip';
import { PageDataContext } from '../util/PageDataContext';

type ReportType = 'Address' | 'Wallet';

const ReportButton: React.FC<{
  chain;
  hash;
  type: ReportType;
  enabled: boolean;
}> = (props) => {
  return (
    <>
      {props.enabled ? <ReportModalTrigger /> : <DisabledReportButton />}
      <Modal {...props} />
    </>
  );
};

const Modal: React.FC<{ chain; hash; type: ReportType }> = (props) => {
  const { userConfigs } = useContext(PageDataContext);
  const { chain, hash, type } = props;
  const url = `/${chain}/${
    type === 'Address' ? 'addresses' : 'wallets'
  }/${hash}/report`;
  return (
    <div className="modal fade report-parameters" id="report-parameters">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 className="modal-title">Report</h4>

            <br />
            <small>
              Generate a single web page with all the information of the current{' '}
              {props.type.toLowerCase()} displayed as Coinbase Tracer does.
              <br />
              The page could be easily <strong>printed</strong>, saved to disk
              or as PDF.
            </small>
          </div>
          <div className="modal-body">
            <form id="rep-parameters" method="get" action={url} target="_blank">
              <div className="form-group">
                <label className="form-label">Sections</label>
                <br />

                <div className="col-sm-4">
                  <div className="checkbox">
                    <label>
                      <input
                        type="checkbox"
                        name="summary"
                        defaultChecked={true}
                        required
                      />
                      Summary
                    </label>
                  </div>
                  <div className="checkbox">
                    <label>
                      <input
                        type="checkbox"
                        name="risk"
                        defaultChecked={true}
                      />
                      Risk Score
                    </label>
                  </div>
                  <div className="checkbox">
                    <label>
                      <input
                        type="checkbox"
                        name="balance"
                        defaultChecked={true}
                      />
                      Balance
                    </label>
                  </div>
                </div>

                <div className="col-sm-6">
                  {userConfigs.permissions.osint && (
                    <div className="checkbox">
                      <label>
                        <input type="checkbox" name="osint" />
                        OSINT
                      </label>
                    </div>
                  )}
                  {props.type === 'Wallet' && (
                    <div className="checkbox">
                      <label>
                        <input type="checkbox" name="addr" />
                        Addresses <span className="text-muted">(max 1000)</span>
                      </label>
                    </div>
                  )}
                  <div className="checkbox">
                    <label>
                      <input type="checkbox" name="tx" />
                      Transactions{' '}
                      <span className="text-muted">(max 1000)</span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="clearfix"></div>

              <div className="form-group form-label">
                <label className="form-label" style={{ marginTop: '20px' }}>
                  Title
                </label>
                <input
                  className="form-control"
                  maxLength={255}
                  name="title"
                  required
                />
              </div>

              <div className="form-group">
                <label className="form-label" style={{ marginTop: '20px' }}>
                  Notes
                </label>
                <textarea
                  className="form-control"
                  maxLength={2000}
                  rows={4}
                  style={{ resize: 'none' }}
                  name="comment"
                ></textarea>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              form="rep-parameters"
            >
              Create
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const DisabledReportButton = () => {
  return (
    <>
      <ReactTooltip id={'disabled-report-button'}>
        <div style={{ maxWidth: 250 }}>License upgrade required</div>
      </ReactTooltip>
      <span data-tip="" data-for="disabled-report-button">
        <a
          aria-disabled="true"
          className="btn btn-primary disabled"
          style={{ width: '100%' }}
        >
          Create Report
        </a>
      </span>
    </>
  );
};

const ReportModalTrigger = () => (
  <a
    data-toggle="modal"
    data-magic-tooltip="Edit"
    data-target=".report-parameters"
    title="Edit"
    data-original-title="Edit"
    className="btn btn-primary"
    style={{ width: '100%' }}
  >
    Create Report
  </a>
);

export default ReportButton;
