import React, { CSSProperties } from 'react';

import { CategoryType, GetCategoryType } from '../util/CategoryUtils';

const WalletCategory: React.FC<{
  category: string;
  mixed?: boolean;
  fontSize?: number;
  spacingBottom?: string;
}> = (props) => {
  let name = props.category.replace('_', ' ');
  let displayType = 'success';
  const type = GetCategoryType(props.category);
  if (type === CategoryType.GENERATED) {
    displayType = 'primary';
  } else if (type === CategoryType.SUSPICIOUS) {
    displayType = 'warning';
  } else if (type === CategoryType.ILLEGAL) {
    displayType = 'danger';
  }
  if (props.category === 'kyc') {
    name = name.toUpperCase();
    displayType = 'success';
  } else if (props.category === 'nokyc') {
    name = name.toUpperCase();
    displayType = 'warning';
  } else if (props.category === 'fiat') {
    name = name.toUpperCase();
    displayType = 'primary';
  }

  if (props.mixed) {
    displayType = 'danger';
  }
  const style: CSSProperties = {};
  if (props.fontSize) {
    style.fontSize = props.fontSize;
  }
  if (props.spacingBottom) {
    style.marginBottom = props.spacingBottom;
  }
  // TODO Brett: Handle :mixed case as in wallet_helpers.rb
  return (
    <span className={`label label-${displayType}`} style={style}>
      {name}
    </span>
  );
};

export default WalletCategory;
