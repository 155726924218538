import React, { useState } from 'react';
import { GraphqlProvider, createGraphqlEnvironment } from '@cbhq/data-layer';

export const GraphqlEnvProvider = (props) => {
  const [environment] = useState(() =>
    createGraphqlEnvironment({
      graphqlUrl: '/graphql',
      getPersistedQueries: () =>
        import('../persisted_queries.json').then((module) => module.default),
    })
  );
  return (
    <GraphqlProvider environment={environment}>
      {props.children}
    </GraphqlProvider>
  );
};
