import React, { Suspense } from 'react';
import { TransactionsTabData } from '../../components/TransactionTabData';
import { useLazyLoadQuery } from 'react-relay';
import { graphql } from '@cbhq/data-layer';
import { LoadingBubbles } from '../../components/LoadingBubbles';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { AddressTransactions_AddressQueryResponse } from './__generated__/AddressTransactions_AddressQuery.graphql';

const addressTransactionsQuery = graphql`
  query AddressTransactions_AddressQuery(
    $tickerSymbol: TickerSymbol!
    $filters: CoinbaseAnalyticsTransactionFilters!
    $addressId: String!
    $after: String!
  ) {
    viewer {
      coinbaseAnalytics {
        addressByHashAndSymbol(hash: $addressId, tickerSymbol: $tickerSymbol) {
          transactions(asset: $tickerSymbol, filters: $filters, after: $after) {
            edges {
              node {
                hash
                ...TransactionTabRow_TransactionFragment
              }
            }
            pageInfo {
              endCursor
              hasNextPage
            }
          }
        }
      }
    }
  }
`;

export const AddressTransactions: React.FC<{
  chain: string;
  addressId: string;
  numTx: number;
}> = (props) => {
  return (
    <ErrorBoundary>
      <Suspense fallback={<LoadingBubbles />}>
        <TransactionsTabData
          tickerSymbol={props.chain}
          numTx={props.numTx}
          walletId={props.addressId}
          fetchTransactionConnection={(cursor, filters) => {
            const result = useLazyLoadQuery(addressTransactionsQuery, {
              tickerSymbol: props.chain,
              filters: filters,
              addressId: props.addressId,
              after: cursor,
            });
            return (result as AddressTransactions_AddressQueryResponse).viewer
              .coinbaseAnalytics.addressByHashAndSymbol.transactions;
          }}
        />
      </Suspense>
    </ErrorBoundary>
  );
};
