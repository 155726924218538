/**
 * @generated SignedSource<<497a89a0e319d47eafe97aa447d3eb2a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TransactionRiskScore_TransactionQuery$variables = {
  tickerSymbol: any;
  transactionId: string;
};
export type TransactionRiskScore_TransactionQueryVariables = TransactionRiskScore_TransactionQuery$variables;
export type TransactionRiskScore_TransactionQuery$data = {
  readonly viewer: {
    readonly coinbaseAnalytics: {
      readonly transactionByHashAndSymbol: {
        readonly risk: {
          readonly score: string;
          readonly factors: {
            readonly positive: ReadonlyArray<{
              readonly desc: string;
            }> | null;
            readonly negative: ReadonlyArray<{
              readonly modifier: number;
              readonly desc: string;
            }> | null;
          };
        } | null;
      } | null;
    };
  };
};
export type TransactionRiskScore_TransactionQueryResponse = TransactionRiskScore_TransactionQuery$data;
export type TransactionRiskScore_TransactionQuery = {
  variables: TransactionRiskScore_TransactionQueryVariables;
  response: TransactionRiskScore_TransactionQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tickerSymbol"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "transactionId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "hash",
    "variableName": "transactionId"
  },
  {
    "kind": "Variable",
    "name": "tickerSymbol",
    "variableName": "tickerSymbol"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "desc",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "CoinbaseAnalyticsRiskScore",
  "kind": "LinkedField",
  "name": "risk",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "score",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CoinbaseAnalyticsRiskScoreFactors",
      "kind": "LinkedField",
      "name": "factors",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CoinbaseAnalyticsRiskScoreFactor",
          "kind": "LinkedField",
          "name": "positive",
          "plural": true,
          "selections": [
            (v2/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CoinbaseAnalyticsRiskScoreFactor",
          "kind": "LinkedField",
          "name": "negative",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "modifier",
              "storageKey": null
            },
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TransactionRiskScore_TransactionQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CoinbaseAnalytics",
            "kind": "LinkedField",
            "name": "coinbaseAnalytics",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "CoinbaseAnalyticsTransaction",
                "kind": "LinkedField",
                "name": "transactionByHashAndSymbol",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TransactionRiskScore_TransactionQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CoinbaseAnalytics",
            "kind": "LinkedField",
            "name": "coinbaseAnalytics",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "CoinbaseAnalyticsTransaction",
                "kind": "LinkedField",
                "name": "transactionByHashAndSymbol",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "57fc35d696ba3c897796da963aa4222b",
    "id": null,
    "metadata": {},
    "name": "TransactionRiskScore_TransactionQuery",
    "operationKind": "query",
    "text": "query TransactionRiskScore_TransactionQuery(\n  $tickerSymbol: TickerSymbol!\n  $transactionId: String!\n) {\n  viewer {\n    coinbaseAnalytics {\n      transactionByHashAndSymbol(hash: $transactionId, tickerSymbol: $tickerSymbol) {\n        risk {\n          score\n          factors {\n            positive {\n              desc\n            }\n            negative {\n              modifier\n              desc\n            }\n          }\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "78ee76e017c206eaeebec759ec482384";

export default node;
