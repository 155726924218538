import React, { Suspense, useState } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { graphql } from '@cbhq/data-layer';
import { BlockIndexPageQuery_BlocksQuery } from './__generated__/BlockIndexPageQuery_BlocksQuery.graphql';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { LoadingBubbles } from '../../components/LoadingBubbles';
import { NextPageCursorNavigator } from '../../components/NextPageCursorNavigator';
import { ChainLink } from '../../components/ChainLink';

const blockPageQuery = graphql`
  query BlockIndexPageQuery_BlocksQuery(
    $tickerSymbol: TickerSymbol!
    $after: String!
  ) {
    viewer {
      coinbaseAnalytics {
        blocksBySymbol(tickerSymbol: $tickerSymbol, after: $after) {
          edges {
            cursor
            node {
              id
              height
              hash
              minedBy
              time
              numTx
              size
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }
    }
  }
`;

// The component for rendering the blocks index page. Paging is only allowed via first page and next page.
export const BlockIndexPage: React.FC<{ chain: string }> = ({ chain }) => {
  return (
    <div className="container-fluid container-max-xl">
      <div className="page-header">
        <h2>Recent Blocks</h2>
        <ErrorBoundary>
          <Suspense fallback={<LoadingBubbles />}>
            <PageContent chain={chain} />
          </Suspense>
        </ErrorBoundary>
      </div>
    </div>
  );
};

// A button for page number navigation at the bottom of an index page. If onClick is not provided, assumes the button is
// active.
const PageContent: React.FC<{ chain: string }> = ({ chain }) => {
  const [state, setState] = useState({
    currentPageCursor: '',
    currentPage: 1,
  });
  const navigateToPage = (nextPage: number, nextPageCursor: string) => {
    setState({
      currentPageCursor: nextPageCursor,
      currentPage: nextPage,
    });
  };
  const data = useLazyLoadQuery<BlockIndexPageQuery_BlocksQuery>(
    blockPageQuery,
    {
      tickerSymbol: chain,
      after: state.currentPageCursor,
    }
  );
  const nextPageCursor =
    data.viewer.coinbaseAnalytics.blocksBySymbol.pageInfo.endCursor;
  const blocks = data.viewer.coinbaseAnalytics.blocksBySymbol.edges.map(
    (edge) => edge.node
  );

  return (
    <React.Fragment>
      <table className="table ">
        <thead>
          <tr>
            <th>Time</th>
            <th>Height</th>
            <th>Hash</th>
            <th>Mined by</th>
            <th className="text-right">No. TX</th>
            <th className="text-right">Size</th>
          </tr>
        </thead>
        <tbody>
          {blocks.map((block) => {
            return (
              <BlockIndexRow
                key={block.hash}
                chain={chain}
                time={block.time}
                height={`${block.height}`}
                hash={block.hash}
                minedBy={block.minedBy}
                numTxns={block.numTx}
                size={block.size}
              />
            );
          })}
        </tbody>
      </table>
      <NextPageCursorNavigator
        currentPage={state.currentPage ? state.currentPage : 1}
        nextPageCursor={nextPageCursor}
        onClick={navigateToPage}
      />
    </React.Fragment>
  );
};

// The component for rendering a single block in the index page list.
const BlockIndexRow: React.FC<{
  chain: string;
  time: string;
  height: string;
  hash: string;
  minedBy: string;
  numTxns: number;
  size: string;
}> = ({ chain, time, height, hash, minedBy, numTxns, size }) => {
  return (
    <tr>
      <td>{time}</td>
      <td className="monospace">
        <ChainLink to={`/blocks/${hash}`}>{height}</ChainLink>
      </td>
      <td width="30%" className="monospace">
        <ChainLink to={`/blocks/${hash}`}>{hash}</ChainLink>
      </td>
      <td>
        <a href={`/${chain}/metadata?name=${minedBy}`}>{minedBy}</a>
      </td>
      <td width="8%" className="text-right">
        <span className="label label-primary">{numTxns}</span>
      </td>
      <td className="text-right">
        <span className="label label-success">{size}</span>
      </td>
    </tr>
  );
};
