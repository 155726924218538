const ILEGAL_LABELS = [
  'scam',
  'ransom',
  'theft',
  'illegal',
  'darknet',
  'malware',
  'terrorism',
  'mixer',
  'laundering',
  'csam',
  'ofac',
  'weapons',
  'fincen_special_measures',
];
const SUSPICIOUS_LABELS = ['gambling', 'nokyc', 'aet'];
const LEGAL_LABELS = ['auction', 'law_enforcement'];
const REPOSITORY_LABELS = ['custodian', 'exchange', 'atm', 'processor'];
const GENERATED_LABELS = ['miner', 'pool', 'faucet'];
const GOVERNANCE_LABELS = ['masternode', 'token_team'];

export const NEGATIVE_LABELS = [...ILEGAL_LABELS, ...SUSPICIOUS_LABELS];
export const POSITIVE_LABELS = [
  ...LEGAL_LABELS,
  ...REPOSITORY_LABELS,
  ...GENERATED_LABELS,
  ...GOVERNANCE_LABELS,
];

export const HIGH_RISK_LABELS = ILEGAL_LABELS;
export const MEDIUM_RISK_LABELS = SUSPICIOUS_LABELS;
export const LOW_OR_NO_RISK_LABELS = POSITIVE_LABELS;
