/**
 * @generated SignedSource<<56a86281a5cb90bd948051d3a253531a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CoinbaseAnalyticsAddressAttributesType = "DEFAULT" | "SUCCESS" | "PRIMARY" | "WARNING" | "%future added value";
export type AddressSummaryPage_AddressSummaryQuery$variables = {
  tickerSymbol: any;
  addressId: string;
};
export type AddressSummaryPage_AddressSummaryQueryVariables = AddressSummaryPage_AddressSummaryQuery$variables;
export type AddressSummaryPage_AddressSummaryQuery$data = {
  readonly viewer: {
    readonly coinbaseAnalytics: {
      readonly addressByHashAndSymbol: {
        readonly hash: string;
        readonly numTx: number | null;
        readonly balanceAmount: {
          readonly value: any;
          readonly currency: any;
        } | null;
        readonly balanceAmountUsd: any | null;
        readonly sentAmount: {
          readonly value: any;
          readonly currency: any;
        } | null;
        readonly sentAmountUsd: any | null;
        readonly receivedAmount: {
          readonly value: any;
          readonly currency: any;
        } | null;
        readonly attributes: ReadonlyArray<{
          readonly title: string | null;
          readonly type: CoinbaseAnalyticsAddressAttributesType | null;
        } | null> | null;
        readonly receivedAmountUsd: any | null;
        readonly gainAmountUsd: any | null;
        readonly gainUsdPercent: number | null;
        readonly firstSeen: any | null;
        readonly lastSeen: any | null;
        readonly wallet: {
          readonly id: string;
          readonly hash: string;
          readonly name: string | null;
        } | null;
        readonly interactedTokens: ReadonlyArray<{
          readonly address: string | null;
          readonly name: string | null;
          readonly tickerSymbol: any | null;
        }> | null;
        readonly " $fragmentSpreads": FragmentRefs<"AdditionalInfoAddress_metadata">;
      } | null;
    };
  };
};
export type AddressSummaryPage_AddressSummaryQueryResponse = AddressSummaryPage_AddressSummaryQuery$data;
export type AddressSummaryPage_AddressSummaryQuery = {
  variables: AddressSummaryPage_AddressSummaryQueryVariables;
  response: AddressSummaryPage_AddressSummaryQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "addressId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tickerSymbol"
},
v2 = [
  {
    "kind": "Variable",
    "name": "hash",
    "variableName": "addressId"
  },
  {
    "kind": "Variable",
    "name": "tickerSymbol",
    "variableName": "tickerSymbol"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hash",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numTx",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currency",
    "storageKey": null
  }
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Amount",
  "kind": "LinkedField",
  "name": "balanceAmount",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "balanceAmountUsd",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "Amount",
  "kind": "LinkedField",
  "name": "sentAmount",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sentAmountUsd",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "Amount",
  "kind": "LinkedField",
  "name": "receivedAmount",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "CoinbaseAnalyticsAddressAttribute",
  "kind": "LinkedField",
  "name": "attributes",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "receivedAmountUsd",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gainAmountUsd",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gainUsdPercent",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstSeen",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastSeen",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "CoinbaseAnalyticsAddressWallet",
  "kind": "LinkedField",
  "name": "wallet",
  "plural": false,
  "selections": [
    (v17/*: any*/),
    (v3/*: any*/),
    (v18/*: any*/)
  ],
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "CoinbaseAnalyticsToken",
  "kind": "LinkedField",
  "name": "interactedTokens",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "address",
      "storageKey": null
    },
    (v18/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tickerSymbol",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AddressSummaryPage_AddressSummaryQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CoinbaseAnalytics",
            "kind": "LinkedField",
            "name": "coinbaseAnalytics",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CoinbaseAnalyticsAddress",
                "kind": "LinkedField",
                "name": "addressByHashAndSymbol",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "AdditionalInfoAddress_metadata"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AddressSummaryPage_AddressSummaryQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CoinbaseAnalytics",
            "kind": "LinkedField",
            "name": "coinbaseAnalytics",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CoinbaseAnalyticsAddress",
                "kind": "LinkedField",
                "name": "addressByHashAndSymbol",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CoinbaseAnalyticsAddressMetadata",
                    "kind": "LinkedField",
                    "name": "metadata",
                    "plural": false,
                    "selections": [
                      (v18/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "categories",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "urls",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "notes",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "handling",
                        "storageKey": null
                      },
                      (v17/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v17/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "532a4a277a7a64c838c6c29286fbb9c1",
    "id": null,
    "metadata": {},
    "name": "AddressSummaryPage_AddressSummaryQuery",
    "operationKind": "query",
    "text": "query AddressSummaryPage_AddressSummaryQuery(\n  $tickerSymbol: TickerSymbol!\n  $addressId: String!\n) {\n  viewer {\n    coinbaseAnalytics {\n      addressByHashAndSymbol(tickerSymbol: $tickerSymbol, hash: $addressId) {\n        hash\n        numTx\n        balanceAmount {\n          value\n          currency\n        }\n        balanceAmountUsd\n        sentAmount {\n          value\n          currency\n        }\n        sentAmountUsd\n        receivedAmount {\n          value\n          currency\n        }\n        attributes {\n          title\n          type\n        }\n        receivedAmountUsd\n        gainAmountUsd\n        gainUsdPercent\n        firstSeen\n        lastSeen\n        wallet {\n          id\n          hash\n          name\n        }\n        interactedTokens {\n          address\n          name\n          tickerSymbol\n        }\n        ...AdditionalInfoAddress_metadata\n        id\n      }\n    }\n  }\n}\n\nfragment AdditionalInfoAddress_metadata on CoinbaseAnalyticsAddress {\n  metadata {\n    name\n    categories\n    urls\n    notes\n    handling\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "ee2a09b4e0d37c74d77a3c9daf65586e";

export default node;
