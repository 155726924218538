// @ts-nocheck - this file contains a lot of compilation errors
// due to required onPress prop that do not exists SelectOption component
// TODO: Update CDS library after June
import React, { useState } from 'react';
import { createBrowserHistory } from 'history';
import { NavigationBar } from '@cbhq/cds-web/navigation';
import { ICONS } from '../util/Icons';
import { Box } from '@cbhq/cds-web/layout/Box';
import { Select } from '@cbhq/cds-web/controls/Select';
import { SelectOption } from '@cbhq/cds-web/controls/SelectOption';
import { HStack, Divider } from '@cbhq/cds-web/layout';
import { NetworkFilter } from './filters/NetworkFilter';

import InitUserLeap from '../util/InitUserLeap';
import { PopoverMenu } from '@cbhq/cds-web/overlays/PopoverMenu/PopoverMenu';
import { PopoverTrigger, SectionTitle, Tooltip } from '@cbhq/cds-web/overlays';
import { Button } from '@cbhq/cds-web/buttons';
import { useToggler } from '@cbhq/cds-web';
import { DotCount } from '@cbhq/cds-web/dots/DotCount';
import SearchInputMenuWrapper from './SearchInputMenuWrapper';
import { Link } from '@cbhq/cds-web/typography';
import TracerLogo from '../../assets/images/tracer-logo.svg';

// The top navigation bar that persists across all pages.
const NewTopNavBar = ({
  chain,
  chainConfigs,
  userConfigs,
  userleapEnvId,
  flags = { search: true },
}) => {
  const [chainValue, setChainValue] = useState(chain);
  const chainClass = chainConfigs.configs[chainValue].class; // 'Nakamoto' or 'Buterin'
  return (
    <>
      <InitUserLeap userleapEnvId={userleapEnvId} userId={userConfigs.id} />
      <NavigationBar
        children={() => {}}
        start={
          <HStack alignItems="center" gap={1}>
            {/*CA logo*/}
            <Link to={`/${chainValue}`}>
              <Box
                justifyContent="center"
                spacing={1}
                spacingEnd={3}
                width={250}
              >
                <img
                  src={TracerLogo}
                  style={{ height: '30px', width: 'auto' }}
                />
              </Box>
            </Link>

            {/*Chain Dropdown Menu*/}
            <AssetSelector
              changeChain={setChainValue}
              chain={chainValue}
              chainConfigs={chainConfigs}
            />

            {/*Search Bar*/}
            <SearchInputMenuWrapper tickerSymbol={chainValue} />
          </HStack>
        }
        end={
          <HStack>
            <ExploreMenu chain={chainValue} chainClass={chainClass} />
            <ToolsMenu
              chain={chainValue}
              chainClass={chainClass}
              userConfigs={userConfigs}
            />
            <CasesMenu chain={chainValue} userConfigs={userConfigs} />
            <ProfileMenu
              chain={chainValue}
              chainClass={chainClass}
              userConfigs={userConfigs}
            />
          </HStack>
        }
      />
    </>
  );
};

const AssetSelector = ({ changeChain, chain, chainConfigs }) => {
  const history = createBrowserHistory({ forceRefresh: true });

  function formatSelectedAsset() {
    return chainConfigs.configs[chain].name + ' (' + chain.toUpperCase() + ')';
  }

  const handleChainChange = (newChain) => {
    changeChain(newChain);
    handleRouting(newChain);
  };

  function handleRouting(newChain) {
    if (location.pathname === `/${chain}/graphs`) {
      history.push(`/${newChain}/graphs`);
    } else if (location.pathname == `/`) return undefined;
    else history.push(`/${newChain}`);
  }

  return (
    <Select
      compact
      width={250}
      value={formatSelectedAsset()}
      placeholder="Select Asset"
      onChange={handleChainChange}
      startNode={
        <Box spacingHorizontal={2}>
          <img className="chainlogo-md" src={ICONS[chain]} />
        </Box>
      }
    >
      {chainConfigs.chains.map(
        (option) =>
          chainConfigs.configs[option].enabled && (
            <SelectOption
              value={option}
              key={option}
              title={chainConfigs.configs[option].name}
              media={<img className="chainlogo-md" src={ICONS[option]} />}
            />
          )
      )}
    </Select>
  );
};

const ExploreMenu = ({ chain, chainClass }) => {
  const blocksTerm = !['fil'].includes(chain) ? 'Blocks' : 'Tipsets';
  const showAddresses = !['AccountBased'].includes(chainClass);
  const transactionsTerm = !['fil'].includes(chain)
    ? 'Transactions'
    : 'Messages';
  const showStatistics = !['AccountBased'].includes(chainClass);
  const showOperations = ['AccountBased'].includes(chainClass);
  const operationsTerm = !['fil'].includes(chain)
    ? 'Operations'
    : 'Transactions';

  const [visible, togglePopoverMenuVisibility] = useToggler(false);

  return (
    <PopoverMenu
      width={200}
      maxHeight={700}
      openMenu={togglePopoverMenuVisibility.toggleOn}
      closeMenu={togglePopoverMenuVisibility.toggleOff}
      visible={visible}
    >
      <PopoverTrigger>
        <Button
          compact
          transparent={true}
          variant="secondary"
          endIcon="caretDown"
        >
          Explorer
        </Button>
      </PopoverTrigger>
      {showStatistics && (
        <SelectOption
          compact
          value="Statistics"
          description="Statistics"
          onPress={() => {
            window.location.href = `/${chain}/statistics`;
          }}
        />
      )}
      <SelectOption
        compact
        value={blocksTerm}
        description={blocksTerm}
        onPress={() => {
          window.location.href = `/${chain}/blocks`;
        }}
      />
      <SelectOption
        value="Wallets"
        description="Wallets"
        onPress={() => {
          window.location.href = `/${chain}/wallets`;
        }}
      />
      {showAddresses && (
        <SelectOption
          compact
          value="Addresses"
          description="Addresses"
          onPress={() => {
            window.location.href = `/${chain}/addresses`;
          }}
        />
      )}
      <SelectOption
        compact
        value={transactionsTerm}
        description={transactionsTerm}
        onPress={() => {
          window.location.href = `/${chain}/transactions`;
        }}
      />
      {showOperations && (
        <SelectOption
          compact
          value={operationsTerm}
          description={operationsTerm}
          onPress={() => {
            window.location.href = `/${chain}/operations`;
          }}
        />
      )}
      {chainClass === 'Buterin' && [
        <Divider
          direction="horizontal"
          key="new-top-nav-bar-buterin-divider-1"
        />,
        <SelectOption
          key="new-top-nav-bar-buterin-select-option-1"
          compact
          value="Uncles"
          description="Uncles"
          onPress={() => {
            window.location.href = `/${chain}/uncles`;
          }}
        />,
        <SelectOption
          key="new-top-nav-bar-buterin-divider-2"
          compact
          value="Contracts"
          description="Contracts"
          onPress={() => {
            window.location.href = `/${chain}/contracts`;
          }}
        />,
        <SelectOption
          key="new-top-nav-bar-buterin-divider-3"
          compact
          value="Tokens List"
          description="Tokens List"
          onPress={() => {
            window.location.href = `/${chain}/tokens`;
          }}
        />,
        <SelectOption
          key="new-top-nav-bar-buterin-divider-4"
          compact
          value="ERC20 Transactions"
          description="ERC20 Transactions"
          onPress={() => {
            window.location.href = `/${chain}/tokens/transactions`;
          }}
        />,
      ]}
    </PopoverMenu>
  );
};

const ToolsMenu = ({ chain, chainClass, userConfigs }) => {
  const showWebSearch = !['AccountBased', 'Usdt'].includes(chainClass);
  const showAdvancedSearch = !['AccountBased'].includes(chainClass);
  const showMetadataImporter =
    userConfigs.permissions.tags &&
    !['AccountBased', 'Usdt'].includes(chainClass);

  const [visible, togglePopoverMenuVisibility] = useToggler(false);

  return (
    <PopoverMenu
      width={200}
      openMenu={togglePopoverMenuVisibility.toggleOn}
      closeMenu={togglePopoverMenuVisibility.toggleOff}
      visible={visible}
    >
      <PopoverTrigger>
        <Button
          compact
          transparent={true}
          variant="secondary"
          endIcon="caretDown"
        >
          Tools
        </Button>
      </PopoverTrigger>
      <SelectOption
        compact
        value="newGraphLink"
        description={'New ' + chain.toUpperCase() + ' graph'}
        onPress={() => {
          window.location.href = `/${chain}/graph/new`;
        }}
      />
      <SelectOption
        compact
        value="mygraphs"
        description={'My ' + chain.toUpperCase() + ' graphs'}
        onPress={() => {
          window.location.href = `/${chain}/graphs`;
        }}
      />
      <Divider direction="horizontal" />
      <SelectOption
        compact
        value="customWallets"
        description="Custom Wallets"
        onPress={() => {
          window.location.href = `/${chain}/custom_wallets`;
        }}
      />
      <Divider direction="horizontal" />
      <SelectOption
        compact
        value="batchRiskScore"
        description="Batch Risk Score"
        onPress={() => {
          window.location.href = `/${chain}/batch_risks/new`;
        }}
      />
      {showAdvancedSearch ||
        showWebSearch ||
        (showMetadataImporter && <Divider direction="horizontal" />)}
      {showAdvancedSearch && (
        <SelectOption
          compact
          value="advancedSearch"
          description="Advanced search"
          onPress={() => {
            window.location.href = `/${chain}/search/advanced_search`;
          }}
        />
      )}
      {showWebSearch && (
        <SelectOption
          compact
          value="webSearch"
          description="Web search"
          onPress={() => {
            window.location.href = `/${chain}/crawler_jobs/new`;
          }}
        />
      )}
      {showMetadataImporter && (
        <SelectOption
          compact
          value="importMetadata"
          description="Import metadata"
          onPress={() => {
            window.location.href = `/${chain}/metadata_bulk_importer/new`;
          }}
        />
      )}
    </PopoverMenu>
  );
};

const CasesMenu = ({ chain, userConfigs }) => {
  const casesTerm = userConfigs.license.type === 'gov' ? 'Cases' : 'Clients';

  const [visible, togglePopoverMenuVisibility] = useToggler(false);

  return (
    <PopoverMenu
      width={200}
      openMenu={togglePopoverMenuVisibility.toggleOn}
      closeMenu={togglePopoverMenuVisibility.toggleOff}
      visible={visible}
    >
      <PopoverTrigger>
        <Button
          compact
          transparent={true}
          variant="secondary"
          endIcon="caretDown"
        >
          {casesTerm}
        </Button>
      </PopoverTrigger>

      <SelectOption
        compact
        value={casesTerm}
        description={casesTerm}
        onPress={() => {
          window.location.href = `/${chain}/cases`;
        }}
      />
      <SelectOption
        compact
        value="Bookmarks"
        description="Bookmarks"
        onPress={() => {
          window.location.href = `/${chain}/watchlists`;
        }}
      />
    </PopoverMenu>
  );
};

const ProfileMenu = ({ chain, chainClass, userConfigs }) => {
  const [visible, togglePopoverMenuVisibility] = useToggler(false);

  return (
    <PopoverMenu
      width={250}
      maxHeight={700}
      openMenu={togglePopoverMenuVisibility.toggleOn}
      closeMenu={togglePopoverMenuVisibility.toggleOff}
      visible={visible}
    >
      <PopoverTrigger>
        <Button
          compact
          transparent={true}
          variant="secondary"
          endIcon="caretDown"
        >
          <HStack gap={1} alignItems="center">
            {userConfigs.name}
            {userConfigs.running_jobs > 0 && (
              <DotCount count={userConfigs.running_jobs} />
            )}
          </HStack>
        </Button>
      </PopoverTrigger>
      <SelectOption
        compact
        value="Account"
        description="Account"
        onPress={() => {
          window.location.href = `/users/edit`;
        }}
      />
      <SelectOption
        compact
        value="Group"
        description="Group"
        onPress={() => {
          window.location.href = `/groups/${userConfigs.group.id}`;
        }}
      />
      <SelectOption
        compact
        value="License"
        description="License"
        onPress={() => {
          window.location.href = `/licenses/${userConfigs.license.id}`;
        }}
      />
      <SelectOption
        disabled={!userConfigs.policies.api_key_index}
        compact
        key="apiKeys"
        value="apiKeys"
        description={
          userConfigs.policies.api_key_index
            ? 'API Keys'
            : 'API Keys (Upgrade License)'
        }
        onPress={() => {
          window.location.href = `/api_keys`;
        }}
      />
      <Divider direction="horizontal" />
      <SelectOption
        compact
        priority="end"
        value="Group"
        description="Background Jobs"
        onPress={() => {
          window.location.href = `/${chain}/jobs`;
        }}
        media={
          userConfigs.running_jobs > 0 && (
            <DotCount count={userConfigs.running_jobs} />
          )
        }
      />
      <Divider direction="horizontal" />
      <SelectOption
        compact
        value="addressesClustering"
        description="Addresses Clustering"
        onPress={() => {
          window.location.href = `/${chain}/clustering_addresses`;
        }}
      />
      {chainClass === 'Nakamoto' && [
        <SelectOption
          key="new-top-navbar-nakamoto-option-1"
          compact
          value="forcedClustering"
          description="Forced Clustering"
          onPress={() => {
            window.location.href = `/${chain}/clustering_force`;
          }}
        />,
        <SelectOption
          key="new-top-navbar-nakamoto-option-2"
          compact
          value="clusterExtraction"
          description="Cluster Extraction"
          onPress={() => {
            window.location.href = `/${chain}/clustering_extract`;
          }}
        />,
      ]}
      {userConfigs.permissions.admin && [
        <Divider direction="horizontal" key="new-top-navbar-admin-divider-1" />,
        <SectionTitle
          text="Administration"
          key="new-top-navbar-admin-section-title-1"
        />,
        userConfigs.policies.user_index && (
          <SelectOption
            compact
            value="Users"
            description="Users"
            onPress={() => {
              window.location.href = `/users`;
            }}
          />
        ),
        userConfigs.policies.group_index && (
          <SelectOption
            compact
            value="Groups"
            description="Groups"
            onPress={() => {
              window.location.href = `/groups`;
            }}
          />
        ),
        userConfigs.policies.license_index && (
          <SelectOption
            compact
            value="Licenses"
            description="Licenses"
            onPress={() => {
              window.location.href = `/licenses`;
            }}
          />
        ),
        userConfigs.policies.sidekiq_index && (
          <SelectOption
            compact
            value="sidekiqJob"
            description="Sidekiq Job"
            onPress={() => {
              window.location.href = `/${chain}/sidekiq`;
            }}
          />
        ),
        <SelectOption
          key="new-top-navbar-admin-select-option-1"
          compact
          value="crawler"
          description="Crawler"
          onPress={() => {
            window.location.href = userConfigs.crawler_external_url;
          }}
          target="_blank"
        />,
      ]}
      <Divider direction="horizontal" />
      {/* TODO(akshi): Fix Styling */}
      <a rel="nofollow" data-method="delete" href="/users/sign_out">
        <SelectOption value="logout" description="Log out" />
      </a>
    </PopoverMenu>
  );
};

export default NewTopNavBar;
