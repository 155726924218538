/**
 * @generated SignedSource<<25181fd825cde9c2e029c4b0861cc749>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WalletPage_OsintQuery$variables = {
  tickerSymbol: any;
  walletId: string;
  after: string;
};
export type WalletPage_OsintQueryVariables = WalletPage_OsintQuery$variables;
export type WalletPage_OsintQuery$data = {
  readonly viewer: {
    readonly coinbaseAnalytics: {
      readonly walletByHashAndSymbol: {
        readonly id: string;
        readonly hash: string;
        readonly osint: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly " $fragmentSpreads": FragmentRefs<"OsintTable_OsintTableRowFragment">;
            } | null;
          }>;
          readonly pageInfo: {
            readonly endCursor: string;
            readonly hasNextPage: boolean;
          };
        } | null;
      } | null;
    };
  };
};
export type WalletPage_OsintQueryResponse = WalletPage_OsintQuery$data;
export type WalletPage_OsintQuery = {
  variables: WalletPage_OsintQueryVariables;
  response: WalletPage_OsintQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tickerSymbol"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "walletId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "hash",
    "variableName": "walletId"
  },
  {
    "kind": "Variable",
    "name": "tickerSymbol",
    "variableName": "tickerSymbol"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hash",
  "storageKey": null
},
v6 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "asset",
    "variableName": "tickerSymbol"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "WalletPage_OsintQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CoinbaseAnalytics",
            "kind": "LinkedField",
            "name": "coinbaseAnalytics",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "CoinbaseAnalyticsWallet",
                "kind": "LinkedField",
                "name": "walletByHashAndSymbol",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": (v6/*: any*/),
                    "concreteType": "CoinbaseAnalyticsOsintConnection",
                    "kind": "LinkedField",
                    "name": "osint",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CoinbaseAnalyticsOsintEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CoinbaseAnalyticsOsint",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "OsintTable_OsintTableRowFragment"
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "WalletPage_OsintQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CoinbaseAnalytics",
            "kind": "LinkedField",
            "name": "coinbaseAnalytics",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "CoinbaseAnalyticsWallet",
                "kind": "LinkedField",
                "name": "walletByHashAndSymbol",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": (v6/*: any*/),
                    "concreteType": "CoinbaseAnalyticsOsintConnection",
                    "kind": "LinkedField",
                    "name": "osint",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CoinbaseAnalyticsOsintEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CoinbaseAnalyticsOsint",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "title",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "firstSeen",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "domain",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "url",
                                "storageKey": null
                              },
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "103e413bf7b3193de19b78be4f7b129f",
    "id": null,
    "metadata": {},
    "name": "WalletPage_OsintQuery",
    "operationKind": "query",
    "text": "query WalletPage_OsintQuery(\n  $tickerSymbol: TickerSymbol!\n  $walletId: String!\n  $after: String!\n) {\n  viewer {\n    coinbaseAnalytics {\n      walletByHashAndSymbol(hash: $walletId, tickerSymbol: $tickerSymbol) {\n        id\n        hash\n        osint(asset: $tickerSymbol, after: $after) {\n          edges {\n            node {\n              ...OsintTable_OsintTableRowFragment\n              id\n            }\n          }\n          pageInfo {\n            endCursor\n            hasNextPage\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment OsintTable_OsintTableRowFragment on CoinbaseAnalyticsOsint {\n  title\n  firstSeen\n  domain\n  url\n}\n"
  }
};
})();

(node as any).hash = "a34e62c04ef09bf5c9e2844ab6620150";

export default node;
