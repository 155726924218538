import React from 'react';

const FundsTrackingButton: React.FC<{ chain; hash }> = (props) => {
  return (
    <>
      <FundsTrackingModalTrigger />
      <Modal {...props} />
    </>
  );
};

const Modal: React.FC<{ chain; hash }> = (props) => {
  const { chain, hash } = props;
  const url = `/${chain}/links/${hash}`;
  return (
    <div className="modal fade funds-tracking-parameters">
      <div className="modal-dialog modal-sm" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 className="modal-title">Tracking direction</h4>
          </div>
          <div className="modal-body">
            <form id="ft-parameters" method="get" action={url}>
              <div className="form-group row">
                <div className="col-xs-12">
                  <select className="form-control" name="d" id="track_type">
                    <option value="received">Incoming funds</option>
                    <option selected value="sent">
                      Outgoing funds
                    </option>
                  </select>
                  <input type="hidden" value="400" name="h" />
                  <input type="hidden" value="0.0" name="t" />
                  <input type="hidden" value="leaf" name="display" />
                  <input type="hidden" value="wallet" name="g" />
                </div>
              </div>
            </form>
            <div>
              Incoming funds: Search known the first known wallets and
              uncategorized miners identified as sources of the incoming funds.
            </div>
            <div>
              Outgoing funds: Search known wallets where outgoing funds landed
              first and all the uncategorized wallets where there is still some
              unspent part of such funds.
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              form="ft-parameters"
            >
              Calculate!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const FundsTrackingModalTrigger = () => (
  <a
    data-toggle="modal"
    data-magic-tooltip="Edit"
    data-target=".funds-tracking-parameters"
    title="Edit"
    data-original-title="Edit"
    className="btn btn-basic"
    style={{ width: '100%' }}
  >
    Funds Tracking
  </a>
);

export default FundsTrackingButton;
