import React, { useCallback, useEffect } from 'react';

// Note: The caller should wrap whatever function is passed here with
// useCallback so the function is the same and does not make useEffect
// update multiple times.
const BootstrapSwitch: React.FC<{
  identifier: string;
  onSwitch: () => void;
  isChecked: boolean;
  dataOn: string;
  dataOff: string;
  width?: string;
}> = (props) => {
  const onChange = useCallback(() => {
    props.onSwitch();
  }, []);

  useEffect(() => {
    $(`input[type=checkbox][data-toggle^=toggle][id=${props.identifier}]`)
      .bootstrapToggle()
      .change(onChange);
  }, [onChange]);
  return (
    <input
      id={props.identifier}
      type="checkbox"
      defaultChecked={props.isChecked}
      data-toggle="toggle"
      data-size="mini"
      data-width={props.width ? props.width : '50px'}
      data-on={props.dataOn}
      data-off={props.dataOff}
    ></input>
  );
};

export default BootstrapSwitch;
