import React from 'react';
import { Fallback } from '@cbhq/cds-web/layout';

export const ExpandableSectionFallback: React.FC = () => {
  return (
    <Fallback
      spacingVertical={1}
      spacingHorizontal={1}
      width="100%"
      height={40}
      shape="squircle"
    />
  );
};
