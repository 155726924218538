/**
 * @generated SignedSource<<30b885c4523995a09c79ab75fc4b1199>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TransactionAdditionalInfo_metadata$data = {
  readonly metadata: {
    readonly name: string;
    readonly urls: ReadonlyArray<string | null> | null;
    readonly notes: string | null;
  } | null;
  readonly " $fragmentType": "TransactionAdditionalInfo_metadata";
};
export type TransactionAdditionalInfo_metadata = TransactionAdditionalInfo_metadata$data;
export type TransactionAdditionalInfo_metadata$key = {
  readonly " $data"?: TransactionAdditionalInfo_metadata$data;
  readonly " $fragmentSpreads": FragmentRefs<"TransactionAdditionalInfo_metadata">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TransactionAdditionalInfo_metadata",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CoinbaseAnalyticsTransactionMetadata",
      "kind": "LinkedField",
      "name": "metadata",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "urls",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "notes",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CoinbaseAnalyticsTransaction",
  "abstractKey": null
};

(node as any).hash = "c042cae803428534cc640493296e779b";

export default node;
