import React from 'react';
import { Sidebar as CBDSSidebar } from '@cbhq/cds-web/navigation/Sidebar';
import { SidebarItem } from '@cbhq/cds-web/navigation';
import { Box, VStack } from '@cbhq/cds-web/layout';
import {
  FeatureFlagProvider,
  Pressable,
  ThemeProvider,
} from '@cbhq/cds-web/system';
import TracerLogo from '../../../assets/images/tracer-logo.svg';
import { LogoMark } from '@cbhq/cds-web/icons/LogoMark';
import { useWindowWidth } from '@react-hook/window-size';
import { IconButton } from '@cbhq/cds-web/buttons/IconButton';

function isCurrentRouteActive(text, partial) {
  return text.toLowerCase().indexOf(partial.toLowerCase()) > -1;
}

export const routingContext = () => {
  const path = window.location.pathname;

  const routeMap = {
    graph: ['/graph'],
    home: [`/cases`, `/watchlists`, `/jobs`],
    explore: [
      '/portal/explore',
      '/statistics',
      '/blocks',
      '/wallets',
      '/transactions',
      '/addresses',
      '/metadata',
      '/eth/contracts',
      '/eth/uncles',
      '/eth/tokens',
      '/eth/tokens/transactions',
      '/portal/search',
      '/share',
    ],
    tools: [
      '/portal/tools',
      '/batch_risks/',
      '/search/advanced_search',
      '/lightning_channels',
      '/crawler_jobs/',
      '/custom_wallets',
      '/clustering',
      '/links/',
      '/paths/',
    ],
    settings: [
      '/portal/settings',
      '/settings',
      '/groups',
      '/licenses',
      '/users',
      '/api_keys',
    ],
  };

  const activeRoutes = {
    graph: routeMap.graph.some((element) => {
      if (path.includes('/share')) return false;
      return isCurrentRouteActive(path, element);
    }),
    home: routeMap.home.some((element) => {
      return isCurrentRouteActive(path, element);
    }),
    explore: routeMap.explore.some((element) => {
      return isCurrentRouteActive(path, element);
    }),
    tools: routeMap.tools.some((element) => {
      return isCurrentRouteActive(path, element);
    }),
    settings: routeMap.settings.some((element) => {
      return isCurrentRouteActive(path, element);
    }),
  };

  return activeRoutes;
};

const Sidebar = ({ chainConfigs }) => {
  const onPress = (path: string) => {
    window.location.href = path;
  };

  const path = window.location.pathname;
  const homePath =
    (chainConfigs.chains.includes(path.split('/')[1]) && !path.split('/')[2]) ||
    path === '/';

  const activeRoute = routingContext();

  return (
    <FeatureFlagProvider frontierButton frontierColor>
      <ThemeProvider>
        <VStack background maxHeight="100vh" height="100%">
          <Box width={240}>
            <CBDSSidebar
              autoCollapse
              logo={
                <Pressable
                  style={{
                    maxWidth: '240px',
                    display: 'flex',
                    justifyContent: 'left',
                    alignItems: 'center',
                    paddingLeft: '0',
                  }}
                  backgroundColor="transparent"
                  onPress={() => {
                    window.location.href = '/';
                  }}
                >
                  {useWindowWidth() <= 767 ? (
                    <LogoMark size={32} />
                  ) : (
                    <img
                      src={TracerLogo}
                      style={{ height: '35px', width: 'auto' }}
                    />
                  )}
                </Pressable>
              }
            >
              <SidebarItem
                title="My work"
                icon="home"
                active={activeRoute.home || homePath}
                onPress={() => onPress('/')}
              />
              <SidebarItem
                title="Explore"
                icon="chartBar"
                active={activeRoute.explore}
                onPress={() => onPress('/portal/explore')}
              />
              <SidebarItem
                title="Tools"
                icon="chartCandles"
                active={activeRoute.tools}
                onPress={() => onPress('/portal/tools')}
              />
              <SidebarItem
                title="Settings"
                icon="settings"
                active={activeRoute.settings}
                onPress={() => onPress('/portal/settings')}
              />
            </CBDSSidebar>
          </Box>
        </VStack>
        <div
          style={{
            position: 'absolute',
            bottom: '0',
            padding: '0 0 2rem 2rem',
          }}
        >
          <IconButton
            onClick={() =>
              window.open(
                'mailto:tracer-support@coinbase.com',
                '_blank',
                'noopener,noreferrer'
              )
            }
            name="comment"
            variant="secondary"
          />
        </div>
      </ThemeProvider>
    </FeatureFlagProvider>
  );
};

export default Sidebar;
