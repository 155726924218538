import React from 'react';

const AttributeLabel: React.FC<{ label }> = ({ label }) => {
  /*
  labels.each do |label|
      type = 'default'
      type = 'success' if label.eql? 'utxo'
      type = 'info' if ['zero', 'op_return'].include? label
      type = 'warning' if ['invalid_address', 'high_fee', 'selfsend'].include? label
      type = 'primary' if ['coinbase', 'selfchange', 'escrow'].include? label
      type = 'danger' if ['mixer', 'shared', 'joinmarket', 'wasabi'].include? label

      output += "<span class=\"label label-#{type}\">#{label}</span> "
    end
  */
  const labelMap = {
    utxo: 'success',
    zero: 'info',
    op_return: 'info',
    invalid_address: 'warning',
    high_fee: 'warning',
    selfsend: 'warning',
    coinbase: 'primary',
    selfchange: 'primary',
    escrow: 'primary',
    mixer: 'danger',
    shared: 'danger',
    joinmarket: 'danger',
    wasabi: 'danger',
  };
  const type = labelMap[label] || 'default';
  return <span className={`label label-${type}`}>{label}</span>;
};

export default AttributeLabel;
