import { graphql, useFragment } from '@cbhq/data-layer';
import React, { Suspense, useState } from 'react';
import { ErrorBoundary } from './ErrorBoundary';
import { OsintTable_OsintTableRowFragment$key } from './__generated__/OsintTable_OsintTableRowFragment.graphql';
import { NextPageCursorNavigator } from './NextPageCursorNavigator';
import ExpandableSection from './ExpandableSection';
import { ExpandableSectionFallback } from './ExpandableSectionFallback';

const osintTableFragment = graphql`
  fragment OsintTable_OsintTableRowFragment on CoinbaseAnalyticsOsint {
    title
    firstSeen
    domain
    url
  }
`;

interface IOsintTableProps extends INoResultsFoundProps {
  fetchOsintConnection: Function;
}

// A collapsible table displaying osint data.
// fetchOsintConnection takes a cursor string as the only argument and returns a transaction connection object.
export const OsintTable = (props: IOsintTableProps) => {
  return (
    <ExpandableSection sectionTitle="OSINT">
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ErrorBoundary>
          <Suspense fallback={<ExpandableSectionFallback />}>
            <DataTable {...props} />
          </Suspense>
        </ErrorBoundary>
      </div>
    </ExpandableSection>
  );
};

// The table shown once the data is fetched.
const DataTable = (props: IOsintTableProps) => {
  const [state, setState] = useState({
    pageCursor: '',
    nextPage: '',
    page: 1,
  });
  const osintConnection = props.fetchOsintConnection(state.pageCursor);
  if (state.nextPage !== osintConnection.pageInfo.endCursor) {
    setState((prevState) => {
      return {
        ...prevState,
        nextPage: osintConnection.pageInfo.endCursor,
      };
    });
  }
  const hasOsintResults = osintConnection.edges.length > 0;

  if (hasOsintResults) {
    return (
      <table className="table table-striped">
        <tbody>
          <tr>
            <td width={'20%'} align={'left'}>
              First Seen
            </td>
            <td width={'20%'} align={'left'}>
              Domain
            </td>
            <td width={'60%'} align={'left'}>
              Link
            </td>
          </tr>
          {osintConnection.edges.map((osintEdge, key) => {
            return <OsintTableRow osintRef={osintEdge.node} key={``} />;
          })}
          <tr>
            <td align={'center'} colSpan={3}>
              <NextPageCursorNavigator
                currentPage={state.page ? state.page : 1}
                nextPageCursor={state.nextPage}
                onClick={() => {
                  setState((prevState) => {
                    const prevStateHadNextPage = prevState.nextPage.length > 0;
                    return {
                      ...prevState,
                      page: prevStateHadNextPage
                        ? prevState.page + 1
                        : prevState.page,
                      pageCursor: prevStateHadNextPage
                        ? prevState.nextPage
                        : prevState.pageCursor,
                    };
                  });
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
  return <NoResultsFound {...props} />;
};

interface INoResultsFoundProps {
  chain: string;
  type: 'wallet' | 'address';
  data: string;
}

const NoResultsFound = (props: INoResultsFoundProps) => {
  const { chain, type, data } = props;
  return (
    <div style={{ marginTop: 12 }}>
      {type === 'address' ? (
        <>
          <span>No results found. Run a&nbsp;</span>
          <a
            data-method="post"
            href={`/${chain}/crawler_jobs?input_string=${data}`}
          >
            Web Search
          </a>
          <span>?</span>
        </>
      ) : (
        <span>No results found.</span>
      )}
    </div>
  );
};

const OsintTableRow: React.FC<{
  osintRef: OsintTable_OsintTableRowFragment$key;
  key: string | number;
}> = (props) => {
  const osintData = useFragment(osintTableFragment, props.osintRef);
  return (
    <tr key={osintData.url}>
      <td>{osintData.firstSeen}</td>
      <td>{osintData.domain}</td>
      <td>
        <a href={osintData.url}>{osintData.url}</a>
      </td>
    </tr>
  );
};
