import React from 'react';
import { useState } from 'react';

const LinkAnalysisButton: React.FC<{ chain; hash; canGroupByType }> = (
  props
) => {
  return (
    <>
      <LinkAnalysisModalTrigger />
      <Modal {...props} />
    </>
  );
};

const Modal: React.FC<{ chain; hash; canGroupByType }> = (props) => {
  const { chain, hash, canGroupByType } = props;
  const [hops, setHops] = useState(1);
  const [threshold, setThreshold] = useState(0.1);
  const url = `/${chain}/links/${hash}`;

  return (
    <div className="modal fade link-parameters">
      <div className="modal-dialog modal-sm" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 className="modal-title">Analysis Parameters</h4>
          </div>
          <div className="modal-body">
            <form id="la-parameters" method="get" action={url}>
              <div className="form-group row">
                <label className="col-xs-4 col-form-label form-label">
                  Direction
                </label>
                <div className="col-xs-8">
                  <select className="form-control" name="d">
                    <option selected>Received</option>
                    <option>Sent</option>
                  </select>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xs-4 col-form-label form-label">
                  Hops
                </label>
                <div className="col-xs-8">
                  <input
                    className="form-control"
                    type="number"
                    value={hops}
                    name="h"
                    min="0"
                    step="0.01"
                    onChange={(event) => {
                      setHops(event.target.valueAsNumber);
                    }}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xs-4 col-form-label form-label">
                  Threshold
                </label>
                <div className="col-xs-8">
                  <div className="input-group">
                    <input
                      className="form-control"
                      type="number"
                      value={threshold}
                      name="t"
                      min="0"
                      max="100"
                      step="0.01"
                      onChange={(event) => {
                        setThreshold(event.target.valueAsNumber);
                      }}
                    />
                    <div className="input-group-addon">%</div>
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xs-4 col-form-label form-label">
                  Format
                </label>
                <div className="col-xs-8">
                  <select className="form-control" name="display">
                    <option selected value="table">
                      Table
                    </option>
                  </select>
                </div>
              </div>

              {canGroupByType && (
                <div className="form-group row">
                  <label className="col-xs-4 col-form-label form-label">
                    Group by
                  </label>
                  <div className="col-xs-8">
                    <select className="form-control" name="g">
                      <option selected value="wallet">
                        Wallets
                      </option>
                      <option value="address">Addresses</option>
                    </select>
                  </div>
                </div>
              )}
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              form="la-parameters"
            >
              Calculate!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const LinkAnalysisModalTrigger = () => (
  <a
    data-toggle="modal"
    data-magic-tooltip="Edit"
    data-target=".link-parameters"
    title="Edit"
    data-original-title="Edit"
    className="btn btn-basic"
    style={{ width: '100%' }}
  >
    Link Analysis
  </a>
);

export default LinkAnalysisButton;
