import React, {
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Route,
  NavLink,
  useParams,
  useRouteMatch,
  useHistory,
} from 'react-router-dom';
import HeaderBlock from '../../components/HeaderBlock';
import HeaderBlocks from '../../components/HeaderBlocks';
import { graphql } from '@cbhq/data-layer';
import { useLazyLoadQuery } from 'react-relay';
import { OsintTable } from '../../components/OsintTable';
import { WalletTransactions } from './WalletTransactions';
import { WalletPage_SummaryQuery } from './__generated__/WalletPage_SummaryQuery.graphql';
import { WalletPage_OsintQueryResponse } from './__generated__/WalletPage_OsintQuery.graphql';
import { formatAmount, formatUsd } from '../../util/AmountUtils';
import ExpandableSection from '../../components/ExpandableSection';
import { WalletAddresses } from './WalletAddresses';
import ReportButton from '../../components/ReportButton';
import {
  HeaderBreadcrumbBlock,
  HeaderBreadcrumbs,
} from '../../components/HeaderBreadcrumbs';
import BookmarksPanel from '../../components/BookmarksPanel';
import { PageDataContext } from '../../util/PageDataContext';
import CasesPanel from '../../components/CasesPanel';
import GraphsPanel from '../../components/GraphsPanel';
import AdditionalInfo from '../../components/AdditionalInfo';
import LinkAnalysisButton from '../../components/LinkAnalysisButton';
import { HStack, VStack } from '@cbhq/cds-web/layout';
import FundsTrackingButton from '../../components/FundsTrackingButton';
import Interactions from '../../components/Interactions';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { LoadingBubbles } from '../../components/LoadingBubbles';
import { ListCellFallback } from '@cbhq/cds-web/cells';
import RiskScoreLabelWrapper from '../../components/RiskScoreLabelWrapper';
import { ExpandableSectionFallback } from '../../components/ExpandableSectionFallback';
import TimePatternCharts from '../../components/TimePatternCharts';
import { TabNavigation } from '@cbhq/cds-web/tabs';
import { useToggler } from '@cbhq/cds-web';
import { Button } from '@cbhq/cds-web/buttons';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@cbhq/cds-web/overlays';
import { PortalProvider } from '@cbhq/cds-web/overlays/PortalProvider';
import { Checkbox } from '@cbhq/cds-web/controls';
import { TextBody } from '@cbhq/cds-web/typography';
import { useLocalStorage } from 'usehooks-ts';
import { analysisService } from '../../services';
import { useToast } from '@cbhq/cds-web/overlays/useToast';

const walletOsintQuery = graphql`
  query WalletPage_OsintQuery(
    $tickerSymbol: TickerSymbol!
    $walletId: String!
    $after: String!
  ) {
    viewer {
      coinbaseAnalytics {
        walletByHashAndSymbol(hash: $walletId, tickerSymbol: $tickerSymbol) {
          id
          hash
          osint(asset: $tickerSymbol, after: $after) {
            edges {
              node {
                ...OsintTable_OsintTableRowFragment
              }
            }
            pageInfo {
              endCursor
              hasNextPage
            }
          }
        }
      }
    }
  }
`;

const walletSummaryQuery = graphql`
  query WalletPage_SummaryQuery(
    $tickerSymbol: TickerSymbol!
    $walletId: String!
  ) {
    viewer {
      coinbaseAnalytics {
        walletByHashAndSymbol(hash: $walletId, tickerSymbol: $tickerSymbol) {
          hash
          displayHash
          name
          lastSeen
          clusterization
          transactionsCount
          balanceAmount {
            value
            currency
          }
          balanceAmountUsd
          receivedAmount {
            value
            currency
          }
          receivedAmountUsd
          sentAmount {
            value
            currency
          }
          sentAmountUsd
          gainUsdPercent
          gainAmountUsd
          behavior
          numAddresses
          ...AdditionalInfoWallet_metadata
        }
      }
    }
  }
`;

type IWallet =
  WalletPage_SummaryQuery['response']['viewer']['coinbaseAnalytics']['walletByHashAndSymbol'];

export const WalletPage: React.FC<{ chain: string }> = (props) => {
  const { hash } = useParams();
  const { url } = useRouteMatch();
  const { userConfigs } = useContext(PageDataContext);
  const history = useHistory();

  const wallet = useLazyLoadQuery<WalletPage_SummaryQuery>(walletSummaryQuery, {
    tickerSymbol: props.chain,
    walletId: hash,
  }).viewer.coinbaseAnalytics.walletByHashAndSymbol;

  const [primary, setPrimary] = useState('');

  const tabs = useMemo(
    () => [
      { id: 'summary_tab', label: 'Summary' },
      { id: 'addresses_tab', label: 'Addresses' },
      { id: 'transactions_tab', label: 'Transactions' },
    ],
    []
  );

  useEffect(() => {
    const currentPath = window.location.pathname;

    if (currentPath.includes('/addresses')) {
      setPrimary('addresses_tab');
    } else if (currentPath.includes('/transactions')) {
      setPrimary('transactions_tab');
    } else {
      setPrimary('summary_tab');
    }
  }, []);

  const handleRedirect = useCallback(
    (newPath) => {
      setPrimary(newPath);

      if (newPath === 'summary_tab') {
        history.push(url);
      }
      if (newPath === 'addresses_tab') {
        history.push(`${url}/addresses`);
      }
      if (newPath === 'transactions_tab') {
        history.push(`${url}/transactions`);
      }
    },
    [primary, tabs]
  );

  const blocks = [
    <HeaderBreadcrumbBlock
      key="wallet-page-header-breadcrumb-1"
      link={wallet.name}
      hash={wallet.name}
      label={'Wallet'}
      riskLabel={
        <ErrorBoundary errorChild={<span />}>
          <Suspense fallback={<ListCellFallback description compact />}>
            <RiskScoreLabelWrapper
              chain={props.chain}
              hash={hash}
              customKey={wallet.name}
              type="wallet"
              useCDScolors
            />
          </Suspense>
        </ErrorBoundary>
      }
    />,
  ];
  return (
    <>
      {userConfigs.license.new_ui_navigation && (
        <HStack
          spacingHorizontal={3}
          spacingVertical={1}
          borderedBottom
          dangerouslySetStyle={{ margin: '0 -2rem', paddingBottom: 0 }}
        >
          <TabNavigation
            value={primary}
            tabs={tabs}
            onChange={handleRedirect}
          />
        </HStack>
      )}
      <div className="container-fluid" style={{ maxWidth: 1700 }}>
        <HeaderBreadcrumbs blocks={wallet && blocks} />

        {!userConfigs.license.new_ui_navigation && (
          <ul className="nav nav-pills">
            <li>
              <NavLink to={url} exact>
                <b>Summary</b>
              </NavLink>
            </li>
            <li>
              <NavLink to={`${url}/addresses`} exact>
                <b>Addresses</b>
              </NavLink>
            </li>
            <li>
              <NavLink to={`${url}/transactions`} exact>
                <b>Transactions</b>
              </NavLink>
            </li>
          </ul>
        )}

        <WalletDetails wallet={wallet} chain={props.chain} />
      </div>
    </>
  );
};

const NAKAMOTO_CHAINS = ['btc', 'bch', 'zec', 'ltc', 'dash'];
const isNakamotoChain = (chain: string) =>
  NAKAMOTO_CHAINS.includes(chain.toLowerCase());

const createNewBehaviorAnalysis = (
  chain: string,
  walletId: string,
  toggleModalOff,
  onSucess,
  onFail
) => {
  try {
    analysisService.newBehaviorAnalysis(chain, walletId);
    onSucess();
  } catch (error) {
    onFail();
  } finally {
    toggleModalOff();
  }
};

const MODAL_LOCAL_STORAGE_KEY = 'behavior_analysis_modal_unshow';

const BehaviorAnalysisModal = ({
  chain,
  visible,
  toggleModalOff,
  onActionPress,
}) => {
  const [isChecked, { toggle: toggleCheckBox }] = useToggler(false);
  const onValueChange = (event) => {
    toggleCheckBox();
    window.localStorage.setItem(
      MODAL_LOCAL_STORAGE_KEY,
      String(event.target.checked)
    );
  };

  return (
    <PortalProvider>
      <Modal visible={visible} onRequestClose={toggleModalOff}>
        <ModalHeader title="Behavioral Analysis" />
        <ModalBody>
          <TextBody as="p" spacingBottom={2}>
            Behavioral analysis can help determine if a wallet or address is
            automated.
          </TextBody>
          <TextBody as="p" spacingBottom={4}>
            Behavioral analyses run in background. You&apos;ll get an email
            notification when it&apos;s done. You can also find it on your
            &nbsp;<a href={`/${chain}/jobs`}>background</a> jobs page.
          </TextBody>
          <Checkbox onChange={onValueChange} checked={isChecked}>
            Don&apos;t show this message again
          </Checkbox>
        </ModalBody>
        <ModalFooter
          primaryAction={<Button onPress={onActionPress}>Run analysis</Button>}
          secondaryAction={
            <Button variant="secondary" onPress={toggleModalOff}>
              Cancel
            </Button>
          }
        />
      </Modal>
    </PortalProvider>
  );
};

// The section of the page that needs to wait for transaction data to asynchronously load.
const WalletDetails: React.FC<{
  chain: string;
  wallet: IWallet;
}> = ({ chain, wallet }) => {
  const { path } = useRouteMatch();
  const { graphs, cases, userConfigs } = useContext(PageDataContext);
  const [isModalVisible, { toggleOn, toggleOff }] = useToggler();
  const toast = useToast();
  const storedValue = window.localStorage.getItem(MODAL_LOCAL_STORAGE_KEY);
  const behaviorAnalysisModalUnshow = storedValue
    ? JSON.parse(storedValue)
    : false;
  const canShowBehaviorAnalysis = isNakamotoChain(chain);
  const onSucess = () =>
    toast.show('Job created successfully', {
      action: {
        label: 'View',
        onPress: () => (window.location.href = `/${chain}/jobs`),
      },
    });

  const onFail = () => toast.show('Error on job creation');

  return (
    <>
      <div className="row">
        <div className="col-md-9">
          <HeaderBlocks
            blocks={[
              <HeaderBlock
                key="wallet-page-header-block-1"
                title="Balance"
                body={formatAmount(wallet?.balanceAmount, chain)}
                footer={formatUsd(wallet?.balanceAmountUsd as string)}
              />,
              <HeaderBlock
                key="wallet-page-header-block-2"
                title="Received"
                body={formatAmount(wallet?.receivedAmount, chain)}
                footer={formatUsd(wallet.receivedAmountUsd as string)}
              />,
              <HeaderBlock
                key="wallet-page-header-block-3"
                title="Sent"
                body={formatAmount(wallet.sentAmount, chain)}
                footer={formatUsd(wallet.sentAmountUsd as string)}
              />,
              <HeaderBlock
                key="wallet-page-header-block-4"
                title="Capital Gain"
                body={`${wallet.gainUsdPercent} %`}
                footer={formatUsd(wallet.gainAmountUsd as string)}
              />,
            ]}
          />
          <WalletDetailTables path={path} wallet={wallet} chain={chain} />
        </div>
        <div className="col-md-3 sidebar" style={{ marginTop: 40 }}>
          <BookmarksPanel
            chain={chain}
            hash={wallet.hash}
            name={wallet.displayHash}
          />
          <GraphsPanel hash={wallet.hash} graphs={graphs} />
          <CasesPanel hash={wallet.displayHash} cases={cases} />
          <VStack gap={1}>
            <LinkAnalysisButton
              hash={wallet.hash}
              chain={chain}
              canGroupByType={false}
            />

            <FundsTrackingButton hash={wallet.hash} chain={chain} />
            {canShowBehaviorAnalysis && (
              <>
                <button
                  type="submit"
                  style={{ width: '100%' }}
                  className="btn btn-basic"
                  onClick={
                    behaviorAnalysisModalUnshow
                      ? () =>
                          createNewBehaviorAnalysis(
                            chain,
                            wallet.hash,
                            toggleOff,
                            onSucess,
                            onFail
                          )
                      : toggleOn
                  }
                >
                  Behavior Analysis
                </button>
                <BehaviorAnalysisModal
                  chain={chain}
                  toggleModalOff={toggleOff}
                  visible={isModalVisible}
                  onActionPress={() =>
                    createNewBehaviorAnalysis(
                      chain,
                      wallet.hash,
                      toggleOff,
                      onSucess,
                      onFail
                    )
                  }
                />
              </>
            )}
            <ReportButton
              hash={wallet.hash}
              chain={chain}
              type="Wallet"
              enabled={userConfigs.license.report}
            />
          </VStack>
        </div>
      </div>
    </>
  );
};

const WalletDetailTables: React.FC<{
  path: string;
  chain: string;
  wallet;
}> = ({ path, chain, wallet }) => {
  return (
    <React.Fragment>
      <Route path={path} exact>
        <WalletOverviewTable wallet={wallet} />
        <WalletOsintTable chain={chain} walletId={wallet.hash} />
        <ExpandableSection sectionTitle="Interactions" defaultExpanded>
          <div style={{ paddingTop: '8px' }}>
            <ErrorBoundary>
              <Suspense fallback={<LoadingBubbles />}>
                <Interactions chain={chain} type="wallets" id={wallet.hash} />
              </Suspense>
            </ErrorBoundary>
          </div>
        </ExpandableSection>
        <ExpandableSection sectionTitle="Time Patterns">
          <ErrorBoundary>
            <Suspense fallback={<ExpandableSectionFallback />}>
              <TimePatternCharts
                chain={chain}
                hash={wallet.hash}
                type="wallet"
                isActivity
              />
            </Suspense>
          </ErrorBoundary>
        </ExpandableSection>
        <AdditionalInfo data={wallet} type="wallet" />
      </Route>
      <Route path={`${path}/addresses`} exact>
        <WalletAddresses
          chain={chain}
          walletId={wallet.hash}
          numAddresses={wallet.numAddresses}
        />
      </Route>
      <Route path={`${path}/transactions`} exact>
        <WalletTransactions
          chain={chain}
          walletId={wallet.hash}
          numTx={wallet.transactionsCount}
        />
      </Route>
    </React.Fragment>
  );
};

const getWalletBehaviorLabel = (behavior: string) => {
  let type = 'default';
  const labelType = {
    program: 'primary',
    human: 'success',
  };
  if (labelType[behavior]) {
    type = labelType[behavior];
  }
  return type;
};

const WalletOverviewTable: React.FC<{ wallet: IWallet }> = (props) => {
  const wallet = props.wallet;
  return (
    <ExpandableSection sectionTitle={'Wallet'} defaultExpanded={true}>
      <table className={'table table-striped'}>
        <colgroup>
          <col width="30%" />
          <col width="70%" />
        </colgroup>
        <tbody>
          <tr>
            <td>ID</td>
            <td>
              <span>{wallet.displayHash}</span>
            </td>
          </tr>
          <tr>
            <td>Last Activity</td>
            <td>{wallet.lastSeen}</td>
          </tr>
          <tr>
            <td>Behavior</td>
            <td>
              {wallet.behavior.map((behavior, index) => (
                <span
                  className={`label label-${getWalletBehaviorLabel(behavior)}`}
                  key={`${behavior}/${index}`}
                >
                  {behavior}
                </span>
              ))}
            </td>
          </tr>
          <tr>
            <td>Clusterization</td>
            <td>
              {wallet.clusterization.map((category) => (
                <span
                  className="label label-default"
                  key={`cluster-category-${category}`}
                >
                  {category}
                </span>
              ))}
            </td>
          </tr>
          <tr>
            <td>Addresses</td>
            <td>
              <span>{wallet.numAddresses}</span>
            </td>
          </tr>
          <tr>
            <td>Transactions</td>
            <td>
              <span>{wallet.transactionsCount}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </ExpandableSection>
  );
};

const WalletOsintTable: React.FC<{
  chain: string;
  walletId: string;
}> = (props) => {
  return (
    <OsintTable
      type="wallet"
      chain={props.chain}
      data={props.walletId}
      fetchOsintConnection={(cursor) => {
        const result = useLazyLoadQuery(walletOsintQuery, {
          tickerSymbol: props.chain,
          walletId: props.walletId,
          after: cursor,
        });
        return (result as WalletPage_OsintQueryResponse).viewer
          .coinbaseAnalytics.walletByHashAndSymbol.osint;
      }}
    />
  );
};
