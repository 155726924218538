import React from 'react';
import { graphql, useLazyLoadQuery } from '@cbhq/data-layer';
import RiskScoreLabel from './RiskScoreLabel';
import { RiskScoreLabelWrapper_WalletRiskQuery } from './__generated__/RiskScoreLabelWrapper_WalletRiskQuery.graphql';
import { RiskScoreLabelWrapper_AddressRiskQuery } from './__generated__/RiskScoreLabelWrapper_AddressRiskQuery.graphql';

const walletRiskQuery = graphql`
  query RiskScoreLabelWrapper_WalletRiskQuery(
    $tickerSymbol: TickerSymbol!
    $walletId: String!
  ) {
    viewer {
      coinbaseAnalytics {
        walletByHashAndSymbol(tickerSymbol: $tickerSymbol, hash: $walletId) {
          risk {
            score
            factors {
              positive {
                desc
              }
              negative {
                modifier
                desc
              }
            }
          }
        }
      }
    }
  }
`;

const addressRiskQuery = graphql`
  query RiskScoreLabelWrapper_AddressRiskQuery(
    $tickerSymbol: TickerSymbol!
    $addressId: String!
  ) {
    viewer {
      coinbaseAnalytics {
        addressByHashAndSymbol(tickerSymbol: $tickerSymbol, hash: $addressId) {
          risk {
            score
            factors {
              positive {
                desc
              }
              negative {
                modifier
                desc
              }
            }
          }
        }
      }
    }
  }
`;

interface IRiskScoreLabelWrapper {
  chain: string;
  hash: string;
  customKey?: any;
  type: 'address' | 'wallet';
  useCDScolors?: boolean;
}

const RiskScoreLabelWrapper = ({
  chain,
  customKey,
  hash,
  type,
  useCDScolors = false,
}: IRiskScoreLabelWrapper) => {
  let riskData;

  if (type === 'wallet') {
    riskData = useLazyLoadQuery<RiskScoreLabelWrapper_WalletRiskQuery>(
      walletRiskQuery,
      {
        tickerSymbol: chain,
        walletId: hash,
      }
    ).viewer.coinbaseAnalytics.walletByHashAndSymbol?.risk;
  } else {
    riskData = useLazyLoadQuery<RiskScoreLabelWrapper_AddressRiskQuery>(
      addressRiskQuery,
      {
        tickerSymbol: chain,
        addressId: hash,
      }
    ).viewer.coinbaseAnalytics.addressByHashAndSymbol?.risk;
  }

  return (
    <RiskScoreLabel
      score={riskData?.score == null ? 'NA' : riskData?.score}
      factors={riskData?.factors}
      customKey={customKey}
      useCDScolors={useCDScolors}
    />
  );
};

export default RiskScoreLabelWrapper;
