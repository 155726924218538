/**
 * @generated SignedSource<<fd8cb3b888c854f1f1fe73c97b488c27>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WalletPage_SummaryQuery$variables = {
  tickerSymbol: any;
  walletId: string;
};
export type WalletPage_SummaryQueryVariables = WalletPage_SummaryQuery$variables;
export type WalletPage_SummaryQuery$data = {
  readonly viewer: {
    readonly coinbaseAnalytics: {
      readonly walletByHashAndSymbol: {
        readonly hash: string;
        readonly displayHash: string;
        readonly name: string;
        readonly lastSeen: any | null;
        readonly clusterization: ReadonlyArray<string>;
        readonly transactionsCount: number | null;
        readonly balanceAmount: {
          readonly value: any;
          readonly currency: any;
        } | null;
        readonly balanceAmountUsd: any | null;
        readonly receivedAmount: {
          readonly value: any;
          readonly currency: any;
        } | null;
        readonly receivedAmountUsd: any | null;
        readonly sentAmount: {
          readonly value: any;
          readonly currency: any;
        } | null;
        readonly sentAmountUsd: any | null;
        readonly gainUsdPercent: number | null;
        readonly gainAmountUsd: any | null;
        readonly behavior: ReadonlyArray<string> | null;
        readonly numAddresses: number | null;
        readonly " $fragmentSpreads": FragmentRefs<"AdditionalInfoWallet_metadata">;
      } | null;
    };
  };
};
export type WalletPage_SummaryQueryResponse = WalletPage_SummaryQuery$data;
export type WalletPage_SummaryQuery = {
  variables: WalletPage_SummaryQueryVariables;
  response: WalletPage_SummaryQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tickerSymbol"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "walletId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "hash",
    "variableName": "walletId"
  },
  {
    "kind": "Variable",
    "name": "tickerSymbol",
    "variableName": "tickerSymbol"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hash",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayHash",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastSeen",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clusterization",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "transactionsCount",
  "storageKey": null
},
v8 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currency",
    "storageKey": null
  }
],
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Amount",
  "kind": "LinkedField",
  "name": "balanceAmount",
  "plural": false,
  "selections": (v8/*: any*/),
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "balanceAmountUsd",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "Amount",
  "kind": "LinkedField",
  "name": "receivedAmount",
  "plural": false,
  "selections": (v8/*: any*/),
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "receivedAmountUsd",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "Amount",
  "kind": "LinkedField",
  "name": "sentAmount",
  "plural": false,
  "selections": (v8/*: any*/),
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sentAmountUsd",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gainUsdPercent",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gainAmountUsd",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "behavior",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numAddresses",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WalletPage_SummaryQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CoinbaseAnalytics",
            "kind": "LinkedField",
            "name": "coinbaseAnalytics",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "CoinbaseAnalyticsWallet",
                "kind": "LinkedField",
                "name": "walletByHashAndSymbol",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "AdditionalInfoWallet_metadata"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WalletPage_SummaryQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CoinbaseAnalytics",
            "kind": "LinkedField",
            "name": "coinbaseAnalytics",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "CoinbaseAnalyticsWallet",
                "kind": "LinkedField",
                "name": "walletByHashAndSymbol",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CoinbaseAnalyticsWalletMetadata",
                    "kind": "LinkedField",
                    "name": "metadata",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "categories",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "urls",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "notes",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "handling",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1edd735317a522efda277a7765dd66d6",
    "id": null,
    "metadata": {},
    "name": "WalletPage_SummaryQuery",
    "operationKind": "query",
    "text": "query WalletPage_SummaryQuery(\n  $tickerSymbol: TickerSymbol!\n  $walletId: String!\n) {\n  viewer {\n    coinbaseAnalytics {\n      walletByHashAndSymbol(hash: $walletId, tickerSymbol: $tickerSymbol) {\n        hash\n        displayHash\n        name\n        lastSeen\n        clusterization\n        transactionsCount\n        balanceAmount {\n          value\n          currency\n        }\n        balanceAmountUsd\n        receivedAmount {\n          value\n          currency\n        }\n        receivedAmountUsd\n        sentAmount {\n          value\n          currency\n        }\n        sentAmountUsd\n        gainUsdPercent\n        gainAmountUsd\n        behavior\n        numAddresses\n        ...AdditionalInfoWallet_metadata\n        id\n      }\n    }\n  }\n}\n\nfragment AdditionalInfoWallet_metadata on CoinbaseAnalyticsWallet {\n  metadata {\n    name\n    categories\n    urls\n    notes\n    handling\n  }\n}\n"
  }
};
})();

(node as any).hash = "cb4d9ec3f9541562fc36022ad77390da";

export default node;
