import React, { useCallback, useContext, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import BootstrapSwitch from './BootstrapSwitch';
import TransactionTabRow from './TransactionTabRow';
import { NextPageCursorNavigator } from './NextPageCursorNavigator';
import TransactionFilters from './TransactionFilters';
import {
  AddressTransactions_AddressQueryResponse,
  CoinbaseAnalyticsTransactionDirection,
  CoinbaseAnalyticsTransactionOrder,
  CoinbaseAnalyticsTransactionTypes,
} from '../pages/addresses/__generated__/AddressTransactions_AddressQuery.graphql';
import { BlockSummaryPageQuery_TransactionsQueryResponse } from '../pages/blocks/__generated__/BlockSummaryPageQuery_TransactionsQuery.graphql';
import { WalletTransactions_WalletQueryResponse } from '../pages/wallets/__generated__/WalletTransactions_WalletQuery.graphql';
import { IconButton } from '@cbhq/cds-web/buttons';
import { Tooltip } from '@cbhq/cds-web/overlays';
import { useToast } from '@cbhq/cds-web/overlays/useToast';
import { PageDataContext } from '../util/PageDataContext';
import { HStack, Spacer } from '@cbhq/cds-web/layout';

type addressTransactions =
  AddressTransactions_AddressQueryResponse['viewer']['coinbaseAnalytics']['addressByHashAndSymbol']['transactions'];
type blockTransactions =
  BlockSummaryPageQuery_TransactionsQueryResponse['viewer']['coinbaseAnalytics']['blockByHashAndSymbol']['transactions'];
type walletTransaction =
  WalletTransactions_WalletQueryResponse['viewer']['coinbaseAnalytics']['walletByHashAndSymbol']['transactions'];
interface ITransactionsTabDataProps<T> {
  tickerSymbol: string;
  numTx: number;
  walletId?: string;
  fetchTransactionConnection: (
    cursor: string,
    filter: TransactionsFiltersType
  ) => T;
}
export interface TransactionsFiltersType {
  direction: CoinbaseAnalyticsTransactionDirection;
  order: CoinbaseAnalyticsTransactionOrder;
  types?: CoinbaseAnalyticsTransactionTypes[];
  addressHashOrWalletId?: string;
  periodStart?: string;
  periodEnd?: string;
  minAssetAmount?: string;
  maxAssetAmount?: string;
}

// fetchTransactionConnection takes a cursor string as the only argument and returns a transaction connection object.
export const TransactionsTabData = (
  props: ITransactionsTabDataProps<
    addressTransactions | blockTransactions | walletTransaction
  >
) => {
  const [state, setState] = useState({
    pageCursor: '',
    page: 1,
  });
  const toast = useToast();
  const { path } = useRouteMatch();
  const { userConfigs } = useContext(PageDataContext);

  const [showUsd, setShowUsd] = useState(false);
  const [showName, setShowName] = useState(true);
  const [transactionFilters, setTransactionFilters] =
    useState<TransactionsFiltersType>({
      direction: 'ALL',
      order: 'DESC',
    });
  const [loadingExport, setLoadingExport] = useState(false);

  const onSwitchShowName = useCallback(() => {
    setShowName((prevState) => {
      return !prevState;
    });
  }, []);

  const onSwitchShowUsd = useCallback(() => {
    setShowUsd((prevState) => {
      return !prevState;
    });
  }, []);

  const transactionConnection = props.fetchTransactionConnection(
    state.pageCursor,
    transactionFilters
  );

  const nextPageCursor = transactionConnection.pageInfo.endCursor;
  const navigateToPage = (nextPage: number, nextPageCursor: string) => {
    setState({
      pageCursor: nextPageCursor,
      page: nextPage,
    });
  };

  const downloadCSV = async () => {
    const history = createBrowserHistory({ forceRefresh: true });
    const queryString = new URLSearchParams();

    queryString.append('direction', transactionFilters.direction.toLowerCase());
    queryString.append(
      'fromto',
      transactionFilters?.addressHashOrWalletId || ''
    );
    queryString.append('maxamount', transactionFilters?.maxAssetAmount || '');
    queryString.append('minamount', transactionFilters?.minAssetAmount || '');
    queryString.append(
      'maxdatetime',
      transactionFilters?.periodEnd?.toString() || ''
    );
    queryString.append(
      'mindatetime',
      transactionFilters?.periodStart?.toString() || ''
    );

    queryString.append('tab', 'transactions');
    queryString.append('txs', 'true');

    if (path.includes('addresses')) {
      queryString.append('address', props?.walletId);
    } else {
      queryString.append('wallet', props?.walletId);
    }

    if (transactionFilters?.types?.length) {
      transactionFilters?.types.forEach((value) => {
        queryString.append('type[]', value);
      });
    }

    setLoadingExport(true);
    try {
      await fetch(
        `/${props.tickerSymbol}/transactions/${
          transactionConnection.edges[0].node.hash
        }/export?${queryString.toString()}`,
        {
          method: 'GET',
        }
      );

      setLoadingExport(false);
      history.push(`/${props.tickerSymbol}/jobs`);
    } catch (error) {
      toast.show(`Failed to export the CSV.`);
      setLoadingExport(false);
    }
  };

  const transactionListToString = () => {
    const txList = [];
    transactionConnection?.edges.map((tx) => txList.push(tx.node.hash));
    return txList.toString();
  };

  const addToGraphTooltipContent = () => {
    const numTxs = transactionConnection.edges?.length;
    return 'Add to graph' + (numTxs > 1 ? ` (${numTxs} elements)` : '');
  };

  return (
    <React.Fragment>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th style={{ width: '50%' }}></th>
              <th style={{ width: '50%' }} className="text-right">
                <BootstrapSwitch
                  identifier={'transaction-tab-name-switch'}
                  onSwitch={onSwitchShowName}
                  isChecked={showName}
                  dataOn="names"
                  dataOff="addresses"
                  width={'80px'}
                />
                &nbsp;
                <BootstrapSwitch
                  identifier={'transaction-tab-usd-switch'}
                  onSwitch={onSwitchShowUsd}
                  isChecked={!showUsd}
                  dataOn={`${props.tickerSymbol.toUpperCase()}`}
                  dataOff="USD"
                />
                &nbsp;
                <TransactionFilters
                  tickerSymbol={props.tickerSymbol}
                  setTransactionFilters={setTransactionFilters}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                No. Transactions{' '}
                <span className="label label-primary">{props.numTx}</span>
              </td>
              {transactionConnection?.edges[0]?.node?.hash && (
                <td>
                  <HStack alignItems="center">
                    <Spacer />

                    {userConfigs.license.type === 'gov' && (
                      <Tooltip content="Export as CSV" placement="left">
                        <IconButton
                          name="download"
                          onPress={() => {
                            downloadCSV();
                          }}
                          variant="foregroundMuted"
                          transparent
                          loading={loadingExport}
                        />
                      </Tooltip>
                    )}
                    <Tooltip
                      content={addToGraphTooltipContent()}
                      placement="right"
                    >
                      <IconButton
                        name="defi"
                        data-element-ids={transactionListToString()}
                        onPress={(e) =>
                          window.xflow.addToGraphModal(e.currentTarget)
                        }
                        variant="foregroundMuted"
                        transparent
                      />
                    </Tooltip>
                  </HStack>
                </td>
              )}
            </tr>
            {transactionConnection.edges.map((transactionEdge) => {
              return (
                <tr key={transactionEdge.node.hash}>
                  <TransactionTabRow
                    transaction={transactionEdge.node}
                    showUsd={showUsd}
                    showName={showName}
                    useFragment={true}
                  />
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <NextPageCursorNavigator
        currentPage={state.page ? state.page : 1}
        nextPageCursor={nextPageCursor}
        onClick={navigateToPage}
      />
    </React.Fragment>
  );
};
