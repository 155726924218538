import { graphql, useLazyLoadQuery } from '@cbhq/data-layer';
import React, { useEffect, useMemo } from 'react';
import { NetworkFilter } from '../../components/filters/NetworkFilter';
import { IChainConfigs } from './SearchResultsWrapper';
import { NetworkFilterWrapper_ChainsQuery } from './__generated__/NetworkFilterWrapper_ChainsQuery.graphql';

export const searchResultsPageQuery = graphql`
  query NetworkFilterWrapper_ChainsQuery($query: String!) {
    viewer {
      coinbaseAnalytics {
        chains: searchValidChains(query: $query) {
          wallets {
            chain
            count
          }
          addresses {
            chain
            count
          }
          totalWallets
          totalAddresses
        }
      }
    }
  }
`;

interface INetworkFilterWrapperProps {
  setChain: React.Dispatch<React.SetStateAction<string>>;
  setTabs: React.Dispatch<
    React.SetStateAction<
      {
        id: string;
        label: string;
      }[]
    >
  >;
  query: string;
  chainConfigs: IChainConfigs;
  chain: string;
  currentTab: string;
}

const NetworkFilterWrapper = (props: INetworkFilterWrapperProps) => {
  const { chainConfigs, setChain, setTabs, query, chain, currentTab } = props;

  const pageQueryResult = useLazyLoadQuery<NetworkFilterWrapper_ChainsQuery>(
    searchResultsPageQuery,
    {
      query,
    }
  );
  const validChains = pageQueryResult.viewer.coinbaseAnalytics.chains;

  useEffect(() => {
    if (validChains) {
      setTabs([
        {
          id: 'addresses',
          label: `Addresses (${
            chain === 'All networks'
              ? validChains.totalAddresses
              : validChains.addresses.find(
                  (item) => item?.chain === chain.toUpperCase()
                )?.count || '0'
          })`,
        },
        {
          id: 'wallets',
          label: `Wallets (${
            chain === 'All networks'
              ? validChains.totalWallets
              : validChains.wallets.find(
                  (item) => item?.chain === chain.toUpperCase()
                )?.count || '0'
          })`,
        },
      ]);
    }
  }, [chain, validChains]);

  const updateChainConfig = (chains) => {
    const chainsSet = new Set<string>();

    if (currentTab === 'addresses') {
      chains.addresses.forEach((addressChain) =>
        chainsSet.add(addressChain.chain.toLowerCase())
      );
    } else {
      chains.wallets.forEach((walletChain) =>
        chainsSet.add(walletChain.chain.toLowerCase())
      );
    }

    const configs = {};
    const chainsArray = Array.from(chainsSet);

    chainsArray.forEach((chain) => {
      configs[chain] = {
        class: chainConfigs.configs[chain].class,
        enabled: chainConfigs.configs[chain].enabled,
        name: chainConfigs.configs[chain].name,
      };
    });

    return { chains: chainsArray, configs };
  };

  const chainsConfigForNetworkFilter = useMemo(
    () => updateChainConfig(validChains),
    [currentTab]
  );

  return (
    <NetworkFilter
      chainConfigs={chainsConfigForNetworkFilter}
      showAllNetworks
      onChange={setChain}
      chain={chain}
    />
  );
};

export default NetworkFilterWrapper;
