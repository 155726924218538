/**
 * @generated SignedSource<<7807bf75534d777b3b2490fef26a13b3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TransactionPageContent$data = {
  readonly outAmount: {
    readonly value: any;
    readonly currency: any;
  } | null;
  readonly outAmountUsd: any | null;
  readonly feeAmount: {
    readonly value: any;
    readonly currency: any;
  } | null;
  readonly feeAmountUsd: any | null;
  readonly isPeeling: boolean | null;
  readonly demixerType: string | null;
  readonly " $fragmentType": "TransactionPageContent";
};
export type TransactionPageContent = TransactionPageContent$data;
export type TransactionPageContent$key = {
  readonly " $data"?: TransactionPageContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"TransactionPageContent">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currency",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TransactionPageContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Amount",
      "kind": "LinkedField",
      "name": "outAmount",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "outAmountUsd",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Amount",
      "kind": "LinkedField",
      "name": "feeAmount",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "feeAmountUsd",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPeeling",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "demixerType",
      "storageKey": null
    }
  ],
  "type": "CoinbaseAnalyticsTransaction",
  "abstractKey": null
};
})();

(node as any).hash = "3a8a925e0e7664f39a9f4a85ad3465e8";

export default node;
