/**
 * @generated SignedSource<<aa9bffcb9e82af912ae018eb199b2803>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BlockSummaryPageQuery_TransactionsQuery$variables = {
  blockId: string;
  tickerSymbol: any;
  after?: string | null;
};
export type BlockSummaryPageQuery_TransactionsQueryVariables = BlockSummaryPageQuery_TransactionsQuery$variables;
export type BlockSummaryPageQuery_TransactionsQuery$data = {
  readonly viewer: {
    readonly coinbaseAnalytics: {
      readonly blockByHashAndSymbol: {
        readonly transactions: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly hash: string;
              readonly " $fragmentSpreads": FragmentRefs<"TransactionTabRow_TransactionFragment">;
            } | null;
          }>;
          readonly pageInfo: {
            readonly endCursor: string;
            readonly hasNextPage: boolean;
          };
        } | null;
      } | null;
    };
  };
};
export type BlockSummaryPageQuery_TransactionsQueryResponse = BlockSummaryPageQuery_TransactionsQuery$data;
export type BlockSummaryPageQuery_TransactionsQuery = {
  variables: BlockSummaryPageQuery_TransactionsQueryVariables;
  response: BlockSummaryPageQuery_TransactionsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "blockId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tickerSymbol"
},
v3 = [
  {
    "kind": "Variable",
    "name": "hash",
    "variableName": "blockId"
  },
  {
    "kind": "Variable",
    "name": "tickerSymbol",
    "variableName": "tickerSymbol"
  }
],
v4 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "asset",
    "variableName": "tickerSymbol"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hash",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currency",
    "storageKey": null
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "address",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Amount",
    "kind": "LinkedField",
    "name": "amount",
    "plural": false,
    "selections": (v7/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "amountUsd",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "prevTxHash",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "nextTxHash",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "prevOutputIndex",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "nextOutputIndex",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "generated",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "osint",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "valid",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "changeAddress",
    "storageKey": null
  },
  (v8/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BlockSummaryPageQuery_TransactionsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CoinbaseAnalytics",
            "kind": "LinkedField",
            "name": "coinbaseAnalytics",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "CoinbaseAnalyticsBlock",
                "kind": "LinkedField",
                "name": "blockByHashAndSymbol",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v4/*: any*/),
                    "concreteType": "CoinbaseAnalyticsBlockTransactionConnection",
                    "kind": "LinkedField",
                    "name": "transactions",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CoinbaseAnalyticsBlockTransactionEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CoinbaseAnalyticsBlockTransaction",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "TransactionTabRow_TransactionFragment"
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "BlockSummaryPageQuery_TransactionsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CoinbaseAnalytics",
            "kind": "LinkedField",
            "name": "coinbaseAnalytics",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "CoinbaseAnalyticsBlock",
                "kind": "LinkedField",
                "name": "blockByHashAndSymbol",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v4/*: any*/),
                    "concreteType": "CoinbaseAnalyticsBlockTransactionConnection",
                    "kind": "LinkedField",
                    "name": "transactions",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CoinbaseAnalyticsBlockTransactionEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CoinbaseAnalyticsBlockTransaction",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "time",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "direction",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Amount",
                                "kind": "LinkedField",
                                "name": "inAmount",
                                "plural": false,
                                "selections": (v7/*: any*/),
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "inAmountUsd",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Amount",
                                "kind": "LinkedField",
                                "name": "outAmount",
                                "plural": false,
                                "selections": (v7/*: any*/),
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "outAmountUsd",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Amount",
                                "kind": "LinkedField",
                                "name": "feeAmount",
                                "plural": false,
                                "selections": (v7/*: any*/),
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "feeAmountUsd",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "mixed",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "CoinbaseAnalyticsOperation",
                                "kind": "LinkedField",
                                "name": "operations",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "index",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "type",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CoinbaseAnalyticsOperationPart",
                                    "kind": "LinkedField",
                                    "name": "inputs",
                                    "plural": true,
                                    "selections": (v9/*: any*/),
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CoinbaseAnalyticsOperationPart",
                                    "kind": "LinkedField",
                                    "name": "outputs",
                                    "plural": true,
                                    "selections": (v9/*: any*/),
                                    "storageKey": null
                                  },
                                  (v8/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "totalInputs",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "totalOutputs",
                                "storageKey": null
                              },
                              (v8/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9665f0cda42969882c1092da32a38d31",
    "id": null,
    "metadata": {},
    "name": "BlockSummaryPageQuery_TransactionsQuery",
    "operationKind": "query",
    "text": "query BlockSummaryPageQuery_TransactionsQuery(\n  $blockId: String!\n  $tickerSymbol: TickerSymbol!\n  $after: String\n) {\n  viewer {\n    coinbaseAnalytics {\n      blockByHashAndSymbol(tickerSymbol: $tickerSymbol, hash: $blockId) {\n        transactions(asset: $tickerSymbol, after: $after) {\n          edges {\n            node {\n              hash\n              ...TransactionTabRow_TransactionFragment\n              id\n            }\n          }\n          pageInfo {\n            endCursor\n            hasNextPage\n          }\n        }\n        id\n      }\n    }\n  }\n}\n\nfragment TransactionTabRow_TransactionDetailsFragment on CoinbaseAnalyticsBlockTransaction {\n  hash\n  inAmount {\n    value\n    currency\n  }\n  inAmountUsd\n  outAmount {\n    value\n    currency\n  }\n  outAmountUsd\n  feeAmount {\n    value\n    currency\n  }\n  feeAmountUsd\n  mixed\n  operations {\n    index\n    type\n    inputs {\n      address\n      name\n      amount {\n        value\n        currency\n      }\n      amountUsd\n      prevTxHash\n      nextTxHash\n      prevOutputIndex\n      nextOutputIndex\n      generated\n      osint\n      valid\n      changeAddress\n      id\n    }\n    outputs {\n      address\n      name\n      amount {\n        value\n        currency\n      }\n      amountUsd\n      prevTxHash\n      nextTxHash\n      prevOutputIndex\n      nextOutputIndex\n      generated\n      osint\n      valid\n      changeAddress\n      id\n    }\n    id\n  }\n  totalInputs\n  totalOutputs\n}\n\nfragment TransactionTabRow_TransactionFragment on CoinbaseAnalyticsBlockTransaction {\n  hash\n  time\n  direction\n  ...TransactionTabRow_TransactionDetailsFragment\n}\n"
  }
};
})();

(node as any).hash = "37defa3e68a5867718d505bb102b69e3";

export default node;
