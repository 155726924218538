import React, {
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Route,
  NavLink,
  useParams,
  useRouteMatch,
  useHistory,
} from 'react-router-dom';
import { useLazyLoadQuery } from 'react-relay';
import { graphql } from '@cbhq/data-layer';
import { BlockSummaryPageQuery_TransactionsQuery } from './__generated__/BlockSummaryPageQuery_TransactionsQuery.graphql';
import { ChainLink } from '../../components/ChainLink';
import HeaderBlock from '../../components/HeaderBlock';
import HeaderBlocks from '../../components/HeaderBlocks';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { LoadingBubbles } from '../../components/LoadingBubbles';
import { TransactionsTabData } from '../../components/TransactionTabData';
import FormatTime from '../../util/TimeFormatter';
import { formatAmount, formatUsd } from '../../util/AmountUtils';
import { BlockSummaryPageQuery_BlockQuery } from './__generated__/BlockSummaryPageQuery_BlockQuery.graphql';
import { HStack } from '@cbhq/cds-web/layout';
import { TabNavigation } from '@cbhq/cds-web/tabs';
import { PageDataContext } from '../../util/PageDataContext';

const blockPageQuery = graphql`
  query BlockSummaryPageQuery_BlockQuery(
    $blockId: String!
    $tickerSymbol: TickerSymbol!
  ) {
    viewer {
      coinbaseAnalytics {
        blockByHashAndSymbol(hash: $blockId, tickerSymbol: $tickerSymbol) {
          hash
          time
          height
          size
          rewardAmount {
            value
            currency
          }
          rewardAmountUsd
          feeAmount {
            value
            currency
          }
          feeAmountUsd
          previousBlock
          nextBlock
          minedBy
          numTx
          numFailedTx
          numInternalTx
          numTokenTransfers
        }
      }
    }
  }
`;

const transactionsTabQuery = graphql`
  query BlockSummaryPageQuery_TransactionsQuery(
    $blockId: String!
    $tickerSymbol: TickerSymbol!
    $after: String
  ) {
    viewer {
      coinbaseAnalytics {
        blockByHashAndSymbol(tickerSymbol: $tickerSymbol, hash: $blockId) {
          transactions(asset: $tickerSymbol, after: $after) {
            edges {
              node {
                hash
                ...TransactionTabRow_TransactionFragment
              }
            }
            pageInfo {
              endCursor
              hasNextPage
            }
          }
        }
      }
    }
  }
`;

// The summary page for a single block.
export const BlockSummaryPage: React.FC<{ chain: string }> = (props) => {
  const { hash } = useParams();
  const { path, url } = useRouteMatch();
  const { userConfigs } = useContext(PageDataContext);
  const history = useHistory();

  const data = useLazyLoadQuery<BlockSummaryPageQuery_BlockQuery>(
    blockPageQuery,
    {
      blockId: hash,
      tickerSymbol: props.chain,
    }
  );
  const block = data.viewer.coinbaseAnalytics.blockByHashAndSymbol;

  const [primary, setPrimary] = useState('');

  const tabs = useMemo(
    () => [
      { id: 'summary_tab', label: 'Summary' },
      { id: 'transactions_tab', label: 'Transactions' },
    ],
    []
  );

  useEffect(() => {
    const currentPath = window.location.pathname;

    if (currentPath.includes('/transactions')) {
      setPrimary('transactions_tab');
    } else {
      setPrimary('summary_tab');
    }
  }, []);

  const handleRedirect = useCallback(
    (newPath) => {
      setPrimary(newPath);

      if (newPath === 'summary_tab') {
        history.push(url);
      }
      if (newPath === 'transactions_tab') {
        history.push(`${url}/transactions`);
      }
    },
    [primary, tabs]
  );

  return (
    <>
      {userConfigs.license.new_ui_navigation && (
        <HStack
          spacingHorizontal={3}
          spacingVertical={1}
          borderedBottom
          dangerouslySetStyle={{ margin: '0 -2rem', paddingBottom: 0 }}
        >
          <TabNavigation
            value={primary}
            tabs={tabs}
            onChange={handleRedirect}
          />
        </HStack>
      )}
      <div className="container-fluid container-max-xl">
        <div className="page-header" style={{ border: 0 }}>
          <h2>Block</h2>
          <h4>{hash}</h4>
        </div>

        {!userConfigs.license.new_ui_navigation && (
          <ul className="nav nav-pills">
            <li>
              <NavLink to={url} exact>
                <b>Summary</b>
              </NavLink>
            </li>
            <li>
              <NavLink to={`${url}/transactions`} exact>
                <b>Transactions</b>
              </NavLink>
            </li>
          </ul>
        )}

        <div className="tab-content">
          <HeaderBlocks
            blocks={[
              <HeaderBlock
                key="block-summary-page-header-block-1"
                title="Reward"
                body={formatAmount(block.rewardAmount, props.chain)}
                footer={formatUsd(block.rewardAmountUsd)}
              />,
              <HeaderBlock
                key="block-summary-page-header-block-2"
                title="Collected Fees"
                body={formatAmount(block.feeAmount, props.chain)}
                footer={formatUsd(block.feeAmountUsd)}
              />,
            ]}
          />

          <Route path={path} exact>
            <div id="summary" className="tab-pane active">
              <table className="table table-summary">
                <colgroup>
                  <col width="30%" />
                  <col width="70%" />
                </colgroup>
                <tbody>
                  <tr>
                    <td>Block</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Hash</td>
                    <td className="monospace">{block.hash}</td>
                  </tr>
                  {block.previousBlock && (
                    <tr>
                      <td>Previous Block</td>
                      <td className="monospace">
                        <ChainLink to={`/blocks/${block.previousBlock}`}>
                          {block.previousBlock}
                        </ChainLink>
                      </td>
                    </tr>
                  )}
                  {block.nextBlock && (
                    <tr>
                      <td>Next Block</td>
                      <td className="monospace">
                        <ChainLink to={`/blocks/${block.nextBlock}`}>
                          {block.nextBlock}
                        </ChainLink>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>Time</td>
                    <td>{FormatTime(block.time)}</td>
                  </tr>
                  <tr>
                    <td>Height</td>
                    <td>{block.height}</td>
                  </tr>
                  <tr>
                    <td>Flags</td>
                    <td>
                      <span className="label label-info">segwit</span>
                    </td>
                  </tr>
                  <tr>
                    <td>Transactions</td>
                    <td>
                      <span className="label label-primary">{block.numTx}</span>
                    </td>
                  </tr>
                  {block.numInternalTx > 0 && (
                    <tr>
                      <td>Internal Transactions</td>
                      <td>
                        <span className="label label-default">
                          {block.numInternalTx}
                        </span>
                      </td>
                    </tr>
                  )}
                  {block.numTokenTransfers > 0 && (
                    <tr>
                      <td>Token Transactions</td>
                      <td>
                        <span className="label label-warning">
                          {block.numTokenTransfers}
                        </span>
                      </td>
                    </tr>
                  )}
                  {block.numFailedTx > 0 && (
                    <tr>
                      <td>Failed Transactions</td>
                      <td>
                        <span className="label label-danger">
                          {block.numFailedTx}
                        </span>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>Size</td>
                    <td>{block.size}</td>
                  </tr>
                  {block.minedBy && (
                    <tr>
                      <td>Mined by</td>
                      <td>
                        <ChainLink to={`/metadata?search=${block.minedBy}`}>
                          {block.minedBy}
                        </ChainLink>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Route>

          <Route path={`${path}/transactions`} exact>
            <ErrorBoundary>
              <Suspense fallback={<LoadingBubbles />}>
                <TransactionsTabData
                  tickerSymbol={props.chain}
                  numTx={block.numTx}
                  fetchTransactionConnection={(cursor) => {
                    const block =
                      useLazyLoadQuery<BlockSummaryPageQuery_TransactionsQuery>(
                        transactionsTabQuery,
                        {
                          blockId: hash,
                          tickerSymbol: props.chain,
                          after: cursor,
                        }
                      );
                    return block.viewer.coinbaseAnalytics.blockByHashAndSymbol
                      .transactions;
                  }}
                />
              </Suspense>
            </ErrorBoundary>
          </Route>
        </div>
      </div>
    </>
  );
};
