/**
 * @generated SignedSource<<48eab875330ebbb56c633894c9ab06fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TimePatternCharts_TimePatternQuery$variables = {
  tickerSymbol: any;
  hash: string;
  type: string;
  isActivity?: boolean | null;
};
export type TimePatternCharts_TimePatternQueryVariables = TimePatternCharts_TimePatternQuery$variables;
export type TimePatternCharts_TimePatternQuery$data = {
  readonly viewer: {
    readonly coinbaseAnalytics: {
      readonly timePatternByHashAndSymbol: {
        readonly charts: ReadonlyArray<{
          readonly chartId: string;
          readonly xAxisLabels: ReadonlyArray<string> | null;
          readonly datasets: ReadonlyArray<{
            readonly data: ReadonlyArray<number> | null;
            readonly legendLabel: string | null;
            readonly valueLabel: string | null;
          }> | null;
        }> | null;
      } | null;
    };
  };
};
export type TimePatternCharts_TimePatternQueryResponse = TimePatternCharts_TimePatternQuery$data;
export type TimePatternCharts_TimePatternQuery = {
  variables: TimePatternCharts_TimePatternQueryVariables;
  response: TimePatternCharts_TimePatternQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hash"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isActivity"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tickerSymbol"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "type"
},
v4 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Viewer",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CoinbaseAnalytics",
        "kind": "LinkedField",
        "name": "coinbaseAnalytics",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "hash",
                "variableName": "hash"
              },
              {
                "kind": "Variable",
                "name": "isActivity",
                "variableName": "isActivity"
              },
              {
                "kind": "Variable",
                "name": "tickerSymbol",
                "variableName": "tickerSymbol"
              },
              {
                "kind": "Variable",
                "name": "type",
                "variableName": "type"
              }
            ],
            "concreteType": "CoinbaseAnalyticsTimePattern",
            "kind": "LinkedField",
            "name": "timePatternByHashAndSymbol",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CoinbaseAnalyticsChartData",
                "kind": "LinkedField",
                "name": "charts",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "chartId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "xAxisLabels",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CoinbaseAnalyticsDataset",
                    "kind": "LinkedField",
                    "name": "datasets",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "data",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "legendLabel",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "valueLabel",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TimePatternCharts_TimePatternQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "TimePatternCharts_TimePatternQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "fbda01ba6ffcf33589ee66ca079853f1",
    "id": null,
    "metadata": {},
    "name": "TimePatternCharts_TimePatternQuery",
    "operationKind": "query",
    "text": "query TimePatternCharts_TimePatternQuery(\n  $tickerSymbol: TickerSymbol!\n  $hash: String!\n  $type: String!\n  $isActivity: Boolean\n) {\n  viewer {\n    coinbaseAnalytics {\n      timePatternByHashAndSymbol(tickerSymbol: $tickerSymbol, hash: $hash, type: $type, isActivity: $isActivity) {\n        charts {\n          chartId\n          xAxisLabels\n          datasets {\n            data\n            legendLabel\n            valueLabel\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9e84f76b8e3870bc159136a7973b5910";

export default node;
