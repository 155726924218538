import { Button } from '@cbhq/cds-web/buttons';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '@cbhq/cds-web/overlays';
import { useToast } from '@cbhq/cds-web/overlays/useToast';
import { TextBody } from '@cbhq/cds-web/typography';
import React, { useState } from 'react';
import { OverlayProps } from '.';

const DeleteGraphAlert = ({
  visible,
  onRequestClose,
  chain,
  name,
  id,
}: OverlayProps) => {
  const toast = useToast();

  const [deletionStatus, setDeletionStatus] = useState<
    'loading' | 'error' | 'success'
  >(null);

  const deleteGraph = async () => {
    setDeletionStatus('loading');
    try {
      await fetch(`/${chain}/graph/${id}`, {
        method: 'DELETE',
      });

      setDeletionStatus('success');
      toast.show(`${name} has been deleted.`);
    } catch (error) {
      setDeletionStatus('error');
      toast.show(`Failed to delete ${name}.`);
    } finally {
      onRequestClose();
      window.location.reload();
    }
  };

  return (
    <Modal visible={visible} onRequestClose={onRequestClose}>
      <ModalHeader title="Delete graph" />
      <ModalBody>
        <TextBody as="p">{`Are you sure you want to delete ${name}?`}</TextBody>
      </ModalBody>

      <ModalFooter
        primaryAction={
          <Button
            variant="negative"
            onPress={() => deleteGraph()}
            loading={deletionStatus === 'loading'}
            disabled={deletionStatus === 'error'}
          >
            Delete
          </Button>
        }
        secondaryAction={
          <Button variant="secondary" onPress={onRequestClose}>
            Cancel
          </Button>
        }
      />
    </Modal>
  );
};

export default DeleteGraphAlert;
