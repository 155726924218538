export const newUnamedGraph = async (
  chain: string
): Promise<{ _id?: string }> => {
  const response = await fetch(`/${chain}/graph/new`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
  });
  return response.json();
};

export const addItemToGraph = async (
  chain: string,
  graphId: string,
  itemId: string
): Promise<{ statusCode: number }> => {
  const { status } = await fetch(
    `/${chain}/graph/${graphId}/elements/enqueue?element_ids=${itemId}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    }
  );
  return { statusCode: status };
};
