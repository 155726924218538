import { Button } from '@cbhq/cds-web/buttons';
import { HStack } from '@cbhq/cds-web/layout';
import React from 'react';

// A pagination bar that only allows navigation to the first and/or next page. If not on the first page, the current page
// number (i.e. the number of times Next was clicked + 1) is shown as an activated button.
export const NextPageCursorNavigator: React.FC<{
  currentPage: number;
  nextPageCursor: string;
  onClick: (nextPage, nextPageCursor) => void;
  useCDS?: boolean;
}> = ({ currentPage, nextPageCursor, onClick, useCDS }) => {
  if (useCDS) {
    return (
      <div style={{ width: '100%' }}>
        <HStack alignItems="center" gap={1} justifyContent="center">
          {currentPage !== 1 && (
            <Button
              compact
              onPress={() => {
                onClick(1, '');
              }}
            >
              1
            </Button>
          )}
          <Button compact>{currentPage}</Button>
          <Button
            compact
            endIcon="arrowRight"
            disabled={!nextPageCursor}
            onPress={() => onClick(currentPage + 1, nextPageCursor)}
          >
            Next
          </Button>
        </HStack>
      </div>
    );
  }
  return (
    <div className="center_div center_text">
      <ul className="pagination pagination">
        {currentPage !== 1 && (
          <PageButton
            label={'1'}
            onClick={() => {
              onClick(1, '');
            }}
          />
        )}
        <PageButton label={`${currentPage}`} />
        <li className={!nextPageCursor ? 'next disabled' : 'next'}>
          <a
            rel="next"
            {...(nextPageCursor
              ? {
                  onClick: () => {
                    onClick(currentPage + 1, nextPageCursor);
                  },
                }
              : {})}
          >
            <span>Next →</span>
          </a>
        </li>
      </ul>
    </div>
  );
};

// A button for page number navigation at the bottom of an index page. If onClick is not provided, assumes the button is
// active.
const PageButton: React.FC<{ label: string; onClick?: VoidFunction }> = ({
  label,
  onClick,
}) => {
  return (
    <li className={onClick ? '' : 'active'}>
      {onClick ? <a onClick={onClick}>{label}</a> : <span>{label}</span>}
    </li>
  );
};
