import { useToggler } from '@cbhq/cds-web';
import { Button } from '@cbhq/cds-web/buttons';
import { Icon } from '@cbhq/cds-web/icons';
import { HStack, Spacer, Divider } from '@cbhq/cds-web/layout';
import { PopoverMenu, PopoverTrigger } from '@cbhq/cds-web/overlays';
import { MenuItem } from '@cbhq/cds-web/dropdown/MenuItem';
import { TextBody } from '@cbhq/cds-web/typography';
import React, { useCallback, useEffect, useState } from 'react';

interface MultiselectDropdownProps {
  allowMultiple: boolean;
  itemList: readonly string[];
  selectAllOptions: boolean;
  allOptionsText?: string;
  onChange: any;
  defaultSelection: any;
}

export const MultiselectDropdown = ({
  allowMultiple,
  selectAllOptions,
  allOptionsText,
  itemList,
  onChange,
  defaultSelection,
}: MultiselectDropdownProps) => {
  const ALL_OPTIONS = allOptionsText;
  const [visible, togglePopoverMenuVisibility] = useToggler(false);

  const [selectedOptions, setSelectedOptions] = useState(
    defaultSelection ?? []
  );

  const [filterText, setFilterText] = useState(null);

  // used to reset the dropdown to allOptionsText
  useEffect(() => {
    if (defaultSelection == null && !selectedOptions.includes(ALL_OPTIONS)) {
      setAllOptions();
    }
  }, [defaultSelection]);

  const updateOptionSelection = (option) => {
    if (allowMultiple) {
      removeOption(ALL_OPTIONS);
      if (selectedOptions.includes(option)) {
        removeOption(option);
      } else selectedOptions.push(option);
      craftFilterText();
    } else {
      setSelectedOptions([option]);
      setFilterText(option);
    }
    onChange(selectedOptions);
  };

  const removeOption = (option) => {
    const index = selectedOptions.indexOf(option);
    if (index !== -1) selectedOptions.splice(index, 1);
  };

  const craftFilterText = useCallback(() => {
    if (selectedOptions.length == 1) {
      setFilterText(selectedOptions[0]);
    } else if (selectedOptions.length > 1) {
      setFilterText(
        selectedOptions[0] + ' + ' + (selectedOptions.length - 1) + ' more'
      );
    } else setAllOptions();
  }, [selectedOptions]);

  const setAllOptions = () => {
    setSelectedOptions([ALL_OPTIONS]);
    setFilterText(ALL_OPTIONS);
    onChange([]);
  };

  return (
    <PopoverMenu
      width="100%"
      openMenu={togglePopoverMenuVisibility.toggleOn}
      closeMenu={togglePopoverMenuVisibility.toggleOff}
      visible={visible}
    >
      <PopoverTrigger>
        <Button block variant="secondary" endIcon="caretDown" compact>
          {filterText || ALL_OPTIONS}
        </Button>
      </PopoverTrigger>
      {allowMultiple ||
        (selectAllOptions && (
          <SelectAllOptions
            setAllOptions={setAllOptions}
            isSelected={selectedOptions.includes(ALL_OPTIONS)}
            allowMultiple={allowMultiple}
          />
        ))}
      {allowMultiple || (selectAllOptions && <Divider />)}
      <DropdownList
        itemList={itemList}
        selectedOptions={selectedOptions}
        updateOptionSelection={updateOptionSelection}
        allowMultiple={allowMultiple}
      />
    </PopoverMenu>
  );
};

interface SelectAllOptionsProps {
  setAllOptions: () => void;
  isSelected: boolean;
  allowMultiple: boolean;
}
const SelectAllOptions = ({
  setAllOptions,
  isSelected,
  allowMultiple,
}: SelectAllOptionsProps) => {
  return (
    <MenuItem
      value="all_options"
      disableCloseOnOptionChange={allowMultiple}
      onPress={() => setAllOptions()}
    >
      <HStack
        alignItems="center"
        spacingVertical={1}
        spacingHorizontal={2}
        gap={1}
      >
        <TextBody as="p">All Options</TextBody>
        <Spacer />
        {isSelected && <Icon name="checkmark" size="xs" />}
      </HStack>
    </MenuItem>
  );
};

interface DropdownListProps {
  itemList: readonly string[];
  selectedOptions: readonly string[];
  updateOptionSelection: Function;
  allowMultiple: boolean;
}

export const DropdownList = ({
  itemList,
  selectedOptions,
  updateOptionSelection,
  allowMultiple,
}: DropdownListProps) => {
  return (
    <>
      {itemList.map((option) => (
        <MenuItem
          key={option}
          value={option}
          disableCloseOnOptionChange={allowMultiple}
          onPress={() => updateOptionSelection(option)}
        >
          <DropdownListItem
            option={option}
            isOptionSelected={selectedOptions.includes(option)}
          />
        </MenuItem>
      ))}
    </>
  );
};

interface DropdownListItemProps {
  option: string;
  isOptionSelected: boolean;
}

const DropdownListItem = ({
  option,
  isOptionSelected,
}: DropdownListItemProps) => {
  return (
    <HStack
      alignItems="center"
      spacingVertical={1}
      spacingHorizontal={2}
      gap={2}
    >
      <TextBody as="p">{option}</TextBody>
      <Spacer />
      {isOptionSelected && <Icon name="checkmark" size="xs" />}
    </HStack>
  );
};
