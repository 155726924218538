import React, { useState, useEffect } from 'react';

const ExpandableSection: React.FC<{
  sectionTitle: string;
  defaultExpanded?: boolean;
  children: React.ReactNode;
}> = ({ defaultExpanded, sectionTitle, children }) => {
  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    setExpanded(defaultExpanded);
  }, [defaultExpanded]);

  return (
    <div style={{ marginBottom: '20px' }}>
      <div
        onClick={() => {
          setExpanded((prevState) => !prevState);
        }}
        className="pinnable-section-header"
        style={{
          borderRadius: expanded ? '6px 6px 0px 0px' : '6px 6px 6px 6px',
        }}
      >
        <i className={`fa fa-caret-${expanded ? 'down' : 'right'}`} />
        &nbsp;
        {sectionTitle}
      </div>
      {expanded && <div className="pinnable-section-body">{children}</div>}
    </div>
  );
};

export default ExpandableSection;
