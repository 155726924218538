import { ThemeProvider } from '@cbhq/cds-web/system/ThemeProvider';
import { PortalProvider } from '@cbhq/cds-web/overlays/PortalProvider';

import React, { Suspense } from 'react';
import {
  Redirect,
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';

import { BlockSummaryPage } from '../pages/blocks/BlockSummaryPage';
import { BlockIndexPage } from '../pages/blocks/BlockIndexPage';
import { GraphqlEnvProvider } from './GraphqlEnvProvider';
import { TransactionIndexPage } from '../pages/transactions/TransactionIndexPage';
import { TransactionPage } from '../pages/transactions/TransactionPage';
import { AddressSummaryPage } from '../pages/addresses/AddressSummaryPage';
import { PageDataContext } from '../util/PageDataContext';
import { ErrorBoundary } from './ErrorBoundary';
import { LoadingBubbles } from './LoadingBubbles';
import { WalletPage } from '../pages/wallets/WalletPage';
import { WalletIndexPage } from '../pages/wallets/WalletIndexPage';
import { MyGraphsPage } from '../pages/MyGraphs';
import HomePage from '../pages/HomePage';
import { FeatureFlagProvider } from '@cbhq/cds-web/system';
import Tools from '../pages/portal/Tools';
import Explore from '../pages/portal/Explore';
import Settings from '../pages/portal/Settings';
import SearchResultsWrapper from '../pages/search/SearchResultsWrapper';

import useFavicon from '../hooks/useFavicon';

// The top level React component for the xflow UI.
const ChainApp = ({ chain, graphs, cases, userConfigs, chainConfigs }) => {
  return (
    <FeatureFlagProvider frontierButton frontierColor frontierTypography>
      <ThemeProvider>
        <PortalProvider>
          <Router>
            <GraphqlEnvProvider>
              <ErrorBoundary>
                <Suspense fallback={<LoadingBubbles />}>
                  <PageDataContext.Provider
                    value={{
                      graphs: graphs,
                      cases: cases,
                      userConfigs: userConfigs,
                    }}
                  >
                    <RouteSwitch chain={chain} chainConfigs={chainConfigs} />
                  </PageDataContext.Provider>
                </Suspense>
              </ErrorBoundary>
            </GraphqlEnvProvider>
          </Router>
        </PortalProvider>
      </ThemeProvider>
    </FeatureFlagProvider>
  );
};

const RouteSwitch = ({ chain, chainConfigs }) => {
  useFavicon(chainConfigs.chains);
  return (
    <Switch>
      <Route exact path={`/`}>
        <HomePage chainConfigs={chainConfigs} />
      </Route>
      <Route exact path={`/portal/explore`}>
        <Explore chainConfigs={chainConfigs} />
      </Route>
      <Route exact path={`/portal/tools`}>
        <Tools chainConfigs={chainConfigs} />
      </Route>
      <Route exact path={`/portal/settings`}>
        <Settings />
      </Route>
      <Route exact path={`/portal/search`}>
        <SearchResultsWrapper chainConfigs={chainConfigs} />
      </Route>
      <Route exact path={`/${chain}`}>
        <Redirect to={`/`} />
      </Route>
      <Route exact path={`/${chain}/search`}>
        <Redirect to={`/`} />
      </Route>
      <Route exact path={`/${chain}/blocks`}>
        <BlockIndexPage chain={chain} />
      </Route>
      <Route strict path={`/${chain}/blocks/:hash`}>
        <BlockSummaryPage chain={chain} />
      </Route>
      <Route exact path={`/${chain}/transactions`}>
        <TransactionIndexPage chain={chain} />
      </Route>
      <Route strict path={`/${chain}/transactions/:hash`}>
        <TransactionPage chain={chain} />
      </Route>
      <Route exact path={`/${chain}/wallets/`}>
        <WalletIndexPage chain={chain} />
      </Route>
      <Route strict path={`/${chain}/wallets/:hash`}>
        <WalletPage chain={chain} />
      </Route>
      <Route strict path={`/${chain}/addresses/:hash`}>
        <AddressSummaryPage chain={chain} />
      </Route>
      <Route exact path={`/${chain}/graphs`}>
        <MyGraphsPage chain={chain} chainConfigs={chainConfigs} />
      </Route>
    </Switch>
  );
};
export default ChainApp;
