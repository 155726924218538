import { PortalProvider } from '@cbhq/cds-web/overlays/PortalProvider';
import { FeatureFlagProvider, ThemeProvider } from '@cbhq/cds-web/system';
import React from 'react';
import Navbar from './layout/Navbar';

const NavbarWrapper = (props) => {
  return (
    <FeatureFlagProvider flexGap frontierButton frontierColor>
      <ThemeProvider>
        <PortalProvider>
          <Navbar {...props} />
        </PortalProvider>
      </ThemeProvider>
    </FeatureFlagProvider>
  );
};

export default NavbarWrapper;
