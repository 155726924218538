import React, { Suspense, useContext, useEffect, useState } from 'react';
import { ErrorBoundary } from '../components/ErrorBoundary';

import { graphql, useLazyLoadQuery } from '@cbhq/data-layer';
import { Button } from '@cbhq/cds-web/buttons';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { TextTitle2, TextTitle3 } from '@cbhq/cds-web/typography';
import { GraphsList_Query } from './__generated__/GraphsList_Query.graphql';
import GraphCard from '../components/GraphCard';
import { LoadingBubbles } from '../components/LoadingBubbles';
import { NextPageCursorNavigator } from '../components/NextPageCursorNavigator';
import { useToggler } from '@cbhq/cds-web';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@cbhq/cds-web/overlays';
import { Select, SelectOption, TextInput } from '@cbhq/cds-web/controls';
import { CellMedia } from '@cbhq/cds-web/cells';
import { ICONS } from '../util/Icons';
import { PageDataContext } from '../util/PageDataContext';
import { OldGraphCard } from './OldGraphCard';
import { useLocalStorage } from 'usehooks-ts';
import { ALL_NETWORKS, DEFAULT_NETWORK } from './filters/NetworkFilter';

const graphDataQuery = graphql`
  query GraphsList_Query($after: String!, $tickerSymbol: TickerSymbol) {
    viewer {
      coinbaseAnalytics {
        graphsForUser(after: $after, tickerSymbol: $tickerSymbol) {
          edges {
            cursor
            node {
              id
              chain
              lastUpdated
              name
              wallets
              categories
              shared
              description
              snapshotUrl
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }
    }
  }
`;

export type UserDetails = {
  email: string;
  id: string;
  isActive: boolean;
  name: string;
};

export type CaseDetails = {
  id: string;
  created: string;
  lastUpdated: string;
  chain: string;
  title: string;
  subtitle?: string;
  folders: CaseFolder[];
};

export type CaseFolder = {
  id: string;
  name?: string;
};

export const GraphsList = ({
  chainConfigs,
  chain,
}: {
  chainConfigs: any;
  chain?: string;
}) => {
  const { userConfigs } = useContext(PageDataContext);

  const [createGraphModalVisible, { toggleOn, toggleOff }] = useToggler();

  const [state, setState] = useState({
    currentPageCursor: '',
    currentPage: 1,
  });
  const navigateToPage = (nextPage: number, nextPageCursor: string) => {
    setState({
      currentPageCursor: nextPageCursor,
      currentPage: nextPage,
    });
  };

  const result = useLazyLoadQuery<GraphsList_Query>(graphDataQuery, {
    after: state.currentPageCursor,
    tickerSymbol: chain ?? null,
  });

  const nextPageCursor =
    result.viewer.coinbaseAnalytics.graphsForUser.pageInfo.endCursor;

  const graphs = result.viewer.coinbaseAnalytics.graphsForUser.edges.map(
    (edge) => edge.node
  );

  return (
    <div
      className="wrapper"
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        display: 'flex',
        gap: 'var(--spacing-3)',
      }}
    >
      <div
        className="container"
        style={
          userConfigs.license.new_ui_navigation
            ? {
                maxWidth: 'fit-content',
                minWidth: '100%',
                justifySelf: 'center',
                padding: 0,
              }
            : {}
        }
      >
        <div className="page-header">
          <HStack justifyContent="space-between" alignItems="center">
            <TextTitle2 as="h2">{`${
              chain ? chainConfigs.configs[chain].name : 'All'
            } Graphs`}</TextTitle2>
            <Box>
              <Button
                startIcon="add"
                variant="positive"
                compact
                onPress={toggleOn}
              >
                New
              </Button>
            </Box>
          </HStack>
        </div>

        <ErrorBoundary>
          <Suspense fallback={<LoadingBubbles />}>
            {graphs.length !== 0 ? (
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: userConfigs.license.new_ui_navigation
                    ? 'repeat(auto-fill, minmax(370px, 1fr))'
                    : 'repeat(auto-fill, minmax(300px, 1fr))',
                  rowGap: 'var(--spacing-8)',
                  columnGap: 'var(--spacing-2)',
                }}
              >
                {graphs.map((graph) => {
                  return userConfigs.license.new_ui_navigation ? (
                    <GraphCard
                      key={graph.id}
                      {...graph}
                      chainConfigs={chainConfigs}
                    />
                  ) : (
                    <OldGraphCard
                      key={graph.id}
                      {...graph}
                      chainConfigs={chainConfigs}
                    />
                  );
                })}
              </div>
            ) : (
              <TextTitle3 as="h4" align="center">
                {`You haven't created any graphs.`}
              </TextTitle3>
            )}
            {graphs.length !== 0 && (
              <NextPageCursorNavigator
                currentPage={state.currentPage ? state.currentPage : 1}
                nextPageCursor={nextPageCursor}
                onClick={navigateToPage}
              />
            )}
          </Suspense>
        </ErrorBoundary>
      </div>

      <CreateGraphModal
        chainConfigs={chainConfigs}
        onRequestClose={toggleOff}
        visible={createGraphModalVisible}
        userConfigs={userConfigs}
      />
    </div>
  );
};

const CreateGraphModal = ({
  visible,
  onRequestClose,
  chainConfigs,
  userConfigs,
}: {
  visible: boolean;
  onRequestClose: () => void;
  chainConfigs: any;
  userConfigs: any;
}) => {
  const [localStorage] = useLocalStorage(
    'currentChain',
    undefined ?? DEFAULT_NETWORK
  );

  const [chainValue, setChainValue] = useState(
    userConfigs.license.new_ui_navigation
      ? localStorage === ALL_NETWORKS
        ? DEFAULT_NETWORK
        : localStorage
      : DEFAULT_NETWORK
  );

  useEffect(() => {
    if (userConfigs.license.new_ui_navigation) {
      setChainValue(
        localStorage === ALL_NETWORKS ? DEFAULT_NETWORK : localStorage
      );
    }
  }, [localStorage]);

  function formatSelectedAsset() {
    return (
      chainConfigs.configs[chainValue].name +
      ' (' +
      chainValue.toUpperCase() +
      ')'
    );
  }

  const getAuthenticityToken = () => {
    return window.$.rails.csrfToken();
  };

  return (
    <Modal visible={visible} onRequestClose={onRequestClose}>
      <ModalHeader title="New Graph" />
      <ModalBody>
        <div
          style={{ display: 'flex', justifyContent: 'center', height: '250px' }}
        >
          <form
            style={{
              position: 'absolute',
              width: '90%',
            }}
            id="create-graph"
            method="post"
            action={`/${chainValue}/graph/new_named`}
          >
            <VStack gap={2}>
              <TextInput
                label="Name"
                placeholder="Graph name"
                type="input"
                name="name"
              />
              <div
                style={{
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Select
                  label="Cryptocurrency"
                  value={formatSelectedAsset()}
                  placeholder="Select Asset"
                  onChange={setChainValue}
                  startNode={
                    <Box spacingHorizontal={2}>
                      <CellMedia type="asset" source={ICONS[chainValue]} />
                    </Box>
                  }
                >
                  {chainConfigs.chains.map(
                    (option) =>
                      chainConfigs.configs[option].enabled && (
                        <SelectOption
                          value={option}
                          key={option}
                          title={chainConfigs.configs[option].name}
                          media={
                            <CellMedia type="asset" source={ICONS[option]} />
                          }
                        />
                      )
                  )}
                </Select>
              </div>
            </VStack>
            <input
              type="hidden"
              name="authenticity_token"
              id="authenticity_token"
              value={getAuthenticityToken()}
            />
          </form>
        </div>
      </ModalBody>
      <ModalFooter
        primaryAction={
          <Button type="submit" form="create-graph">
            Create
          </Button>
        }
        secondaryAction={
          <Button variant="secondary" onPress={onRequestClose}>
            Cancel
          </Button>
        }
      />
    </Modal>
  );
};
