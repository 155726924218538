import React, { Suspense, useState } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { graphql } from '@cbhq/data-layer';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { LoadingBubbles } from '../../components/LoadingBubbles';
import { NextPageCursorNavigator } from '../../components/NextPageCursorNavigator';
import { WalletIndexPageQuery_WalletsQuery } from './__generated__/WalletIndexPageQuery_WalletsQuery.graphql';
import { ValueLabel } from '../../components/ValueLabel';
import { formatAmount, formatUsd } from '../../util/AmountUtils';

const walletIndexQuery = graphql`
  query WalletIndexPageQuery_WalletsQuery(
    $tickerSymbol: TickerSymbol!
    $after: String!
  ) {
    viewer {
      coinbaseAnalytics {
        walletsBySymbol(tickerSymbol: $tickerSymbol, after: $after) {
          edges {
            cursor
            node {
              id
              lastSeen
              hash
              numAddresses
              balanceAmount {
                value
                currency
              }
              balanceAmountUsd
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }
    }
  }
`;

// The component for rendering the wallets index page. Paging is only allowed via first page and next page.
export const WalletIndexPage: React.FC<{ chain: string }> = ({ chain }) => {
  return (
    <div className="container">
      <div className="page-header">
        <h2>Wallets active in the last 24 hours</h2>
        <ErrorBoundary>
          <Suspense fallback={<LoadingBubbles />}>
            <PageContent chain={chain} />
          </Suspense>
        </ErrorBoundary>
      </div>
    </div>
  );
};

// A button for page number navigation at the bottom of an index page. If onClick is not provided, assumes the button is
// active.
const PageContent: React.FC<{ chain: string }> = ({ chain }) => {
  const [state, setState] = useState({
    currentPageCursor: '',
    currentPage: 1,
  });
  const navigateToPage = (nextPage: number, nextPageCursor: string) => {
    setState({
      currentPageCursor: nextPageCursor,
      currentPage: nextPage,
    });
  };
  const data = useLazyLoadQuery<WalletIndexPageQuery_WalletsQuery>(
    walletIndexQuery,
    {
      tickerSymbol: chain,
      after: state.currentPageCursor,
    }
  );
  const nextPageCursor =
    data.viewer.coinbaseAnalytics.walletsBySymbol.pageInfo.endCursor;
  const wallets = data.viewer.coinbaseAnalytics.walletsBySymbol.edges.map(
    (edge) => edge.node
  );

  return (
    <React.Fragment>
      <table className="table ">
        <thead>
          <tr>
            <th>Time</th>
            <th>Wallet ID</th>
            <th>Name</th>
            <th className="text-right">Addresses</th>
            <th className="text-right">Value</th>
            <th className="text-right">Identified</th>
          </tr>
        </thead>
        <tbody>
          {wallets.map((wallet, index) => {
            {
              /* TODO(jaleel): Populate missing fields */
            }
            return (
              <WalletIndexRow
                key={`wallet-index-page-wallet-${index + 1}`}
                chain={chain}
                lastSeen={wallet.lastSeen}
                hash={wallet.hash}
                numAddresses={wallet.numAddresses}
                balanceAmount={wallet.balanceAmount}
                balanceAmountUsd={wallet.balanceAmountUsd}
                identified={''}
                name={''}
                showUsd={false}
              />
            );
          })}
        </tbody>
      </table>
      <NextPageCursorNavigator
        currentPage={state.currentPage ? state.currentPage : 1}
        nextPageCursor={nextPageCursor}
        onClick={navigateToPage}
      />
    </React.Fragment>
  );
};

// The component for rendering a single wallet in the index page list.
const WalletIndexRow: React.FC<{
  chain: string;
  lastSeen: string;
  hash: string;
  name: string;
  numAddresses: number;
  balanceAmount;
  balanceAmountUsd: string;
  identified: string;
  showUsd: boolean;
}> = (props) => {
  return (
    <tr>
      <td>{props.lastSeen}</td>
      <td>{props.hash}</td>
      <td width="30%" className="monospace">
        {props.name}
      </td>
      <td className="text-right">{props.numAddresses}</td>
      <td width="8%" className="text-right">
        <ValueLabel
          value={props.balanceAmount?.value}
          labelText={
            props.showUsd
              ? formatUsd(props.balanceAmountUsd)
              : formatAmount(props.balanceAmount, props.chain)
          }
          nonzeroLabelClass={'label-success'}
        />
      </td>
      <td className="text-right">{props.identified}</td>
    </tr>
  );
};
