/**
 * @generated SignedSource<<25e6887f98629b0beacac3ce2a7346f4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WalletAddresses_AddressRowFragment$data = {
  readonly hash: string;
  readonly lastSeen: any | null;
  readonly numTx: number | null;
  readonly metadata: {
    readonly name: string;
  } | null;
  readonly balanceAmount: {
    readonly value: any;
    readonly currency: any;
  } | null;
  readonly sentAmount: {
    readonly value: any;
    readonly currency: any;
  } | null;
  readonly receivedAmount: {
    readonly value: any;
    readonly currency: any;
  } | null;
  readonly balanceAmountUsd: any | null;
  readonly sentAmountUsd: any | null;
  readonly receivedAmountUsd: any | null;
  readonly " $fragmentType": "WalletAddresses_AddressRowFragment";
};
export type WalletAddresses_AddressRowFragment = WalletAddresses_AddressRowFragment$data;
export type WalletAddresses_AddressRowFragment$key = {
  readonly " $data"?: WalletAddresses_AddressRowFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"WalletAddresses_AddressRowFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currency",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WalletAddresses_AddressRowFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hash",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastSeen",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numTx",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CoinbaseAnalyticsAddressMetadata",
      "kind": "LinkedField",
      "name": "metadata",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Amount",
      "kind": "LinkedField",
      "name": "balanceAmount",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Amount",
      "kind": "LinkedField",
      "name": "sentAmount",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Amount",
      "kind": "LinkedField",
      "name": "receivedAmount",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "balanceAmountUsd",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sentAmountUsd",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "receivedAmountUsd",
      "storageKey": null
    }
  ],
  "type": "CoinbaseAnalyticsAddress",
  "abstractKey": null
};
})();

(node as any).hash = "d9b198d6fbb965191a45c65779c4d9fa";

export default node;
