/**
 * @generated SignedSource<<765e0edbeb7e0110dc93df3cb305ff49>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type WalletIndexPageQuery_WalletsQuery$variables = {
  tickerSymbol: any;
  after: string;
};
export type WalletIndexPageQuery_WalletsQueryVariables = WalletIndexPageQuery_WalletsQuery$variables;
export type WalletIndexPageQuery_WalletsQuery$data = {
  readonly viewer: {
    readonly coinbaseAnalytics: {
      readonly walletsBySymbol: {
        readonly edges: ReadonlyArray<{
          readonly cursor: string;
          readonly node: {
            readonly id: string;
            readonly lastSeen: any | null;
            readonly hash: string;
            readonly numAddresses: number | null;
            readonly balanceAmount: {
              readonly value: any;
              readonly currency: any;
            } | null;
            readonly balanceAmountUsd: any | null;
          } | null;
        }>;
        readonly pageInfo: {
          readonly endCursor: string;
          readonly hasNextPage: boolean;
        };
      } | null;
    };
  };
};
export type WalletIndexPageQuery_WalletsQueryResponse = WalletIndexPageQuery_WalletsQuery$data;
export type WalletIndexPageQuery_WalletsQuery = {
  variables: WalletIndexPageQuery_WalletsQueryVariables;
  response: WalletIndexPageQuery_WalletsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tickerSymbol"
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Viewer",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CoinbaseAnalytics",
        "kind": "LinkedField",
        "name": "coinbaseAnalytics",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "after",
                "variableName": "after"
              },
              {
                "kind": "Variable",
                "name": "tickerSymbol",
                "variableName": "tickerSymbol"
              }
            ],
            "concreteType": "CoinbaseAnalyticsWalletConnection",
            "kind": "LinkedField",
            "name": "walletsBySymbol",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CoinbaseAnalyticsWalletEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CoinbaseAnalyticsWallet",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastSeen",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hash",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "numAddresses",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Amount",
                        "kind": "LinkedField",
                        "name": "balanceAmount",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "value",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "currency",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "balanceAmountUsd",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "WalletIndexPageQuery_WalletsQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "WalletIndexPageQuery_WalletsQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "3ecffe6b6fb7e3d10876a816a83b4270",
    "id": null,
    "metadata": {},
    "name": "WalletIndexPageQuery_WalletsQuery",
    "operationKind": "query",
    "text": "query WalletIndexPageQuery_WalletsQuery(\n  $tickerSymbol: TickerSymbol!\n  $after: String!\n) {\n  viewer {\n    coinbaseAnalytics {\n      walletsBySymbol(tickerSymbol: $tickerSymbol, after: $after) {\n        edges {\n          cursor\n          node {\n            id\n            lastSeen\n            hash\n            numAddresses\n            balanceAmount {\n              value\n              currency\n            }\n            balanceAmountUsd\n          }\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6db234ecdb0c6c08f784aef173160569";

export default node;
