import { getAuthenticityToken, IResponse } from '.';

export const newBehaviorAnalysis = async (
  chain: string,
  walletId: string
): Promise<IResponse<{ id: string }>> => {
  const response = await fetch(
    `/${chain}/jobs?type=behavior&wallet=${walletId}&`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify({
        authenticity_token: getAuthenticityToken(),
      }),
    }
  );
  return response.json();
};
