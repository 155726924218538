import { useState, useEffect } from 'react';

const cachedScripts: string[] = [];

type LoadingStrategy = 'async' | 'defer' | 'onIdle';

/*
 * This hook takes in a URL for a script and loads it when the component using it is
 * rendered on the page.  This is used to bring in scripts like the google autocomplete
 * script.
 *
 * Adopted from https://github.cbhq.net/frontend/coinbase-www/tree/master/shared/src/utils/useScript
 */
export const useScript = (
  src: string | null,
  loadingStrategy: LoadingStrategy = 'async'
) => {
  // Keeping track of script loaded and error state
  const [state, setState] = useState({
    isLoaded: false,
    isError: false,
  });
  useEffect(() => {
    if (typeof document === 'undefined' || !src || !loadingStrategy) {
      return;
    }

    const requestIdleCallbackExists = !!window.requestIdleCallback;

    // If cachedScripts array already includes src that means another instance
    // of this hook already loaded this script, so no need to load again.
    if (cachedScripts.includes(src)) {
      setState({
        isLoaded: true,
        isError: false,
      });
    } else {
      cachedScripts.push(src); // Create script

      const script = document.createElement('script');
      script.src = src;
      script.async = loadingStrategy === 'async';
      script.defer =
        loadingStrategy === 'defer' ||
        (loadingStrategy === 'onIdle' && !requestIdleCallbackExists);

      // Script event listener callbacks for load and error
      const onScriptLoad = () => {
        setState({
          isLoaded: true,
          isError: false,
        });
      };

      const onScriptError = () => {
        // Remove from cachedScripts we can try loading again
        const index = cachedScripts.indexOf(src);
        if (index >= 0) cachedScripts.splice(index, 1);
        script.remove();
        setState({
          isLoaded: true,
          isError: true,
        });
      };

      script.addEventListener('load', onScriptLoad);
      script.addEventListener('error', onScriptError);

      if (document.body) {
        if (requestIdleCallbackExists && loadingStrategy === 'onIdle') {
          setTimeout(() => {
            window.requestIdleCallback(() => {
              document.body.appendChild(script);
            });
          }, 0);
        } else {
          document.body.appendChild(script);
        }
      }

      // Remove event listeners on cleanup
      return () => {
        script.removeEventListener('load', onScriptLoad);
        script.removeEventListener('error', onScriptError);
      };
    }
  }, [src, loadingStrategy]);
  return [state.isLoaded, state.isError];
};
