/**
 * @generated SignedSource<<7c7fe0d98f27eb3a828c6063b507d20b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CoinbaseAnalyticsInteractionsEntities = "ADDRESSES" | "WALLETS" | "%future added value";
export type CoinbaseAnalyticsInteractionsRisk = "LOW_RISK" | "MEDIUM_RISK" | "HIGH_RISK" | "UNKNOWN" | "%future added value";
export type Interactions_InteractionsQuery$variables = {
  entity: CoinbaseAnalyticsInteractionsEntities;
  uuid: any;
  tickerSymbol: any;
};
export type Interactions_InteractionsQueryVariables = Interactions_InteractionsQuery$variables;
export type Interactions_InteractionsQuery$data = {
  readonly viewer: {
    readonly coinbaseAnalytics: {
      readonly interactions: {
        readonly sent: ReadonlyArray<{
          readonly name: string | null;
          readonly wallet: {
            readonly hash: string;
          } | null;
          readonly categories: ReadonlyArray<string | null> | null;
          readonly risk: CoinbaseAnalyticsInteractionsRisk | null;
          readonly percent: number | null;
        } | null> | null;
        readonly received: ReadonlyArray<{
          readonly name: string | null;
          readonly wallet: {
            readonly hash: string;
          } | null;
          readonly categories: ReadonlyArray<string | null> | null;
          readonly risk: CoinbaseAnalyticsInteractionsRisk | null;
          readonly percent: number | null;
        } | null> | null;
      } | null;
    };
  };
};
export type Interactions_InteractionsQueryResponse = Interactions_InteractionsQuery$data;
export type Interactions_InteractionsQuery = {
  variables: Interactions_InteractionsQueryVariables;
  response: Interactions_InteractionsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "entity"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tickerSymbol"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "uuid"
},
v3 = [
  {
    "kind": "Variable",
    "name": "entity",
    "variableName": "entity"
  },
  {
    "kind": "Variable",
    "name": "tickerSymbol",
    "variableName": "tickerSymbol"
  },
  {
    "kind": "Variable",
    "name": "uuid",
    "variableName": "uuid"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hash",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "categories",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "risk",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "percent",
  "storageKey": null
},
v9 = [
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "CoinbaseAnalyticsWallet",
    "kind": "LinkedField",
    "name": "wallet",
    "plural": false,
    "selections": [
      (v5/*: any*/)
    ],
    "storageKey": null
  },
  (v6/*: any*/),
  (v7/*: any*/),
  (v8/*: any*/)
],
v10 = [
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "CoinbaseAnalyticsWallet",
    "kind": "LinkedField",
    "name": "wallet",
    "plural": false,
    "selections": [
      (v5/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  (v6/*: any*/),
  (v7/*: any*/),
  (v8/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "Interactions_InteractionsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CoinbaseAnalytics",
            "kind": "LinkedField",
            "name": "coinbaseAnalytics",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "CoinbaseAnalyticsInteractions",
                "kind": "LinkedField",
                "name": "interactions",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CoinbaseAnalyticsInteraction",
                    "kind": "LinkedField",
                    "name": "sent",
                    "plural": true,
                    "selections": (v9/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CoinbaseAnalyticsInteraction",
                    "kind": "LinkedField",
                    "name": "received",
                    "plural": true,
                    "selections": (v9/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "Interactions_InteractionsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CoinbaseAnalytics",
            "kind": "LinkedField",
            "name": "coinbaseAnalytics",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "CoinbaseAnalyticsInteractions",
                "kind": "LinkedField",
                "name": "interactions",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CoinbaseAnalyticsInteraction",
                    "kind": "LinkedField",
                    "name": "sent",
                    "plural": true,
                    "selections": (v10/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CoinbaseAnalyticsInteraction",
                    "kind": "LinkedField",
                    "name": "received",
                    "plural": true,
                    "selections": (v10/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5250eb16d1a6cce949064ec23989e6c5",
    "id": null,
    "metadata": {},
    "name": "Interactions_InteractionsQuery",
    "operationKind": "query",
    "text": "query Interactions_InteractionsQuery(\n  $entity: CoinbaseAnalyticsInteractionsEntities!\n  $uuid: Uuid!\n  $tickerSymbol: TickerSymbol!\n) {\n  viewer {\n    coinbaseAnalytics {\n      interactions(entity: $entity, uuid: $uuid, tickerSymbol: $tickerSymbol) {\n        sent {\n          name\n          wallet {\n            hash\n            id\n          }\n          categories\n          risk\n          percent\n        }\n        received {\n          name\n          wallet {\n            hash\n            id\n          }\n          categories\n          risk\n          percent\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8f59a0f607a744c9a538e74867905e32";

export default node;
