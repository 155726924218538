import React from 'react';

export interface IHeaderBlock {
  title: string;
  body: string;
  footer: string;
}

const HeaderBlock = (props: IHeaderBlock) => {
  return (
    <div className="header-block-container">
      <div className="header-block-title-text">{props.title}</div>
      <div className="header-block-body-text">{props.body}</div>
      <div className="header-block-footer-text">{props.footer}</div>
    </div>
  );
};

export default HeaderBlock;
