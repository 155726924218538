import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { Box, HStack } from '@cbhq/cds-web/layout';
import { NavigationBar, NavigationTitle } from '@cbhq/cds-web/navigation';
import { AvatarButton, IconButton } from '@cbhq/cds-web/buttons';
import { Pressable } from '@cbhq/cds-web/system';
import { TextHeadline } from '@cbhq/cds-web/typography';
import { TabNavigation } from '@cbhq/cds-web/tabs';
import { Divider } from '@cbhq/cds-web/layout/Divider';
import { ALL_NETWORKS, NetworkFilter } from '../filters/NetworkFilter';
import { useLocalStorage } from 'usehooks-ts';
import { routingContext } from './Sidebar';
import { GraphqlEnvProvider } from '../GraphqlEnvProvider';
import { useWindowWidth } from '@react-hook/window-size';
import { Dropdown } from '@cbhq/cds-web/dropdown';
import { SelectOption } from '@cbhq/cds-web/controls';
import { AppSwitcherMVP } from '@cbhq/identity';
import MultichainSearchInputWrapper from '../multichainSearch/MultichainSearchInputWrapper';

const Navbar = ({
  chain,
  chainConfigs,
  userConfigs,
  userleapEnvId,
  flags = { search: true },
}) => {
  const [localStorage, setLocalStorage] = useLocalStorage(
    'currentChain',
    undefined
  );
  const [graphTitle, setGraphTitle] = useState('Loading...');

  const path = window.location.pathname;
  const homePath =
    (chainConfigs.chains.includes(path.split('/')[1]) && !path.split('/')[2]) ||
    path === '/';

  const [primary, setPrimary] = useState('');
  const [accountSwitcher, setAccountSwitcher] = useState(undefined);
  const [selectedChain, setSelectedChain] = useState(localStorage ?? chain);

  const tabs = useMemo(
    () => [
      { id: 'graphs_tab', label: 'Graphs', route: '/' },
      {
        id: 'cases_tab',
        label: 'Cases',
        route: `/${
          selectedChain === ALL_NETWORKS ? chain : selectedChain
        }/cases`,
      },
      {
        id: 'bookmarks_tab',
        label: 'Bookmarks',
        route: `/${
          selectedChain === ALL_NETWORKS ? chain : selectedChain
        }/watchlists`,
      },
      {
        id: 'background_jobs_tab',
        label: 'Background jobs',
        route: `/${
          selectedChain === ALL_NETWORKS ? chain : selectedChain
        }/jobs`,
      },
    ],
    [selectedChain]
  );

  useEffect(() => {
    if (homePath) {
      setPrimary('graphs_tab');
    }
    if (path.includes('/cases')) {
      setPrimary('cases_tab');
    }
    if (path.includes('/watchlists')) {
      setPrimary('bookmarks_tab');
    }
    if (path.includes('/jobs')) {
      setPrimary('background_jobs_tab');
    }
  }, []);

  useEffect(() => {
    setLocalStorage(selectedChain);
  }, [selectedChain]);

  const handleHomepageTabRedirect = (newPath) => {
    setPrimary(newPath);

    let redirectTo = window.location.href;

    if (newPath === 'graphs_tab') {
      redirectTo = tabs[0].route;
    } else if (newPath === 'cases_tab') {
      redirectTo = tabs[1].route;
    } else if (newPath === 'bookmarks_tab') {
      redirectTo = tabs[2].route;
    } else if (newPath === 'background_jobs_tab') {
      redirectTo = tabs[3].route;
    }

    window.location.href = redirectTo;
  };

  const handleNetworkChange = (chain: string) => {
    setSelectedChain(chain);
    if (
      path.includes('/cases') ||
      path.includes('/watchlists') ||
      (path.includes('/jobs') && chain !== path.split('/')[1])
    )
      window.location.pathname = `${chain}/${path.split('/')[2]}`;
  };

  const activeRoute = routingContext();

  useEffect(() => {
    window.addEventListener('storage', (_) => {
      setGraphTitle(window.localStorage.getItem('graphTitle'));
    });
    () => {
      window.onstorage = null;
    };
  }, []);

  const navigationTitle = useMemo(() => {
    if (activeRoute.graph) return graphTitle;
    if (activeRoute.home || homePath) return 'My work';
    if (activeRoute.explore) return 'Explore';
    if (activeRoute.tools) return 'Tools';
    if (activeRoute.settings) return 'Settings';
  }, [activeRoute, homePath]);

  const logOut = async () => {
    try {
      const formData = new FormData();
      formData.append('authenticity_token', window.$.rails.csrfToken());
      formData.append('_method', 'delete');

      await fetch(`/users/sign_out`, {
        method: 'POST',
        credentials: 'include',
        body: formData,
      });
    } finally {
      window.location.reload();
    }
  };

  const content = useMemo(
    () => (
      <>
        <SelectOption
          compact
          value="Account"
          description="Account"
          onPress={() => {
            window.location.href = `/users/edit`;
          }}
        />
        <SelectOption
          compact
          value="logout"
          description="Log out"
          onPress={() => logOut()}
        />
      </>
    ),
    []
  );

  return (
    <>
      <GraphqlEnvProvider>
        <NavigationBar
          start={
            ((!homePath &&
              !path.includes('/portal/explore') &&
              !path.includes('/portal/tools') &&
              !path.includes('/portal/settings') &&
              !path.includes('/cases') &&
              !path.includes('/jobs') &&
              !path.includes('/watchlists') &&
              !path.includes(`/${selectedChain || chain}/graph/`)) ||
              path.includes(`/share`)) && (
              <IconButton
                name="backArrow"
                onPress={() => window.history.back()}
              />
            )
          }
          end={
            <HStack
              gap={2}
              alignContent="center"
              justifyContent="center"
              spacingEnd={1}
            >
              <AppSwitcherMVP
                shouldNotDisplay={{
                  tiles: [
                    'CB_ONE',
                    'DIRECT_DEPOSIT',
                    'DATA_MARKETPLACE',
                    'CARD',
                  ],
                }}
              />

              <HStack gap={1} alignItems="center">
                <Dropdown
                  onChange={setAccountSwitcher}
                  content={content}
                  width={250}
                >
                  <Pressable backgroundColor="transparent">
                    <HStack gap={1} alignItems="center">
                      <AvatarButton alt="avatar" compact />
                      <TextHeadline as="h2">{userConfigs.name}</TextHeadline>
                    </HStack>
                  </Pressable>
                </Dropdown>
              </HStack>
            </HStack>
          }
          bottom={
            (activeRoute.home || homePath) &&
            !path.includes(`/${selectedChain || chain}/graph/`) && (
              <TabNavigation
                value={primary}
                tabs={tabs}
                onChange={handleHomepageTabRedirect}
              />
            )
          }
        >
          <HStack alignItems="center" gap={1}>
            <NavigationTitle>{navigationTitle}</NavigationTitle>
            <Box width={0.2 * useWindowWidth()}></Box>
            <MultichainSearchInputWrapper />
          </HStack>
        </NavigationBar>
      </GraphqlEnvProvider>

      {(activeRoute.home || homePath) &&
        !path.includes(`/${selectedChain || chain}/graph/`) && (
          <>
            <Box spacing={2}>
              <NetworkFilter
                showAllNetworks={
                  !path.includes('/cases') &&
                  !path.includes('/watchlists') &&
                  !path.includes('/jobs')
                }
                chainConfigs={chainConfigs}
                chain={!homePath ? chain : selectedChain}
                onChange={handleNetworkChange}
              />
            </Box>
            <Divider direction="horizontal" />
          </>
        )}
    </>
  );
};

export default Navbar;
