import React from 'react';

// Adjusts the label display color according to whether the input value is nonzero or not.
export const ValueLabel: React.FC<{
  value: number;
  labelText: string;
  nonzeroLabelClass: string;
}> = (props) => {
  let labelClass = 'label-default';
  if (props.value != null && props.value > 0) {
    labelClass = props.nonzeroLabelClass;
  }

  return <span className={`label ${labelClass}`}>{props.labelText}</span>;
};
