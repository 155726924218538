import React, { useEffect, useState } from 'react';
import { TextDisplay2, TextTitle4 } from '@cbhq/cds-web/typography';
import { Pictogram } from '@cbhq/cds-web/illustrations/Pictogram';
import { graphql, useLazyLoadQuery } from '@cbhq/data-layer';
import { addressColumnsWithoutGeo, walletColumnsWithoutGeo } from './Columns';

import SearchResults from './SearchResults';
import { SearchResultsDataWrapper_SearchResultsPageQuery } from './__generated__/SearchResultsDataWrapper_SearchResultsPageQuery.graphql';

export type ISearchResultTypes = 'addresses' | 'wallets';

export const searchResultTypesMap = {
  wallets: 'wallet',
  addresses: 'address',
};

export const searchResultsPageQuery = graphql`
  query SearchResultsDataWrapper_SearchResultsPageQuery(
    $query: String!
    $tickerSymbol: TickerSymbol!
    $walletsAfter: String!
    $addressesAfter: String!
  ) {
    viewer {
      coinbaseAnalytics {
        wallets: searchResults(
          query: $query
          entity: WALLETS
          tickerSymbol: $tickerSymbol
          after: $walletsAfter
        ) {
          edges {
            node {
              uuid
              displayHash
              chain
              name
              labels
              addresses
              balanceAmountUsd
              sentAmountUsd
              receivedAmountUsd
              lastSeen
              geo
              type
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
        addresses: searchResults(
          query: $query
          entity: ADDRESSES
          tickerSymbol: $tickerSymbol
          after: $addressesAfter
        ) {
          edges {
            node {
              uuid
              displayHash
              chain
              name
              labels
              addresses
              balanceAmountUsd
              sentAmountUsd
              receivedAmountUsd
              lastSeen
              geo
              type
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    }
  }
`;

const SearchResultsDataWrapper = ({
  query,
  tickerSymbol,
  displayedEntity,
}: {
  query: string;
  tickerSymbol: string;
  displayedEntity: string;
}) => {
  const [walletsPagination, setWalletsPagination] = useState({
    currentPageCursor: '',
    currentPage: 1,
  });

  const [addressesPagination, setAddressesPagination] = useState({
    currentPageCursor: '',
    currentPage: 1,
  });

  const pageQueryResult =
    useLazyLoadQuery<SearchResultsDataWrapper_SearchResultsPageQuery>(
      searchResultsPageQuery,
      {
        query,
        walletsAfter: `${walletsPagination.currentPage}`,
        addressesAfter: `${addressesPagination.currentPage}`,
        tickerSymbol: tickerSymbol,
      }
    );

  const walletsData = pageQueryResult.viewer.coinbaseAnalytics.wallets.edges;
  const walletsEndCursor =
    pageQueryResult.viewer.coinbaseAnalytics.wallets.pageInfo.endCursor;

  const addressesData =
    pageQueryResult.viewer.coinbaseAnalytics.addresses.edges;
  const addressEndCursor =
    pageQueryResult.viewer.coinbaseAnalytics.addresses.pageInfo.endCursor;

  useEffect(() => {
    setAddressesPagination({ currentPage: 1, currentPageCursor: '' });
    setWalletsPagination({ currentPage: 1, currentPageCursor: '' });
  }, [tickerSymbol]);

  const navigateToPage = (nextPage: number, nextPageCursor: string) => {
    displayedEntity === 'addresses'
      ? setAddressesPagination({
          currentPageCursor: nextPageCursor,
          currentPage: nextPage,
        })
      : setWalletsPagination({
          currentPageCursor: nextPageCursor,
          currentPage: nextPage,
        });
  };

  const getData = {
    addresses: {
      columns: addressColumnsWithoutGeo,
      result: addressesData,
    },
    wallets: {
      columns: walletColumnsWithoutGeo,
      result: walletsData,
    },
  };
  const { columns, result } = getData[displayedEntity];
  const noResultsFound =
    getData.wallets.result.length == 0 && getData.addresses.result.length == 0;

  return (
    <>
      {result ? (
        noResultsFound ? (
          <div
            style={{
              width: '110%',
              height: '50em',
              backgroundColor: '#EEF0F3',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '-12px 0px 0px -20px',
            }}
          >
            <div style={{ marginBottom: 12 }}>
              <Pictogram name="exploreGray" />
            </div>
            <TextTitle4 as="p" color="foregroundMuted">
              {`We couldn’t find anything matching "${query}".`}
            </TextTitle4>
          </div>
        ) : (
          <SearchResults
            columns={columns}
            result={result}
            type={displayedEntity as ISearchResultTypes}
            nextPageCursor={
              displayedEntity === 'addresses'
                ? addressEndCursor
                : walletsEndCursor
            }
            navigateToPage={navigateToPage}
            currentPage={
              displayedEntity === 'addresses'
                ? addressesPagination.currentPage
                : walletsPagination.currentPage
            }
          />
        )
      ) : (
        <TextDisplay2 as="p">
          Failed to fetch search results. Try again later.
        </TextDisplay2>
      )}
    </>
  );
};

export default SearchResultsDataWrapper;
