import { useEffect } from 'react';
import { useScript } from './useScript';

// Increment version if new js code is deployed to static assets
const VERSION = 'v2.0.0';

const BASE_URL = `https://static-assets.coinbase.com/js/userleap/${VERSION}`;
const CONTROLLER_SDK_URL = `${BASE_URL}/web-controller-sdk.js`;
const VIEW_SDK_URL = `${BASE_URL}/web-view-sdk.js`;

const InitUserLeap: React.FC<{
  userleapEnvId: string;
  userId: string;
}> = ({ userleapEnvId, userId }) => {
  useEffect(() => {
    if (typeof window !== 'undefined' && userleapEnvId) {
      // Unable to type external interface
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).UserLeap = function (...args: any[]) {
        if (ulSDK._queue) ulSDK._queue.push(args);
      };

      const ulSDK = window.UserLeap;

      ulSDK._queue = [];

      ulSDK.config = {
        envId: userleapEnvId,
        controllerSDKURL: CONTROLLER_SDK_URL,
        viewSDKURL: VIEW_SDK_URL,
        fontFamily: `'Graphik', 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Helvetica', 'Arial', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`,
      };
      ulSDK('setUserId', userId);
    }
  }, []);

  // Loading userLeap script
  const [isLoaded, isError] = useScript(CONTROLLER_SDK_URL, 'onIdle');
  useEffect(() => {
    if (isLoaded && isError) {
      console.log({
        error: new Error('UserLeap script could not be loaded'),
      });
    }
  }, [isLoaded, isError]);

  return null;
};

export default InitUserLeap;
