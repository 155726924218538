/**
 * @generated SignedSource<<0bc4a53fabf792f595f10fba4f4e0fcd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FundsExposure_ExposureTableFragment$data = {
  readonly links: ReadonlyArray<{
    readonly type: string | null;
    readonly walletName: string | null;
    readonly categories: ReadonlyArray<string> | null;
    readonly country: string | null;
    readonly linkFactor: number | null;
    readonly mixed: boolean | null;
    readonly numTx: number | null;
    readonly pathLength: number | null;
    readonly path: ReadonlyArray<string> | null;
  }> | null;
  readonly " $fragmentType": "FundsExposure_ExposureTableFragment";
};
export type FundsExposure_ExposureTableFragment = FundsExposure_ExposureTableFragment$data;
export type FundsExposure_ExposureTableFragment$key = {
  readonly " $data"?: FundsExposure_ExposureTableFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"FundsExposure_ExposureTableFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FundsExposure_ExposureTableFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CoinbaseAnalyticsExposureLink",
      "kind": "LinkedField",
      "name": "links",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "walletName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "categories",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "country",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "linkFactor",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mixed",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numTx",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pathLength",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "path",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CoinbaseAnalyticsExposure",
  "abstractKey": null
};

(node as any).hash = "95dba52fed5dbe7676b888afdb5c7961";

export default node;
