import React from 'react';

const CasesPanel = ({ cases, hash }) => {
  const handleAddItemToCase = (source, data) => {
    const caseModal = window.xflow.addToCaseModal(source);
    caseModal.onCaseClick(data);
  };
  return (
    <div className="panel panel-default panel-sidebar">
      <div className="panel-heading">Add to cases</div>
      {cases.length > 0 && (
        <div className="panel-body">
          <ul className="list-unstyled">
            {cases.map((c) => (
              <li key={c._id.$oid}>
                <a
                  href="#"
                  data-element-ids={hash}
                  onClick={(e) => handleAddItemToCase(e.target, c)}
                >
                  {c.t}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="panel-footer">
        <a
          className="panel-action"
          href="#"
          data-element-ids={hash}
          onClick={(e) => window.xflow.addToCaseModal(e.target)}
        >
          + New case
        </a>
      </div>
    </div>
  );
};

export default CasesPanel;
