import React from 'react';
import { useParams } from 'react-router-dom';
import { graphql } from '@cbhq/data-layer';
import { useLazyLoadQuery } from 'react-relay';
import RiskScoreLabel from './RiskScoreLabel';
import { TransactionRiskScore_TransactionQuery } from './__generated__/TransactionRiskScore_TransactionQuery.graphql';

const transactionRiskQuery = graphql`
  query TransactionRiskScore_TransactionQuery(
    $tickerSymbol: TickerSymbol!
    $transactionId: String!
  ) {
    viewer {
      coinbaseAnalytics {
        transactionByHashAndSymbol(
          hash: $transactionId
          tickerSymbol: $tickerSymbol
        ) {
          risk {
            score
            factors {
              positive {
                desc
              }
              negative {
                modifier
                desc
              }
            }
          }
        }
      }
    }
  }
`;

export const TransactionRiskScore: React.FC<{ chain: string }> = (props) => {
  const { hash } = useParams();
  const data = useLazyLoadQuery<TransactionRiskScore_TransactionQuery>(
    transactionRiskQuery,
    {
      transactionId: hash,
      tickerSymbol: props.chain,
    }
  );
  const risk = data.viewer.coinbaseAnalytics.transactionByHashAndSymbol.risk;
  return (
    risk && (
      <RiskScoreLabel
        score={risk?.score}
        factors={risk?.factors}
        customKey={hash}
      />
    )
  );
};
