import { IResponse } from '.';

export interface ICase {
  id: string;
  created: string;
  lastUpdated: string;
  chain: string;
  title: string;
  subtitle?: string;
  folders: [
    {
      id: string;
      name: string;
    }
  ];
}

export const getCases = async (chain: string): Promise<IResponse<ICase[]>> => {
  const response = await fetch(`/api/internal/cases?asset=${chain}`, {
    method: 'GET',
  });
  return response.json();
};

export const createCase = async (chain: string): Promise<IResponse<ICase>> => {
  const response = await fetch(`/api/internal/cases?asset=${chain}`, {
    method: 'POST',
  });
  return response.json();
};

export const addItemToCase = async (
  chain: string,
  caseId: string,
  itemId: string
) => {
  const response = await fetch(
    `/api/internal/cases/${caseId}/add_items?asset=${chain}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify({
        text: itemId,
      }),
    }
  );
  return response.json();
};
