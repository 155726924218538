import { TextLabel1 } from '@cbhq/cds-web/typography';
import React from 'react';
import { HIGH_RISK_LABELS, MEDIUM_RISK_LABELS } from '../pages/search/Labels';

export const HIGH_RISK_COLORS = {
  backgroundColor: 'rgba(207, 32, 47, 1)',
  textColor: 'positiveForeground',
};

export const MEDIUM_RISK_COLORS = {
  backgroundColor: 'rgba(213, 173, 54, 1)',
  textColor: 'foreground',
};

export const LOW_OR_NO_RISK_COLORS = {
  backgroundColor: 'rgba(222, 225, 231, 1)',
  textColor: 'foreground',
};

const upperCaseCategories = ['csam', 'ofac', 'atm', 'kyc'];
const KYCWalletCategory: React.FC<{
  category: string;
  spacingBottom?: string;
}> = (props) => {
  const { category } = props;
  let name = category.replace('_', ' ');
  if (upperCaseCategories.includes(name)) {
    name = name.toUpperCase();
  } else if (category === 'nokyc') {
    name = 'NO KYC';
  } else if (category === 'fincen_special_measures') {
    name = 'FinCEN Special Measures';
  } else {
    name = name[0].toUpperCase() + name.slice(1);
  }

  let colors;
  if (HIGH_RISK_LABELS.includes(category)) {
    colors = HIGH_RISK_COLORS;
  } else if (MEDIUM_RISK_LABELS.includes(category)) {
    colors = MEDIUM_RISK_COLORS;
  } else {
    colors = LOW_OR_NO_RISK_COLORS;
  }

  const style = {} as any;
  if (props.spacingBottom) {
    style.marginBottom = props.spacingBottom;
  }

  return (
    <div
      style={{
        borderRadius: 4,
        padding: '2px 4px 2px 4px',
        backgroundColor: colors.backgroundColor,
        display: 'inline-block',
        marginRight: '8px',
        ...style,
      }}
    >
      <TextLabel1 as={'p'} color={colors.textColor}>
        {name}
      </TextLabel1>
    </div>
  );
};

export default KYCWalletCategory;
