import React from 'react';
import ReactTooltip from 'react-tooltip';
import { Checkmark, Cross } from '../components/Icons';
import { RiskScoreLabelWrapper_WalletRiskQueryResponse } from './__generated__/RiskScoreLabelWrapper_WalletRiskQuery.graphql';
import { RiskScoreLabelWrapper_AddressRiskQueryResponse } from './__generated__/RiskScoreLabelWrapper_AddressRiskQuery.graphql';
import { HIGH_RISK_COLORS, MEDIUM_RISK_COLORS } from './KYCWalletCategory';

const mapRiskColors = {
  lowRisk: ['A', 'A+', 'A-'],
  mediumRisk: ['B', 'B+', 'B-'],
  highRisk: ['C', 'C+', 'C-'],
};

const LOW_RISK_COLORS = {
  backgroundColor: 'positive',
  textColor: 'positiveForeground',
};

function riskScoreStyleClass(score: string): string {
  switch (score) {
    case 'A':
      return 'label-success';
    case 'A+':
      return 'label-success';
    case 'A-':
      return 'label-success';
    case 'B+':
      return 'label-warning';
    case 'B':
      return 'label-warning';
    case 'B-':
      return 'label-warning';
    case 'C+':
      return 'label-danger';
    case 'C':
      return 'label-danger';
    case 'C-':
      return 'label-danger';
    default:
      return 'label-warning';
  }
}

type WalletRiskScoreFactors =
  RiskScoreLabelWrapper_WalletRiskQueryResponse['viewer']['coinbaseAnalytics']['walletByHashAndSymbol']['risk']['factors'];
type AddressRiskScoreFactors =
  RiskScoreLabelWrapper_AddressRiskQueryResponse['viewer']['coinbaseAnalytics']['addressByHashAndSymbol']['risk']['factors'];

type RiskScoreFactors = WalletRiskScoreFactors | AddressRiskScoreFactors;
export interface IRiskScoreLabelProps {
  score: string;
  factors?: RiskScoreFactors;
  customKey?: string;
  useCDScolors?: boolean;
}

const RiskScoreLabel = (props: IRiskScoreLabelProps) => {
  const canShowTooltip =
    props.factors &&
    (props.factors.positive.length > 0 || props.factors.negative.length > 0);

  let colors;
  if (mapRiskColors.highRisk.includes(props.score)) {
    colors = HIGH_RISK_COLORS;
  } else if (mapRiskColors.mediumRisk.includes(props.score)) {
    colors = MEDIUM_RISK_COLORS;
  } else {
    colors = LOW_RISK_COLORS;
  }

  return (
    <div
      style={{ display: 'inline-block', paddingRight: '4px' }}
      key={props.customKey}
    >
      <div
        data-border={true}
        data-effect="solid"
        data-tip="necessary for react-tooltip"
        data-for={`risk-score-explanation-${props.customKey}`}
        data-place="bottom"
        data-background-color="white"
        data-border-color="#CCC"
        data-class={`tooltip-container`}
        className={`label label-score ${riskScoreStyleClass(props.score)}`}
        style={
          props.useCDScolors
            ? { background: colors.backgroundColor, color: colors.textColor }
            : {}
        }
        data-offset="{'top': -25}"
      >
        {props.score}
      </div>
      {canShowTooltip && (
        <ReactTooltip id={`risk-score-explanation-${props.customKey}`}>
          <RiskScorePopover
            redFactors={props.factors.negative}
            greenFactors={props.factors.positive}
          />
        </ReactTooltip>
      )}
    </div>
  );
};

/* greenAttributes will be displayed in green text with a green checkmark, redAttributes will be displayed in red
   text with a red cross. */
const RiskScorePopover: React.FC<{
  greenFactors;
  redFactors;
}> = (props) => {
  return (
    <table className={'tooltip-content'}>
      <colgroup>
        <col width="10%" />
        <col width="90%" />
      </colgroup>
      <tbody>
        {props.redFactors.map((factor, index) => {
          return <RedRow key={`${factor}/${index}`} text={factor.desc} />;
        })}
        {props.greenFactors.map((factor, index) => {
          return <GreenRow key={`${factor}/${index}`} text={factor.desc} />;
        })}
      </tbody>
    </table>
  );
};

const RedRow: React.FC<{ text: string }> = (props) => {
  return (
    <tr>
      <td>
        <Cross color={'#A94442'} />
      </td>
      <td>
        <span style={{ color: '#A94442' }}>{props.text}</span>
      </td>
    </tr>
  );
};

const GreenRow: React.FC<{ text: string }> = (props) => {
  return (
    <tr>
      <td>
        <Checkmark color={'#3C763D'} />
      </td>
      <td>
        <div style={{ color: '#3C763D' }}>{props.text}</div>
      </td>
    </tr>
  );
};

export default RiskScoreLabel;
