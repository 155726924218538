// TODO Merge these lists with XFlow::Category (need to keep in sync)
export enum CategoryType {
  DEFAULT,
  ILLEGAL,
  SUSPICIOUS,
  LEGAL, // Law enforcement or auction
  REPOSITORY,
  GENERATED,
  GOVERNANCE,
}

export const illegalList = [
  'scam',
  'ransom',
  'theft',
  'illegal',
  'darknet',
  'malware',
  'terrorism',
  'mixer',
  'laundering',
  'csam',
  'ofac',
  'weapons',
  'drugs',
  'violence',
  'fincen_special_measures',
];
export const suspiciousList = ['gambling', 'nokyc', 'aet'];
export const legalList = ['auction', 'law_enforcement', 'kyc'];
export const repositoryList = ['custodian', 'exchange', 'atm', 'processor'];
export const generatedList = ['miner', 'pool', 'faucet'];
export const governanceList = ['masternode', 'token_team'];

export const allCategories = illegalList.concat(
  suspiciousList,
  legalList,
  repositoryList,
  generatedList,
  governanceList
);

export const GetCategoryType = (category: string) => {
  if (illegalList.includes(category)) {
    return CategoryType.ILLEGAL;
  } else if (suspiciousList.includes(category)) {
    return CategoryType.SUSPICIOUS;
  } else if (legalList.includes(category)) {
    return CategoryType.LEGAL;
  } else if (repositoryList.includes(category)) {
    return CategoryType.REPOSITORY;
  } else if (generatedList.includes(category)) {
    return CategoryType.GENERATED;
  } else if (governanceList.includes(category)) {
    return CategoryType.GOVERNANCE;
  }
  return CategoryType.DEFAULT;
};

export const GetCategoryColor = (category: string) => {
  let displayType = '#21C25C'; //success
  const type = GetCategoryType(category);
  if (type === CategoryType.GENERATED) {
    displayType = '#307cb4'; //primary
  } else if (type === CategoryType.SUSPICIOUS) {
    displayType = '#F8AB3E'; //warning
  } else if (type === CategoryType.ILLEGAL) {
    displayType = '#E93743'; //danger
  }
  if (category === 'kyc') {
    displayType = '#21C25C'; //success
  } else if (category === 'nokyc') {
    displayType = '#F8AB3E'; //warning
  } else if (category === 'fiat') {
    displayType = '#307cb4'; //primary
  }
  return displayType;
};
