/**
 * @generated SignedSource<<fe1db015afc718b9e7c5e93800f36270>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TransactionWallets_TransactionQuery$variables = {
  tickerSymbol: any;
  transactionId: string;
};
export type TransactionWallets_TransactionQueryVariables = TransactionWallets_TransactionQuery$variables;
export type TransactionWallets_TransactionQuery$data = {
  readonly viewer: {
    readonly coinbaseAnalytics: {
      readonly transactionByHashAndSymbol: {
        readonly isUtxo: boolean | null;
        readonly wallets: {
          readonly inputs: ReadonlyArray<{
            readonly addressHash: string;
            readonly walletHash: string;
            readonly name: string;
            readonly balance: {
              readonly value: any;
              readonly currency: any;
            };
            readonly numAddresses: number;
          }> | null;
          readonly outputs: ReadonlyArray<{
            readonly addressHash: string;
            readonly walletHash: string;
            readonly name: string;
            readonly balance: {
              readonly value: any;
              readonly currency: any;
            };
            readonly numAddresses: number;
          }> | null;
        } | null;
      } | null;
    };
  };
};
export type TransactionWallets_TransactionQueryResponse = TransactionWallets_TransactionQuery$data;
export type TransactionWallets_TransactionQuery = {
  variables: TransactionWallets_TransactionQueryVariables;
  response: TransactionWallets_TransactionQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tickerSymbol"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "transactionId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "hash",
    "variableName": "transactionId"
  },
  {
    "kind": "Variable",
    "name": "tickerSymbol",
    "variableName": "tickerSymbol"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isUtxo",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "addressHash",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "walletHash",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Amount",
  "kind": "LinkedField",
  "name": "balance",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numAddresses",
  "storageKey": null
},
v8 = [
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = [
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/),
  (v9/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TransactionWallets_TransactionQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CoinbaseAnalytics",
            "kind": "LinkedField",
            "name": "coinbaseAnalytics",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "CoinbaseAnalyticsTransaction",
                "kind": "LinkedField",
                "name": "transactionByHashAndSymbol",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CoinbaseAnalyticsTransactionWallets",
                    "kind": "LinkedField",
                    "name": "wallets",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CoinbaseAnalyticsTransactionWallet",
                        "kind": "LinkedField",
                        "name": "inputs",
                        "plural": true,
                        "selections": (v8/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CoinbaseAnalyticsTransactionWallet",
                        "kind": "LinkedField",
                        "name": "outputs",
                        "plural": true,
                        "selections": (v8/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TransactionWallets_TransactionQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CoinbaseAnalytics",
            "kind": "LinkedField",
            "name": "coinbaseAnalytics",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "CoinbaseAnalyticsTransaction",
                "kind": "LinkedField",
                "name": "transactionByHashAndSymbol",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CoinbaseAnalyticsTransactionWallets",
                    "kind": "LinkedField",
                    "name": "wallets",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CoinbaseAnalyticsTransactionWallet",
                        "kind": "LinkedField",
                        "name": "inputs",
                        "plural": true,
                        "selections": (v10/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CoinbaseAnalyticsTransactionWallet",
                        "kind": "LinkedField",
                        "name": "outputs",
                        "plural": true,
                        "selections": (v10/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5c721a9abc1fd1a5a248cd6c263d4707",
    "id": null,
    "metadata": {},
    "name": "TransactionWallets_TransactionQuery",
    "operationKind": "query",
    "text": "query TransactionWallets_TransactionQuery(\n  $tickerSymbol: TickerSymbol!\n  $transactionId: String!\n) {\n  viewer {\n    coinbaseAnalytics {\n      transactionByHashAndSymbol(hash: $transactionId, tickerSymbol: $tickerSymbol) {\n        isUtxo\n        wallets {\n          inputs {\n            addressHash\n            walletHash\n            name\n            balance {\n              value\n              currency\n            }\n            numAddresses\n            id\n          }\n          outputs {\n            addressHash\n            walletHash\n            name\n            balance {\n              value\n              currency\n            }\n            numAddresses\n            id\n          }\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "85c293f48686dbda434b7bf3288f9714";

export default node;
