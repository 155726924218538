import React, {
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  NavLink,
  useParams,
  useRouteMatch,
  useHistory,
} from 'react-router-dom';
import TransactionPageContent from './TransactionPageContent';
import { LoadingBubbles } from '../../components/LoadingBubbles';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { graphql } from '@cbhq/data-layer';
import { useLazyLoadQuery } from 'react-relay';
import { TransactionRiskScore } from '../../components/TransactionRiskScore';
import {
  HeaderBreadcrumbBlock,
  HeaderBreadcrumbs,
} from '../../components/HeaderBreadcrumbs';
import { TransactionPage_TransactionQuery } from './__generated__/TransactionPage_TransactionQuery.graphql';
import { PageDataContext } from '../../util/PageDataContext';
import { HStack } from '@cbhq/cds-web/layout';
import { TabNavigation } from '@cbhq/cds-web/tabs';

const transactionQuery = graphql`
  query TransactionPage_TransactionQuery(
    $tickerSymbol: TickerSymbol!
    $transactionId: String!
  ) {
    viewer {
      coinbaseAnalytics {
        transactionByHashAndSymbol(
          hash: $transactionId
          tickerSymbol: $tickerSymbol
        ) {
          ...DemixToolInputsFragment
          ...TransactionAdditionalInfo_metadata
          ...TransactionDetailsTable
          ...TransactionPageContent
          ...TransactionFlowTableFragment
        }
      }
    }
  }
`;

export const TransactionPage: React.FC<{ chain: string }> = (props) => {
  const { hash } = useParams();
  const { url } = useRouteMatch();

  const { userConfigs } = useContext(PageDataContext);
  const history = useHistory();

  const [primary, setPrimary] = useState('');

  const tabs = useMemo(
    () => [
      { id: 'summary_tab', label: 'Summary' },
      { id: 'wallets_tab', label: 'Wallets' },
      { id: 'scripts_tab', label: 'Scripts' },
    ],
    []
  );

  useEffect(() => {
    const currentPath = window.location.pathname;

    if (currentPath.includes('/wallets')) {
      setPrimary('wallets_tab');
    } else if (currentPath.includes('/scripts')) {
      setPrimary('scripts_tab');
    } else {
      setPrimary('summary_tab');
    }
  }, []);

  const handleRedirect = useCallback(
    (newPath) => {
      setPrimary(newPath);

      if (newPath === 'summary_tab') {
        history.push(url);
      }
      if (newPath === 'wallets_tab') {
        history.push(`${url}/wallets`);
      }
      if (newPath === 'scripts_tab') {
        history.push(`${url}/scripts`);
      }
    },
    [primary, tabs]
  );

  return (
    <>
      {userConfigs.license.new_ui_navigation && (
        <HStack
          spacingHorizontal={3}
          spacingVertical={1}
          borderedBottom
          dangerouslySetStyle={{ margin: '0 -2rem', paddingBottom: 0 }}
        >
          <TabNavigation
            value={primary}
            tabs={tabs}
            onChange={handleRedirect}
          />
        </HStack>
      )}
      <div className="container-fluid container-max-xl">
        <HeaderBreadcrumbs
          blocks={[
            <HeaderBreadcrumbBlock
              key="transaction-page-header-breadcrumb-1"
              hash={hash}
              label={'Transaction'}
              riskLabel={<TransactionRiskScore chain={props.chain} />}
            />,
          ]}
        />

        {!userConfigs.license.new_ui_navigation && (
          <ul className="nav nav-pills">
            <li>
              <NavLink to={url} exact>
                <b>Summary</b>
              </NavLink>
            </li>
            <li>
              <NavLink to={`${url}/wallets`} exact>
                <b>Wallets</b>
              </NavLink>
            </li>
            <li>
              <NavLink to={`${url}/scripts`} exact>
                <b>Scripts</b>
              </NavLink>
            </li>
          </ul>
        )}

        <ErrorBoundary>
          <Suspense fallback={<LoadingBubbles />}>
            <TransactionPageBody
              chain={props.chain}
              onTransactionLoad={() => {}}
            />
          </Suspense>
        </ErrorBoundary>
      </div>
    </>
  );
};

// This component exists because `TransactionPageContent` needs to be wrapped in `Suspense`
const TransactionPageBody: React.FC<{
  chain: string;
  onTransactionLoad: Function;
}> = (props) => {
  const { hash } = useParams();
  const data = useLazyLoadQuery<TransactionPage_TransactionQuery>(
    transactionQuery,
    {
      transactionId: hash,
      tickerSymbol: props.chain,
    }
  );

  const transactionRef =
    data.viewer.coinbaseAnalytics.transactionByHashAndSymbol;
  useEffect(() => {
    props.onTransactionLoad && props.onTransactionLoad(transactionRef);
  }, []);

  return (
    <TransactionPageContent
      chain={props.chain}
      transactionRef={transactionRef}
    />
  );
};
