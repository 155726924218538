import React, { Suspense, useCallback, useContext, useState } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { graphql } from '@cbhq/data-layer';
import { useToggler } from '@cbhq/cds-common/hooks/useToggler';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { LoadingBubbles } from '../../components/LoadingBubbles';
import { TransactionIndexPageQuery_TransactionsQuery } from './__generated__/TransactionIndexPageQuery_TransactionsQuery.graphql';
import { NextPageCursorNavigator } from '../../components/NextPageCursorNavigator';
import { ChainLink } from '../../components/ChainLink';
import { PageDataContext } from '../../util/PageDataContext';
import BootstrapSwitch from '../../components/BootstrapSwitch';

const transactionIndexPageQuery = graphql`
  query TransactionIndexPageQuery_TransactionsQuery(
    $tickerSymbol: TickerSymbol!
    $after: String!
  ) {
    viewer {
      coinbaseAnalytics {
        transactionsBySymbol(tickerSymbol: $tickerSymbol, after: $after) {
          edges {
            cursor
            node {
              id
              hash
              realTime
              blockTime: time
              outAmount {
                value
              }
              outAmountUsd
              identifiedTransaction
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }
    }
  }
`;

export const TransactionIndexPage: React.FC<{ chain: string }> = ({
  chain,
}) => {
  return (
    <div className="container-fluid container-max-xl">
      <div className="page-header">
        <h2>Transactions made in the last 24 hours</h2>
        <ErrorBoundary>
          <Suspense fallback={<LoadingBubbles />}>
            <PageContent chain={chain} />
          </Suspense>
        </ErrorBoundary>
      </div>
    </div>
  );
};

const PageContent: React.FC<{ chain: string }> = ({ chain }) => {
  const { userConfigs } = useContext(PageDataContext);
  const showIdentifiedColumn = userConfigs.policies.geo_tracking_index;

  const [isChecked, { toggle }] = useToggler(true);

  const label = isChecked ? chain.toUpperCase() : 'USD';

  const handleSwitchChange = useCallback(() => {
    toggle();
  }, [toggle]);

  const [state, setState] = useState({
    currentPageCursor: '',
    currentPage: 1,
  });

  const navigateToPage = (nextPage: number, nextPageCursor: string) => {
    setState({
      currentPageCursor: nextPageCursor,
      currentPage: nextPage,
    });
  };

  const data = useLazyLoadQuery<TransactionIndexPageQuery_TransactionsQuery>(
    transactionIndexPageQuery,
    {
      tickerSymbol: chain,
      after: state.currentPageCursor,
    }
  );

  const nextPageCursor =
    data.viewer.coinbaseAnalytics.transactionsBySymbol.pageInfo.endCursor;

  const transactions =
    data.viewer.coinbaseAnalytics.transactionsBySymbol.edges.map(
      (edge) => edge.node
    );

  return (
    <React.Fragment>
      <span className="pull-right">
        <BootstrapSwitch
          identifier="transactions-usd-switch"
          onSwitch={handleSwitchChange}
          isChecked={isChecked}
          dataOn={chain.toUpperCase()}
          dataOff="USD"
        />
      </span>
      <table className="table ">
        <thead>
          <tr>
            <th>Time</th>
            <th>Blocktime</th>
            <th>Transaction Hash</th>
            <th className="text-right">Value</th>
            {showIdentifiedColumn && <th className="text-right">Identified</th>}
          </tr>
        </thead>
        <tbody>
          {transactions.map(
            ({
              hash,
              realTime,
              blockTime,
              outAmount: { value },
              outAmountUsd,
              identifiedTransaction,
            }) => {
              return (
                <TransactionRow
                  key={hash}
                  realTime={realTime}
                  blockTime={blockTime}
                  hash={hash}
                  value={`${isChecked ? value : outAmountUsd} ${label}`}
                  showIdentifiedColumn={showIdentifiedColumn}
                  identifiedTransaction={identifiedTransaction}
                />
              );
            }
          )}
        </tbody>
      </table>
      <NextPageCursorNavigator
        currentPage={state.currentPage ? state.currentPage : 1}
        nextPageCursor={nextPageCursor}
        onClick={navigateToPage}
      />
    </React.Fragment>
  );
};

const TransactionRow: React.FC<{
  realTime: string;
  blockTime: string;
  hash: string;
  value: string;
  showIdentifiedColumn: boolean;
  identifiedTransaction: string;
}> = ({
  realTime,
  blockTime,
  hash,
  value,
  showIdentifiedColumn,
  identifiedTransaction,
}) => {
  return (
    <tr>
      <td>{realTime}</td>
      <td>{blockTime}</td>
      <td className="monospace">
        <ChainLink to={`/transactions/${hash}`}>{hash}</ChainLink>
      </td>
      <td className="text-right">
        <span className="label label-success">{value}</span>
      </td>
      {showIdentifiedColumn && (
        <td className="text-right">
          <span className="label label-primary">{identifiedTransaction}</span>
        </td>
      )}
    </tr>
  );
};
