import { graphql } from '@cbhq/data-layer';
import React, { useContext } from 'react';
import { PageDataContext } from '../../util/PageDataContext';
import { useFragment } from 'react-relay';
import { Pencil } from '../../components/Icons';

const additionalInfoFragment = graphql`
  fragment TransactionAdditionalInfo_metadata on CoinbaseAnalyticsTransaction {
    metadata {
      name
      urls
      notes
    }
  }
`;

const TransactionAdditionalInfo: React.FC<{ transactionRef }> = (props) => {
  const { userConfigs } = useContext(PageDataContext);
  const tx = useFragment(additionalInfoFragment, props.transactionRef);
  const canTag = userConfigs.permissions.tags;
  return (
    <>
      <table className="table table-summary">
        <colgroup>
          <col width="30%" />
          <col width="70%" />
        </colgroup>
        <tbody>
          <tr>
            <td>Additional Info</td>
            <td style={{ textAlign: 'right' }}>
              {canTag && <TransactionAdditionalInfoModalTrigger />}
            </td>
          </tr>
          {tx.metadata?.name && (
            <>
              <tr>
                <td>Name</td>
                <td>{tx.metadata.name}</td>
              </tr>
              <tr>
                <td>URL</td>
                <td>{tx.metadata.urls}</td>
              </tr>
              <tr>
                <td>Notes</td>
                <td>{tx.metadata.notes}</td>
              </tr>
            </>
          )}
        </tbody>
      </table>
      {canTag && <TransactionAdditionalInfoModal />}
    </>
  );
};
export default TransactionAdditionalInfo;

const TransactionAdditionalInfoModal = () => {
  const getAuthenticityToken = () => {
    return window.$.rails.csrfToken();
  };
  return (
    <div className="modal fade metadata-parameters in">
      <div className="modal-dialog modal-md" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
            <h4 className="modal-title">Edit Metadata for Transaction</h4>
          </div>
          <div className="modal-body">
            <form
              id="meta-parameters"
              method="post"
              action=""
              encType="multipart/form-data"
            >
              <input type="hidden" name="_method" value="put" />
              <input
                type="hidden"
                name="authenticity_token"
                id="authenticity_token"
                value={getAuthenticityToken()}
              />

              <div className="form-group row">
                <label className="col-xs-3 col-form-label form-label">
                  Name
                </label>
                <div className="col-xs-9">
                  <input className="form-control" type="input" name="name" />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xs-3 col-form-label form-label">
                  URL
                </label>
                <div className="col-xs-9">
                  <input className="form-control" type="input" name="url" />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xs-3 col-form-label form-label">
                  Notes
                </label>
                <div className="col-xs-9">
                  <textarea
                    className="form-control"
                    name="notes"
                    rows={3}
                  ></textarea>
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              form="meta-parameters"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
const TransactionAdditionalInfoModalTrigger = () => (
  <a
    data-toggle="modal"
    data-magic-tooltip="Edit"
    data-target=".metadata-parameters"
    title="Edit"
    data-original-title="Edit"
  >
    <Pencil />
  </a>
);
