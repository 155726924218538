import { graphql } from '@cbhq/data-layer';
import React, { useState } from 'react';
import { useFragment } from 'react-relay';
import BootstrapSwitch from '../../components/BootstrapSwitch';
import ExpandableSection from '../../components/ExpandableSection';
import { TransactionDetails } from '../../components/TransactionTabRow';

export const transactionDataFragment = graphql`
  fragment TransactionFlowTableFragment on CoinbaseAnalyticsTransaction {
    hash
    inAmount {
      value
      currency
    }
    inAmountUsd
    outAmount {
      value
      currency
    }
    outAmountUsd
    feeAmount {
      value
      currency
    }
    feeAmountUsd
    demixerType
    operations {
      index
      type
      inputs {
        address
        name
        amount {
          value
          currency
        }
        amountUsd
        prevTxHash
        nextTxHash
        prevOutputIndex
        nextOutputIndex
        generated
        osint
        valid
        changeAddress
      }
      outputs {
        address
        name
        amount {
          value
          currency
        }
        amountUsd
        prevTxHash
        nextTxHash
        prevOutputIndex
        nextOutputIndex
        generated
        osint
        valid
        changeAddress
      }
    }
  }
`;

const TransactionFlowTable: React.FC<{ chain: string; transactionRef }> = (
  props
) => {
  const tx = useFragment(transactionDataFragment, props.transactionRef);

  const [state, setState] = useState({
    showName: true,
    showUsd: false,
  });
  const showUsd = state.showUsd;
  const showName = state.showName;
  return (
    <ExpandableSection sectionTitle={'Transaction'} defaultExpanded={true}>
      <div className="text-right" style={{ padding: 8 }}>
        <BootstrapSwitch
          identifier={'transaction-summary-name-switch'}
          width={'80px'}
          onSwitch={() => {
            setState((prevState) => {
              return {
                ...prevState,
                showName: !prevState.showName,
              };
            });
          }}
          isChecked={showName}
          dataOn="names"
          dataOff="addresses"
        />
        &nbsp; &nbsp;
        <BootstrapSwitch
          identifier={'transaction-summary-usd-switch'}
          onSwitch={() => {
            setState((prevState) => {
              return {
                ...prevState,
                showUsd: !prevState.showUsd,
              };
            });
          }}
          isChecked={!showUsd}
          dataOn={`${props.chain.toUpperCase()}`}
          dataOff="USD"
        />
      </div>
      <table
        className="table table-borderless"
        style={{ background: tx.demixerType ? '#f2dede' : 'transparent' }}
      >
        <tbody>
          <TransactionDetails
            transaction={tx}
            showUsd={state.showUsd}
            showName={state.showName}
            useFragment={false}
          />
        </tbody>
      </table>
    </ExpandableSection>
  );
};

export default TransactionFlowTable;
