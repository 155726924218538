/**
 * @generated SignedSource<<9f0027e2911ab05230b89e26a3e638e8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DemixToolInputsFragment$data = {
  readonly hash: string;
  readonly time: any;
  readonly demixerType: string | null;
  readonly operations: ReadonlyArray<{
    readonly inputs: ReadonlyArray<{
      readonly address: string;
    }> | null;
  }> | null;
  readonly " $fragmentType": "DemixToolInputsFragment";
};
export type DemixToolInputsFragment = DemixToolInputsFragment$data;
export type DemixToolInputsFragment$key = {
  readonly " $data"?: DemixToolInputsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DemixToolInputsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DemixToolInputsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hash",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "time",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "demixerType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CoinbaseAnalyticsOperation",
      "kind": "LinkedField",
      "name": "operations",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CoinbaseAnalyticsOperationPart",
          "kind": "LinkedField",
          "name": "inputs",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "address",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CoinbaseAnalyticsTransaction",
  "abstractKey": null
};

(node as any).hash = "ef7d358b48eb46843b6f3356eea07443";

export default node;
