import React from 'react';
import { ListCell } from '@cbhq/cds-web/cells';

import { IconName } from '@cbhq/cds-common/types';
import { Icon } from '@cbhq/cds-web/icons/Icon';
import { Box, Divider, Group } from '@cbhq/cds-web/layout';
import { TextBody, TextCaption } from '@cbhq/cds-web/typography';

export enum listItemType {
  CELL,
  SECTION,
}

interface ListItem {
  id: string;
  type: listItemType;
}

interface Cell extends ListItem {
  route: string;
  title: string;
  iconName: IconName;
  detail: string;
  disabled?: boolean;
}

interface Section extends ListItem {
  label: string;
}

export type List = (Cell | Section)[];

interface IProps {
  list: List;
}

const PortalList: React.FC<IProps> = ({ list }) => (
  <Group
    width="100%"
    borderColor="line"
    borderRadius="standard"
    divider={Divider}
  >
    {list.map(({ id, type, route, label, title, detail, iconName, disabled }) =>
      type === listItemType.CELL ? (
        <ListCell
          key={id}
          title={title}
          detail={
            <TextBody as="span" color="foregroundMuted">
              {detail}
            </TextBody>
          }
          accessory="arrow"
          onPress={() => {
            window.location.pathname = route;
          }}
          media={<Icon name={iconName} size="m" color="foreground" />}
          disabled={disabled}
        />
      ) : (
        <Box key={id} spacing={2}>
          <TextCaption as="span" color="foregroundMuted">
            {label}
          </TextCaption>
        </Box>
      )
    )}
  </Group>
);

export default PortalList;
