/**
 * @generated SignedSource<<0714b182c7e9a58b6c2b14d94da552b0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TransactionIndexPageQuery_TransactionsQuery$variables = {
  tickerSymbol: any;
  after: string;
};
export type TransactionIndexPageQuery_TransactionsQueryVariables = TransactionIndexPageQuery_TransactionsQuery$variables;
export type TransactionIndexPageQuery_TransactionsQuery$data = {
  readonly viewer: {
    readonly coinbaseAnalytics: {
      readonly transactionsBySymbol: {
        readonly edges: ReadonlyArray<{
          readonly cursor: string;
          readonly node: {
            readonly id: string;
            readonly hash: string;
            readonly realTime: any;
            readonly blockTime: any;
            readonly outAmount: {
              readonly value: any;
            } | null;
            readonly outAmountUsd: any | null;
            readonly identifiedTransaction: string | null;
          } | null;
        }>;
        readonly pageInfo: {
          readonly endCursor: string;
          readonly hasNextPage: boolean;
        };
      } | null;
    };
  };
};
export type TransactionIndexPageQuery_TransactionsQueryResponse = TransactionIndexPageQuery_TransactionsQuery$data;
export type TransactionIndexPageQuery_TransactionsQuery = {
  variables: TransactionIndexPageQuery_TransactionsQueryVariables;
  response: TransactionIndexPageQuery_TransactionsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tickerSymbol"
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Viewer",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CoinbaseAnalytics",
        "kind": "LinkedField",
        "name": "coinbaseAnalytics",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "after",
                "variableName": "after"
              },
              {
                "kind": "Variable",
                "name": "tickerSymbol",
                "variableName": "tickerSymbol"
              }
            ],
            "concreteType": "CoinbaseAnalyticsTransactionConnection",
            "kind": "LinkedField",
            "name": "transactionsBySymbol",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CoinbaseAnalyticsTransactionEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CoinbaseAnalyticsTransaction",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hash",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "realTime",
                        "storageKey": null
                      },
                      {
                        "alias": "blockTime",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "time",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Amount",
                        "kind": "LinkedField",
                        "name": "outAmount",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "value",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "outAmountUsd",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "identifiedTransaction",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TransactionIndexPageQuery_TransactionsQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "TransactionIndexPageQuery_TransactionsQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "bbdf69a510be1ff4387fbb178a9253ac",
    "id": null,
    "metadata": {},
    "name": "TransactionIndexPageQuery_TransactionsQuery",
    "operationKind": "query",
    "text": "query TransactionIndexPageQuery_TransactionsQuery(\n  $tickerSymbol: TickerSymbol!\n  $after: String!\n) {\n  viewer {\n    coinbaseAnalytics {\n      transactionsBySymbol(tickerSymbol: $tickerSymbol, after: $after) {\n        edges {\n          cursor\n          node {\n            id\n            hash\n            realTime\n            blockTime: time\n            outAmount {\n              value\n            }\n            outAmountUsd\n            identifiedTransaction\n          }\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a104d8a17b73f7daceb232fb1ad97ca0";

export default node;
