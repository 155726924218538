import { graphql } from '@cbhq/data-layer';
import React, { useContext } from 'react';
import { useFragment } from 'react-relay';
import { InfoSign, Pencil } from './Icons';
import { allCategories } from '../util/CategoryUtils';
import { PageDataContext } from '../util/PageDataContext';
import Select from 'react-select';
import WalletCategory from './WalletCategory';
import ReactTooltip from 'react-tooltip';
import { AddressSummaryPage_AddressSummaryQueryResponse } from '../pages/addresses/__generated__/AddressSummaryPage_AddressSummaryQuery.graphql';
import { WalletPage_SummaryQueryResponse } from '../pages/wallets/__generated__/WalletPage_SummaryQuery.graphql';

const additionalInfoAddressFragment = graphql`
  fragment AdditionalInfoAddress_metadata on CoinbaseAnalyticsAddress {
    metadata {
      name
      categories
      urls
      notes
      handling
    }
  }
`;

const additionalInfoWalletFragment = graphql`
  fragment AdditionalInfoWallet_metadata on CoinbaseAnalyticsWallet {
    metadata {
      name
      categories
      urls
      notes
      handling
    }
  }
`;

interface AdditionalInfoMetada {
  metadata: {
    id: string;
    name: string;
    categories: string[];
    urls: string[];
    notes: string;
    handling: string;
  };
}

type Address =
  AddressSummaryPage_AddressSummaryQueryResponse['viewer']['coinbaseAnalytics']['addressByHashAndSymbol'];

type Wallet =
  WalletPage_SummaryQueryResponse['viewer']['coinbaseAnalytics']['walletByHashAndSymbol'];

type AdditionalInfoProps =
  | { type: 'wallet'; data: Wallet }
  | { type: 'address'; data: Address };

const AdditionalInfo = (props: AdditionalInfoProps) => {
  const { type, data } = props;

  const { userConfigs } = useContext(PageDataContext);
  const fetchedData = useFragment(
    type === 'address'
      ? additionalInfoAddressFragment
      : additionalInfoWalletFragment,
    data as any
  ) as AdditionalInfoMetada;
  const canTag = userConfigs?.permissions.tags;
  const has_metadata = userConfigs?.license.has_metadata;

  return (
    has_metadata && (
      <>
        <table className="table table-summary">
          <colgroup>
            <col width="30%" />
            <col width="70%" />
          </colgroup>
          <tbody>
            <tr>
              <td>Additional Info</td>
              <td style={{ textAlign: 'right' }}>
                {canTag && <AdditionalInfoModalTrigger />}
              </td>
            </tr>
            {fetchedData.metadata && (
              <>
                {fetchedData.metadata.name && (
                  <tr>
                    <td>Name</td>
                    <td>{fetchedData.metadata.name}</td>
                  </tr>
                )}
                {fetchedData.metadata.categories.length > 0 && (
                  <tr>
                    <td>Categories</td>
                    <td>
                      {fetchedData.metadata.categories.map((category) => (
                        <WalletCategory key={category} category={category} />
                      ))}
                    </td>
                  </tr>
                )}
                {fetchedData.metadata.notes && (
                  <tr>
                    <td>Notes</td>
                    <td>{fetchedData.metadata.notes}</td>
                  </tr>
                )}
                {fetchedData.metadata.handling && (
                  <tr>
                    <td>
                      Handling&nbsp;
                      <a data-tip="" data-for="handling-tooltip">
                        <InfoSign />
                      </a>
                      <ReactTooltip id={'handling-tooltip'}>
                        <div style={{ maxWidth: 250 }}>{handlingTooltip}</div>
                      </ReactTooltip>
                    </td>
                    <td>
                      <HandlingLabel type={fetchedData.metadata.handling} />
                    </td>
                  </tr>
                )}
                {fetchedData.metadata.urls.length > 0 && (
                  <tr>
                    <td>URL</td>
                    <td>
                      {fetchedData.metadata.urls.map((url, key) => (
                        <div
                          key={`additional-info-fetched-metadata-${key + 1}`}
                        >
                          <a
                            href={url.startsWith('https://') ? url : '//' + url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {url}
                          </a>
                        </div>
                      ))}
                    </td>
                  </tr>
                )}
              </>
            )}
            {!fetchedData.metadata && (
              <tr>
                <td>Not Available</td>
                <td></td>
              </tr>
            )}
          </tbody>
        </table>
        {canTag && (
          <AdditionalInfoModal metadata={fetchedData.metadata} type={type} />
        )}
      </>
    )
  );
};

const AdditionalInfoModal = ({
  metadata,
  type,
}: {
  metadata: AdditionalInfoMetada['metadata'];
  type: 'wallet' | 'address';
}) => {
  const getAuthenticityToken = () => {
    return window.$.rails.csrfToken();
  };

  const { userConfigs } = useContext(PageDataContext);
  const userInGlobalMetadata = userConfigs?.group.global_metadata;

  const categoryOptions = allCategories.map((category) => ({
    value: category,
    label: category,
  }));
  const selectedCategories = metadata?.categories?.map((category) => ({
    value: category,
    label: category,
  }));

  const selectedHandling = metadata?.handling
    ? { value: metadata.handling, label: metadata.handling }
    : null;

  const formatCategoryOptions = ({ label }) => (
    <WalletCategory category={label} />
  );

  const formatHandlingOptions = ({ label }) => <HandlingLabel type={label} />;

  function onShareAnalyticsChange(e) {
    const shareAnalyticsDiv = document.getElementById('show-share-analytics');
    if (e.target.checked) {
      shareAnalyticsDiv.style.display = 'block';
    } else {
      shareAnalyticsDiv.style.display = 'none';
    }
  }

  return (
    <div className="modal fade metadata-parameters in">
      <div className="modal-dialog modal-md" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
            <h4 className="modal-title">
              Edit Metadata for {type === 'address' ? 'Address' : 'Wallet'}
            </h4>
          </div>
          <div className="modal-body">
            <form
              id="meta-parameters"
              method="post"
              action=""
              encType="multipart/form-data"
            >
              <input type="hidden" name="_method" value="put" />
              <input
                type="hidden"
                name="authenticity_token"
                id="authenticity_token"
                value={getAuthenticityToken()}
              />

              <div className="form-group row">
                <label className="col-xs-3 col-form-label form-label">
                  Name
                </label>
                <div className="col-xs-9">
                  <input
                    className="form-control"
                    type="input"
                    name="name"
                    defaultValue={metadata?.name ?? ''}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xs-3 col-form-label form-label">
                  URL
                </label>
                <div className="col-xs-9">
                  <input
                    className="form-control"
                    type="input"
                    name="url"
                    placeholder="URLs are space seperated"
                    defaultValue={metadata?.urls.join(' ') ?? ''}
                  />
                  <div>URLs are space separated</div>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xs-3 col-form-label form-label">
                  Notes
                </label>
                <div className="col-xs-9">
                  <textarea
                    className="form-control"
                    name="notes"
                    rows={3}
                    defaultValue={metadata?.notes ?? ''}
                  ></textarea>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xs-3 col-form-label form-label">
                  Category
                </label>
                <div className="col-xs-9">
                  <Select
                    options={categoryOptions}
                    defaultValue={selectedCategories}
                    formatOptionLabel={formatCategoryOptions}
                    name="categories[]"
                    isClearable
                    isMulti
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xs-3 col-form-label form-label">
                  Handling&nbsp;
                  <a data-tip="" data-for="handling-tooltip">
                    <InfoSign />
                  </a>
                </label>
                <ReactTooltip id={'handling-tooltip'}>
                  <div style={{ maxWidth: 250 }}>{handlingTooltip}</div>
                </ReactTooltip>
                <div className="col-xs-9">
                  <Select
                    options={handlingOptions}
                    defaultValue={selectedHandling}
                    formatOptionLabel={formatHandlingOptions}
                    name="handling"
                    isClearable
                  />
                </div>
              </div>

              {userInGlobalMetadata && type === 'address' && (
                <div className="form-group row">
                  <div className="col-xs-12 checkbox">
                    <label>
                      <input
                        type="checkbox"
                        id="share-with-analytics-checkbox"
                        name="share_with_analytics"
                        defaultChecked={false}
                        onChange={(e) => onShareAnalyticsChange(e)}
                      />
                      Share with Tracer&nbsp;
                      <a data-tip="" data-for="share-analytics-tooltip">
                        <InfoSign />
                      </a>
                    </label>
                    <ReactTooltip id={'share-analytics-tooltip'}>
                      <div style={{ maxWidth: 250 }}>
                        {shareWithAnalyticsTooltip}
                      </div>
                    </ReactTooltip>
                  </div>
                  <div id="show-share-analytics" style={{ display: 'none' }}>
                    <div className="col-xs-10">
                      <label className="col-xs-3 col-form-label form-label">
                        Evidence Type
                      </label>
                      <div className="col-xs-9">
                        <Select
                          options={evidenceTypeOptions}
                          name="evidence_type"
                          isClearable
                        />
                      </div>
                    </div>
                    <div className="col-xs-10">
                      <label className="col-xs-3 col-form-label form-label">
                        Screenshot Evidence
                      </label>
                      <div className="col-xs-9">
                        <input
                          type="file"
                          className="form-control"
                          name="screenshot"
                          accept="image/png"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12 checkbox">
                    <label>
                      <input
                        type="checkbox"
                        name="tag_wallet"
                        defaultChecked={false}
                      />
                      Tag its wallet (if untagged) with the same data
                    </label>
                  </div>
                  <div className="col-xs-10 checkbox">
                    <label>
                      <input
                        type="checkbox"
                        id="ofac-checkbox"
                        name="ofac"
                        defaultChecked={false}
                      />
                      Share OFAC tag&nbsp;
                      <a data-tip="" data-for="ofac-tooltip">
                        <InfoSign />
                      </a>
                    </label>
                    <ReactTooltip id={'ofac-tooltip'}>
                      <div style={{ maxWidth: 250 }}>{ofacTooltip}</div>
                    </ReactTooltip>
                  </div>
                </div>
              )}
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              form="meta-parameters"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const AdditionalInfoModalTrigger = () => (
  <a
    data-toggle="modal"
    data-magic-tooltip="Edit"
    data-target=".metadata-parameters"
    title="Edit"
    data-original-title="Edit"
  >
    <Pencil />
  </a>
);

const HandlingLabel: React.FC<{ type: string }> = (props) => {
  if (props.type == 'none') {
    return <span>{props.type}</span>;
  }

  let displayType = 'default';
  if (props.type == 'green') {
    displayType = 'success';
  } else if (props.type == 'amber') {
    displayType = 'warning';
  } else if (props.type == 'red') {
    displayType = 'danger';
  }

  return <span className={`label label-${displayType}`}>{props.type}</span>;
};

const handlingOptions = [
  { value: 'none', label: 'none' },
  { value: 'white', label: 'white' },
  { value: 'green', label: 'green' },
  { value: 'amber', label: 'amber' },
  { value: 'red', label: 'red' },
];

const evidenceTypeOptions = [
  { value: 'none', label: 'none' },
  { value: 'transaction', label: 'transaction' },
  { value: 'OSINT', label: 'OSINT' },
  { value: 'third party attribution', label: 'third party attribution' },
  { value: 'pattern analysis', label: 'pattern analysis' },
];

const handlingTooltip =
  "The new 'handling' field indicates to your teammates the sensitivity of this information & how to use it. From WHITE (not sensitive. minimal risk of misuse) to RED (highly sensitive information).";
const shareWithAnalyticsTooltip =
  'By checking this checkbox, the metadata entered will be shared with Tracer Team.';
const ofacTooltip =
  'By checking this checkbox, certain category tags selected, i.e. the ofac tag, will be shared with other teams including the compliance team.';

export default AdditionalInfo;
