import React from 'react';
import { Bar } from 'react-chartjs-2';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { graphql } from '@cbhq/data-layer';
import { useLazyLoadQuery } from 'react-relay';
import { TimePatternCharts_TimePatternQuery } from './__generated__/TimePatternCharts_TimePatternQuery.graphql';
import ReactTooltip from 'react-tooltip';

const timePatternQuery = graphql`
  query TimePatternCharts_TimePatternQuery(
    $tickerSymbol: TickerSymbol!
    $hash: String!
    $type: String!
    $isActivity: Boolean
  ) {
    viewer {
      coinbaseAnalytics {
        timePatternByHashAndSymbol(
          tickerSymbol: $tickerSymbol
          hash: $hash
          type: $type
          isActivity: $isActivity
        ) {
          charts {
            chartId
            xAxisLabels
            datasets {
              data
              legendLabel
              valueLabel
            }
          }
        }
      }
    }
  }
`;

const TimePatternCharts: React.FC<{
  chain: string;
  hash: string;
  type?: string;
  interactedTokens?;
  isActivity?: boolean;
}> = ({
  chain,
  hash,
  interactedTokens = [],
  type = 'address',
  isActivity = false,
}) => {
  const timePatternData = useLazyLoadQuery<TimePatternCharts_TimePatternQuery>(
    timePatternQuery,
    {
      tickerSymbol: chain,
      hash,
      type,
      isActivity,
    }
  );

  let tokens = interactedTokens;
  if (tokens.length == 0) {
    tokens = [
      {
        address: hash,
        name: chain,
      },
    ];
  }
  const charts =
    timePatternData.viewer.coinbaseAnalytics.timePatternByHashAndSymbol.charts;
  return (
    <table className="table table-striped">
      <tbody>
        {/*TODO ZJ: Show token dropdown for buterin assets */}
        {chain != 'btc' && (
          <tr>
            <td>
              <div className="dropdown">
                Selected Token:
                <button
                  className="btn btn-primary btn-xs dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                >
                  Ether (ETH) <span className="caret"></span>
                </button>
                <ul className="dropdown-menu">
                  (
                  {tokens.map((token) => {
                    return (
                      <li key={token.address}>
                        <a>{token.name}</a>
                      </li>
                    );
                  })}
                  )
                </ul>
                <span className="pull-right">
                  This address interacted with{' '}
                  <span className="label label-primary">
                    {interactedTokens.length}
                  </span>{' '}
                  different tokens .
                </span>
              </div>
            </td>
          </tr>
        )}
        <tr>
          <td>
            <div className="row">
              <div className="col-lg-12">
                <div align="left">
                  <b>{charts[0].chartId}</b>
                  <TimePatternBarChart chartData={charts[0]} />
                </div>
              </div>
              <div className="col-lg-12">
                <div align="left">
                  <b>{charts[1].chartId}</b>
                  <TimePatternBarChart chartData={charts[1]} />
                </div>
              </div>
              <div className="col-lg-12">
                <div align="left">
                  <b>{charts[2].chartId}</b>{' '}
                  <ReactTooltip id="time-pattersn-export-csv">
                    Export as CSV
                  </ReactTooltip>
                  <span
                    data-effect="solid"
                    data-tip="necessary for react-tooltip"
                    data-for="time-pattersn-export-csv"
                    data-place="right"
                    title="Export as CSV"
                    data-original-title="Export as CSV"
                  >
                    <a href={`/${chain}/addresses/${hash}/export_balance`}>
                      <i className="fa fa-download"></i>
                    </a>
                  </span>{' '}
                  <BalanceOverTimeChart chain={chain} chartData={charts[2]} />
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const TimePatternBarChart: React.FC<{ chartData }> = (props) => {
  const data = {
    datasets: props.chartData.datasets.map((dataset) => {
      return {
        data: dataset.data,
        label: dataset.legendLabel,
        backgroundColor: GetTransactionDirectionColor(dataset.legendLabel),
      };
    }),
    labels: props.chartData.xAxisLabels,
  };
  const options = {
    scales: { yAxes: [{ display: false, ticks: { beginAtZero: true } }] },
    legend: { display: false },
  };

  return <Bar data={data} options={options} height={60} />;
};

const BalanceOverTimeChart: React.FC<{ chartData; chain: string }> = (
  props
) => {
  const tokenDataset = props.chartData.datasets[0];
  const tokenData = [];
  for (let i = 0; i < tokenDataset.data.length; i++) {
    const d = Date.parse(props.chartData.xAxisLabels[i]);
    tokenData.push([d, tokenDataset.data[i]]);
  }

  const usdDataset = props.chartData.datasets[1];
  const usdData = [];
  for (let i = 0; i < usdDataset.data.length; i++) {
    const d = Date.parse(props.chartData.xAxisLabels[i]);
    usdData.push([d, usdDataset.data[i]]);
  }

  const options = {
    title: {
      text: '',
    },
    xAxis: [
      {
        type: 'datetime',
        tickPixelInterval: 130,
        dateTimeLabelFormats: { day: "%d %b '%y", week: "%d %b '%y" },
        gridLineWidth: 1,
        crosshair: true,
      },
    ],
    yAxis: [
      {
        title: {
          text: props.chain.toUpperCase(),
        },
        min: 0,
      },
      {
        title: {
          text: usdDataset.valueLabel,
        },
        min: 0,
        opposite: true,
      },
    ],
    series: [
      {
        showInLegend: true,
        name: tokenDataset.legendLabel,
        yAxis: 0,
        data: tokenData,
        step: true,
        color: '#21C25C',
      },
      {
        showInLegend: true,
        name: usdDataset.legendLabel,
        yAxis: 1,
        data: usdData,
        step: true,
        color: '#F8AB3E',
      },
    ],
    chart: {
      zoomType: 'x',
      height: 240,
    },
    exporting: {
      enabled: false,
    },
    plotOptions: {
      series: {
        boostThreshold: 2000,
      },
    },
    tooltip: {
      shared: true,
      dateTimeLabelFormats: {
        millisecond: '%Y-%m-%d %H:%M:%S.%L',
        second: '%Y-%m-%d %H:%M:%S',
        minute: '%Y-%m-%d %H:%M',
        hour: '%Y-%m-%d %H:%M',
        day: '%Y-%m-%d',
        week: 'Week from %A, %b %e, %Y',
        month: '%B %Y',
        year: '%Y',
      },
    },
    credits: {
      enabled: false,
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export const GetTransactionDirectionColor = (direction: string) => {
  if (direction.toLowerCase() == 'sent') {
    return '#d8544f'; //red
  } else if (direction.toLowerCase() == 'received') {
    return '#307cb4'; //blue
  }
  return '#F8AB3E'; //yellow
};

export default TimePatternCharts;
