import { graphql } from '@cbhq/data-layer';
import React, { Suspense } from 'react';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { LoadingBubbles } from '../../components/LoadingBubbles';
import { TransactionsTabData } from '../../components/TransactionTabData';
import { useLazyLoadQuery } from 'react-relay';
import { WalletTransactions_WalletQueryResponse } from './__generated__/WalletTransactions_WalletQuery.graphql';

const walletTransactionsQuery = graphql`
  query WalletTransactions_WalletQuery(
    $tickerSymbol: TickerSymbol!
    $filters: CoinbaseAnalyticsTransactionFilters!
    $walletId: String!
    $after: String!
  ) {
    viewer {
      coinbaseAnalytics {
        walletByHashAndSymbol(hash: $walletId, tickerSymbol: $tickerSymbol) {
          transactions(asset: $tickerSymbol, filters: $filters, after: $after) {
            edges {
              node {
                hash
                ...TransactionTabRow_TransactionFragment
              }
            }
            pageInfo {
              endCursor
              hasNextPage
            }
          }
        }
      }
    }
  }
`;

export const WalletTransactions: React.FC<{
  chain: string;
  walletId: string;
  numTx: number;
}> = (props) => {
  return (
    <ErrorBoundary>
      <Suspense fallback={<LoadingBubbles />}>
        <TransactionsTabData
          tickerSymbol={props.chain}
          numTx={props.numTx}
          walletId={props.walletId}
          fetchTransactionConnection={(cursor, filters) => {
            const result = useLazyLoadQuery(walletTransactionsQuery, {
              tickerSymbol: props.chain,
              filters: filters,
              walletId: props.walletId,
              after: cursor,
            }) as WalletTransactions_WalletQueryResponse;
            return result.viewer.coinbaseAnalytics.walletByHashAndSymbol
              .transactions;
          }}
        />
      </Suspense>
    </ErrorBoundary>
  );
};
