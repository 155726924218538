import React from 'react';
import { IHeaderBlock } from './HeaderBlock';

interface IHeaderBlocks {
  blocks: Array<React.ReactNode>;
}

const HeaderBlocks = (props: IHeaderBlocks) => {
  return (
    <ul className="header-blocks">
      {props.blocks.map((block: IHeaderBlock, index: number) => {
        return (
          <React.Fragment key={index}>
            {block}
            {index < props.blocks.length - 1 && (
              <div className="header-block-separator"></div>
            )}
          </React.Fragment>
        );
      })}
    </ul>
  );
};

export default HeaderBlocks;
