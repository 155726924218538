import React from 'react';

const GraphsPanel = ({ graphs, hash }) => {
  const handleAddGraphClick = (source) => {
    window.xflow.addToGraphModal(source);
  };

  const handleAddItemToGraph = (source, graphId, name) => {
    const graphModal = window.xflow.addToGraphModal(source, false);
    graphModal.submit(graphId, name);
  };
  return (
    <div className="panel panel-default panel-sidebar">
      <div className="panel-heading">Add to graphs</div>
      {graphs.length > 0 && (
        <div className="panel-body">
          <ul className="list-unstyled">
            {graphs.map((graph) => (
              <li key={graph.id}>
                <a
                  href="#"
                  data-element-ids={hash}
                  onClick={(e) =>
                    handleAddItemToGraph(e.target, graph.id, graph.name)
                  }
                >
                  {graph.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="panel-footer">
        <a
          className="panel-action"
          href="#"
          data-element-ids={hash}
          onClick={(e) => handleAddGraphClick(e.target)}
        >
          + New graph
        </a>
      </div>
    </div>
  );
};

export default GraphsPanel;
