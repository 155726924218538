import { graphql } from '@cbhq/data-layer';
import React, { Suspense, useCallback, useContext, useState } from 'react';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { createBrowserHistory } from 'history';
import { LoadingBubbles } from '../../components/LoadingBubbles';
import { useFragment, useLazyLoadQuery } from 'react-relay';
import BootstrapSwitch from '../../components/BootstrapSwitch';
import { NextPageCursorNavigator } from '../../components/NextPageCursorNavigator';
import { formatAmount, formatUsd } from '../../util/AmountUtils';
import { ValueLabel } from '../../components/ValueLabel';
import { WalletAddresses_QueryResponse } from './__generated__/WalletAddresses_Query.graphql';
import { ChainLink } from '../../components/ChainLink';
import FormatTime from '../../util/TimeFormatter';
import { Tooltip } from '@cbhq/cds-web/overlays';
import { IconButton } from '@cbhq/cds-web/buttons';
import { useToast } from '@cbhq/cds-web/overlays/useToast';
import { PageDataContext } from '../../util/PageDataContext';

const walletAddressesQuery = graphql`
  query WalletAddresses_Query(
    $tickerSymbol: TickerSymbol!
    $walletId: String!
    $after: String!
  ) {
    viewer {
      coinbaseAnalytics {
        walletByHashAndSymbol(hash: $walletId, tickerSymbol: $tickerSymbol) {
          addresses(asset: $tickerSymbol, after: $after) {
            edges {
              node {
                ...WalletAddresses_AddressRowFragment
              }
            }
            pageInfo {
              endCursor
              hasNextPage
            }
          }
        }
      }
    }
  }
`;

const addressRowFragment = graphql`
  fragment WalletAddresses_AddressRowFragment on CoinbaseAnalyticsAddress {
    hash
    lastSeen
    numTx
    metadata {
      name
    }
    balanceAmount {
      value
      currency
    }
    sentAmount {
      value
      currency
    }
    receivedAmount {
      value
      currency
    }
    balanceAmountUsd
    sentAmountUsd
    receivedAmountUsd
  }
`;

export const WalletAddresses: React.FC<{
  chain: string;
  walletId: string;
  numAddresses: number;
}> = (props) => {
  return (
    <ErrorBoundary>
      <Suspense fallback={<LoadingBubbles />}>
        <AddressTabData
          chain={props.chain}
          tickerSymbol={props.chain}
          numAddresses={props.numAddresses}
          walletId={props.walletId}
          fetchAddressConnection={(cursor) => {
            const result = useLazyLoadQuery(walletAddressesQuery, {
              tickerSymbol: props.chain,
              walletId: props.walletId,
              after: cursor,
            });
            return (result as WalletAddresses_QueryResponse).viewer
              .coinbaseAnalytics.walletByHashAndSymbol.addresses;
          }}
        />
      </Suspense>
    </ErrorBoundary>
  );
};

// fetchAddressConnection takes a cursor string as the only argument and returns an address connection object.
export const AddressTabData: React.FC<{
  chain: string;
  tickerSymbol: string;
  numAddresses: number;
  walletId: string;
  fetchAddressConnection: Function;
}> = (props) => {
  const history = createBrowserHistory({ forceRefresh: true });
  const toast = useToast();
  const { userConfigs } = useContext(PageDataContext);

  const [state, setState] = useState({
    pageCursor: '',
    page: 1,
  });

  const [showUsd, setShowUsd] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);

  const onSwitchShowUsd = useCallback(() => {
    setShowUsd((prevState) => {
      return !prevState;
    });
  }, []);

  const addressConnection = props.fetchAddressConnection(state.pageCursor);
  const nextPageCursor = addressConnection.pageInfo.endCursor;

  const navigateToPage = (nextPage: number, nextPageCursor: string) => {
    setState({
      pageCursor: nextPageCursor,
      page: nextPage,
    });
  };

  const downloadCSV = async () => {
    setLoadingExport(true);
    try {
      await fetch(
        `/${props.chain}/wallets/${props.walletId}/export_addresses/`,
        {
          method: 'GET',
        }
      );

      setLoadingExport(false);
      history.push(`/${props.tickerSymbol}/jobs`);
    } catch (error) {
      toast.show(`Failed to export the CSV.`);
      setLoadingExport(false);
    }
  };

  return (
    <React.Fragment>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th colSpan={6} className="text-right">
                <BootstrapSwitch
                  identifier={'address-tab-switch'}
                  onSwitch={onSwitchShowUsd}
                  isChecked={!showUsd}
                  dataOn={`${props.tickerSymbol.toUpperCase()}`}
                  dataOff="USD"
                />
                &nbsp;
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                style={{
                  paddingTop: '15px',
                }}
              >
                No. Addresses{' '}
                <span className="label label-primary">
                  {props.numAddresses}
                </span>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>
                {userConfigs.license.type === 'gov' && (
                  <span className="pull-right">
                    <Tooltip content="Export as CSV" placement="left">
                      <IconButton
                        name="download"
                        onPress={() => {
                          downloadCSV();
                        }}
                        variant="foregroundMuted"
                        transparent
                        loading={loadingExport}
                      />
                    </Tooltip>
                  </span>
                )}
              </td>
            </tr>
            <tr>
              <td width={'45%'}>Address</td>
              <td width={'5%'} align={'center'}>
                Tx
              </td>
              <td width={'20%'} align={'center'}>
                Last seen
              </td>
              <td width={'10%'} align={'center'}>
                Value
              </td>
              <td width={'10%'} align={'center'}>
                Sent
              </td>
              <td width={'10%'} align={'center'}>
                Received
              </td>
            </tr>
            {addressConnection.edges.map((addressEdge) => {
              return (
                <tr key={addressEdge.node.hash}>
                  <td colSpan={6}>
                    <AddressesTable
                      address={addressEdge.node}
                      chain={props.chain}
                      showUsd={showUsd}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <NextPageCursorNavigator
        currentPage={state.page ? state.page : 1}
        nextPageCursor={nextPageCursor}
        onClick={navigateToPage}
      />
    </React.Fragment>
  );
};

const AddressesTable: React.FC<{
  address;
  chain: string;
  showUsd: boolean;
}> = (props) => {
  const address = useFragment(addressRowFragment, props.address);
  return (
    <table className="table table-striped">
      <tbody>
        <tr>
          <td width={'45%'}>
            <ChainLink to={`/addresses/${address.hash}`}>
              {address.hash}
            </ChainLink>
          </td>
          <td width={'5%'} align={'center'}>
            {address.numTx}
          </td>
          <td width={'20%'} align={'center'}>
            {FormatTime(address.lastSeen)}
          </td>
          <td width={'10%'} align={'right'}>
            <ValueLabel
              value={address.balanceAmount?.value}
              labelText={
                props.showUsd
                  ? formatUsd(address.balanceAmountUsd)
                  : formatAmount(address.balanceAmount, props.chain)
              }
              nonzeroLabelClass={'label-success'}
            />
          </td>
          <td width={'10%'} align={'right'}>
            <ValueLabel
              value={address.sentAmount?.value}
              labelText={
                props.showUsd
                  ? formatUsd(address.sentAmountUsd)
                  : formatAmount(address.sentAmount, props.chain)
              }
              nonzeroLabelClass={'label-danger'}
            />
          </td>
          <td width={'10%'} align={'right'}>
            <ValueLabel
              value={address.receivedAmount?.value}
              labelText={
                props.showUsd
                  ? formatUsd(address.receivedAmountUsd)
                  : formatAmount(address.receivedAmount, props.chain)
              }
              nonzeroLabelClass={'label-success'}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};
