import React, { useContext, useEffect } from 'react';
import { VStack } from '@cbhq/cds-web/layout';

import { PageDataContext } from '../../util/PageDataContext';
import PortalList, { List, listItemType } from './PortalList';

import './styles.scss';
import { useLocalStorage } from 'usehooks-ts';
import {
  ALL_NETWORKS,
  DEFAULT_NETWORK,
  NetworkFilter,
} from '../../components/filters/NetworkFilter';

const toolsList: (
  symbol: string,
  isAdmin: boolean,
  showMetadataImport: boolean,
  chainClass: string
) => List = (symbol, isAdmin, showMetadataImport, chainClass) => {
  const defaultEntries = [
    {
      id: 'id',
      route: `${symbol}/batch_risks/new`,
      type: listItemType.CELL,
      title: 'Batch Risk Score',
      iconName: 'shield',
      detail: 'Assess risk on up to 1000 addresses and transactions at a time',
    },
    {
      id: 'id',
      route: `${symbol}/search/advanced_search`,
      type: listItemType.CELL,
      title: 'Advanced Search',
      iconName: 'filter',
      detail:
        'Use advanced filters to search for more specific paymets, addresses, and more',
    },
    {
      id: 'id',
      route: `${symbol}/crawler_jobs/new`,
      type: listItemType.CELL,
      title: 'Web Search',
      iconName: 'identityCard',
      detail: 'Find addresses and transaction ID references on websites',
    },
    {
      id: 'id',
      route: `${symbol}/custom_wallets`,
      type: listItemType.CELL,
      title: 'Custom Wallets',
      iconName: 'wallet',
      detail: 'Create a custom wallet from a group of addresses',
    },
  ];

  if (symbol !== 'usdt') {
    defaultEntries.push({
      id: 'id',
      route: `${symbol}/api/v1/docs`,
      type: listItemType.CELL,
      title: 'API Documentation',
      iconName: 'smartContract',
      detail: 'Review Tracer API documentation, per chain',
    });
  }

  const administrativeSection = [
    {
      id: 'id',
      type: listItemType.SECTION,
      label: 'Administrative',
    },
    {
      id: 'id',
      route: `${symbol}/clustering_addresses`,
      type: listItemType.CELL,
      title: 'Address Clustering',
      iconName: 'defi',
      disabled: !isAdmin,
      detail: 'Merge a group of addresses and the clusters they belong to',
    },
  ];

  const nakamotoEntries = [
    {
      id: 'id',
      route: `${symbol}/clustering_force`,
      type: listItemType.CELL,
      title: 'Forced Clustering',
      iconName: 'regulated',
      disabled: !isAdmin,
      detail: 'Force the clustering of a list of transactions',
    },
    {
      id: 'id',
      route: `${symbol}/clustering_extract`,
      type: listItemType.CELL,
      title: 'Cluster Extraction',
      iconName: 'lightningBolt',
      disabled: !isAdmin,
      detail: 'Extract an address from its cluster and all the other addresses',
    },
  ];

  const metadataImportCell = {
    id: 'id',
    route: `${symbol}/metadata_bulk_importer/new`,
    type: listItemType.CELL,
    title: 'Metadata Import',
    iconName: 'upload',
    detail: 'Bulk import address labels by uploading a CSV',
  };

  let options = defaultEntries as List;
  if (showMetadataImport) {
    options = [...options, metadataImportCell] as List;
  }
  if (chainClass === 'Nakamoto') {
    options = [
      ...options,
      ...administrativeSection,
      ...nakamotoEntries,
    ] as List;
  } else {
    options = [...options, ...administrativeSection] as List;
  }

  return options;
};

const Tools = ({
  chainConfigs,
}: {
  chainConfigs: { configs: any; chains: any[] };
}) => {
  const { userConfigs } = useContext(PageDataContext);

  const [localStorage, setLocalStorage] = useLocalStorage(
    'currentChain',
    (undefined || ALL_NETWORKS) ?? DEFAULT_NETWORK
  );

  useEffect(() => {
    if (localStorage === ALL_NETWORKS) {
      setLocalStorage(DEFAULT_NETWORK);
    }
  }, []);

  const chainClass = chainConfigs.configs[localStorage]?.class;

  return (
    <VStack gap={3} spacingTop={3} spacingHorizontal={0}>
      <NetworkFilter
        showAllNetworks={false}
        chainConfigs={chainConfigs}
        chain={localStorage === ALL_NETWORKS ? DEFAULT_NETWORK : localStorage}
        onChange={setLocalStorage}
      />
      <hr className="portal-page-divider" />
      <PortalList
        list={toolsList(
          localStorage,
          userConfigs.permissions.admin,
          userConfigs.permissions.tags &&
            !['AccountBased', 'Usdt'].includes(chainClass),
          chainClass
        )}
      />
    </VStack>
  );
};

export default Tools;
