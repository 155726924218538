/**
 * @generated SignedSource<<d60a06ad4eb83a96421bb878f2365fb6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TransactionTabRow_TransactionFragment$data = {
  readonly hash: string;
  readonly time: any;
  readonly direction: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"TransactionTabRow_TransactionDetailsFragment">;
  readonly " $fragmentType": "TransactionTabRow_TransactionFragment";
};
export type TransactionTabRow_TransactionFragment = TransactionTabRow_TransactionFragment$data;
export type TransactionTabRow_TransactionFragment$key = {
  readonly " $data"?: TransactionTabRow_TransactionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"TransactionTabRow_TransactionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TransactionTabRow_TransactionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hash",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "time",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "direction",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TransactionTabRow_TransactionDetailsFragment"
    }
  ],
  "type": "CoinbaseAnalyticsBlockTransaction",
  "abstractKey": null
};

(node as any).hash = "37aa14c69c8af62dc72e50c471da9863";

export default node;
