import { TextLabel2 } from '@cbhq/cds-web/typography';
import React from 'react';

const WALLETS_TO_DISPLAY = 3;

const WalletLabels = ({ wallets }: { wallets: readonly string[] }) => {
  if (wallets.length > WALLETS_TO_DISPLAY) {
    const topItems = wallets
      .slice(0, WALLETS_TO_DISPLAY)
      .filter(
        (value, index) =>
          wallets.slice(0, WALLETS_TO_DISPLAY).indexOf(value) === index
      )
      .join(', ');

    return (
      <TextLabel2 as="span" color="foregroundMuted">{`${topItems}, +${
        wallets.length - WALLETS_TO_DISPLAY
      } more`}</TextLabel2>
    );
  } else if (wallets.length <= WALLETS_TO_DISPLAY) {
    const topItems = wallets.join(', ');

    return (
      <TextLabel2 as="span" color="foregroundMuted">
        {topItems.length === 0
          ? "This graph doesn't have named wallets"
          : topItems}
      </TextLabel2>
    );
  }
};

export default WalletLabels;
