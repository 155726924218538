import { Button } from '@cbhq/cds-web/buttons';
import { TextInput } from '@cbhq/cds-web/controls';
import { VStack } from '@cbhq/cds-web/layout';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '@cbhq/cds-web/overlays';
import { useToast } from '@cbhq/cds-web/overlays/useToast';
import React, { useState } from 'react';
import { OverlayProps } from '.';

const DuplicateGraphAlert = ({
  visible,
  onRequestClose,
  chain,
  id,
  name,
}: OverlayProps) => {
  const toast = useToast();

  const [graphName, setGraphName] = useState('');
  const [duplicationStatus, setDuplicationStatus] = useState<
    'loading' | 'error' | 'success'
  >(null);

  const duplicateGraph = async () => {
    setDuplicationStatus('loading');
    try {
      const formData = new FormData();
      formData.append('name', graphName);
      formData.append('authenticity_token', window.$.rails.csrfToken());

      await fetch(`/${chain}/graph/${id}/duplicate`, {
        method: 'POST',
        body: formData,
      });

      setDuplicationStatus('success');
      toast.show(`New copy has been created.`);
    } catch (error) {
      setDuplicationStatus('error');
      toast.show(`Failed to duplicate ${name}.`);
    } finally {
      onRequestClose();
      window.location.reload();
    }
  };

  return (
    <Modal visible={visible} onRequestClose={onRequestClose}>
      <ModalHeader title="Duplicate graph" />
      <ModalBody>
        <VStack gap={1}>
          <TextInput
            label="Name"
            placeholder="Graph name"
            type="input"
            name="name"
            onChange={(e) => {
              setGraphName(e.target.value);
            }}
          />
        </VStack>
      </ModalBody>

      <ModalFooter
        primaryAction={
          <Button
            onPress={() => duplicateGraph()}
            loading={duplicationStatus === 'loading'}
            disabled={duplicationStatus === 'error'}
          >
            Duplicate
          </Button>
        }
        secondaryAction={
          <Button variant="secondary" onPress={onRequestClose}>
            Cancel
          </Button>
        }
      />
    </Modal>
  );
};

export default DuplicateGraphAlert;
