import { useRelayEnvironment, fetchQuery } from '@cbhq/data-layer';
import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { multichainSearchQuery } from './MultichainSearchInput';
import { Fallback } from '@cbhq/cds-web/layout';
import { useWindowWidth } from '@react-hook/window-size';
import useDebounce from '../../hooks/useDebounce';
import MultichainSearchInput from './MultichainSearchInput';

const MultichainSearchInputWrapper = () => {
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 300);

  const variables = useMemo(() => ({ query: searchValue }), [searchValue]);
  const environment = useRelayEnvironment();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [queryArgs, setQueryArgs] = useState({
    options: { fetchKey: 0, fetchPolicy: 'network-only' },
    variables,
  });

  const refetch = useCallback((variables) => {
    if (isRefreshing) {
      return;
    }
    setIsRefreshing(true);
    fetchQuery(environment, multichainSearchQuery, variables).subscribe({
      complete: () => {
        setIsRefreshing(false);
        setQueryArgs((prev) => ({
          options: {
            fetchKey: (prev?.options.fetchKey ?? 0) + 1,
            fetchPolicy: 'store-only',
          },
          variables,
        }));
      },
      error: () => {
        setIsRefreshing(false);
      },
    });
  }, []);

  useEffect(() => {
    if (debouncedSearchValue !== '') {
      refetch(variables);
    }
  }, [debouncedSearchValue]);
  return (
    <Suspense
      fallback={
        <Fallback width={0.3 * useWindowWidth()} height={40} shape="squircle" />
      }
    >
      <MultichainSearchInput
        queryArgs={queryArgs}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
    </Suspense>
  );
};

export default MultichainSearchInputWrapper;
