import React from 'react';

const BookmarksPanel = ({ chain, hash, name = null }) => (
  <div className="panel panel-default panel-sidebar">
    <div className="panel-heading">Bookmarks</div>
    <div className="panel-footer">
      <a
        className="panel-action"
        target="_top"
        rel="nofollow"
        data-method="post"
        href={`/${chain}/watchlists?x_flow_watchlist[name]=${
          name ?? hash
        }&x_flow_watchlist[ref]=${hash}`}
      >
        + Add bookmark
      </a>
    </div>
  </div>
);

export default BookmarksPanel;
