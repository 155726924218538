/**
 * @generated SignedSource<<9ccce2f631e0dab0c014fea1eb9c5d45>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FundsExposure_ExposureQuery$variables = {
  type: string;
  tickerSymbol: any;
  hash: string;
  direction: string;
  hops?: string | null;
  threshold?: number | null;
};
export type FundsExposure_ExposureQueryVariables = FundsExposure_ExposureQuery$variables;
export type FundsExposure_ExposureQuery$data = {
  readonly viewer: {
    readonly coinbaseAnalytics: {
      readonly exposure: {
        readonly chartData: ReadonlyArray<{
          readonly label: string;
          readonly value: number | null;
        }> | null;
        readonly " $fragmentSpreads": FragmentRefs<"FundsExposure_ExposureTableFragment">;
      } | null;
    };
  };
};
export type FundsExposure_ExposureQueryResponse = FundsExposure_ExposureQuery$data;
export type FundsExposure_ExposureQuery = {
  variables: FundsExposure_ExposureQueryVariables;
  response: FundsExposure_ExposureQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "direction"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hash"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hops"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "threshold"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tickerSymbol"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "type"
},
v6 = [
  {
    "kind": "Variable",
    "name": "direction",
    "variableName": "direction"
  },
  {
    "kind": "Variable",
    "name": "hash",
    "variableName": "hash"
  },
  {
    "kind": "Variable",
    "name": "hops",
    "variableName": "hops"
  },
  {
    "kind": "Variable",
    "name": "threshold",
    "variableName": "threshold"
  },
  {
    "kind": "Variable",
    "name": "tickerSymbol",
    "variableName": "tickerSymbol"
  },
  {
    "kind": "Variable",
    "name": "type",
    "variableName": "type"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "FundsExposure_ExposureQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CoinbaseAnalytics",
            "kind": "LinkedField",
            "name": "coinbaseAnalytics",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v6/*: any*/),
                "concreteType": "CoinbaseAnalyticsExposure",
                "kind": "LinkedField",
                "name": "exposure",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "FundsExposure_ExposureTableFragment"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CoinbaseAnalyticsExposureChartData",
                    "kind": "LinkedField",
                    "name": "chartData",
                    "plural": true,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "FundsExposure_ExposureQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CoinbaseAnalytics",
            "kind": "LinkedField",
            "name": "coinbaseAnalytics",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v6/*: any*/),
                "concreteType": "CoinbaseAnalyticsExposure",
                "kind": "LinkedField",
                "name": "exposure",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CoinbaseAnalyticsExposureLink",
                    "kind": "LinkedField",
                    "name": "links",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "walletName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "categories",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "country",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "linkFactor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "mixed",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "numTx",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "pathLength",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "path",
                        "storageKey": null
                      },
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CoinbaseAnalyticsExposureChartData",
                    "kind": "LinkedField",
                    "name": "chartData",
                    "plural": true,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a3d294a0c92db5970c1dcbb128f5f365",
    "id": null,
    "metadata": {},
    "name": "FundsExposure_ExposureQuery",
    "operationKind": "query",
    "text": "query FundsExposure_ExposureQuery(\n  $type: String!\n  $tickerSymbol: TickerSymbol!\n  $hash: String!\n  $direction: String!\n  $hops: String\n  $threshold: Float\n) {\n  viewer {\n    coinbaseAnalytics {\n      exposure(type: $type, hash: $hash, tickerSymbol: $tickerSymbol, direction: $direction, hops: $hops, threshold: $threshold) {\n        ...FundsExposure_ExposureTableFragment\n        chartData {\n          label\n          value\n          id\n        }\n        id\n      }\n    }\n  }\n}\n\nfragment FundsExposure_ExposureTableFragment on CoinbaseAnalyticsExposure {\n  links {\n    type\n    walletName\n    categories\n    country\n    linkFactor\n    mixed\n    numTx\n    pathLength\n    path\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "bb790bd55145178322260a214659f113";

export default node;
