export const formatAmount = (
  amount: { readonly value: unknown; readonly currency: unknown },
  chain: string
): string => {
  if (amount) {
    return `${amount.value} ${(amount.currency as string).toUpperCase()}`;
  } else {
    return `N/A ${chain.toUpperCase()}`;
  }
};

export const formatUsd = (amountUsd: string): string => {
  if (amountUsd) {
    return `$ ${amountUsd}`;
  } else {
    return '$ N/A';
  }
};
