import { useToast } from '@cbhq/cds-web/overlays/useToast';
import React, { useState, useEffect } from 'react';
import Moment from 'moment';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@cbhq/cds-web/overlays';
import { Button } from '@cbhq/cds-web/buttons';
import { ListCell, ListCellFallback } from '@cbhq/cds-web/cells';
import { VStack } from '@cbhq/cds-web/layout';
import { TextBody } from '@cbhq/cds-web/typography';
import { casesService } from '../../services';
import { ICase } from '../../services/cases';
import {
  ISearchResultTypes,
  searchResultTypesMap,
} from './SearchResultsWrapper';

interface ICasesModalProps {
  visible: boolean;
  onRequestClose: () => void;
  chain: string;
  itemId: string;
  description: string;
  entity: ISearchResultTypes;
  title?: string;
  secondaryActionButtonTitle?: string;
}

const renderResults = (
  selectedValueId: string,
  isLoadingCasesList: boolean,
  options: casesService.ICase[],
  selectCase: (newValue: casesService.ICase) => void
) => {
  if (isLoadingCasesList) {
    return (
      <>
        <ListCellFallback title description />
        <ListCellFallback title description />
        <ListCellFallback title description />
        <ListCellFallback title description />
        <ListCellFallback title description />
      </>
    );
  } else if (!isLoadingCasesList && options?.length > 0) {
    return options.map((item) => {
      return (
        <ListCell
          key={item.id}
          title={item.title}
          description={Moment(item.lastUpdated).format('MMM DD, YYYY')}
          selected={selectedValueId === item?.id}
          onPress={() => {
            selectCase(item);
          }}
        />
      );
    });
  }
  return <ListCell title="You haven’t created any cases yet." disabled />;
};

export const CasesModal = ({
  visible,
  onRequestClose,
  itemId,
  chain,
  entity,
  title,
  secondaryActionButtonTitle,
}: ICasesModalProps) => {
  const toast = useToast();

  const [isLoadingCasesList, setIsLoadingCasesList] = useState(true);
  const [options, setOptions] = useState<ICase[]>([]);
  const [value, setValue] = useState<ICase>(null);
  const [caseCreationStatus, setCaseCreationStatus] = useState<
    'loading' | 'error' | 'success'
  >(null);

  useEffect(() => {
    loadCasesList();
  }, []);

  const loadCasesList = async () => {
    const response = await casesService.getCases(chain);
    if (response.status == 'ok') {
      setOptions(response.result);
    } else {
      toast.show('Failed to load cases list');
    }
    setIsLoadingCasesList(false);
  };

  const onNewCase = async () => {
    setCaseCreationStatus('loading');

    try {
      const createCaseResult = await casesService.createCase(chain);
      if (createCaseResult.status !== 'ok') {
        throw new Error();
      }
      const { status: addItemToCaseStatus } = await casesService.addItemToCase(
        chain,
        createCaseResult.result.id,
        itemId
      );

      if (addItemToCaseStatus !== 'ok') {
        throw new Error();
      }
      setCaseCreationStatus('success');
      toast.show('Successfully added.', {
        action: {
          label: 'View',
          onPress: () =>
            (window.location.href = `/${chain}/cases/${createCaseResult.result.id}`),
        },
      });
    } catch (_) {
      setCaseCreationStatus('error');
      toast.show('Failed to add.');
    } finally {
      onRequestClose();
    }
  };

  const onAddToCase = async () => {
    setCaseCreationStatus('loading');

    try {
      const { status } = await casesService.addItemToCase(
        chain,
        value.id,
        itemId
      );
      if (status !== 'ok') {
        throw new Error();
      }
      setCaseCreationStatus('success');
      toast.show('Successfully added.', {
        action: {
          label: 'View',
          onPress: () => (window.location.href = `/${chain}/cases/${value.id}`),
        },
      });
    } catch (_) {
      setCaseCreationStatus('error');
      toast.show('Failed to add.');
    } finally {
      onRequestClose();
    }
  };

  const selectCase = (newValue) => {
    if (value === null) {
      return setValue(newValue);
    }

    if (value !== null && value?.id === newValue?.id) {
      setValue(null);
    } else {
      setValue(newValue);
    }
  };

  return (
    <Modal visible={visible} onRequestClose={onRequestClose}>
      <ModalHeader title="Add to case" />
      <ModalBody>
        <VStack gap={2}>
          <TextBody spacingStart={3} as="p">
            {title ||
              `Select a case to add the ${searchResultTypesMap[entity]} to.`}
          </TextBody>
          <VStack gap={0}>
            {renderResults(value?.id, isLoadingCasesList, options, selectCase)}
          </VStack>
        </VStack>
      </ModalBody>
      <ModalFooter
        primaryAction={
          <Button
            onPress={onAddToCase}
            disabled={options?.length === 0 || value === null}
          >
            Add
          </Button>
        }
        secondaryAction={
          <Button
            variant="secondary"
            onPress={() => {
              onNewCase();
              onRequestClose();
            }}
            disabled={caseCreationStatus !== null}
          >
            {secondaryActionButtonTitle || 'Add to a new case'}
          </Button>
        }
      />
    </Modal>
  );
};
