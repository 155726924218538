import Moment from 'moment';

const FormatTime = (time: string) => {
  return Moment(time).format('YYYY-MM-DD h:mm:ss');
};

export const addHoursToDate = (objDate: Date, intHours: number) => {
  const numberOfMlSeconds = objDate.getTime();
  const addMlSeconds = intHours * 60 * 60 * 1000;
  const newDateObj = new Date(numberOfMlSeconds + addMlSeconds);
  return Moment(newDateObj).utc();
};

export default FormatTime;
