import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useFavicon = (chains) => {
  const location = useLocation();
  useEffect(() => {
    const chainRegex = new RegExp(`^/(${chains.join('|')})/`, 'g');
    const found = location.pathname.match(chainRegex);
    const link = document.querySelector("link[rel~='icon']");
    if (found) {
      link.href = `/favicon${found[0]}favicon-96x96.png`;
    } else {
      link.href = '/favicon.ico';
    }
  }, [location]);
};

export default useFavicon;
