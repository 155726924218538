import React, {
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Route,
  NavLink,
  useParams,
  useRouteMatch,
  useHistory,
} from 'react-router-dom';
import HeaderBlock from '../../components/HeaderBlock';
import HeaderBlocks from '../../components/HeaderBlocks';
import { AddressTransactions } from './AddressTransactions';
import { useLazyLoadQuery } from 'react-relay';
import { OsintTable } from '../../components/OsintTable';
import { graphql } from '@cbhq/data-layer';
import { AddressSummaryPage_AddressSummaryQuery } from './__generated__/AddressSummaryPage_AddressSummaryQuery.graphql';
import { AddressSummaryPage_OsintQueryResponse } from './__generated__/AddressSummaryPage_OsintQuery.graphql';
import ExpandableSection from '../../components/ExpandableSection';
import TimePatternCharts from '../../components/TimePatternCharts';
import { formatAmount, formatUsd } from '../../util/AmountUtils';
import AdditionalInfo from '../../components/AdditionalInfo';
import {
  HeaderBreadcrumbBlock,
  HeaderBreadcrumbs,
} from '../../components/HeaderBreadcrumbs';
import { ChainLink } from '../../components/ChainLink';
import BookmarksPanel from '../../components/BookmarksPanel';
import GraphsPanel from '../../components/GraphsPanel';
import CasesPanel from '../../components/CasesPanel';
import ReactTooltip from 'react-tooltip';
import { PageDataContext } from '../../util/PageDataContext';
import ReportButton from '../../components/ReportButton';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { Fallback, HStack, VStack } from '@cbhq/cds-web/layout';
import FundsTrackingButton from '../../components/FundsTrackingButton';
import LinkAnalysisButton from '../../components/LinkAnalysisButton';
import Interactions from '../../components/Interactions';
import { ExpandableSectionFallback } from '../../components/ExpandableSectionFallback';
import RiskScoreLabelWrapper from '../../components/RiskScoreLabelWrapper';
import { TabNavigation } from '@cbhq/cds-web/tabs/TabNavigation';

const addressSummaryQuery = graphql`
  query AddressSummaryPage_AddressSummaryQuery(
    $tickerSymbol: TickerSymbol!
    $addressId: String!
  ) {
    viewer {
      coinbaseAnalytics {
        addressByHashAndSymbol(tickerSymbol: $tickerSymbol, hash: $addressId) {
          hash
          numTx
          balanceAmount {
            value
            currency
          }
          balanceAmountUsd
          sentAmount {
            value
            currency
          }
          sentAmountUsd
          receivedAmount {
            value
            currency
          }
          attributes {
            title
            type
          }
          receivedAmountUsd
          gainAmountUsd
          gainUsdPercent
          firstSeen
          lastSeen
          wallet {
            id
            hash
            name
          }
          interactedTokens {
            address
            name
            tickerSymbol
          }
          ...AdditionalInfoAddress_metadata
        }
      }
    }
  }
`;

export const AddressSummaryPage: React.FC<{ chain: string }> = (props) => {
  const { hash } = useParams();
  const { path, url } = useRouteMatch();
  const { graphs, cases, userConfigs } = useContext(PageDataContext);
  const history = useHistory();

  // Fetch Address Summary for main panels
  const data = useLazyLoadQuery<AddressSummaryPage_AddressSummaryQuery>(
    addressSummaryQuery,
    {
      tickerSymbol: props.chain,
      addressId: hash,
    }
  );
  const address = data.viewer.coinbaseAnalytics.addressByHashAndSymbol;

  // Fetch Wallet's Risk Score
  const walletHash = address?.wallet?.hash;
  const walletName = address?.wallet?.name;

  const crumbs = [];
  if (walletHash) {
    crumbs.push(
      <HeaderBreadcrumbBlock
        link={<ChainLink to={`/wallets/${walletHash}`}>{walletName}</ChainLink>}
        customTooltip={walletName}
        hash={walletHash}
        label={'Wallet'}
        riskLabel={
          <ErrorBoundary errorChild={<span />}>
            <Suspense
              fallback={<Fallback width={25} height={25} shape="squircle" />}
            >
              <RiskScoreLabelWrapper
                chain={props.chain}
                hash={walletHash}
                customKey={walletName}
                type="wallet"
                useCDScolors
              />
            </Suspense>
          </ErrorBoundary>
        }
      />
    );
  }
  crumbs.push(
    <HeaderBreadcrumbBlock
      hash={hash}
      label={'Address'}
      riskLabel={
        <ErrorBoundary errorChild={<span />}>
          <Suspense
            fallback={<Fallback width={25} height={25} shape="squircle" />}
          >
            <RiskScoreLabelWrapper
              hash={hash}
              chain={props.chain}
              type="address"
              useCDScolors
            />
          </Suspense>
        </ErrorBoundary>
      }
    />
  );

  const [primary, setPrimary] = useState('');

  const tabs = useMemo(
    () => [
      { id: 'summary_tab', label: 'Summary' },
      { id: 'transactions_tab', label: 'Transactions' },
    ],
    []
  );

  useEffect(() => {
    const currentPath = window.location.pathname;

    if (currentPath.includes('/transactions')) {
      setPrimary('transactions_tab');
    } else {
      setPrimary('summary_tab');
    }
  }, []);

  const handleRedirect = useCallback(
    (newPath) => {
      setPrimary(newPath);

      if (newPath === 'summary_tab') {
        history.push(url);
      }
      if (newPath === 'transactions_tab') {
        history.push(`${url}/transactions`);
      }
    },
    [primary, tabs]
  );

  return (
    address && (
      <>
        {userConfigs.license.new_ui_navigation && (
          <HStack
            spacingHorizontal={3}
            spacingVertical={1}
            borderedBottom
            dangerouslySetStyle={{ margin: '0 -2rem', paddingBottom: 0 }}
          >
            <TabNavigation
              value={primary}
              tabs={tabs}
              onChange={handleRedirect}
            />
          </HStack>
        )}
        <div className="container-fluid container-max-xl">
          <HeaderBreadcrumbs blocks={crumbs} />

          {!userConfigs.license.new_ui_navigation && (
            <ul className="nav nav-pills">
              <li>
                <NavLink to={url} exact>
                  <b>Summary</b>
                </NavLink>
              </li>
              <li>
                <NavLink to={`${url}/transactions`} exact>
                  <b>Transactions</b>
                </NavLink>
              </li>
            </ul>
          )}

          <div className="row">
            <div className="col-md-9">
              <HeaderBlocks
                blocks={[
                  <HeaderBlock
                    key="address-summary-page-header-block-1"
                    title="Balance"
                    body={formatAmount(address.balanceAmount, props.chain)}
                    footer={formatUsd(address.balanceAmountUsd as string)}
                  />,
                  <HeaderBlock
                    key="address-summary-page-header-block-2"
                    title="Received"
                    body={formatAmount(address.receivedAmount, props.chain)}
                    footer={formatUsd(address.receivedAmountUsd as string)}
                  />,
                  <HeaderBlock
                    key="address-summary-page-header-block-3"
                    title="Sent"
                    body={formatAmount(address.sentAmount, props.chain)}
                    footer={formatUsd(address.sentAmountUsd as string)}
                  />,
                  <HeaderBlock
                    key="address-summary-page-header-block-4"
                    title="Capital Gain"
                    body={`${address.gainUsdPercent} %`}
                    footer={formatUsd(address.gainAmountUsd as string)}
                  />,
                ]}
              />

              <Route path={path} exact>
                <div id="summary" className="tab-pane active">
                  <table className="table table-summary">
                    <colgroup>
                      <col width="30%" />
                      <col width="70%" />
                    </colgroup>
                    <tbody>
                      <tr>
                        <td>Address</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>ID</td>
                        <td>
                          <span>{address.hash}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>Activity</td>
                        <td>
                          {address.firstSeen &&
                            new Date(
                              Date.parse(address.firstSeen.toString())
                            ).toUTCString()}{' '}
                          <i className="fa fa-arrows-h"></i>{' '}
                          {address.lastSeen &&
                            new Date(
                              Date.parse(address.lastSeen.toString())
                            ).toUTCString()}
                        </td>
                      </tr>
                      {address.attributes.length > 0 && (
                        <tr>
                          <td>Attributes</td>
                          <td>
                            {address.attributes.map((attribute, index) => (
                              <span
                                key={index}
                                className={`label label-${attribute.type}`}
                                style={{ marginRight: 6 }}
                              >
                                {attribute.title}
                              </span>
                            ))}
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td>Transactions</td>
                        <td>
                          <span>{address.numTx}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <AddressOsintTable chain={props.chain} addressId={hash} />

                  <ExpandableSection
                    sectionTitle="Interactions"
                    defaultExpanded
                  >
                    <div style={{ paddingTop: '8px' }}>
                      <ErrorBoundary>
                        <Suspense fallback={<ExpandableSectionFallback />}>
                          <Interactions
                            chain={props.chain}
                            type="addresses"
                            id={hash}
                          />
                        </Suspense>
                      </ErrorBoundary>
                    </div>
                  </ExpandableSection>

                  <ExpandableSection sectionTitle="Time Patterns">
                    <ErrorBoundary>
                      <Suspense fallback={<ExpandableSectionFallback />}>
                        <TimePatternCharts
                          chain={props.chain}
                          hash={hash}
                          interactedTokens={address.interactedTokens}
                        />
                      </Suspense>
                    </ErrorBoundary>
                  </ExpandableSection>

                  <AdditionalInfo data={address} type="address" />
                </div>
              </Route>
              <Route path={`${path}/transactions`} exact>
                <AddressTransactions
                  chain={props.chain}
                  addressId={hash}
                  numTx={address.numTx}
                />
              </Route>
            </div>
            <div className="col-md-3 sidebar" style={{ marginTop: 40 }}>
              <BookmarksPanel chain={props.chain} hash={hash} />
              <GraphsPanel hash={hash} graphs={graphs} />
              <CasesPanel hash={hash} cases={cases} />
              <ReactTooltip id="tool-follow-peeling">
                <div style={{ maxWidth: 250 }}>
                  This transaction appears to be part of a peeling chain, where
                  a big amount of coins is divided and spent in many subsequent
                  small transactions. This tool try to find the origin of the
                  peeling chain, showing each spending transaction up to the
                  root.
                </div>
              </ReactTooltip>
              <VStack gap={1}>
                <LinkAnalysisButton
                  hash={hash}
                  chain={props.chain}
                  canGroupByType={true}
                />
                <FundsTrackingButton hash={hash} chain={props.chain} />
                <ReportButton
                  hash={hash}
                  chain={props.chain}
                  type="Address"
                  enabled={userConfigs.license.report}
                />
              </VStack>
            </div>
          </div>
        </div>
      </>
    )
  );
};

const addressOsintQuery = graphql`
  query AddressSummaryPage_OsintQuery(
    $tickerSymbol: TickerSymbol!
    $addressId: String!
    $after: String!
  ) {
    viewer {
      coinbaseAnalytics {
        addressByHashAndSymbol(hash: $addressId, tickerSymbol: $tickerSymbol) {
          osint(asset: $tickerSymbol, after: $after) {
            edges {
              node {
                ...OsintTable_OsintTableRowFragment
              }
            }
            pageInfo {
              endCursor
              hasNextPage
            }
          }
        }
      }
    }
  }
`;

const AddressOsintTable: React.FC<{
  chain: string;
  addressId: string;
}> = (props) => {
  return (
    <OsintTable
      chain={props.chain}
      type="address"
      data={props.addressId}
      fetchOsintConnection={(cursor) => {
        const result = useLazyLoadQuery(addressOsintQuery, {
          tickerSymbol: props.chain,
          addressId: props.addressId,
          after: cursor,
        });
        return (result as AddressSummaryPage_OsintQueryResponse).viewer
          .coinbaseAnalytics.addressByHashAndSymbol.osint;
      }}
    />
  );
};
