/**
 * @generated SignedSource<<29a43a2d789c7492e10f475a30439831>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TransactionDetailsTable$data = {
  readonly hash: string;
  readonly time: any;
  readonly attributes: ReadonlyArray<string> | null;
  readonly blockHeight: number;
  readonly totalInputs: number | null;
  readonly totalOutputs: number | null;
  readonly totalUniqueInputs: number | null;
  readonly totalUniqueInputTxs: number | null;
  readonly totalUniqueOutputs: number | null;
  readonly totalSpentOutputs: number | null;
  readonly totalUniqueSpentOutputTxs: number | null;
  readonly isUtxo: boolean | null;
  readonly gasPrice: {
    readonly value: any;
    readonly currency: any;
  } | null;
  readonly gasLimit: {
    readonly value: any;
    readonly currency: any;
  } | null;
  readonly gasUsed: {
    readonly value: any;
    readonly currency: any;
  } | null;
  readonly error: string | null;
  readonly " $fragmentType": "TransactionDetailsTable";
};
export type TransactionDetailsTable = TransactionDetailsTable$data;
export type TransactionDetailsTable$key = {
  readonly " $data"?: TransactionDetailsTable$data;
  readonly " $fragmentSpreads": FragmentRefs<"TransactionDetailsTable">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currency",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TransactionDetailsTable",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hash",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "time",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "attributes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "blockHeight",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalInputs",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalOutputs",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalUniqueInputs",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalUniqueInputTxs",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalUniqueOutputs",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalSpentOutputs",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalUniqueSpentOutputTxs",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isUtxo",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Amount",
      "kind": "LinkedField",
      "name": "gasPrice",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Amount",
      "kind": "LinkedField",
      "name": "gasLimit",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Amount",
      "kind": "LinkedField",
      "name": "gasUsed",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "error",
      "storageKey": null
    }
  ],
  "type": "CoinbaseAnalyticsTransaction",
  "abstractKey": null
};
})();

(node as any).hash = "b7ba162ee7df824239015c60c2fd43b7";

export default node;
