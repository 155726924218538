export function abbreviateHash(
  hash: string,
  prefixLength = 6,
  suffixLength = 4
): string {
  if (hash.length <= prefixLength + suffixLength) {
    return hash;
  }

  return hash.slice(0, prefixLength) + '...' + hash.slice(-suffixLength);
}
