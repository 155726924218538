/**
 * @generated SignedSource<<037e6e3cab4110cdd8d3321fc2d4c17f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CoinbaseAnalyticsOperationType = "UNKNOWN" | "PAYMENT" | "BLOCK_REWARD" | "FEE" | "CONTRACT" | "CREATE_ACCOUNT" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TransactionTabRow_TransactionDetailsFragment$data = {
  readonly hash: string;
  readonly inAmount: {
    readonly value: any;
    readonly currency: any;
  } | null;
  readonly inAmountUsd: any | null;
  readonly outAmount: {
    readonly value: any;
    readonly currency: any;
  } | null;
  readonly outAmountUsd: any | null;
  readonly feeAmount: {
    readonly value: any;
    readonly currency: any;
  } | null;
  readonly feeAmountUsd: any | null;
  readonly mixed: boolean | null;
  readonly operations: ReadonlyArray<{
    readonly index: number | null;
    readonly type: CoinbaseAnalyticsOperationType;
    readonly inputs: ReadonlyArray<{
      readonly address: string;
      readonly name: string | null;
      readonly amount: {
        readonly value: any;
        readonly currency: any;
      };
      readonly amountUsd: any;
      readonly prevTxHash: string | null;
      readonly nextTxHash: string | null;
      readonly prevOutputIndex: number | null;
      readonly nextOutputIndex: number | null;
      readonly generated: boolean | null;
      readonly osint: string | null;
      readonly valid: boolean | null;
      readonly changeAddress: boolean | null;
    }> | null;
    readonly outputs: ReadonlyArray<{
      readonly address: string;
      readonly name: string | null;
      readonly amount: {
        readonly value: any;
        readonly currency: any;
      };
      readonly amountUsd: any;
      readonly prevTxHash: string | null;
      readonly nextTxHash: string | null;
      readonly prevOutputIndex: number | null;
      readonly nextOutputIndex: number | null;
      readonly generated: boolean | null;
      readonly osint: string | null;
      readonly valid: boolean | null;
      readonly changeAddress: boolean | null;
    }> | null;
  }> | null;
  readonly totalInputs: number;
  readonly totalOutputs: number;
  readonly " $fragmentType": "TransactionTabRow_TransactionDetailsFragment";
};
export type TransactionTabRow_TransactionDetailsFragment = TransactionTabRow_TransactionDetailsFragment$data;
export type TransactionTabRow_TransactionDetailsFragment$key = {
  readonly " $data"?: TransactionTabRow_TransactionDetailsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"TransactionTabRow_TransactionDetailsFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currency",
    "storageKey": null
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "address",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Amount",
    "kind": "LinkedField",
    "name": "amount",
    "plural": false,
    "selections": (v0/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "amountUsd",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "prevTxHash",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "nextTxHash",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "prevOutputIndex",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "nextOutputIndex",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "generated",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "osint",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "valid",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "changeAddress",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TransactionTabRow_TransactionDetailsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hash",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Amount",
      "kind": "LinkedField",
      "name": "inAmount",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "inAmountUsd",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Amount",
      "kind": "LinkedField",
      "name": "outAmount",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "outAmountUsd",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Amount",
      "kind": "LinkedField",
      "name": "feeAmount",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "feeAmountUsd",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mixed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CoinbaseAnalyticsOperation",
      "kind": "LinkedField",
      "name": "operations",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "index",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CoinbaseAnalyticsOperationPart",
          "kind": "LinkedField",
          "name": "inputs",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CoinbaseAnalyticsOperationPart",
          "kind": "LinkedField",
          "name": "outputs",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalInputs",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalOutputs",
      "storageKey": null
    }
  ],
  "type": "CoinbaseAnalyticsBlockTransaction",
  "abstractKey": null
};
})();

(node as any).hash = "9bea68a8d89a8cbb26f2f18db0d5f990";

export default node;
