import React from 'react';
import { GraphsList } from '../components/GraphsList';

export const MyGraphsPage = ({
  chainConfigs,
  chain,
}: {
  chainConfigs: any;
  chain: string;
}) => {
  return (
    <>
      <GraphsList chain={chain} chainConfigs={chainConfigs} />
    </>
  );
};
