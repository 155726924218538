import React from 'react';

export const LoadingBubbles: React.FC<{ inline?: boolean }> = (props) => {
  return (
    <div className={props.inline ? 'spinner-inline' : 'spinner'}>
      <div className="bounce1" />
      <div className="bounce2" />
      <div className="bounce3" />
    </div>
  );
};
