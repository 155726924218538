import { Button } from '@cbhq/cds-web/buttons';
import { ListCell } from '@cbhq/cds-web/cells';
import { VStack } from '@cbhq/cds-web/layout';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '@cbhq/cds-web/overlays';
import { useToast } from '@cbhq/cds-web/overlays/useToast';
import { TextBody } from '@cbhq/cds-web/typography';
import React, { useState, useEffect } from 'react';
import { OverlayProps } from '.';
import { CaseDetails } from '../GraphsList';
import Moment from 'moment';

const MoveToModal = ({
  visible,
  onRequestClose,
  id: graphId,
  chain,
  name,
}: OverlayProps) => {
  const toast = useToast();

  const [isLoadingCasesList, setIsLoadingCasesList] = useState(true);
  const [options, setOptions] = useState<CaseDetails[]>([]);
  const [value, setValue] = useState<CaseDetails>(null);
  const [caseCreationStatus, setCaseCreationStatus] = useState<
    'loading' | 'error' | 'success'
  >(null);

  const getAuthenticityToken = () => {
    return window.$.rails.csrfToken();
  };

  useEffect(() => {
    loadCasesList();
  }, []);

  const loadCasesList = async () => {
    const response = await fetch(`/api/internal/cases?asset=${chain}`, {
      method: 'GET',
    });
    const data = await response.json();

    setOptions(data.result);
    setIsLoadingCasesList(false);
  };

  const createCase = async () => {
    setCaseCreationStatus('loading');

    try {
      const caseCreationResponse = await fetch(`/${chain}/cases`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'x-csrf-token': getAuthenticityToken(),
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      });
      const data = await caseCreationResponse.json();

      await fetch(
        `/${chain}/cases/${data._id.$oid}/folders/${data.folders[0]._id.$oid}/items`,
        {
          method: 'POST',
          credentials: 'include',
          headers: {
            'x-csrf-token': getAuthenticityToken(),
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          body: JSON.stringify({
            text: graphId,
          }),
        }
      );

      setCaseCreationStatus('success');
      toast.show('Successfully moved.');
    } catch (error) {
      setCaseCreationStatus('error');
      toast.show('Failed to move case.');
    } finally {
      window.location.reload();
    }
  };

  const selectCase = (newValue) => {
    if (value === null) setValue(newValue);

    if (value !== null && value?.id === newValue?.id) {
      setValue(null);
    } else {
      setValue(newValue);
    }
  };

  return (
    <Modal visible={visible} onRequestClose={onRequestClose}>
      <ModalHeader title="Move to case" />
      <ModalBody>
        <form
          id="move-graph"
          method="post"
          action={`${chain}/cases/${value?.id}/folders/${value?.folders[0]?.id}/items`}
        >
          <VStack gap={2}>
            <TextBody spacingStart={3} as="p">
              Select a case to move your graph to.
            </TextBody>

            <VStack gap={0}>
              {options?.length !== 0 && !isLoadingCasesList ? (
                options.map((item) => {
                  return (
                    <>
                      <ListCell
                        key={item.id}
                        title={item.title}
                        description={Moment(item.lastUpdated).format(
                          'MMM DD, YYYY'
                        )}
                        selected={value?.id === item?.id}
                        onPress={() => {
                          selectCase(item);
                        }}
                      />
                    </>
                  );
                })
              ) : (
                <ListCell title="You haven’t created any cases yet." disabled />
              )}
            </VStack>
          </VStack>

          <input type="hidden" name="text" value={graphId} />
          <input
            type="hidden"
            name="authenticity_token"
            id="authenticity_token"
            value={getAuthenticityToken()}
          />
        </form>
      </ModalBody>
      <ModalFooter
        primaryAction={
          <Button
            type="submit"
            form="move-graph"
            disabled={options.length === 0 || value === null}
          >
            Move
          </Button>
        }
        secondaryAction={
          <Button
            variant="secondary"
            onPress={() => {
              createCase();
              onRequestClose();
            }}
            disabled={caseCreationStatus !== null}
          >
            Move to new case
          </Button>
        }
      />
    </Modal>
  );
};

export default MoveToModal;
