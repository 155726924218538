import React from 'react';
import { graphql } from '@cbhq/data-layer';
import { useParams } from 'react-router-dom';
import { useLazyLoadQuery } from 'react-relay';
import { ChainLink } from '../../components/ChainLink';
import { TransactionWallets_TransactionQueryResponse } from './__generated__/TransactionWallets_TransactionQuery.graphql';

const txWalletsQuery = graphql`
  query TransactionWallets_TransactionQuery(
    $tickerSymbol: TickerSymbol!
    $transactionId: String!
  ) {
    viewer {
      coinbaseAnalytics {
        transactionByHashAndSymbol(
          hash: $transactionId
          tickerSymbol: $tickerSymbol
        ) {
          isUtxo
          wallets {
            inputs {
              addressHash
              walletHash
              name
              balance {
                value
                currency
              }
              numAddresses
            }
            outputs {
              addressHash
              walletHash
              name
              balance {
                value
                currency
              }
              numAddresses
            }
          }
        }
      }
    }
  }
`;

// A table showing the input and output wallets of a single transaction.
export const TransactionWalletSection: React.FC<{
  chain: string;
}> = (props) => {
  const { hash } = useParams();

  const { viewer } = useLazyLoadQuery(txWalletsQuery, {
    transactionId: hash,
    tickerSymbol: props.chain,
  }) as TransactionWallets_TransactionQueryResponse;

  const tx = viewer.coinbaseAnalytics.transactionByHashAndSymbol;

  return (
    <React.Fragment>
      <TransactionWalletTable
        header={tx.isUtxo ? 'Input Wallets' : 'Sender Wallet'}
        chain={props.chain}
        transactionWallets={tx.wallets.inputs}
      />
      <TransactionWalletTable
        header={tx.isUtxo ? 'Output Wallets' : 'Receiver Wallet'}
        chain={props.chain}
        transactionWallets={tx.wallets.outputs}
      />
    </React.Fragment>
  );
};

const TransactionWalletTable: React.FC<{
  header: string;
  chain: string;
  transactionWallets;
}> = (props) => {
  return (
    <table className="table ">
      <thead>
        <tr>
          <th>{props.header}</th>
          <th> </th>
          <th className="text-right">Addresses</th>
          <th className="text-right">Value</th>
        </tr>
      </thead>
      <tbody>
        {props.transactionWallets?.map((transactionWallet) => {
          return (
            <TransactionWalletRow
              key={transactionWallet.addressHash}
              address={transactionWallet.addressHash}
              walletName={transactionWallet.name}
              walletId={transactionWallet.walletHash}
              amount={transactionWallet.balance.value}
              currency={transactionWallet.balance.currency}
              numAddresses={transactionWallet.numAddresses}
            />
          );
        })}
      </tbody>
    </table>
  );
};

const TransactionWalletRow: React.FC<{
  address: string;
  walletId: string;
  walletName: string;
  amount: string;
  currency: string;
  numAddresses: number;
}> = (props) => {
  const isNegative = props.amount.length > 0 && props.amount[0] == '-';
  return (
    <tr>
      <td className="monospace">
        <ChainLink to={`/addresses/${props.address}`}>
          {props.address}
        </ChainLink>
      </td>
      <td width="30%" className="monospace">
        <ChainLink to={`/wallets/${props.walletId}`}>
          {props.walletName}
        </ChainLink>
      </td>
      <td width="8%" className="text-right">
        {props.numAddresses.toLocaleString()}
      </td>
      <td width="8%" className="text-right">
        <span
          className={`label ${isNegative ? 'label-danger' : 'label-success'}`}
        >{`${props.amount} ${props.currency}`}</span>
      </td>
    </tr>
  );
};
