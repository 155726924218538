/**
 * @generated SignedSource<<1d097f305571d0ee8bf8804769c2807c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WalletAddresses_Query$variables = {
  tickerSymbol: any;
  walletId: string;
  after: string;
};
export type WalletAddresses_QueryVariables = WalletAddresses_Query$variables;
export type WalletAddresses_Query$data = {
  readonly viewer: {
    readonly coinbaseAnalytics: {
      readonly walletByHashAndSymbol: {
        readonly addresses: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly " $fragmentSpreads": FragmentRefs<"WalletAddresses_AddressRowFragment">;
            } | null;
          }>;
          readonly pageInfo: {
            readonly endCursor: string;
            readonly hasNextPage: boolean;
          };
        } | null;
      } | null;
    };
  };
};
export type WalletAddresses_QueryResponse = WalletAddresses_Query$data;
export type WalletAddresses_Query = {
  variables: WalletAddresses_QueryVariables;
  response: WalletAddresses_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tickerSymbol"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "walletId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "hash",
    "variableName": "walletId"
  },
  {
    "kind": "Variable",
    "name": "tickerSymbol",
    "variableName": "tickerSymbol"
  }
],
v4 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "asset",
    "variableName": "tickerSymbol"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currency",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "WalletAddresses_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CoinbaseAnalytics",
            "kind": "LinkedField",
            "name": "coinbaseAnalytics",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "CoinbaseAnalyticsWallet",
                "kind": "LinkedField",
                "name": "walletByHashAndSymbol",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v4/*: any*/),
                    "concreteType": "CoinbaseAnalyticsWalletAddressConnection",
                    "kind": "LinkedField",
                    "name": "addresses",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CoinbaseAnalyticsWalletAddressEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CoinbaseAnalyticsAddress",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "WalletAddresses_AddressRowFragment"
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "WalletAddresses_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CoinbaseAnalytics",
            "kind": "LinkedField",
            "name": "coinbaseAnalytics",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "CoinbaseAnalyticsWallet",
                "kind": "LinkedField",
                "name": "walletByHashAndSymbol",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v4/*: any*/),
                    "concreteType": "CoinbaseAnalyticsWalletAddressConnection",
                    "kind": "LinkedField",
                    "name": "addresses",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CoinbaseAnalyticsWalletAddressEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CoinbaseAnalyticsAddress",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hash",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "lastSeen",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "numTx",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "CoinbaseAnalyticsAddressMetadata",
                                "kind": "LinkedField",
                                "name": "metadata",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                  },
                                  (v6/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Amount",
                                "kind": "LinkedField",
                                "name": "balanceAmount",
                                "plural": false,
                                "selections": (v7/*: any*/),
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Amount",
                                "kind": "LinkedField",
                                "name": "sentAmount",
                                "plural": false,
                                "selections": (v7/*: any*/),
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Amount",
                                "kind": "LinkedField",
                                "name": "receivedAmount",
                                "plural": false,
                                "selections": (v7/*: any*/),
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "balanceAmountUsd",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "sentAmountUsd",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "receivedAmountUsd",
                                "storageKey": null
                              },
                              (v6/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6c3bf53366afba73d53229806c99d2dd",
    "id": null,
    "metadata": {},
    "name": "WalletAddresses_Query",
    "operationKind": "query",
    "text": "query WalletAddresses_Query(\n  $tickerSymbol: TickerSymbol!\n  $walletId: String!\n  $after: String!\n) {\n  viewer {\n    coinbaseAnalytics {\n      walletByHashAndSymbol(hash: $walletId, tickerSymbol: $tickerSymbol) {\n        addresses(asset: $tickerSymbol, after: $after) {\n          edges {\n            node {\n              ...WalletAddresses_AddressRowFragment\n              id\n            }\n          }\n          pageInfo {\n            endCursor\n            hasNextPage\n          }\n        }\n        id\n      }\n    }\n  }\n}\n\nfragment WalletAddresses_AddressRowFragment on CoinbaseAnalyticsAddress {\n  hash\n  lastSeen\n  numTx\n  metadata {\n    name\n    id\n  }\n  balanceAmount {\n    value\n    currency\n  }\n  sentAmount {\n    value\n    currency\n  }\n  receivedAmount {\n    value\n    currency\n  }\n  balanceAmountUsd\n  sentAmountUsd\n  receivedAmountUsd\n}\n"
  }
};
})();

(node as any).hash = "6585e82839d0404fefcddcaad8516cd6";

export default node;
