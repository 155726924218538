/**
 * @generated SignedSource<<6cf0d15ace52b70f306f9fdb170a3e1e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BlockSummaryPageQuery_BlockQuery$variables = {
  blockId: string;
  tickerSymbol: any;
};
export type BlockSummaryPageQuery_BlockQueryVariables = BlockSummaryPageQuery_BlockQuery$variables;
export type BlockSummaryPageQuery_BlockQuery$data = {
  readonly viewer: {
    readonly coinbaseAnalytics: {
      readonly blockByHashAndSymbol: {
        readonly hash: string;
        readonly time: any;
        readonly height: number;
        readonly size: string;
        readonly rewardAmount: {
          readonly value: any;
          readonly currency: any;
        } | null;
        readonly rewardAmountUsd: any | null;
        readonly feeAmount: {
          readonly value: any;
          readonly currency: any;
        } | null;
        readonly feeAmountUsd: any | null;
        readonly previousBlock: string | null;
        readonly nextBlock: string | null;
        readonly minedBy: string;
        readonly numTx: number;
        readonly numFailedTx: number;
        readonly numInternalTx: number;
        readonly numTokenTransfers: number;
      } | null;
    };
  };
};
export type BlockSummaryPageQuery_BlockQueryResponse = BlockSummaryPageQuery_BlockQuery$data;
export type BlockSummaryPageQuery_BlockQuery = {
  variables: BlockSummaryPageQuery_BlockQueryVariables;
  response: BlockSummaryPageQuery_BlockQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "blockId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tickerSymbol"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "hash",
    "variableName": "blockId"
  },
  {
    "kind": "Variable",
    "name": "tickerSymbol",
    "variableName": "tickerSymbol"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hash",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "time",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "height",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "size",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currency",
    "storageKey": null
  }
],
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "Amount",
  "kind": "LinkedField",
  "name": "rewardAmount",
  "plural": false,
  "selections": (v6/*: any*/),
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rewardAmountUsd",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Amount",
  "kind": "LinkedField",
  "name": "feeAmount",
  "plural": false,
  "selections": (v6/*: any*/),
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "feeAmountUsd",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "previousBlock",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nextBlock",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "minedBy",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numTx",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numFailedTx",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numInternalTx",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numTokenTransfers",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BlockSummaryPageQuery_BlockQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CoinbaseAnalytics",
            "kind": "LinkedField",
            "name": "coinbaseAnalytics",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "CoinbaseAnalyticsBlock",
                "kind": "LinkedField",
                "name": "blockByHashAndSymbol",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BlockSummaryPageQuery_BlockQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CoinbaseAnalytics",
            "kind": "LinkedField",
            "name": "coinbaseAnalytics",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "CoinbaseAnalyticsBlock",
                "kind": "LinkedField",
                "name": "blockByHashAndSymbol",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "29815ce085f89c782709ebb3918d1ca5",
    "id": null,
    "metadata": {},
    "name": "BlockSummaryPageQuery_BlockQuery",
    "operationKind": "query",
    "text": "query BlockSummaryPageQuery_BlockQuery(\n  $blockId: String!\n  $tickerSymbol: TickerSymbol!\n) {\n  viewer {\n    coinbaseAnalytics {\n      blockByHashAndSymbol(hash: $blockId, tickerSymbol: $tickerSymbol) {\n        hash\n        time\n        height\n        size\n        rewardAmount {\n          value\n          currency\n        }\n        rewardAmountUsd\n        feeAmount {\n          value\n          currency\n        }\n        feeAmountUsd\n        previousBlock\n        nextBlock\n        minedBy\n        numTx\n        numFailedTx\n        numInternalTx\n        numTokenTransfers\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "476b8a0822934cc8957a2d7fb86886ae";

export default node;
