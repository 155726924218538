/**
 * @generated SignedSource<<87f4b79bb60a516841265d6148ba9652>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SearchInputMenu_MultichainSearchQuery$variables = {
  query: string;
};
export type SearchInputMenu_MultichainSearchQueryVariables = SearchInputMenu_MultichainSearchQuery$variables;
export type SearchInputMenu_MultichainSearchQuery$data = {
  readonly viewer: {
    readonly coinbaseAnalytics: {
      readonly multichainSearch: {
        readonly autocomplete: ReadonlyArray<{
          readonly asset: any | null;
          readonly names: ReadonlyArray<string | null> | null;
          readonly addresses: {
            readonly acceptable: boolean | null;
            readonly minLength: number | null;
            readonly triggered: boolean | null;
            readonly matches: ReadonlyArray<string | null> | null;
          } | null;
        } | null> | null;
      } | null;
    };
  };
};
export type SearchInputMenu_MultichainSearchQueryResponse = SearchInputMenu_MultichainSearchQuery$data;
export type SearchInputMenu_MultichainSearchQuery = {
  variables: SearchInputMenu_MultichainSearchQueryVariables;
  response: SearchInputMenu_MultichainSearchQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "query"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Viewer",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CoinbaseAnalytics",
        "kind": "LinkedField",
        "name": "coinbaseAnalytics",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "query",
                "variableName": "query"
              }
            ],
            "concreteType": "CoinbaseAnalyticsMultichainSearch",
            "kind": "LinkedField",
            "name": "multichainSearch",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CoinbaseAnalyticsSearchByAsset",
                "kind": "LinkedField",
                "name": "autocomplete",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "asset",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "names",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CoinbaseAnalyticsAutocompleteSearchAddress",
                    "kind": "LinkedField",
                    "name": "addresses",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "acceptable",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "minLength",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "triggered",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "matches",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SearchInputMenu_MultichainSearchQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SearchInputMenu_MultichainSearchQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "bff29e51f470b83c256c19e7b8b9d711",
    "id": null,
    "metadata": {},
    "name": "SearchInputMenu_MultichainSearchQuery",
    "operationKind": "query",
    "text": "query SearchInputMenu_MultichainSearchQuery(\n  $query: String!\n) {\n  viewer {\n    coinbaseAnalytics {\n      multichainSearch(query: $query) {\n        autocomplete {\n          asset\n          names\n          addresses {\n            acceptable\n            minLength\n            triggered\n            matches\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "cbff15264afec033514925b48d0ca2e3";

export default node;
