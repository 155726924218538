import React from 'react';
import ReactTooltip from 'react-tooltip';

export const DemixerTooltip = ({ demixerType }) => {
  const tooltips = {
    joinmarket:
      "The Demix tool tries to link each input address to group of outputs for a mixing transaction. Each group's output contains one payment address and an optional change. All payments have the same value for a CoinJoin/JoinMarket transaction, and the payment addresses could be the intended recipient or may lead to another round of mixing.",
    sharedsend: `The Demix tool for SharedSend try to follow a series of inputs, belonging to the same user, through a mixing chain. A list of inputs to choose from is presented to the user (grey inputs are the less probable fundings): if the selected inputs match no solution, the software tries to identify any other unknown input, belonging to the same user, that may have participated in the transaction. The result is a series of potential solutions representing plausible paths (sequences of transactions) thorugh the mixing chains; for each transaction of every path the result is presented in the form of transaction ID (left column), addresses linking the transaction to the following one in the path, and potential changes (right column). Each change address, as well as the last Out Address of the chain, could be either a cashout or an input for another mixing chain. Multiple exit addresses, from various chains belonging to the same user, may converge as inputs in a new mixing chain that can be demixed as well. All the elements in the solutions can be added automatically to a graph to easily remove false positives or highlight relations between different mixing chains.`,
    wasabi:
      'The Demix tool for Wasabi CoinJoin try to follow a series of inputs, belonging to the same user, through a mixing chain. A list of inputs to choose from is presented to the user (grey inputs are the less probable fundings). The solution shows a series of potential exit transactions and addresses where the user moved the mixed funds. Such exit points are sorted by degree of accuracy. This feature is still in beta state and will take ~5 minutes to run.',
    walletdemix:
      'The Demix tool for BitcoinBlender calculates all the funds sent to BitcoinBlender by the source wallet (in the specified funding time frame) and shows all the receiving wallets that withdrawed the most from the mixer (in the specified withdrawal time frame). It then marks the closest withdrawal (if any) that matches the funding. Please note that this tool works well only for large fund shifts. This feature is still in beta state.',
    zec: "The Demix tool for Shielded transactions tries to identify potential unshielding transactions matching the shielded amount in a given time-frame and viceversa. It also supports the tracking of small full-shielded transactions' chains.",
    default:
      'The Demix tool for centralized mixers tries to find withdrawals matching a given funding transaction (and viceversa) considering the mixer fee, the withdrawal time window and the mixer patterns. This feature is still in beta state.',
  };
  const tip = tooltips[demixerType] || tooltips.default;
  return (
    <ReactTooltip id="tool-demixer">
      <div style={{ maxWidth: 250 }}>{tip}</div>
    </ReactTooltip>
  );
};
