/**
 * @generated SignedSource<<b615ac3eb18d7cf939cf6cd5abffc741>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TransactionScriptsSection_TransactionQuery$variables = {
  tickerSymbol: any;
  transactionId: string;
};
export type TransactionScriptsSection_TransactionQueryVariables = TransactionScriptsSection_TransactionQuery$variables;
export type TransactionScriptsSection_TransactionQuery$data = {
  readonly viewer: {
    readonly coinbaseAnalytics: {
      readonly transactionByHashAndSymbol: {
        readonly scripts: {
          readonly inputScripts: ReadonlyArray<{
            readonly content: string;
          }> | null;
          readonly outputScripts: ReadonlyArray<{
            readonly content: string;
          }> | null;
        } | null;
      } | null;
    };
  };
};
export type TransactionScriptsSection_TransactionQueryResponse = TransactionScriptsSection_TransactionQuery$data;
export type TransactionScriptsSection_TransactionQuery = {
  variables: TransactionScriptsSection_TransactionQueryVariables;
  response: TransactionScriptsSection_TransactionQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tickerSymbol"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "transactionId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "hash",
    "variableName": "transactionId"
  },
  {
    "kind": "Variable",
    "name": "tickerSymbol",
    "variableName": "tickerSymbol"
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "content",
    "storageKey": null
  }
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "CoinbaseAnalyticsTransactionScripts",
  "kind": "LinkedField",
  "name": "scripts",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CoinbaseAnalyticsTransactionScript",
      "kind": "LinkedField",
      "name": "inputScripts",
      "plural": true,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CoinbaseAnalyticsTransactionScript",
      "kind": "LinkedField",
      "name": "outputScripts",
      "plural": true,
      "selections": (v2/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TransactionScriptsSection_TransactionQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CoinbaseAnalytics",
            "kind": "LinkedField",
            "name": "coinbaseAnalytics",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "CoinbaseAnalyticsTransaction",
                "kind": "LinkedField",
                "name": "transactionByHashAndSymbol",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TransactionScriptsSection_TransactionQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CoinbaseAnalytics",
            "kind": "LinkedField",
            "name": "coinbaseAnalytics",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "CoinbaseAnalyticsTransaction",
                "kind": "LinkedField",
                "name": "transactionByHashAndSymbol",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8ce43ba8043109e3ec76d77092ab1886",
    "id": null,
    "metadata": {},
    "name": "TransactionScriptsSection_TransactionQuery",
    "operationKind": "query",
    "text": "query TransactionScriptsSection_TransactionQuery(\n  $tickerSymbol: TickerSymbol!\n  $transactionId: String!\n) {\n  viewer {\n    coinbaseAnalytics {\n      transactionByHashAndSymbol(hash: $transactionId, tickerSymbol: $tickerSymbol) {\n        scripts {\n          inputScripts {\n            content\n          }\n          outputScripts {\n            content\n          }\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1ab78cd40620ad60f63a68358d1fdb09";

export default node;
