import React from 'react';

type ObjectId = {
  $oid: string;
};
interface IPageDataContext {
  userConfigs: {
    crawler_external_url: string;
    group: {
      global_metadata: boolean;
      id: string;
    };
    id: string;
    license: {
      has_metadata: boolean;
      id: string;
      new_homepage_and_search: boolean;
      new_ui_navigation: boolean;
      type: string;
      report: boolean;
    };
    name: string;
    permissions: {
      admin: boolean;
      osint: boolean;
      tags: boolean;
    };
    policies: {
      api_key_index: boolean;
      experimental_force_clustering: number;
      group_index: boolean;
      license_index: boolean;
      sidekiq_index: boolean;
      user_index: boolean;
      geo_tracking_index: boolean;
    };
    running_jobs: number;
    show_search: boolean;
  };
  graphs: [
    {
      name: string;
      id: string;
    }
  ];
  cases: {
    _id: ObjectId;
    t: string; // case name
    user_ids: [ObjectId];
    ch: string; // case chain
  };
}

export const PageDataContext = React.createContext<null | IPageDataContext>(
  null
);
