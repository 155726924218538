/**
 * @generated SignedSource<<8d90d192165d7b7706746bc1bb7204d6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BlockIndexPageQuery_BlocksQuery$variables = {
  tickerSymbol: any;
  after: string;
};
export type BlockIndexPageQuery_BlocksQueryVariables = BlockIndexPageQuery_BlocksQuery$variables;
export type BlockIndexPageQuery_BlocksQuery$data = {
  readonly viewer: {
    readonly coinbaseAnalytics: {
      readonly blocksBySymbol: {
        readonly edges: ReadonlyArray<{
          readonly cursor: string;
          readonly node: {
            readonly id: string;
            readonly height: number;
            readonly hash: string;
            readonly minedBy: string;
            readonly time: any;
            readonly numTx: number;
            readonly size: string;
          } | null;
        }>;
        readonly pageInfo: {
          readonly endCursor: string;
          readonly hasNextPage: boolean;
        };
      } | null;
    };
  };
};
export type BlockIndexPageQuery_BlocksQueryResponse = BlockIndexPageQuery_BlocksQuery$data;
export type BlockIndexPageQuery_BlocksQuery = {
  variables: BlockIndexPageQuery_BlocksQueryVariables;
  response: BlockIndexPageQuery_BlocksQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tickerSymbol"
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Viewer",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CoinbaseAnalytics",
        "kind": "LinkedField",
        "name": "coinbaseAnalytics",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "after",
                "variableName": "after"
              },
              {
                "kind": "Variable",
                "name": "tickerSymbol",
                "variableName": "tickerSymbol"
              }
            ],
            "concreteType": "CoinbaseAnalyticsBlockConnection",
            "kind": "LinkedField",
            "name": "blocksBySymbol",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CoinbaseAnalyticsBlockEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CoinbaseAnalyticsBlock",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "height",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hash",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "minedBy",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "time",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "numTx",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "size",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BlockIndexPageQuery_BlocksQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "BlockIndexPageQuery_BlocksQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "ee172c14555042e366022e658d2039b5",
    "id": null,
    "metadata": {},
    "name": "BlockIndexPageQuery_BlocksQuery",
    "operationKind": "query",
    "text": "query BlockIndexPageQuery_BlocksQuery(\n  $tickerSymbol: TickerSymbol!\n  $after: String!\n) {\n  viewer {\n    coinbaseAnalytics {\n      blocksBySymbol(tickerSymbol: $tickerSymbol, after: $after) {\n        edges {\n          cursor\n          node {\n            id\n            height\n            hash\n            minedBy\n            time\n            numTx\n            size\n          }\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3be5bf59409ac53d689522d2f30a6582";

export default node;
