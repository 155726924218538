import React, { useContext } from 'react';
import { VStack } from '@cbhq/cds-web/layout';

import PortalList, { List, listItemType } from './PortalList';
import { PageDataContext } from '../../util/PageDataContext';

const settingsList: (
  isAdmin: boolean,
  groupId: string,
  licenseId: string
) => List = (isAdmin, groupId, licenseId) => [
  {
    id: 'id',
    route: `groups/${groupId}`,
    type: listItemType.CELL,
    title: 'My Group',
    iconName: 'followActive',
    detail: 'Manage users in your group',
  },
  {
    id: 'id',
    route: `licenses/${licenseId}`,
    type: listItemType.CELL,
    title: 'Group License',
    iconName: 'identityCard',
    detail: `Review your group's license and request upgrades`,
  },
  {
    id: 'id',
    route: 'api_keys',
    type: listItemType.CELL,
    title: 'API Keys',
    iconName: 'lock',
    detail: 'Manage your API Keys',
  },
  {
    id: 'id',
    type: listItemType.SECTION,
    label: 'Administrative',
  },
  {
    id: 'id',
    route: `users/`,
    type: listItemType.CELL,
    title: 'All Users',
    iconName: 'followInactive',
    detail: 'Manage users for your organization',
    disabled: !isAdmin,
  },
  {
    id: 'id',
    route: `groups/`,
    type: listItemType.CELL,
    title: 'All Groups',
    iconName: 'followActive',
    detail: 'Manage groups in your organization',
    disabled: !isAdmin,
  },
  {
    id: 'id',
    route: `licenses/`,
    type: listItemType.CELL,
    title: 'All Licenses',
    iconName: 'identityCard',
    detail: `Manage licenses for your organization`,
    disabled: !isAdmin,
  },
];

const Settings = () => {
  const { userConfigs } = useContext(PageDataContext);

  return (
    <VStack gap={3} spacingTop={3} spacingHorizontal={0}>
      <PortalList
        list={settingsList(
          userConfigs.permissions.admin,
          userConfigs.group.id,
          userConfigs.license.id
        )}
      />
    </VStack>
  );
};

export default Settings;
