import React from 'react';
import { ChainLink } from './ChainLink';
import FormatTime from '../util/TimeFormatter';
import { graphql } from '@cbhq/data-layer';
import { useFragment } from 'react-relay';
import { TransactionTabRow_TransactionDetailsFragment$key } from './__generated__/TransactionTabRow_TransactionDetailsFragment.graphql';
import { Tooltip } from '@cbhq/cds-web/overlays';
import { Icon } from '@cbhq/cds-web/icons';
import { HStack, Spacer } from '@cbhq/cds-web/layout';
import { Link } from '@cbhq/cds-web/typography';

const transactionFragment = graphql`
  fragment TransactionTabRow_TransactionFragment on CoinbaseAnalyticsBlockTransaction {
    hash
    time
    # blockTime needed
    direction
    ...TransactionTabRow_TransactionDetailsFragment
  }
`;

const transactionDetailsFragment = graphql`
  fragment TransactionTabRow_TransactionDetailsFragment on CoinbaseAnalyticsBlockTransaction {
    hash
    inAmount {
      value
      currency
    }
    inAmountUsd
    outAmount {
      value
      currency
    }
    outAmountUsd
    feeAmount {
      value
      currency
    }
    feeAmountUsd
    mixed
    operations {
      index
      type
      inputs {
        address
        name
        amount {
          value
          currency
        }
        amountUsd
        prevTxHash
        nextTxHash
        prevOutputIndex
        nextOutputIndex
        generated
        osint
        valid
        changeAddress
      }
      outputs {
        address
        name
        amount {
          value
          currency
        }
        amountUsd
        prevTxHash
        nextTxHash
        prevOutputIndex
        nextOutputIndex
        generated
        osint
        valid
        changeAddress
      }
    }
    totalInputs
    totalOutputs
  }
`;

// Transaction information rendered as a row in a block summary page's "transactions" tab.
const TransactionTabRow: React.FC<{
  transaction;
  showUsd: boolean;
  showName: boolean;
  useFragment: boolean;
}> = (props) => {
  // TODO(jaleel): Merge BlockTransaction with AnalyticsTransaction.
  const transaction = props.useFragment
    ? useFragment(transactionFragment, props.transaction)
    : props.transaction;

  const directionIcon = (direction) => {
    if (direction == 'Send') return 'upArrow';
    if (direction == 'Receive') return 'downArrow';
    else return 'arrowsHorizontal';
  };

  return (
    <td colSpan={2}>
      <table
        className="table table-striped"
        style={{ background: 'transparent' }}
      >
        <tbody>
          <tr>
            <td colSpan={5}>
              {/* TODO Brett: add blockTime here and use as the second transaction.time */}
              <HStack alignItems="center" gap={1}>
                {transaction.direction && (
                  <HStack alignItems="center" offsetStart={1} spacingEnd={1}>
                    <Icon
                      name={directionIcon(transaction.direction)}
                      size="xs"
                      color="foreground"
                      spacingEnd={0.5}
                    />
                    <b>{transaction.direction}</b>
                  </HStack>
                )}
                <b> {FormatTime(transaction.time)} </b>
                {FormatTime(transaction.time)}
                <Spacer />
                <ChainLink to={`/transactions/${transaction.hash}`}>
                  {transaction.hash}
                </ChainLink>
                <Tooltip content="Add to graph" placement="right">
                  <Link
                    data-element-ids={transaction.hash}
                    onPress={(e) =>
                      window.xflow.addToGraphModal(e.currentTarget)
                    }
                  >
                    <Icon name="defi" size="xs" dangerouslySetColor="#3379b7" />
                  </Link>
                </Tooltip>
              </HStack>
              <br />
            </td>
          </tr>
          <TransactionDetails
            key={transaction.hash}
            transaction={transaction}
            ioLimit={15}
            showUsd={props.showUsd}
            showName={props.showName}
            useFragment={props.useFragment}
          />
        </tbody>
      </table>
    </td>
  );
};

export const TransactionDetails: React.FC<{
  transaction: any;
  ioLimit?: number;
  showName: boolean;
  showUsd: boolean;
  useFragment: boolean;
}> = (props) => {
  const transaction = props.useFragment
    ? useFragment<TransactionTabRow_TransactionDetailsFragment$key>(
        transactionDetailsFragment,
        props.transaction
      )
    : props.transaction;
  let flattenedTransactionInputs = [];
  let flattenedTransactionOutputs = [];
  transaction.operations.forEach((operation) => {
    if (operation?.inputs !== null) {
      flattenedTransactionInputs = flattenedTransactionInputs.concat(
        operation.inputs
      );
    }
    if (operation?.outputs !== null) {
      flattenedTransactionOutputs = flattenedTransactionOutputs.concat(
        operation.outputs
      );
    }
  });
  const slicedInputs = flattenedTransactionInputs.slice(0, props.ioLimit);
  const slicedOutputs = flattenedTransactionOutputs.slice(0, props.ioLimit);
  const inAmount = getAmountString(
    props.showUsd,
    () => transaction.inAmountUsd,
    () => transaction.inAmount
  );
  const feeAmount = getAmountString(
    props.showUsd,
    () => transaction.feeAmountUsd,
    () => transaction.feeAmount
  );
  const outAmount = getAmountString(
    props.showUsd,
    () => transaction.outAmountUsd,
    () => transaction.outAmount
  );

  const generated = flattenedTransactionInputs.reduce(function (
    previousVal,
    currentVal,
    currentIndex,
    arr
  ) {
    return previousVal || arr[currentIndex].generated;
  },
  false);

  return (
    <React.Fragment>
      <tr className={transaction.mixed ? 'danger' : ''}>
        <td width="5%" />
        <td colSpan={3} className="text-center">
          {!generated && inAmount && (
            <span className="pull-left">
              <span className="label label-danger">In: {inAmount}</span>
            </span>
          )}
          {!generated && feeAmount && (
            <span className="label label-warning">Fee: {feeAmount}</span>
          )}
          {outAmount && (
            <span className="pull-right">
              <span className="label label-success">Out: {outAmount}</span>
            </span>
          )}
        </td>
        <td width="6%" />
      </tr>

      <tr className={transaction.mixed ? 'danger' : ''}>
        <td width="5%" className="small">
          {!generated &&
            slicedInputs.map((inputPart) => {
              return (
                <div key={inputPart.prevTxHash}>
                  <ChainLink to={`/transactions/${inputPart.prevTxHash}`}>
                    prev
                  </ChainLink>
                </div>
              );
            })}
        </td>

        <td width="43%" className="text-right monospace small">
          {generated && (
            <span className="label label-primary">
              Coinbase Generated Coins
            </span>
          )}

          {slicedInputs.map((inputPart) => {
            const amount = getAmountString(
              props.showUsd,
              () => inputPart.amountUsd,
              () => inputPart.amount
            );
            return (
              <React.Fragment key={inputPart.address}>
                {amount && (
                  <span className="pull-left">
                    <span>{amount}</span>
                  </span>
                )}
                {inputPart.valid && (
                  <ChainLink to={`/addresses/${inputPart.address}`}>
                    {props.showName && inputPart.name
                      ? inputPart.name
                      : inputPart.address}
                  </ChainLink>
                )}
                {!inputPart.generated && !inputPart.valid && <InvalidAddress />}
                <br />
              </React.Fragment>
            );
          })}
          {transaction.totalInputs > props.ioLimit && (
            <ChainLink to={`/transactions/${transaction.hash}`}>
              {moreIoMessage(transaction.totalInputs - props.ioLimit)}
            </ChainLink>
          )}

          {transaction.hasShieldedInput && (
            <span className="label label-primary">Shielded Input</span>
          )}
        </td>
        <td width="35px" style={{ padding: '10px', textAlign: 'center' }}>
          <i className="glyphicon glyphicon-log-out"></i>
        </td>
        <td className="monospace small">
          {slicedOutputs.map((outputPart) => {
            const amount = getAmountString(
              props.showUsd,
              () => outputPart.amountUsd,
              () => outputPart.amount
            );
            return (
              <React.Fragment key={outputPart.address}>
                {outputPart.valid && (
                  <ChainLink to={`/addresses/${outputPart.address}`}>
                    {props.showName && outputPart.name
                      ? outputPart.name
                      : outputPart.address}
                  </ChainLink>
                )}

                {outputPart.changeAddress && <ChangeAddressIcon />}

                {outputPart.osint && <OsintIcon />}

                {!outputPart.valid && <InvalidAddress />}
                {amount && (
                  <span className="pull-right">
                    <span>{amount}</span>
                  </span>
                )}
                <br />
              </React.Fragment>
            );
          })}

          {transaction.totalOutputs > props.ioLimit && (
            <ChainLink to={`/transactions/${transaction.hash}`}>
              {moreIoMessage(transaction.totalOutputs - props.ioLimit)}
            </ChainLink>
          )}

          {transaction.hasShieldedOutput && (
            <span className="label label-primary">Shielded Output</span>
          )}
        </td>

        <td width="7%" className="small text-right">
          {slicedOutputs.map((outputPart) => {
            return (
              <div key={outputPart.address}>
                {outputPart.nextTxHash && (
                  <ChainLink to={`/transactions/${outputPart.nextTxHash}`}>
                    next
                  </ChainLink>
                )}
                {!outputPart.nextTxHash && (
                  <div style={{ color: '#cccccc' }}>unspent</div>
                )}
              </div>
            );
          })}
        </td>
      </tr>
    </React.Fragment>
  );
};

const InvalidAddress = (props) => {
  return <span className="label label-default">Invalid Address</span>;
};

const ChangeAddressIcon = (props) => {
  return (
    <span
      className="fa fa-reply text-muted"
      style={{ transform: 'scaleY(-1)' }}
      aria-hidden="true"
      data-toggle="tooltip"
      title="Change address"
      data-original-title="Change address"
    ></span>
  );
};

const OsintIcon = () => {
  return (
    <span
      className="fa fa-info-circle text-muted"
      aria-hidden="true"
      data-toggle="tooltip"
      title=""
      data-original-title="This address has some OSINT items"
    ></span>
  );
};

const getAmountString = (
  useUsd: boolean,
  getUsdAmount: () => string,
  getAmount: () => { value: string; currency: string }
): string => {
  if (useUsd) {
    const usdAmount = getUsdAmount();
    if (!usdAmount) {
      return null;
    }
    return `${usdAmount} USD`;
  }
  const amount = getAmount();
  if (!amount) {
    return null;
  }
  return `${amount.value} ${amount.currency}`;
};

const moreIoMessage = (count: number) => {
  return `[show ${count} more ${count != 1 ? 'addresses' : 'address'}]`;
};

export default TransactionTabRow;
