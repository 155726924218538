/**
 * @generated SignedSource<<0ad462d2051b5bc38204cad8b7daf9e1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type NewTopNavbar_AutocompleteSearchQuery$variables = {
  query: string;
  tickerSymbol: any;
};
export type NewTopNavbar_AutocompleteSearchQueryVariables = NewTopNavbar_AutocompleteSearchQuery$variables;
export type NewTopNavbar_AutocompleteSearchQuery$data = {
  readonly viewer: {
    readonly coinbaseAnalytics: {
      readonly autocompleteSearch: {
        readonly names: ReadonlyArray<string | null> | null;
        readonly addresses: {
          readonly acceptable: boolean | null;
          readonly minLength: number | null;
          readonly triggered: boolean | null;
          readonly matches: ReadonlyArray<string | null> | null;
        } | null;
      } | null;
    };
  };
};
export type NewTopNavbar_AutocompleteSearchQueryResponse = NewTopNavbar_AutocompleteSearchQuery$data;
export type NewTopNavbar_AutocompleteSearchQuery = {
  variables: NewTopNavbar_AutocompleteSearchQueryVariables;
  response: NewTopNavbar_AutocompleteSearchQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "query"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tickerSymbol"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Viewer",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CoinbaseAnalytics",
        "kind": "LinkedField",
        "name": "coinbaseAnalytics",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "query",
                "variableName": "query"
              },
              {
                "kind": "Variable",
                "name": "tickerSymbol",
                "variableName": "tickerSymbol"
              }
            ],
            "concreteType": "CoinbaseAnalyticsAutocompleteSearch",
            "kind": "LinkedField",
            "name": "autocompleteSearch",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "names",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CoinbaseAnalyticsAutocompleteSearchAddress",
                "kind": "LinkedField",
                "name": "addresses",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "acceptable",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "minLength",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "triggered",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "matches",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NewTopNavbar_AutocompleteSearchQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NewTopNavbar_AutocompleteSearchQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6de552757a595d5a06e2d701ff724578",
    "id": null,
    "metadata": {},
    "name": "NewTopNavbar_AutocompleteSearchQuery",
    "operationKind": "query",
    "text": "query NewTopNavbar_AutocompleteSearchQuery(\n  $query: String!\n  $tickerSymbol: TickerSymbol!\n) {\n  viewer {\n    coinbaseAnalytics {\n      autocompleteSearch(query: $query, tickerSymbol: $tickerSymbol) {\n        names\n        addresses {\n          acceptable\n          minLength\n          triggered\n          matches\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6822aca3fd41b76d56e9eea7ebf59fca";

export default node;
