/**
 * @generated SignedSource<<db3e59b5b48fd8a51383297cce7a3169>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SearchResultsDataWrapper_SearchResultsPageQuery$variables = {
  query: string;
  tickerSymbol: any;
  walletsAfter: string;
  addressesAfter: string;
};
export type SearchResultsDataWrapper_SearchResultsPageQueryVariables = SearchResultsDataWrapper_SearchResultsPageQuery$variables;
export type SearchResultsDataWrapper_SearchResultsPageQuery$data = {
  readonly viewer: {
    readonly coinbaseAnalytics: {
      readonly wallets: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly uuid: any;
            readonly displayHash: string | null;
            readonly chain: string | null;
            readonly name: string | null;
            readonly labels: ReadonlyArray<string | null> | null;
            readonly addresses: number | null;
            readonly balanceAmountUsd: any | null;
            readonly sentAmountUsd: any | null;
            readonly receivedAmountUsd: any | null;
            readonly lastSeen: any | null;
            readonly geo: string | null;
            readonly type: string | null;
          };
        }>;
        readonly pageInfo: {
          readonly hasNextPage: boolean;
          readonly endCursor: string;
        };
      } | null;
      readonly addresses: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly uuid: any;
            readonly displayHash: string | null;
            readonly chain: string | null;
            readonly name: string | null;
            readonly labels: ReadonlyArray<string | null> | null;
            readonly addresses: number | null;
            readonly balanceAmountUsd: any | null;
            readonly sentAmountUsd: any | null;
            readonly receivedAmountUsd: any | null;
            readonly lastSeen: any | null;
            readonly geo: string | null;
            readonly type: string | null;
          };
        }>;
        readonly pageInfo: {
          readonly hasNextPage: boolean;
          readonly endCursor: string;
        };
      } | null;
    };
  };
};
export type SearchResultsDataWrapper_SearchResultsPageQueryResponse = SearchResultsDataWrapper_SearchResultsPageQuery$data;
export type SearchResultsDataWrapper_SearchResultsPageQuery = {
  variables: SearchResultsDataWrapper_SearchResultsPageQueryVariables;
  response: SearchResultsDataWrapper_SearchResultsPageQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "addressesAfter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "query"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tickerSymbol"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "walletsAfter"
},
v4 = {
  "kind": "Variable",
  "name": "query",
  "variableName": "query"
},
v5 = {
  "kind": "Variable",
  "name": "tickerSymbol",
  "variableName": "tickerSymbol"
},
v6 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "walletsAfter"
  },
  {
    "kind": "Literal",
    "name": "entity",
    "value": "WALLETS"
  },
  (v4/*: any*/),
  (v5/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayHash",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "chain",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "labels",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "addresses",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "balanceAmountUsd",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sentAmountUsd",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "receivedAmountUsd",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastSeen",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "geo",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v20 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CoinbaseAnalyticsSearchResultEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CoinbaseAnalyticsSearchResult",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  (v19/*: any*/)
],
v21 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "addressesAfter"
  },
  {
    "kind": "Literal",
    "name": "entity",
    "value": "ADDRESSES"
  },
  (v4/*: any*/),
  (v5/*: any*/)
],
v22 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CoinbaseAnalyticsSearchResultEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CoinbaseAnalyticsSearchResult",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  (v19/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SearchResultsDataWrapper_SearchResultsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CoinbaseAnalytics",
            "kind": "LinkedField",
            "name": "coinbaseAnalytics",
            "plural": false,
            "selections": [
              {
                "alias": "wallets",
                "args": (v6/*: any*/),
                "concreteType": "CoinbaseAnalyticsSearchResultsConnection",
                "kind": "LinkedField",
                "name": "searchResults",
                "plural": false,
                "selections": (v20/*: any*/),
                "storageKey": null
              },
              {
                "alias": "addresses",
                "args": (v21/*: any*/),
                "concreteType": "CoinbaseAnalyticsSearchResultsConnection",
                "kind": "LinkedField",
                "name": "searchResults",
                "plural": false,
                "selections": (v20/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SearchResultsDataWrapper_SearchResultsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CoinbaseAnalytics",
            "kind": "LinkedField",
            "name": "coinbaseAnalytics",
            "plural": false,
            "selections": [
              {
                "alias": "wallets",
                "args": (v6/*: any*/),
                "concreteType": "CoinbaseAnalyticsSearchResultsConnection",
                "kind": "LinkedField",
                "name": "searchResults",
                "plural": false,
                "selections": (v22/*: any*/),
                "storageKey": null
              },
              {
                "alias": "addresses",
                "args": (v21/*: any*/),
                "concreteType": "CoinbaseAnalyticsSearchResultsConnection",
                "kind": "LinkedField",
                "name": "searchResults",
                "plural": false,
                "selections": (v22/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "33cb69641417a77bbc11d215db9e4d28",
    "id": null,
    "metadata": {},
    "name": "SearchResultsDataWrapper_SearchResultsPageQuery",
    "operationKind": "query",
    "text": "query SearchResultsDataWrapper_SearchResultsPageQuery(\n  $query: String!\n  $tickerSymbol: TickerSymbol!\n  $walletsAfter: String!\n  $addressesAfter: String!\n) {\n  viewer {\n    coinbaseAnalytics {\n      wallets: searchResults(query: $query, entity: WALLETS, tickerSymbol: $tickerSymbol, after: $walletsAfter) {\n        edges {\n          node {\n            uuid\n            displayHash\n            chain\n            name\n            labels\n            addresses\n            balanceAmountUsd\n            sentAmountUsd\n            receivedAmountUsd\n            lastSeen\n            geo\n            type\n            id\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n      }\n      addresses: searchResults(query: $query, entity: ADDRESSES, tickerSymbol: $tickerSymbol, after: $addressesAfter) {\n        edges {\n          node {\n            uuid\n            displayHash\n            chain\n            name\n            labels\n            addresses\n            balanceAmountUsd\n            sentAmountUsd\n            receivedAmountUsd\n            lastSeen\n            geo\n            type\n            id\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2c18e7118d803e6cd2a5c2a1d8aef136";

export default node;
