import { useToggler } from '@cbhq/cds-web';
import { IconButton } from '@cbhq/cds-web/buttons';
import { SelectOption } from '@cbhq/cds-web/controls';
import { Icon } from '@cbhq/cds-web/icons';
import { Fallback } from '@cbhq/cds-web/layout';
import { HStack } from '@cbhq/cds-web/alpha/HStack';
import { PopoverMenu, PopoverTrigger } from '@cbhq/cds-web/overlays';
import { TableRow, TableCell } from '@cbhq/cds-web/tables';
import { TextBody } from '@cbhq/cds-web/typography';
import React, { Suspense, useCallback } from 'react';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import RiskScoreLabelWrapper from '../../components/RiskScoreLabelWrapper';
import { abbreviateHash } from '../../util/StringUtils';
import { ISearchResultTypes } from './SearchResultsWrapper';
import { useToast } from '@cbhq/cds-web/overlays/useToast';
import KYCWalletCategory from '../../components/KYCWalletCategory';
import { SearchResultsDataWrapper_SearchResultsPageQueryResponse } from './__generated__/SearchResultsDataWrapper_SearchResultsPageQuery.graphql';

const options = [
  { title: 'Add to graph', value: 'add_to_graph' },
  { title: 'Add to case', value: 'add_to_case' },
  { title: 'Bookmark', value: 'bookmark' },
];

const formatDate = (date: string) => {
  if (!date) {
    return '';
  }
  const dateAndTime = date.split('T');
  const yearMonthDay = dateAndTime[0].split('-');
  return `${yearMonthDay[1]}-${yearMonthDay[2]}-${yearMonthDay[0]}`;
};
type RowType =
  SearchResultsDataWrapper_SearchResultsPageQueryResponse['viewer']['coinbaseAnalytics']['addresses']['edges'][0];
interface ISearchResultRowProps {
  index: number;
  edge: RowType;
  type: ISearchResultTypes;
  toggleGraphsModalOn: () => void;
  toggleCasesModalOn: () => void;
  onRowPress: (hash: string, chain: string, type: ISearchResultTypes) => void;
  setSelectedRowParams: React.Dispatch<
    React.SetStateAction<{
      chain: string;
      name: string;
      itemId: string;
    }>
  >;
  bookmark: (chain: string, ref: string, name?: string) => Promise<void>;
  key: string;
}

export const SearchResultsRow = ({
  index,
  edge,
  type,
  toggleGraphsModalOn,
  toggleCasesModalOn,
  onRowPress,
  setSelectedRowParams,
  bookmark,
  key,
}: ISearchResultRowProps) => {
  const toast = useToast();
  const [visible, togglePopoverMenuVisibility] = useToggler(false);

  const handleMenuValueChange = useCallback((newValue, chain, name, hash) => {
    setSelectedRowParams({ chain, name, itemId: hash });
    switch (newValue) {
      case 'add_to_graph':
        return toggleGraphsModalOn();
      case 'add_to_case':
        return toggleCasesModalOn();
      case 'bookmark':
        return bookmark(chain, hash);
    }
  }, []);
  const row = edge.node;
  const lowerCaseChain = row.chain.toLowerCase();
  return (
    <TableRow
      key={key}
      onPress={() => onRowPress(row.uuid, lowerCaseChain, type)}
    >
      <TableCell>
        <ErrorBoundary errorChild={<span />}>
          <Suspense
            fallback={<Fallback width={25} height={25} shape="squircle" />}
          >
            <RiskScoreLabelWrapper
              chain={lowerCaseChain}
              hash={row.uuid}
              customKey={`${index}-${row.type}-${row.uuid}-${row.name}`}
              type={row.type as 'address' | 'wallet'}
              useCDScolors
            />
          </Suspense>
        </ErrorBoundary>
      </TableCell>
      <TableCell title={row.chain} color="foregroundMuted" />
      <TableCell
        title={row.name.replace(/(.{20})..+/, '$1…')}
        color="foregroundMuted"
      />
      <TableCell>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{ marginRight: 12 }}
            onClick={(e) => {
              e.stopPropagation();
              navigator.clipboard.writeText(row.uuid);
              toast.show(
                `${
                  type === 'wallets' ? 'Wallet' : 'Address'
                } ID copied to clipboard`
              );
            }}
          >
            <Icon name="copy" color="foregroundMuted" size="s" />
          </div>
          <TextBody color="foregroundMuted" as="p">
            {abbreviateHash(row.uuid)}
          </TextBody>
        </div>
      </TableCell>
      <TableCell>
        <HStack spacing={0.5} flexWrap="wrap">
          {row.labels.map((label, index) => {
            return (
              <KYCWalletCategory
                category={label}
                key={index}
                spacingBottom="10px"
              />
            );
          })}
        </HStack>
      </TableCell>
      {type === 'wallets' && (
        <TableCell>
          <TextBody as="p" mono>
            {row.addresses}
          </TextBody>
        </TableCell>
      )}
      <TableCell alignItems="flex-end">
        <TextBody as="p" mono>
          ${' '}
          {Math.round(
            parseFloat(row.receivedAmountUsd.replaceAll(',', ''))
          ).toLocaleString('en-US')}
        </TextBody>
      </TableCell>
      <TableCell alignItems="flex-end">
        <TextBody as="p" mono>
          ${' '}
          {Math.round(
            parseFloat(row.sentAmountUsd.replaceAll(',', ''))
          ).toLocaleString('en-US')}
        </TextBody>
      </TableCell>
      <TableCell alignItems="flex-end">
        <TextBody as="p" mono>
          ${' '}
          {Math.round(
            parseFloat(row.receivedAmountUsd.replaceAll(',', ''))
          ).toLocaleString('en-US')}
        </TextBody>
      </TableCell>
      <TableCell alignItems="center" title={formatDate(row.lastSeen)} />
      <TableCell>
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <PopoverMenu
            width={140}
            onChange={(value: string) =>
              handleMenuValueChange(
                value,
                lowerCaseChain,
                row.name,
                row.displayHash
              )
            }
            openMenu={togglePopoverMenuVisibility.toggleOn}
            closeMenu={togglePopoverMenuVisibility.toggleOff}
            visible={visible}
          >
            <PopoverTrigger>
              <IconButton transparent name="more" variant="secondary" />
            </PopoverTrigger>
            {options.map(({ title, value }) => (
              <SelectOption value={value} key={value} title={title} compact />
            ))}
          </PopoverMenu>
        </div>
      </TableCell>
    </TableRow>
  );
};
