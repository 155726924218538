import React from 'react';
import TransactionAdditionalInfo from '../../pages/transactions/TransactionAdditionalInfo';
import TransactionDetailsTable from '../../pages/transactions/TransactionDetailsTable';
import TransactionFlowTable from '../../pages/transactions/TransactionFlowTable';

export const TransactionSummarySection: React.FC<{
  chain: string;
  transactionFragmentReference;
}> = (props) => {
  return (
    <React.Fragment>
      <TransactionFlowTable
        chain={props.chain}
        transactionRef={props.transactionFragmentReference}
      />
      <TransactionDetailsTable
        chain={props.chain}
        transactionRef={props.transactionFragmentReference}
      />
      <TransactionAdditionalInfo
        transactionRef={props.transactionFragmentReference}
      />
    </React.Fragment>
  );
};
