import { IResponse } from '.';

interface IBookmark {
  id: string;
  createdAt: string;
  name: string;
  ref: string;
  type: 'address' | 'wallet' | 'transaction';
}

export const bookmarkItem = async (
  chain: string,
  ref: string,
  name?: string
): Promise<IResponse<IBookmark>> => {
  const response = await fetch(
    `/api/internal/watchlists?asset=${chain}&x_flow_watchlist[name]=${
      name ?? ref
    }&x_flow_watchlist[ref]=${ref}}`,
    {
      method: 'POST',
    }
  );
  return response.json();
};
