import { useToggler, IconName } from '@cbhq/cds-web';
import { CellMedia } from '@cbhq/cds-web/cells';
import { SelectOption } from '@cbhq/cds-web/controls';
import { Dropdown } from '@cbhq/cds-web/dropdown';
import { VStack } from '@cbhq/cds-web/layout';
import { TextLabel2, TextTitle3 } from '@cbhq/cds-web/typography';
import React, { useState, useCallback, useMemo } from 'react';
import { ICONS } from '../../util/Icons';
import DeleteGraphAlert from './DeleteGraphAlert';
import DuplicateGraphAlert from './DuplicateGraphAlert';
import EditModal from './EditModal';
import MoveToModal from './MoveToModal';
import SendGraphModal from './SendGraphModal';
import WalletLabels from './WalletLabels';
import Moment from 'moment';
import { IconButton } from '@cbhq/cds-web/buttons';
import KYCWalletCategory from '../KYCWalletCategory';

export interface OverlayProps {
  visible: boolean;
  onRequestClose: () => void;
  chain: string;
  name: string;
  id: string;
  description: string;
}

interface GraphCardProps {
  id: string;
  chain: string;
  lastUpdated: string;
  name: string;
  snapshotUrl?: string;
  wallets: readonly string[];
  categories: readonly string[];
  shared: boolean;
  description: string;
  chainConfigs: any;
}

const GraphCard = (props: GraphCardProps) => {
  const [_, setValue] = useState('');
  const [hasSnapshot, setHasSnapshot] = useState(!!props.snapshotUrl);

  const [
    editModalVisible,
    { toggleOn: toggleEditModalOn, toggleOff: toggleEditModalOff },
  ] = useToggler();

  const [
    moveToModalVisible,
    { toggleOn: toggleMoveToModalOn, toggleOff: toggleMoveToModalOff },
  ] = useToggler();

  const [
    sendToModalVisible,
    { toggleOn: toggleSendToModalOn, toggleOff: toggleSendToModalOff },
  ] = useToggler();

  const [
    duplicateAlertVisible,
    { toggleOn: toggleDuplicateAlertOn, toggleOff: toggleDuplicateAlertOff },
  ] = useToggler();

  const [
    deleteAlertVisible,
    { toggleOn: toggleDeleteAlertOn, toggleOff: toggleDeleteAlertOff },
  ] = useToggler();

  const options = [
    { title: 'Edit', value: 'edit', icon: 'pencil' },
    { title: 'Duplicate', value: 'duplicate', icon: 'copy' },
    { title: 'Move to case', value: 'moveTo', icon: 'arrowsVertical' },
    { title: 'Send a copy', value: 'sendTo', icon: 'email' },
    { title: 'Share', value: 'sharingSettings', icon: 'profile' },
    { title: 'Delete', value: 'delete', icon: 'trashCan' },
  ];

  const handleEventBubbling = useCallback((event) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  }, []);

  const handleMenuValueChange = useCallback(
    (newValue) => {
      if (newValue === 'edit') {
        toggleEditModalOn();
      }

      if (newValue === 'duplicate') {
        toggleDuplicateAlertOn();
      }

      if (newValue === 'moveTo') {
        toggleMoveToModalOn();
      }

      if (newValue === 'sendTo') {
        toggleSendToModalOn();
      }

      if (newValue === 'delete') {
        toggleDeleteAlertOn();
      }

      if (newValue === 'sharingSettings') {
        window.location.href = `/${props.chain}/graph/${props.id}/share`;
      }

      setValue(newValue);
    },
    [setValue]
  );

  const content = useMemo(
    () => (
      <>
        {options.map((option) => (
          <SelectOption
            value={option.value}
            key={option.title}
            title={option.title}
            media={<CellMedia type="icon" name={option.icon as IconName} />}
            compact
          />
        ))}
      </>
    ),
    []
  );

  return (
    <>
      <div
        className="new-ui-hovered"
        onClick={() => {
          window.location.href = `/${props.chain}/graph/${props.id}`;
        }}
        style={{
          cursor: 'pointer',
          display: 'grid',
          backgroundColor: 'var(--background)',
          gridTemplateRows: 'auto 1fr 1fr 1fr',
          gap: 'var(--spacing-0.5)',
          padding: 'var(--spacing-1)',
          borderRadius: '1rem',
          transition: 'all 0.1s ease',
        }}
      >
        <div
          style={{
            outline: '1px solid rgba(91, 97, 110, 0.2)',
            borderRadius: 8,
            position: 'relative',
            gridRow: 1,
            gridColumn: '1 / -1',
            overflowX: 'hidden',
            marginBottom: 8,
            backgroundColor: props.snapshotUrl
              ? 'rgba(245,248,255,0.25)'
              : 'inherit',
          }}
        >
          <div style={{ position: 'absolute', top: 12, left: 10 }}>
            <img className="chainlogo-md" src={ICONS[props.chain]} />
          </div>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {hasSnapshot ? (
              <img
                src={props.snapshotUrl.replace('localstack', 'localhost')} // Necessary for local development
                style={{
                  width: 'auto',
                  height: 222,
                  objectFit: 'contain',
                }}
                onError={() => setHasSnapshot(false)}
              />
            ) : (
              <div
                style={{
                  width: 'auto',
                  height: 222,
                  backgroundColor: '#EEF0F3',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <TextLabel2 as="p" color="foregroundMuted" spacingBottom={1}>
                  Open and save this graph to create a thumbnail
                </TextLabel2>
              </div>
            )}
          </div>
          <div
            onClick={handleEventBubbling}
            style={{ position: 'absolute', top: 3, right: 5 }}
          >
            <Dropdown
              onChange={handleMenuValueChange}
              content={content}
              width={250}
            >
              <IconButton name="more" color="foreground" transparent />
            </Dropdown>
          </div>
        </div>
        <div
          style={{
            gridRow: 2,
            gridColumn: '1 / -1',
          }}
        >
          <TextTitle3 as="h3" overflow="truncate">
            {props.name}
          </TextTitle3>
        </div>
        <div
          style={{
            gridRow: 3,
            gridColumn: '1 / -1',
            marginTop: 2,
          }}
        >
          <TextLabel2 as="span" color="foregroundMuted">
            {props.lastUpdated &&
              Moment(props.lastUpdated).format('MMM DD, YYYY')}
          </TextLabel2>
        </div>
        <div
          style={{
            gridRow: 4,
            gridColumn: '1 / -1',
          }}
        >
          <WalletLabels wallets={props.wallets} />
        </div>

        <div
          style={{
            gridRow: 5,
            gridColumn: '1 / -1',
          }}
        >
          <VStack>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '0.2em',
              }}
            >
              {props.categories.map((category) => (
                <KYCWalletCategory category={category} key={category} />
              ))}
            </div>
          </VStack>
        </div>
      </div>

      <EditModal
        {...props}
        visible={editModalVisible}
        onRequestClose={toggleEditModalOff}
      />

      <DuplicateGraphAlert
        {...props}
        visible={duplicateAlertVisible}
        onRequestClose={toggleDuplicateAlertOff}
      />

      {moveToModalVisible && (
        <MoveToModal
          {...props}
          visible={moveToModalVisible}
          onRequestClose={toggleMoveToModalOff}
        />
      )}

      {sendToModalVisible && (
        <SendGraphModal
          {...props}
          visible={sendToModalVisible}
          onRequestClose={toggleSendToModalOff}
        />
      )}

      <DeleteGraphAlert
        {...props}
        visible={deleteAlertVisible}
        onRequestClose={toggleDeleteAlertOff}
      />
    </>
  );
};

export default GraphCard;
