/**
 * @generated SignedSource<<0860a02e2011fda933cbc4cddb5fd8c2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AdditionalInfoWallet_metadata$data = {
  readonly metadata: {
    readonly name: string;
    readonly categories: ReadonlyArray<string | null> | null;
    readonly urls: ReadonlyArray<any | null> | null;
    readonly notes: string | null;
    readonly handling: string | null;
  } | null;
  readonly " $fragmentType": "AdditionalInfoWallet_metadata";
};
export type AdditionalInfoWallet_metadata = AdditionalInfoWallet_metadata$data;
export type AdditionalInfoWallet_metadata$key = {
  readonly " $data"?: AdditionalInfoWallet_metadata$data;
  readonly " $fragmentSpreads": FragmentRefs<"AdditionalInfoWallet_metadata">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdditionalInfoWallet_metadata",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CoinbaseAnalyticsWalletMetadata",
      "kind": "LinkedField",
      "name": "metadata",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "categories",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "urls",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "notes",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "handling",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CoinbaseAnalyticsWallet",
  "abstractKey": null
};

(node as any).hash = "bc4f7a47e9496aae1233a6abef9288d3";

export default node;
