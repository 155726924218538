const baseWalletColumns = [
  {
    title: 'Risk',
    key: 'col-risk',
    width: '3%',
    alignment: 'flex-start',
  },
  {
    title: 'Chain',
    key: 'col-chain',
    width: '3%',
    alignment: 'flex-start',
  },
  {
    title: 'Name',
    key: 'col-name',
    width: '12%',
    alignment: 'flex-start',
  },
  {
    title: 'ID',
    key: 'col-id',
    width: '12%',
    alignment: 'flex-start',
  },
  {
    title: 'Labels',
    key: 'col-labels',
    width: '12%',
    alignment: 'flex-start',
  },
  {
    title: 'Addresses',
    key: 'col-addresses',
    width: '4%',
    alignment: 'flex-start',
  },
  {
    title: 'Value',
    key: 'col-value',
    width: '6%',
    alignment: 'flex-end',
  },
  {
    title: 'Sent',
    key: 'col-sent',
    width: '6%',
    alignment: 'flex-end',
  },
  {
    title: 'Received',
    key: 'col-received',
    width: '6%',
    alignment: 'flex-end',
  },
  {
    title: 'Last Active',
    key: 'col-active',
    width: '7%',
    alignment: 'center',
  },
];
export const walletColumns = [
  ...baseWalletColumns,
  {
    title: 'Geo',
    key: 'col-geo',
    width: '4%',
  },
  {
    title: '',
    key: 'col-action',
    width: '3%',
  },
];

export const walletColumnsWithoutGeo = [
  ...baseWalletColumns,
  {
    title: '',
    key: 'col-action',
    width: '3%',
  },
];

const baseAddressColumns = [
  {
    title: 'Risk',
    key: 'col-risk',
    width: '3%',
    alignment: 'flex-start',
  },
  {
    title: 'Chain',
    key: 'col-chain',
    width: '3%',
    alignment: 'flex-start',
  },
  {
    title: 'Name',
    key: 'col-name',
    width: '12%',
    alignment: 'flex-start',
  },
  {
    title: 'Address',
    key: 'col-address',
    width: '8%',
    alignment: 'flex-start',
  },
  {
    title: 'Labels',
    key: 'col-labels',
    width: '10%',
    alignment: 'flex-start',
  },
  {
    title: 'Value',
    key: 'col-value',
    width: '6%',
    alignment: 'flex-end',
  },
  {
    title: 'Sent',
    key: 'col-sent',
    width: '6%',
    alignment: 'flex-end',
  },
  {
    title: 'Received',
    key: 'col-received',
    width: '6%',
    alignment: 'flex-end',
  },
  {
    title: 'Last Active',
    key: 'col-active',
    width: '7%',
    alignment: 'center',
  },
];

export const addressColumns = [
  ...baseAddressColumns,
  {
    title: 'Geo',
    key: 'col-geo',
    width: '4%',
  },
  {
    title: '',
    key: 'col-action',
    width: '3%',
  },
];

export const addressColumnsWithoutGeo = [
  ...baseAddressColumns,
  {
    title: '',
    key: 'col-action',
    width: '3%',
  },
];
