import { PortalProvider } from '@cbhq/cds-web/overlays/PortalProvider';
import { ThemeProvider } from '@cbhq/cds-web/system';
import React, { Suspense } from 'react';
import { ErrorBoundary } from './ErrorBoundary';
import { GraphqlEnvProvider } from './GraphqlEnvProvider';
import { LoadingBubbles } from './LoadingBubbles';
import NewTopNavBar from './NewTopNavbar';

const NewTopNavbarLayer = (props) => {
  return (
    <ThemeProvider>
      <PortalProvider>
        <GraphqlEnvProvider>
          <ErrorBoundary>
            <Suspense fallback={<LoadingBubbles />}>
              <NewTopNavBar {...props} />
            </Suspense>
          </ErrorBoundary>
        </GraphqlEnvProvider>
      </PortalProvider>
    </ThemeProvider>
  );
};

export default NewTopNavbarLayer;
