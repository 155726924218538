import React from 'react';
import { ALL_NETWORKS } from '../components/filters/NetworkFilter';
import { GraphsList } from '../components/GraphsList';
import { useLocalStorage } from 'usehooks-ts';

const HomePage = ({ chainConfigs }: { chainConfigs: any }) => {
  const [selectedChain] = useLocalStorage('currentChain', undefined);

  return (
    <GraphsList
      chainConfigs={chainConfigs}
      chain={selectedChain === ALL_NETWORKS ? undefined : selectedChain}
    />
  );
};

export default HomePage;
