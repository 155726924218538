import { Divider } from '@cbhq/cds-web/layout';
import { graphql, useLazyLoadQuery } from '@cbhq/data-layer';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
  CoinbaseAnalyticsInteractionsEntities,
  Interactions_InteractionsQuery,
  Interactions_InteractionsQueryResponse,
} from './__generated__/Interactions_InteractionsQuery.graphql';

type SentInteractions =
  Interactions_InteractionsQueryResponse['viewer']['coinbaseAnalytics']['interactions']['sent'];

type ReceivedInteractions =
  Interactions_InteractionsQueryResponse['viewer']['coinbaseAnalytics']['interactions']['received'];

const backgroundColorMap = {
  LOW_RISK: '#5cb85c',
  MEDIUM_RISK: '#f0ad4e',
  HIGH_RISK: '#d9534f',
  UNKNOWN: '#777777',
};

const riskIndicatorClass = {
  LOW_RISK: 'success',
  MEDIUM_RISK: 'warning',
  HIGH_RISK: 'danger',
  UNKNOWN: 'default',
};

const graphOptions = {
  plugins: { legend: { display: false }, tooltip: { displayColors: false } },
  circumference: 180,
  rotation: 270,
  responsive: false,
};

const GRAPH_WIDTH = 425;
const GRAPH_HEIGHT = 250;

const formatData = (rawData: SentInteractions | ReceivedInteractions) => {
  const labels = [];
  const data = [];
  const backgroundColor = [];
  rawData.forEach((value) => {
    labels.push(value.name);
    data.push(value.percent);
    backgroundColor.push(backgroundColorMap[value.risk]);
  });
  return {
    labels,
    datasets: [
      {
        data,
        backgroundColor,
      },
    ],
  };
};

const interactionsQuery = graphql`
  query Interactions_InteractionsQuery(
    $entity: CoinbaseAnalyticsInteractionsEntities!
    $uuid: Uuid!
    $tickerSymbol: TickerSymbol!
  ) {
    viewer {
      coinbaseAnalytics {
        interactions(
          entity: $entity
          uuid: $uuid
          tickerSymbol: $tickerSymbol
        ) {
          sent {
            name
            wallet {
              hash
            }
            categories
            risk
            percent
          }
          received {
            name
            wallet {
              hash
            }
            categories
            risk
            percent
          }
        }
      }
    }
  }
`;

interface IInteractionsProps {
  chain: string;
  type: 'wallets' | 'addresses';
  id: string;
}

const Interactions = ({ chain, type, id }: IInteractionsProps) => {
  const interactionsData = useLazyLoadQuery<Interactions_InteractionsQuery>(
    interactionsQuery,
    {
      entity: type.toUpperCase() as CoinbaseAnalyticsInteractionsEntities,
      uuid: id,
      tickerSymbol: chain,
    }
  );
  const { sent, received } =
    interactionsData.viewer.coinbaseAnalytics.interactions;

  const graphSectionClicked = (_, element, type) => {
    const { datasetIndex } = element[0];
    let walletId = null;
    if (type === 'received') {
      walletId = received[datasetIndex].wallet?.hash;
    } else {
      walletId = sent[datasetIndex].wallet?.hash;
    }
    if (walletId != null) {
      return window.open(`/${chain.toLowerCase()}/wallets/${walletId}`);
    }
  };

  return (
    <div>
      {sent.length == 0 && received.length == 0 ? (
        <span style={{ padding: 8 }}>Interactions are not available</span>
      ) : (
        <>
          <div
            style={{
              padding: 8,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
            }}
          >
            <div style={{ marginRight: 16 }}>
              <span style={{ fontWeight: 'bold' }}>Incoming</span>
              {received.length == 0 ? (
                <div
                  style={{
                    width: GRAPH_WIDTH,
                    height: GRAPH_HEIGHT,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  Incoming interactions are not available
                </div>
              ) : (
                <Doughnut
                  data={formatData(received)}
                  width={GRAPH_WIDTH}
                  height={GRAPH_HEIGHT}
                  options={{
                    ...graphOptions,
                    onClick: (_, element) =>
                      graphSectionClicked(_, element, 'received'),
                  }}
                />
              )}
            </div>
            <div>
              <span style={{ fontWeight: 'bold' }}>Outgoing</span>
              {sent.length == 0 ? (
                <div
                  style={{
                    width: GRAPH_WIDTH,
                    height: GRAPH_HEIGHT,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  Outgoing interactions are not available
                </div>
              ) : (
                <Doughnut
                  data={formatData(sent)}
                  width={GRAPH_WIDTH}
                  height={GRAPH_HEIGHT}
                  options={{
                    ...graphOptions,
                    onClick: (_, element) =>
                      graphSectionClicked(_, element, 'sent'),
                  }}
                />
              )}
            </div>
          </div>
          <GraphsSubtitles />
        </>
      )}
    </div>
  );
};

const GraphsSubtitles = () => {
  return (
    <div style={{ padding: 8 }}>
      <Divider direction="horizontal" />
      <div style={{ display: 'flex', marginTop: 8, alignItems: 'center' }}>
        <span
          className={`label label-${riskIndicatorClass.LOW_RISK}`}
          style={{ marginLeft: 15 }}
        >
          &nbsp;
        </span>
        Low Risk
        <span
          className={`label label-${riskIndicatorClass.MEDIUM_RISK}`}
          style={{ marginLeft: 15 }}
        >
          &nbsp;
        </span>
        Medium Risk
        <span
          className={`label label-${riskIndicatorClass.HIGH_RISK}`}
          style={{ marginLeft: 15 }}
        >
          &nbsp;
        </span>
        High Risk
        <span
          className={`label label-${riskIndicatorClass.UNKNOWN}`}
          style={{ marginLeft: 15 }}
        >
          &nbsp;
        </span>
        Unknown
      </div>
    </div>
  );
};

export default Interactions;
