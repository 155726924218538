import { init, initTrackPageview, PlatformName } from '@cbhq/cca';
import { createBrowserHistory } from 'history';

init({
  isProd: process.env.NODE_ENV === 'production',
  platform: PlatformName.web,
  projectName: 'cb_analytics',
  showDebugLogging: process.env.NODE_ENV !== 'production',
  version: process.env.BUILD_VERSION,
});

initTrackPageview({
  blacklistRegex: [/users\/.+/, /groups\/.+/],
  browserHistory: createBrowserHistory(),
});
