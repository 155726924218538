import { useToggler } from '@cbhq/cds-web';
import { Icon } from '@cbhq/cds-web/icons';
import { HStack, Spacer, Divider } from '@cbhq/cds-web/layout';
import { PopoverMenu, PopoverTrigger } from '@cbhq/cds-web/overlays';
import { MenuItem } from '@cbhq/cds-web/dropdown/MenuItem';
import { TextBody, TextHeadline } from '@cbhq/cds-web/typography';
import React, { useState } from 'react';
import { ICONS } from '../../util/Icons';
import { Pressable } from '@cbhq/cds-web/system';

export const ALL_NETWORKS = 'All networks';
export const DEFAULT_NETWORK = 'btc';

interface NetworkFilterProps {
  chainConfigs: any;
  showAllNetworks: boolean;
  onChange: (option: string) => void;
  chain?: string;
}

export const NetworkFilter = ({
  showAllNetworks,
  chainConfigs,
  onChange,
  chain,
}: NetworkFilterProps) => {
  const [visible, togglePopoverMenuVisibility] = useToggler(false);

  const [selectedNetwork, setSelectedNetwork] = useState(
    chain ?? (showAllNetworks ? ALL_NETWORKS : DEFAULT_NETWORK)
  );

  const craftFilterTextForChain = (chain) => {
    return chainConfigs.configs[chain]?.name + ' (' + chain.toUpperCase() + ')';
  };

  const filterTextValue = () => {
    if (chain) {
      return chain == ALL_NETWORKS && showAllNetworks
        ? ALL_NETWORKS
        : craftFilterTextForChain(chain);
    } else {
      return showAllNetworks
        ? ALL_NETWORKS
        : craftFilterTextForChain(DEFAULT_NETWORK);
    }
  };

  const [filterText, setFilterText] = useState(filterTextValue());

  const updateNetworkSelection = (option) => {
    onChange(option);
    setSelectedNetwork(option);
    option == ALL_NETWORKS
      ? setFilterText(ALL_NETWORKS)
      : setFilterText(craftFilterTextForChain(option));
  };

  const setAllNetworks = () => {
    setSelectedNetwork(ALL_NETWORKS);
    setFilterText(ALL_NETWORKS);
    onChange(ALL_NETWORKS);
  };

  const startIcon = () => {
    if (selectedNetwork != ALL_NETWORKS) {
      return <img className="chainlogo-md" src={ICONS[selectedNetwork]} />;
    } else {
      return <Icon name="defi" size="s" dangerouslySetColor="black" />;
    }
  };

  return (
    <PopoverMenu
      width={400}
      openMenu={togglePopoverMenuVisibility.toggleOn}
      closeMenu={togglePopoverMenuVisibility.toggleOff}
      visible={visible}
    >
      <PopoverTrigger>
        <Pressable
          as="button"
          backgroundColor="backgroundAlternate"
          borderRadius="roundedFull"
        >
          <HStack
            alignItems="center"
            gap={1}
            spacingHorizontal={2}
            spacingVertical={1}
          >
            {startIcon()}
            <TextHeadline as="p">{filterText || ALL_NETWORKS}</TextHeadline>
            <Icon name="caretDown" size="s" dangerouslySetColor="black" />
          </HStack>
        </Pressable>
      </PopoverTrigger>
      {showAllNetworks && (
        <SelectAllNetworks
          setAllNetworks={setAllNetworks}
          isSelected={selectedNetwork == ALL_NETWORKS}
        />
      )}
      {showAllNetworks && <Divider />}
      <NetworksList
        chainConfigs={chainConfigs}
        selectedNetwork={selectedNetwork}
        updateNetworkSelection={updateNetworkSelection}
      />
    </PopoverMenu>
  );
};

interface SelectAllNetworksProps {
  setAllNetworks: () => void;
  isSelected: boolean;
}
const SelectAllNetworks = ({
  setAllNetworks,
  isSelected,
}: SelectAllNetworksProps) => {
  return (
    <MenuItem value={ALL_NETWORKS} onPress={() => setAllNetworks()}>
      <HStack
        alignItems="center"
        spacingVertical={1}
        spacingHorizontal={2}
        gap={1}
      >
        <TextBody as="p">{ALL_NETWORKS}</TextBody>
        <Spacer />
        {isSelected && <Icon name="checkmark" size="xs" />}
      </HStack>
    </MenuItem>
  );
};

interface NetworkListProps {
  chainConfigs: any;
  selectedNetwork: string;
  updateNetworkSelection: Function;
}

export const NetworksList = ({
  chainConfigs,
  selectedNetwork,
  updateNetworkSelection,
}: NetworkListProps) => {
  return (
    <>
      {chainConfigs.chains.map(
        (option) =>
          chainConfigs.configs[option].enabled && (
            <MenuItem
              key={option}
              value={option}
              onPress={() => updateNetworkSelection(option)}
            >
              <NetworkListItem
                option={option}
                chainConfigs={chainConfigs}
                isNetworkSelected={selectedNetwork == option}
              />
            </MenuItem>
          )
      )}
    </>
  );
};

interface NetworkListItemProps {
  option: string;
  chainConfigs: any;
  isNetworkSelected: boolean;
}

const NetworkListItem = ({
  option,
  chainConfigs,
  isNetworkSelected,
}: NetworkListItemProps) => {
  return (
    <HStack
      alignItems="center"
      spacingVertical={1}
      spacingHorizontal={2}
      gap={2}
    >
      <img className="chainlogo-md" src={ICONS[option]} />
      <TextBody as="p">
        {chainConfigs.configs[option].name + ' (' + option.toUpperCase() + ')'}
      </TextBody>
      <Spacer />
      {isNetworkSelected && <Icon name="checkmark" size="xs" />}
    </HStack>
  );
};
