/**
 * @generated SignedSource<<cae90552835589ffd00d350d3e65a862>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AdditionalInfoAddress_metadata$data = {
  readonly metadata: {
    readonly name: string;
    readonly categories: ReadonlyArray<string | null> | null;
    readonly urls: ReadonlyArray<string | null> | null;
    readonly notes: string | null;
    readonly handling: string | null;
  } | null;
  readonly " $fragmentType": "AdditionalInfoAddress_metadata";
};
export type AdditionalInfoAddress_metadata = AdditionalInfoAddress_metadata$data;
export type AdditionalInfoAddress_metadata$key = {
  readonly " $data"?: AdditionalInfoAddress_metadata$data;
  readonly " $fragmentSpreads": FragmentRefs<"AdditionalInfoAddress_metadata">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdditionalInfoAddress_metadata",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CoinbaseAnalyticsAddressMetadata",
      "kind": "LinkedField",
      "name": "metadata",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "categories",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "urls",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "notes",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "handling",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CoinbaseAnalyticsAddress",
  "abstractKey": null
};

(node as any).hash = "94ab5059fea8be206beac82564bc2df7";

export default node;
