import { Fallback, VStack } from '@cbhq/cds-web/layout';
import { TabNavigation } from '@cbhq/cds-web/tabs';
import { useLocation } from 'react-router-dom';
import React, { Suspense, useContext, useEffect, useState } from 'react';
import { ALL_NETWORKS } from '../../components/filters/NetworkFilter';

import NetworkFilterWrapper from './NetworkFilterWrapper';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import SearchResultsDataWrapper from './SearchResultsDataWrapper';
import { ListCellFallback } from '@cbhq/cds-web/cells';

export type ISearchResultTypes = 'addresses' | 'wallets';

export const searchResultTypesMap = {
  wallets: 'wallet',
  addresses: 'address',
};

export interface IChainConfigs {
  chains: [string];
  configs: IConfigObjectLiteral;
}

interface IConfigObjectLiteral {
  [key: string]: IConfig;
}
interface IConfig {
  enabled: boolean;
  name: string;
  class: string;
}

const SearchResultsWrapper = ({
  chainConfigs,
}: {
  chainConfigs: IChainConfigs;
}) => {
  const useQuery = () => new URLSearchParams(useLocation().search);

  const query = useQuery().get('query');
  const [displayedEntity, setDisplayedEntity] = useState('addresses');
  const [chain, setChain] = useState(ALL_NETWORKS);
  const tickerSymbol = chain === ALL_NETWORKS ? 'all_networks' : chain;
  const [tabs, setTabs] = useState([
    {
      id: 'addresses',
      label: `Addresses`,
    },
    { id: 'wallets', label: `Wallets` },
  ]);

  return (
    <VStack gap={2}>
      <>
        <TabNavigation
          value={displayedEntity}
          tabs={tabs}
          onChange={setDisplayedEntity}
        />
        <hr className="portal-page-divider" />
      </>
      <ErrorBoundary>
        <Suspense
          fallback={<Fallback width={144} height={36} shape="circle" />}
        >
          <NetworkFilterWrapper
            chainConfigs={chainConfigs}
            query={query}
            setChain={setChain}
            setTabs={setTabs}
            chain={chain}
            currentTab={displayedEntity}
          />
        </Suspense>
      </ErrorBoundary>
      <hr className="portal-page-divider" />

      <ErrorBoundary>
        <Suspense
          fallback={
            <>
              <ListCellFallback title description />
              <ListCellFallback title description />
              <ListCellFallback title description />
              <ListCellFallback title description />
              <ListCellFallback title description />
            </>
          }
        >
          <SearchResultsDataWrapper
            displayedEntity={displayedEntity}
            query={query}
            tickerSymbol={tickerSymbol}
          />
        </Suspense>
      </ErrorBoundary>
    </VStack>
  );
};

export default SearchResultsWrapper;
