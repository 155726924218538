/**
 * @generated SignedSource<<7560ec7bb7e7888ff47e35157e1ee3c9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CoinbaseAnalyticsTransactionDirection = "SENT" | "RECEIVED" | "ALL" | "UNSPENT" | "%future added value";
export type CoinbaseAnalyticsTransactionOrder = "ASC" | "DESC" | "%future added value";
export type CoinbaseAnalyticsTransactionTypes = "COINBASE" | "ESCROW" | "HIGH_FEE" | "INVALID_ADDRESS" | "JOINMARKET" | "MIXER" | "OP_RETURN" | "SEGWIT" | "SEGWIT_NATIVE" | "SELFCHANGE" | "SELFSEND" | "SHARED" | "SHIELDED" | "UTXO" | "WASABI" | "ZERO" | "%future added value";
export type CoinbaseAnalyticsTransactionFilters = {
  direction?: CoinbaseAnalyticsTransactionDirection | null;
  order?: CoinbaseAnalyticsTransactionOrder | null;
  types?: ReadonlyArray<CoinbaseAnalyticsTransactionTypes> | null;
  addressHashOrWalletId?: string | null;
  periodStart?: any | null;
  periodEnd?: any | null;
  minAssetAmount?: any | null;
  maxAssetAmount?: any | null;
};
export type WalletTransactions_WalletQuery$variables = {
  tickerSymbol: any;
  filters: CoinbaseAnalyticsTransactionFilters;
  walletId: string;
  after: string;
};
export type WalletTransactions_WalletQueryVariables = WalletTransactions_WalletQuery$variables;
export type WalletTransactions_WalletQuery$data = {
  readonly viewer: {
    readonly coinbaseAnalytics: {
      readonly walletByHashAndSymbol: {
        readonly transactions: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly hash: string;
              readonly " $fragmentSpreads": FragmentRefs<"TransactionTabRow_TransactionFragment">;
            } | null;
          }>;
          readonly pageInfo: {
            readonly endCursor: string;
            readonly hasNextPage: boolean;
          };
        } | null;
      } | null;
    };
  };
};
export type WalletTransactions_WalletQueryResponse = WalletTransactions_WalletQuery$data;
export type WalletTransactions_WalletQuery = {
  variables: WalletTransactions_WalletQueryVariables;
  response: WalletTransactions_WalletQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tickerSymbol"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "walletId"
},
v4 = [
  {
    "kind": "Variable",
    "name": "hash",
    "variableName": "walletId"
  },
  {
    "kind": "Variable",
    "name": "tickerSymbol",
    "variableName": "tickerSymbol"
  }
],
v5 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "asset",
    "variableName": "tickerSymbol"
  },
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hash",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currency",
    "storageKey": null
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "address",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Amount",
    "kind": "LinkedField",
    "name": "amount",
    "plural": false,
    "selections": (v8/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "amountUsd",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "prevTxHash",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "nextTxHash",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "prevOutputIndex",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "nextOutputIndex",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "generated",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "osint",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "valid",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "changeAddress",
    "storageKey": null
  },
  (v9/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "WalletTransactions_WalletQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CoinbaseAnalytics",
            "kind": "LinkedField",
            "name": "coinbaseAnalytics",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "CoinbaseAnalyticsWallet",
                "kind": "LinkedField",
                "name": "walletByHashAndSymbol",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v5/*: any*/),
                    "concreteType": "CoinbaseAnalyticsWalletTransactionConnection",
                    "kind": "LinkedField",
                    "name": "transactions",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CoinbaseAnalyticsWalletTransactionEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CoinbaseAnalyticsBlockTransaction",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "TransactionTabRow_TransactionFragment"
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "WalletTransactions_WalletQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CoinbaseAnalytics",
            "kind": "LinkedField",
            "name": "coinbaseAnalytics",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "CoinbaseAnalyticsWallet",
                "kind": "LinkedField",
                "name": "walletByHashAndSymbol",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v5/*: any*/),
                    "concreteType": "CoinbaseAnalyticsWalletTransactionConnection",
                    "kind": "LinkedField",
                    "name": "transactions",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CoinbaseAnalyticsWalletTransactionEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CoinbaseAnalyticsBlockTransaction",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "time",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "direction",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Amount",
                                "kind": "LinkedField",
                                "name": "inAmount",
                                "plural": false,
                                "selections": (v8/*: any*/),
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "inAmountUsd",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Amount",
                                "kind": "LinkedField",
                                "name": "outAmount",
                                "plural": false,
                                "selections": (v8/*: any*/),
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "outAmountUsd",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Amount",
                                "kind": "LinkedField",
                                "name": "feeAmount",
                                "plural": false,
                                "selections": (v8/*: any*/),
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "feeAmountUsd",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "mixed",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "CoinbaseAnalyticsOperation",
                                "kind": "LinkedField",
                                "name": "operations",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "index",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "type",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CoinbaseAnalyticsOperationPart",
                                    "kind": "LinkedField",
                                    "name": "inputs",
                                    "plural": true,
                                    "selections": (v10/*: any*/),
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CoinbaseAnalyticsOperationPart",
                                    "kind": "LinkedField",
                                    "name": "outputs",
                                    "plural": true,
                                    "selections": (v10/*: any*/),
                                    "storageKey": null
                                  },
                                  (v9/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "totalInputs",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "totalOutputs",
                                "storageKey": null
                              },
                              (v9/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b4f2b4be7db2e12120e13494ad710c0a",
    "id": null,
    "metadata": {},
    "name": "WalletTransactions_WalletQuery",
    "operationKind": "query",
    "text": "query WalletTransactions_WalletQuery(\n  $tickerSymbol: TickerSymbol!\n  $filters: CoinbaseAnalyticsTransactionFilters!\n  $walletId: String!\n  $after: String!\n) {\n  viewer {\n    coinbaseAnalytics {\n      walletByHashAndSymbol(hash: $walletId, tickerSymbol: $tickerSymbol) {\n        transactions(asset: $tickerSymbol, filters: $filters, after: $after) {\n          edges {\n            node {\n              hash\n              ...TransactionTabRow_TransactionFragment\n              id\n            }\n          }\n          pageInfo {\n            endCursor\n            hasNextPage\n          }\n        }\n        id\n      }\n    }\n  }\n}\n\nfragment TransactionTabRow_TransactionDetailsFragment on CoinbaseAnalyticsBlockTransaction {\n  hash\n  inAmount {\n    value\n    currency\n  }\n  inAmountUsd\n  outAmount {\n    value\n    currency\n  }\n  outAmountUsd\n  feeAmount {\n    value\n    currency\n  }\n  feeAmountUsd\n  mixed\n  operations {\n    index\n    type\n    inputs {\n      address\n      name\n      amount {\n        value\n        currency\n      }\n      amountUsd\n      prevTxHash\n      nextTxHash\n      prevOutputIndex\n      nextOutputIndex\n      generated\n      osint\n      valid\n      changeAddress\n      id\n    }\n    outputs {\n      address\n      name\n      amount {\n        value\n        currency\n      }\n      amountUsd\n      prevTxHash\n      nextTxHash\n      prevOutputIndex\n      nextOutputIndex\n      generated\n      osint\n      valid\n      changeAddress\n      id\n    }\n    id\n  }\n  totalInputs\n  totalOutputs\n}\n\nfragment TransactionTabRow_TransactionFragment on CoinbaseAnalyticsBlockTransaction {\n  hash\n  time\n  direction\n  ...TransactionTabRow_TransactionDetailsFragment\n}\n"
  }
};
})();

(node as any).hash = "ab64e0e98ffcfe330f23422cf0e142d4";

export default node;
