import React from 'react';
import { useParams } from 'react-router-dom';
import { graphql } from '@cbhq/data-layer';
import { useLazyLoadQuery } from 'react-relay';
import { TransactionScriptsSection_TransactionQueryResponse } from './__generated__/TransactionScriptsSection_TransactionQuery.graphql';

const txScriptsQuery = graphql`
  query TransactionScriptsSection_TransactionQuery(
    $tickerSymbol: TickerSymbol!
    $transactionId: String!
  ) {
    viewer {
      coinbaseAnalytics {
        transactionByHashAndSymbol(
          hash: $transactionId
          tickerSymbol: $tickerSymbol
        ) {
          scripts {
            inputScripts {
              content
            }
            outputScripts {
              content
            }
          }
        }
      }
    }
  }
`;

// A table showing the input and output scripts of a single transaction.
export const TransactionScriptsSection: React.FC<{
  chain: string;
}> = (props) => {
  const { hash } = useParams();

  const { viewer } = useLazyLoadQuery(txScriptsQuery, {
    transactionId: hash,
    tickerSymbol: props.chain,
  }) as TransactionScriptsSection_TransactionQueryResponse;

  return (
    <React.Fragment>
      <TransactionScriptTable
        label={'Input'}
        transactionScripts={
          viewer.coinbaseAnalytics.transactionByHashAndSymbol.scripts
            .inputScripts
        }
      />
      <TransactionScriptTable
        label={'Output'}
        transactionScripts={
          viewer.coinbaseAnalytics.transactionByHashAndSymbol.scripts
            .outputScripts
        }
      />
    </React.Fragment>
  );
};

const TransactionScriptTable: React.FC<{
  label: string;
  transactionScripts;
}> = (props) => {
  return (
    <table className="table">
      <tbody>
        <tr>
          <td width={'100px'}>{props.label}</td>
          <td>
            <table className="table table-striped">
              <tbody>
                {props.transactionScripts.map((script) => {
                  return (
                    <TransactionScriptRow
                      key={script.content}
                      scriptContent={script.content}
                    />
                  );
                })}
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const TransactionScriptRow: React.FC<{ scriptContent: string }> = (props) => {
  return (
    <tr>
      <td className="small">
        <div style={{ wordBreak: 'break-all' }}>{props.scriptContent}</div>
      </td>
    </tr>
  );
};
