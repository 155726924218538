import React, { Suspense, useContext } from 'react';
import { Route, useParams, useRouteMatch } from 'react-router-dom';
import { TransactionSummarySection } from './TransactionSummarySection';
import HeaderBlock from '../../components/HeaderBlock';
import HeaderBlocks from '../../components/HeaderBlocks';
import { PageDataContext } from '../../util/PageDataContext';
import { TransactionWalletSection } from './TransactionWallets';
import { LoadingBubbles } from '../../components/LoadingBubbles';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { graphql } from '@cbhq/data-layer';
import { useFragment } from 'react-relay';
import ReactTooltip from 'react-tooltip';
import { TransactionScriptsSection } from './TransactionScriptsSection';
import { formatAmount, formatUsd } from '../../util/AmountUtils';
import { DemixTool } from '../../components/DemixTool';
import BookmarksPanel from '../../components/BookmarksPanel';
import GraphsPanel from '../../components/GraphsPanel';
import CasesPanel from '../../components/CasesPanel';

const transactionDataFragment = graphql`
  fragment TransactionPageContent on CoinbaseAnalyticsTransaction {
    outAmount {
      value
      currency
    }
    outAmountUsd
    feeAmount {
      value
      currency
    }
    feeAmountUsd
    isPeeling
    demixerType
  }
`;

const TransactionPageContent: React.FC<{
  chain: string;
  transactionRef;
}> = (props) => {
  const { hash } = useParams();
  const { path } = useRouteMatch();
  const { graphs, cases } = useContext(PageDataContext);
  const transactionData = useFragment(
    transactionDataFragment,
    props.transactionRef
  );

  return (
    <div className="row">
      <div className="col-md-9">
        <HeaderBlocks
          blocks={[
            <HeaderBlock
              key="transaction-page-content-header-block-1"
              title="Amount"
              body={formatAmount(transactionData?.outAmount, props.chain)}
              footer={formatUsd(transactionData.outAmountUsd)}
            />,
            <HeaderBlock
              key="transaction-page-content-header-block-2"
              title="Fee"
              body={formatAmount(transactionData.feeAmount, props.chain)}
              footer={formatUsd(transactionData.feeAmountUsd)}
            />,
          ]}
        />
        <Route path={path} exact>
          <TransactionSummarySection
            chain={props.chain}
            transactionFragmentReference={props.transactionRef}
          />
        </Route>
        <Route path={`${path}/wallets`} exact>
          <ErrorBoundary>
            <Suspense fallback={<LoadingBubbles />}></Suspense>
            <TransactionWalletSection chain={props.chain} />
          </ErrorBoundary>
        </Route>
        <Route path={`${path}/scripts`} exact>
          <ErrorBoundary>
            <Suspense fallback={<LoadingBubbles />}></Suspense>
            <TransactionScriptsSection chain={props.chain} />
          </ErrorBoundary>
        </Route>
      </div>
      <div className="col-md-3 sidebar" style={{ marginTop: 40 }}>
        <BookmarksPanel chain={props.chain} hash={hash} />
        <GraphsPanel hash={hash} graphs={graphs} />
        <CasesPanel hash={hash} cases={cases} />
        <ReactTooltip id="tool-follow-peeling">
          <div style={{ maxWidth: 250 }}>
            This transaction appears to be part of a peeling chain, where a big
            amount of coins is divided and spent in many subsequent small
            transactions. This tool try to find the origin of the peeling chain,
            showing each spending transaction up to the root.
          </div>
        </ReactTooltip>
        {transactionData.isPeeling && (
          <PeelingButton chain={props.chain} hash={hash} />
        )}
        {transactionData.demixerType && (
          <DemixTool transactionReference={props.transactionRef} />
        )}
      </div>
    </div>
  );
};

const PeelingButton = ({ chain, hash }) => (
  <a
    data-effect="solid"
    data-tip="necessary for react-tooltip"
    data-for="tool-follow-peeling"
    data-place="top"
    target="_blank"
    href={`/${chain}/transactions/${hash}/follow_peeling`}
    className="btn btn-block btn-basic"
    rel="noreferrer"
  >
    Follow Peeling
  </a>
);

export default TransactionPageContent;
