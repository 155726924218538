import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHeader,
  TableRow,
} from '@cbhq/cds-web/tables';
import React, { useState } from 'react';
import { ISearchResultTypes } from './SearchResultsWrapper';
import { NextPageCursorNavigator } from '../../components/NextPageCursorNavigator';
import { useToggler } from '@cbhq/cds-web';
import { CasesModal } from './CasesModal';
import { GraphsModalWrapper } from './GraphsModal';
import { SearchResultsRow } from './SearchResultsRow';
import useBookmark from './useBookmark';

interface IColumn {
  title: string;
  key: string;
  width: string;
  alignment: 'center' | 'flex-start' | 'flex-end';
}

interface ISearchResultsProps<T> {
  columns: IColumn[];
  result: T;
  type: ISearchResultTypes;
  nextPageCursor: string;
  navigateToPage: (nextPage: number, nextPageCursor: string) => void;
  currentPage: number;
}

const SearchResults = ({
  columns,
  type,
  result,
  nextPageCursor,
  navigateToPage,
  currentPage,
}: ISearchResultsProps<any>) => {
  const [selectedRowParams, setSelectedRowParams] = useState({
    chain: '',
    name: '',
    itemId: '',
  });
  const bookmark = useBookmark();
  const onRowPress = (
    hash: string,
    chain: string,
    type: ISearchResultTypes
  ) => {
    window.location.href = `/${chain}/${type}/${hash}`;
  };

  const [
    casesModalVisible,
    { toggleOn: toggleCasesModalOn, toggleOff: toggleCasesModalOff },
  ] = useToggler();

  const [
    graphsModalVisible,
    { toggleOn: toggleGraphsModalOn, toggleOff: toggleGraphsModalOff },
  ] = useToggler();

  return (
    <>
      {graphsModalVisible && (
        <GraphsModalWrapper
          entity={type}
          description=""
          visible={graphsModalVisible}
          onRequestClose={toggleGraphsModalOff}
          {...selectedRowParams}
        />
      )}

      {casesModalVisible && (
        <CasesModal
          entity={type}
          {...selectedRowParams}
          description=""
          visible={casesModalVisible}
          onRequestClose={toggleCasesModalOff}
        />
      )}
      <Table bordered variant="ruled" tableLayout="auto">
        <TableHeader>
          <TableRow>
            {columns.map((row) => {
              return (
                <TableCell
                  key={row.key}
                  title={row.title}
                  width={row.width}
                  alignItems={row.alignment}
                />
              );
            })}
          </TableRow>
        </TableHeader>
        <TableBody>
          {result.map((row, index) => {
            const params = {
              type,
              edge: row,
              toggleCasesModalOn,
              toggleGraphsModalOn,
              setSelectedRowParams,
              onRowPress,
              bookmark,
              index,
            };
            return (
              <SearchResultsRow
                {...params}
                key={`row-${index}-${type}-${row.hash}`}
              />
            );
          })}
        </TableBody>
        <TableFooter>
          <TableRow fullWidth>
            <TableCell direction="horizontal" alignItems="center" width="100%">
              <NextPageCursorNavigator
                useCDS
                currentPage={currentPage}
                nextPageCursor={nextPageCursor}
                onClick={navigateToPage}
              />
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </>
  );
};

export default SearchResults;
