import React from 'react';
import { graphql } from '@cbhq/data-layer';
import { useFragment } from 'react-relay';
import AttributeLabel from '../../components/AttributeLabel';
import { formatAmount } from '../../util/AmountUtils';
import { ChainLink } from '../../components/ChainLink';

const transactionDetailsFragment = graphql`
  fragment TransactionDetailsTable on CoinbaseAnalyticsTransaction {
    hash
    time
    attributes
    blockHeight
    totalInputs
    totalOutputs
    totalUniqueInputs
    totalUniqueInputTxs
    totalUniqueOutputs
    totalSpentOutputs
    totalUniqueSpentOutputTxs
    isUtxo
    gasPrice {
      value
      currency
    }
    gasLimit {
      value
      currency
    }
    gasUsed {
      value
      currency
    }
    error
  }
`;

const TransactionDetailsTable: React.FC<{ chain: string; transactionRef }> = (
  props
) => {
  const tx = useFragment(transactionDetailsFragment, props.transactionRef);
  return (
    <table className="table table-summary">
      <tbody>
        <tr>
          <td>{'Details'}</td>
          <td>
            {/* Dummy; table-summary CSS rounds corners differently depending on first/last child elements */}
          </td>
        </tr>
        <tr>
          <td width={'30%'}>ID</td>
          <td width={'70%'}>{tx?.hash}</td>
        </tr>
        <tr>
          <td width={'30%'}>Time</td>
          <td width={'70%'}>{tx?.time}</td>
        </tr>
        <tr>
          <td width={'30%'}>Block</td>
          <td width={'70%'} className="monospace">
            <ChainLink to={`/blocks/${tx?.blockHeight}`}>
              {tx?.blockHeight}
            </ChainLink>
          </td>
        </tr>
        {tx.attributes && tx.attributes.length > 0 && (
          <tr>
            <td width={'30%'}>Attributes</td>
            <td width={'70%'}>
              {tx.attributes.map((attrib) => (
                <AttributeLabel key={attrib} label={attrib} />
              ))}
            </td>
          </tr>
        )}
        {tx.error && (
          <tr>
            <td width={'30%'}>Error</td>
            <td width={'70%'} style={{ color: 'red' }}>
              tx.error
            </td>
          </tr>
        )}
        {tx.isUtxo && (
          <tr>
            <td width={'30%'}>Inputs</td>
            <td width={'70%'}>
              {tx?.totalInputs}
              <span className="pull-right">
                unique: {tx.totalUniqueInputs} , source tx:{' '}
                {tx.totalUniqueInputTxs}
              </span>
            </td>
          </tr>
        )}
        {!tx.isUtxo && (
          <tr>
            <td width={'30%'}>Gas</td>
            <td width={'70%'}>
              Limit: {formatAmount(tx?.gasLimit, props.chain)}
              <br />
              Used: {formatAmount(tx?.gasUsed, props.chain)}
              <br />
              Price: {formatAmount(tx?.gasPrice, props.chain)}
            </td>
          </tr>
        )}
        {tx.isUtxo && (
          <tr>
            <td width={'30%'}>Outputs</td>
            <td width={'70%'}>
              {tx?.totalOutputs}
              <span className="pull-right">
                unique: {tx.totalUniqueOutputs} , spent {tx.totalSpentOutputs}{' '}
                in {tx.totalUniqueSpentOutputTxs} transactions
              </span>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default TransactionDetailsTable;
