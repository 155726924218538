import * as bookmarksService from './bookmarks';
import * as graphsService from './graphs';
import * as casesService from './cases';
import * as analysisService from './analysis';

export const getAuthenticityToken = () => {
  return window.$.rails.csrfToken();
};

export type IResponse<T> = IGoodResponse<T> | IBadResponse;

type IGoodResponse<T> = {
  status: 'ok';
  result: T;
};

type IBadResponse = {
  status: 'error';
  message: string;
};

export { bookmarksService, graphsService, casesService, analysisService };
