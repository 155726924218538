import React, {
  CSSProperties,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Moment from 'moment';
import { IconName, useToggler } from '@cbhq/cds-web';
import { Button, IconButton } from '@cbhq/cds-web/buttons';
import { CellMedia, ListCell } from '@cbhq/cds-web/cells';
import { NativeTextArea, SearchInput, TextInput } from '@cbhq/cds-web/controls';
import { SelectOption } from '@cbhq/cds-web/controls/SelectOption';
import { Icon } from '@cbhq/cds-web/icons/Icon';
import { HStack, VStack } from '@cbhq/cds-web/layout';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  PopoverMenu,
  PopoverTrigger,
  Tooltip,
} from '@cbhq/cds-web/overlays';
import { useToast } from '@cbhq/cds-web/overlays/useToast';
import {
  TextBody,
  TextCaption,
  TextHeadline,
  TextLabel1,
  TextLabel2,
} from '@cbhq/cds-web/typography';
import { CaseDetails } from './GraphsList';
import { ICONS } from '../util/Icons';
import WalletCategory from './WalletCategory';
import { PageDataContext } from '../util/PageDataContext';

interface GraphCardProps {
  id: string;
  chain: string;
  lastUpdated: string;
  name: string;
  wallets: readonly string[];
  categories: readonly string[];
  shared: boolean;
  description: string;
  chainConfigs: any;
}

export const OldGraphCard = (props: GraphCardProps) => {
  const [_, setValue] = useState('');
  const [visible, togglePopoverMenuVisibility] = useToggler(false);

  const [
    editModalVisible,
    { toggleOn: toggleEditModalOn, toggleOff: toggleEditModalOff },
  ] = useToggler();

  const [
    moveToModalVisible,
    { toggleOn: toggleMoveToModalOn, toggleOff: toggleMoveToModalOff },
  ] = useToggler();

  const [
    sendToModalVisible,
    { toggleOn: toggleSendToModalOn, toggleOff: toggleSendToModalOff },
  ] = useToggler();

  const [
    duplicateAlertVisible,
    { toggleOn: toggleDuplicateAlertOn, toggleOff: toggleDuplicateAlertOff },
  ] = useToggler();

  const [
    deleteAlertVisible,
    { toggleOn: toggleDeleteAlertOn, toggleOff: toggleDeleteAlertOff },
  ] = useToggler();

  const options = [
    { title: 'Edit', value: 'edit', icon: 'pencil' },
    { title: 'Duplicate', value: 'duplicate', icon: 'copy' },
    { title: 'Move to case', value: 'moveTo', icon: 'arrowsVertical' },
    { title: 'Send a copy', value: 'sendTo', icon: 'email' },
    { title: 'Share', value: 'sharingSettings', icon: 'profile' },
    { title: 'Delete', value: 'delete', icon: 'trashCan' },
  ];

  const handleEventBubbling = useCallback((event) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  }, []);

  const handleMenuValueChange = useCallback(
    (newValue) => {
      if (newValue === 'edit') {
        toggleEditModalOn();
      }

      if (newValue === 'duplicate') {
        toggleDuplicateAlertOn();
      }

      if (newValue === 'moveTo') {
        toggleMoveToModalOn();
      }

      if (newValue === 'sendTo') {
        toggleSendToModalOn();
      }

      if (newValue === 'delete') {
        toggleDeleteAlertOn();
      }

      if (newValue === 'sharingSettings') {
        window.location.href = `/${props.chain}/graph/${props.id}/share`;
      }

      setValue(newValue);
    },
    [setValue]
  );

  return (
    <>
      <div
        onClick={() => {
          window.location.href = `/${props.chain}/graph/${props.id}`;
        }}
        style={{
          cursor: 'pointer',
          border: '1px solid var(--line)',
          borderRadius: 'var(--spacing-1)',
          display: 'grid',
          backgroundColor: 'var(--background)',
          gridTemplateColumns: '2fr 0.5fr',
          gridTemplateRows: 'auto 1fr 1fr 1fr',
          gap: 'var(--spacing-2)',
          padding: 'var(--spacing-3)',
        }}
      >
        <div style={{ minWidth: 0 }}>
          <VStack gap={0.5}>
            <TextLabel2 as="span" color="foregroundMuted">
              {props.lastUpdated &&
                Moment(props.lastUpdated).format('MMM DD, YYYY')}
            </TextLabel2>
            <TextHeadline as="h3" overflow="truncate">
              {props.name}
            </TextHeadline>
          </VStack>
        </div>

        <div
          style={{
            justifySelf: 'right',
            alignSelf: 'center',
          }}
        >
          <HStack alignSelf="center" alignItems="center" gap={1}>
            {props.shared && (
              <>
                <Tooltip content="Shared">
                  <Icon name="profile" size="s" title="Shared" />
                </Tooltip>
              </>
            )}

            <div onClick={handleEventBubbling}>
              <PopoverMenu
                width={250}
                onChange={handleMenuValueChange}
                openMenu={togglePopoverMenuVisibility.toggleOn}
                closeMenu={togglePopoverMenuVisibility.toggleOff}
                visible={visible}
              >
                <PopoverTrigger>
                  <IconButton transparent name="more" variant="secondary" />
                </PopoverTrigger>
                {options.map((option) => (
                  <SelectOption
                    value={option.value}
                    key={option.title}
                    title={option.title}
                    media={
                      <CellMedia type="icon" name={option.icon as IconName} />
                    }
                    compact
                  />
                ))}
              </PopoverMenu>
            </div>
          </HStack>
        </div>

        <div
          style={{
            gridRow: 2,
            gridColumn: '1 / -1',
          }}
        >
          <VStack gap={0.5}>
            <TextLabel2 as="span" color="foregroundMuted">
              Wallets
            </TextLabel2>
            <WalletLabels wallets={props.wallets} />
          </VStack>
        </div>

        <div
          style={{
            gridRow: 3,
            gridColumn: '1 / -1',
          }}
        >
          <VStack gap={0.5}>
            <TextLabel2 as="span" color="foregroundMuted">
              Chain
            </TextLabel2>
            <HStack alignContent="center" gap={1} alignItems="center">
              <img className="chainlogo-sm" src={ICONS[props.chain]} />
              <TextLabel2 as="span">
                {props.chainConfigs.configs[props.chain].name +
                  ' (' +
                  props.chain.toUpperCase() +
                  ')'}
              </TextLabel2>
            </HStack>
          </VStack>
        </div>

        <div
          style={{
            gridRow: 4,
            gridColumn: '1 / -1',
          }}
        >
          <VStack gap={0.5}>
            <TextLabel2 as="span" color="foregroundMuted">
              Categories
            </TextLabel2>
            <div>
              {props.categories.length !== 0 ? (
                props.categories.map((attrib) => (
                  <WalletCategory
                    key={attrib}
                    category={attrib}
                    mixed={false}
                  />
                ))
              ) : (
                <TextLabel2 as="span" color="foregroundMuted">
                  None
                </TextLabel2>
              )}
            </div>
          </VStack>
        </div>
      </div>

      <EditModal
        {...props}
        visible={editModalVisible}
        onRequestClose={toggleEditModalOff}
      />

      <DuplicateGraphAlert
        {...props}
        visible={duplicateAlertVisible}
        onRequestClose={toggleDuplicateAlertOff}
      />

      <MoveToModal
        {...props}
        visible={moveToModalVisible}
        onRequestClose={toggleMoveToModalOff}
      />

      <SendGraphModal
        {...props}
        visible={sendToModalVisible}
        onRequestClose={toggleSendToModalOff}
      />

      <DeleteGraphAlert
        {...props}
        visible={deleteAlertVisible}
        onRequestClose={toggleDeleteAlertOff}
      />
    </>
  );
};

interface OverlayProps {
  visible: boolean;
  onRequestClose: () => void;
  chain: string;
  name: string;
  id: string;
  description: string;
}

const EditModal = ({
  visible,
  onRequestClose,
  chain,
  id,
  name,
  description,
}: OverlayProps) => {
  const toast = useToast();

  const [graphName, setGraphName] = useState(name ?? '');
  const [graphDescription, setGraphDescription] = useState(description ?? '');

  const [editStatus, setEditStatus] = useState<'loading' | 'error' | 'success'>(
    null
  );

  const editGraph = async () => {
    setEditStatus('loading');
    try {
      const formData = new FormData();
      formData.append('name', graphName);
      formData.append('desc', graphDescription);
      formData.append('authenticity_token', window.$.rails.csrfToken());
      formData.append('_method', 'put');

      await fetch(`/${chain}/graph/${id}/`, {
        method: 'POST',
        body: formData,
      });

      setEditStatus('success');
      toast.show(`Successfully updated ${name}`);
    } catch (error) {
      setEditStatus('error');
      toast.show(`Failed to update ${name}.`);
    } finally {
      onRequestClose();
      window.location.reload();
    }
  };

  const customNativeTextAreaCSS = useMemo(() => {
    return {
      resize: 'none',
    } as CSSProperties;
  }, []);

  return (
    <Modal visible={visible} onRequestClose={onRequestClose}>
      <ModalHeader title="Edit Graph" />
      <ModalBody>
        <VStack gap={3}>
          <TextInput
            label="Name"
            placeholder="Graph name"
            aria-required="true"
            type="input"
            onChange={(e) => {
              setGraphName(e.target.value);
            }}
            name="name"
            value={graphName}
          />
          <TextInput
            inputNode={
              <NativeTextArea
                style={customNativeTextAreaCSS}
                rows={4}
                name="desc"
                onChange={(e) => {
                  setGraphDescription(e.target.value);
                }}
                value={graphDescription}
              />
            }
            label="Description"
            type="input"
            name="desc"
          />
        </VStack>
      </ModalBody>

      <ModalFooter
        primaryAction={
          <Button
            onPress={() => {
              editGraph();
            }}
            loading={editStatus === 'loading'}
            disabled={editStatus === 'error'}
          >
            Update
          </Button>
        }
        secondaryAction={
          <Button variant="secondary" onPress={onRequestClose}>
            Cancel
          </Button>
        }
      />
    </Modal>
  );
};

const MoveToModal = ({
  visible,
  onRequestClose,
  id: graphId,
  chain,
  name,
}: OverlayProps) => {
  const toast = useToast();

  const [isLoadingCasesList, setIsLoadingCasesList] = useState(true);
  const [options, setOptions] = useState<CaseDetails[]>([]);
  const [value, setValue] = useState<CaseDetails>(null);
  const [caseCreationStatus, setCaseCreationStatus] = useState<
    'loading' | 'error' | 'success'
  >(null);

  const getAuthenticityToken = () => {
    return window.$.rails.csrfToken();
  };

  useEffect(() => {
    loadCasesList();
  }, []);

  const loadCasesList = async () => {
    const response = await fetch(`/api/internal/cases?asset=${chain}`, {
      method: 'GET',
    });
    const data = await response.json();

    setOptions(data.result);
    setIsLoadingCasesList(false);
  };

  const createCase = async () => {
    setCaseCreationStatus('loading');

    try {
      const caseCreationResponse = await fetch(`/${chain}/cases`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'x-csrf-token': getAuthenticityToken(),
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      });
      const data = await caseCreationResponse.json();

      await fetch(
        `/${chain}/cases/${data._id.$oid}/folders/${data.folders[0]._id.$oid}/items`,
        {
          method: 'POST',
          credentials: 'include',
          headers: {
            'x-csrf-token': getAuthenticityToken(),
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          body: JSON.stringify({
            text: graphId,
          }),
        }
      );

      setCaseCreationStatus('success');
      toast.show('Successfully moved.');
    } catch (error) {
      setCaseCreationStatus('error');
      toast.show('Failed to move case.');
    } finally {
      window.location.reload();
    }
  };

  const selectCase = (newValue) => {
    if (value === null) setValue(newValue);

    if (value !== null && value?.id === newValue?.id) {
      setValue(null);
    } else {
      setValue(newValue);
    }
  };

  return (
    <Modal visible={visible} onRequestClose={onRequestClose}>
      <ModalHeader title="Move to case" />
      <ModalBody>
        <form
          id="move-graph"
          method="post"
          action={`${chain}/cases/${value?.id}/folders/${value?.folders[0]?.id}/items`}
        >
          <VStack gap={2}>
            <TextBody spacingStart={3} as="p">
              Select a case to move your graph to.
            </TextBody>

            <VStack gap={0}>
              {options?.length !== 0 && !isLoadingCasesList ? (
                options.map((item) => {
                  return (
                    <>
                      <ListCell
                        key={item.id}
                        title={item.title}
                        description={Moment(item.lastUpdated).format(
                          'MMM DD, YYYY'
                        )}
                        selected={value?.id === item?.id}
                        onPress={() => {
                          selectCase(item);
                        }}
                      />
                    </>
                  );
                })
              ) : (
                <ListCell title="You haven’t created any cases yet." disabled />
              )}
            </VStack>
          </VStack>

          <input type="hidden" name="text" value={graphId} />
          <input
            type="hidden"
            name="authenticity_token"
            id="authenticity_token"
            value={getAuthenticityToken()}
          />
        </form>
      </ModalBody>
      <ModalFooter
        primaryAction={
          <Button
            type="submit"
            form="move-graph"
            disabled={options.length === 0 || value === null}
          >
            Move
          </Button>
        }
        secondaryAction={
          <Button
            variant="secondary"
            onPress={() => {
              createCase();
              onRequestClose();
            }}
            disabled={caseCreationStatus !== null}
          >
            Move to new case
          </Button>
        }
      />
    </Modal>
  );
};

const SendGraphModal = ({
  visible,
  onRequestClose,
  name,
  id,
  chain,
}: OverlayProps) => {
  const toast = useToast();

  const [options, setOptions] = useState(null);
  const [isVisible, { toggleOn: openMenu, toggleOff: closeMenu }] =
    useToggler(false);
  const [selectedValue, setSelectedValue] = useState(undefined);
  const [searchValue, setSearchValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(null);
  const [message, setMessage] = useState('');

  const popoverMenuRef = useRef(null);

  const [loadingStatus, setLoadingStatus] = useState<
    'loading' | 'error' | 'success'
  >(null);

  const { userConfigs } = useContext(PageDataContext);

  const loadUsersList = async () => {
    const response = await fetch(`/api/internal/users?asset=${chain}`, {
      method: 'GET',
      credentials: 'include',
    });
    const data = await response.json();

    const sortedUsers = data.result.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    );

    setOptions(sortedUsers);
    setFilteredOptions(sortedUsers);
  };

  useEffect(() => {
    loadUsersList();
  }, []);

  const customNativeTextAreaCSS = useMemo(() => {
    return {
      resize: 'none',
    } as CSSProperties;
  }, []);

  const filterOptions = useMemo(
    () =>
      !searchValue
        ? options
        : options.filter(
            (option) =>
              searchValue &&
              typeof searchValue === 'string' &&
              option.name.toLowerCase().includes(searchValue.toLowerCase())
          ),
    [searchValue]
  );

  useEffect(() => {
    setFilteredOptions(filterOptions);
  }, [filterOptions]);

  const renderOptions = useMemo(
    () =>
      filteredOptions &&
      filteredOptions.map((option) => (
        <SelectOption value={option.name} key={option.id} title={option.name} />
      )),
    [filteredOptions]
  );

  const handleSearchInputPress = useCallback(() => {
    openMenu();
  }, [openMenu]);

  const handleClear = useCallback(() => {
    setSelectedValue(undefined);
    setSearchValue(undefined);
  }, [setSelectedValue]);

  const handleMenuChange = useCallback(
    (newValue) => {
      setSelectedValue(newValue);
      setSearchValue(newValue);
    },
    [setSearchValue, setSelectedValue]
  );

  const handleBlur = useCallback((event) => {
    popoverMenuRef.current.handlePopoverMenuBlur(event);
  }, []);

  const handleKeyDown = useCallback(
    (event) => {
      if (!isVisible) {
        openMenu();
      }
      if (selectedValue) {
        setSelectedValue(undefined);
      }
      if (event.key === 'ArrowDown' || event.key === 'Enter') {
        popoverMenuRef.current.focusSelectOption();
      }
    },
    [selectedValue, setSelectedValue, openMenu, isVisible]
  );

  const sendGraph = async () => {
    setLoadingStatus('loading');
    try {
      const formData = new FormData();
      formData.append('graph_name', name);
      formData.append('from', userConfigs.name);
      formData.append('message', message);
      formData.append('to_email', '');
      formData.append(
        'to_user',
        options.find((user) => user.name === selectedValue).id
      );
      formData.append('authenticity_token', window.$.rails.csrfToken());

      await fetch(`/${chain}/graph/${id}/send_to`, {
        method: 'POST',
        body: formData,
      });

      setLoadingStatus('success');
      toast.show(`A copy of this graph has been sent to ${selectedValue}.`);
    } catch (error) {
      setLoadingStatus('error');
      toast.show(`Failed to send a copy of this graph.`);
    } finally {
      onRequestClose();
      window.location.reload();
    }
  };

  return (
    <Modal visible={visible} onRequestClose={onRequestClose}>
      <ModalHeader title="Send graph" />
      <ModalBody>
        <VStack gap={1}>
          <TextBody as="p">
            Send a copy of {name} to another user on Coinbase Tracer.
          </TextBody>
          <VStack gap={2}>
            <PopoverMenu
              visible={isVisible}
              openMenu={openMenu}
              closeMenu={closeMenu}
              onChange={handleMenuChange}
              value={selectedValue}
              width="100%"
              flush
              searchEnabled
              ref={popoverMenuRef}
            >
              <PopoverTrigger>
                <VStack gap={1} width="100%">
                  <TextLabel1 as="p">Recipient</TextLabel1>
                  <SearchInput
                    borderRadius={8}
                    onChangeText={setSearchValue}
                    value={
                      selectedValue
                        ? selectedValue
                        : searchValue
                        ? searchValue
                        : ''
                    }
                    width="100%"
                    onClear={handleClear}
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}
                    onPress={handleSearchInputPress}
                    placeholder="Search"
                  />
                </VStack>
              </PopoverTrigger>
              {filteredOptions && filteredOptions.length > 0 ? (
                renderOptions
              ) : (
                <HStack spacing={3}>
                  <TextCaption as="p">No options were found. </TextCaption>
                </HStack>
              )}
            </PopoverMenu>

            <TextInput
              inputNode={
                <NativeTextArea
                  style={customNativeTextAreaCSS}
                  rows={4}
                  name="message"
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                  value={message}
                />
              }
              label="Message (OPTIONAL)"
              type="input"
              name="message"
            />
          </VStack>
        </VStack>
      </ModalBody>
      <ModalFooter
        primaryAction={
          <Button
            onPress={() => {
              sendGraph();
            }}
            loading={loadingStatus === 'loading'}
            disabled={selectedValue === undefined || loadingStatus === 'error'}
          >
            Send
          </Button>
        }
        secondaryAction={
          <Button variant="secondary" onPress={onRequestClose}>
            Cancel
          </Button>
        }
      />
    </Modal>
  );
};

const DuplicateGraphAlert = ({
  visible,
  onRequestClose,
  chain,
  id,
  name,
}: OverlayProps) => {
  const toast = useToast();

  const [graphName, setGraphName] = useState('');
  const [duplicationStatus, setDuplicationStatus] = useState<
    'loading' | 'error' | 'success'
  >(null);

  const duplicateGraph = async () => {
    setDuplicationStatus('loading');
    try {
      const formData = new FormData();
      formData.append('name', graphName);
      formData.append('authenticity_token', window.$.rails.csrfToken());

      await fetch(`/${chain}/graph/${id}/duplicate`, {
        method: 'POST',
        body: formData,
      });

      setDuplicationStatus('success');
      toast.show(`New copy has been created.`);
    } catch (error) {
      setDuplicationStatus('error');
      toast.show(`Failed to duplicate ${name}.`);
    } finally {
      onRequestClose();
      window.location.reload();
    }
  };

  return (
    <Modal visible={visible} onRequestClose={onRequestClose}>
      <ModalHeader title="Duplicate graph" />
      <ModalBody>
        <VStack gap={1}>
          <TextInput
            label="Name"
            placeholder="Graph name"
            type="input"
            name="name"
            onChange={(e) => {
              setGraphName(e.target.value);
            }}
          />
        </VStack>
      </ModalBody>

      <ModalFooter
        primaryAction={
          <Button
            onPress={() => duplicateGraph()}
            loading={duplicationStatus === 'loading'}
            disabled={duplicationStatus === 'error'}
          >
            Duplicate
          </Button>
        }
        secondaryAction={
          <Button variant="secondary" onPress={onRequestClose}>
            Cancel
          </Button>
        }
      />
    </Modal>
  );
};

const DeleteGraphAlert = ({
  visible,
  onRequestClose,
  chain,
  name,
  id,
}: OverlayProps) => {
  const toast = useToast();

  const [deletionStatus, setDeletionStatus] = useState<
    'loading' | 'error' | 'success'
  >(null);

  const deleteGraph = async () => {
    setDeletionStatus('loading');
    try {
      await fetch(`/${chain}/graph/${id}`, {
        method: 'DELETE',
      });

      setDeletionStatus('success');
      toast.show(`${name} has been deleted.`);
    } catch (error) {
      setDeletionStatus('error');
      toast.show(`Failed to delete ${name}.`);
    } finally {
      onRequestClose();
      window.location.reload();
    }
  };

  return (
    <Modal visible={visible} onRequestClose={onRequestClose}>
      <ModalHeader title="Delete graph" />
      <ModalBody>
        <TextBody as="p">{`Are you sure you want to delete ${name}?`}</TextBody>
      </ModalBody>

      <ModalFooter
        primaryAction={
          <Button
            variant="negative"
            onPress={() => deleteGraph()}
            loading={deletionStatus === 'loading'}
            disabled={deletionStatus === 'error'}
          >
            Delete
          </Button>
        }
        secondaryAction={
          <Button variant="secondary" onPress={onRequestClose}>
            Cancel
          </Button>
        }
      />
    </Modal>
  );
};

const WalletLabels = ({ wallets }: { wallets: readonly string[] }) => {
  if (wallets.length === 0 || wallets === undefined) {
    return (
      <TextLabel2 as="span" color="foregroundMuted">
        None
      </TextLabel2>
    );
  } else if (wallets.length > 0 && wallets.length <= 5) {
    const topItems = wallets.join(', ');

    return <TextLabel2 as="span">{topItems}</TextLabel2>;
  } else {
    const topItems = wallets
      .slice(0, 5)
      .filter((value, index) => wallets.slice(0, 5).indexOf(value) === index)
      .join(', ');

    return (
      <TextLabel2 as="span">{`${topItems}, +${
        wallets.length - 5
      } more`}</TextLabel2>
    );
  }
};
