import React from 'react';

export const RightChevron: React.FC = (_) => {
  return (
    <span className="glyphicon glyphicon-chevron-right" aria-hidden="true" />
  );
};

export const CollapsedArrow: React.FC = (_) => {
  return (
    <span className="glyphicon glyphicon-triangle-right" aria-hidden="true" />
  );
};

export const ExpandedArrow: React.FC = (_) => {
  return (
    <span className="glyphicon glyphicon-triangle-bottom" aria-hidden="true" />
  );
};

export const Checkmark: React.FC<{ color: string }> = (props) => {
  return (
    <span
      className="glyphicon glyphicon-ok"
      style={{ color: props.color }}
      aria-hidden="true"
    />
  );
};

export const Cross: React.FC<{ color: string }> = (props) => {
  return (
    <span
      className="glyphicon glyphicon-remove"
      style={{ color: props.color }}
      aria-hidden="true"
    />
  );
};

export const Pencil: React.FC<{ color?: string }> = (props) => {
  return (
    <i
      className="glyphicon glyphicon-pencil pointer"
      style={{ color: props.color }}
      aria-hidden="true"
    ></i>
  );
};

export const InfoSign: React.FC<{ color?: string }> = (props) => {
  return (
    <i
      className="glyphicon glyphicon-info-sign"
      style={{ color: props.color }}
      aria-hidden="true"
    ></i>
  );
};
