import { Button } from '@cbhq/cds-web/buttons';
import { TextInput, NativeTextArea } from '@cbhq/cds-web/controls';
import { VStack } from '@cbhq/cds-web/layout';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '@cbhq/cds-web/overlays';
import { useToast } from '@cbhq/cds-web/overlays/useToast';
import React, { useState, useMemo, CSSProperties } from 'react';
import { OverlayProps } from '.';

const EditModal = ({
  visible,
  onRequestClose,
  chain,
  id,
  name,
  description,
}: OverlayProps) => {
  const toast = useToast();

  const [graphName, setGraphName] = useState(name ?? '');
  const [graphDescription, setGraphDescription] = useState(description ?? '');

  const [editStatus, setEditStatus] = useState<'loading' | 'error' | 'success'>(
    null
  );

  const editGraph = async () => {
    setEditStatus('loading');
    try {
      const formData = new FormData();
      formData.append('name', graphName);
      formData.append('desc', graphDescription);
      formData.append('authenticity_token', window.$.rails.csrfToken());
      formData.append('_method', 'put');

      await fetch(`/${chain}/graph/${id}/`, {
        method: 'POST',
        body: formData,
      });

      setEditStatus('success');
      toast.show(`Successfully updated ${name}`);
    } catch (error) {
      setEditStatus('error');
      toast.show(`Failed to update ${name}.`);
    } finally {
      onRequestClose();
      window.location.reload();
    }
  };

  const customNativeTextAreaCSS = useMemo(() => {
    return {
      resize: 'none',
    } as CSSProperties;
  }, []);

  return (
    <Modal visible={visible} onRequestClose={onRequestClose}>
      <ModalHeader title="Edit Graph" />
      <ModalBody>
        <VStack gap={3}>
          <TextInput
            label="Name"
            placeholder="Graph name"
            aria-required="true"
            type="input"
            onChange={(e) => {
              setGraphName(e.target.value);
            }}
            name="name"
            value={graphName}
          />
          <TextInput
            inputNode={
              <NativeTextArea
                style={customNativeTextAreaCSS}
                rows={4}
                name="desc"
                onChange={(e) => {
                  setGraphDescription(e.target.value);
                }}
                value={graphDescription}
              />
            }
            label="Description"
            type="input"
            name="desc"
          />
        </VStack>
      </ModalBody>

      <ModalFooter
        primaryAction={
          <Button
            onPress={() => {
              editGraph();
            }}
            loading={editStatus === 'loading'}
            disabled={editStatus === 'error'}
          >
            Update
          </Button>
        }
        secondaryAction={
          <Button variant="secondary" onPress={onRequestClose}>
            Cancel
          </Button>
        }
      />
    </Modal>
  );
};

export default EditModal;
