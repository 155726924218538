import { useToast } from '@cbhq/cds-web/overlays/useToast';
import React, { useState, Suspense } from 'react';
import Moment from 'moment';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@cbhq/cds-web/overlays';
import { Button } from '@cbhq/cds-web/buttons';
import { ListCell, ListCellFallback } from '@cbhq/cds-web/cells';
import { VStack } from '@cbhq/cds-web/layout';
import { TextBody } from '@cbhq/cds-web/typography';
import { graphsService } from '../../services';
import { graphql, useLazyLoadQuery } from '@cbhq/data-layer';
import {
  GraphsModal_GraphsListQuery,
  GraphsModal_GraphsListQueryResponse,
} from './__generated__/GraphsModal_GraphsListQuery.graphql';
import { NextPageCursorNavigator } from '../../components/NextPageCursorNavigator';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import {
  ISearchResultTypes,
  searchResultTypesMap,
} from './SearchResultsWrapper';

const graphDataQuery = graphql`
  query GraphsModal_GraphsListQuery(
    $after: String!
    $tickerSymbol: TickerSymbol
  ) {
    viewer {
      coinbaseAnalytics {
        graphsForUser(after: $after, tickerSymbol: $tickerSymbol) {
          edges {
            cursor
            node {
              id
              chain
              lastUpdated
              name
              wallets
              categories
              shared
              description
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }
    }
  }
`;

type IGraph =
  GraphsModal_GraphsListQueryResponse['viewer']['coinbaseAnalytics']['graphsForUser']['edges'][0]['node'];

interface IGraphsModalProps {
  visible: boolean;
  onRequestClose: () => void;
  chain: string;
  itemId: string;
  description: string;
  entity: ISearchResultTypes;
  title?: string;
  secondaryActionButtonTitle?: string;
}

export const GraphsModal = ({
  visible,
  onRequestClose,
  itemId,
  chain,
  entity,
  title,
  secondaryActionButtonTitle,
}: IGraphsModalProps) => {
  const [value, setValue] = useState<IGraph>(null);
  const [graphCreationStatus, setGraphCreationStatus] = useState<
    'loading' | 'error' | 'success'
  >(null);
  const [state, setState] = useState({
    currentPageCursor: '',
    currentPage: 1,
  });
  const toast = useToast();

  const navigateToPage = (nextPage: number, nextPageCursor: string) => {
    setState({
      currentPageCursor: nextPageCursor,
      currentPage: nextPage,
    });
  };

  const onNewGraph = async () => {
    setGraphCreationStatus('loading');

    try {
      const { _id: createdGraphId } = await graphsService.newUnamedGraph(chain);

      if (!createdGraphId) {
        throw new Error();
      }

      const { statusCode } = await graphsService.addItemToGraph(
        chain,
        createdGraphId,
        itemId
      );
      console.log({ chain, createdGraphId, itemId });
      if (statusCode !== 204) {
        throw new Error();
      }
      setGraphCreationStatus('success');
      toast.show('Successfully added.', {
        action: {
          label: 'View',
          onPress: () =>
            (window.location.href = `/${chain}/graph/${createdGraphId}`),
        },
      });
    } catch (error) {
      console.log({ error });
      setGraphCreationStatus('error');
      toast.show('Failed to add.');
    } finally {
      onRequestClose();
    }
  };

  const onAddToGraph = async () => {
    setGraphCreationStatus('loading');

    try {
      const { statusCode } = await graphsService.addItemToGraph(
        chain,
        value.id,
        itemId
      );
      if (statusCode !== 204) {
        throw new Error();
      }
      setGraphCreationStatus('success');
      toast.show('Successfully added.', {
        action: {
          label: 'View',
          onPress: () => (window.location.href = `/${chain}/graph/${value.id}`),
        },
      });
    } catch (error) {
      setGraphCreationStatus('error');
      toast.show('Failed to add.');
    } finally {
      onRequestClose();
    }
  };

  const selectGraph = (newValue) => {
    if (value === null) {
      return setValue(newValue);
    }

    if (value !== null && value?.id === newValue?.id) {
      setValue(null);
    } else {
      setValue(newValue);
    }
  };

  const result = useLazyLoadQuery<GraphsModal_GraphsListQuery>(
    graphDataQuery,
    {
      after: state.currentPageCursor,
      tickerSymbol: chain,
    },
    { fetchPolicy: 'network-only' }
  );

  const nextPageCursor =
    result.viewer.coinbaseAnalytics.graphsForUser.pageInfo.endCursor;

  const graphs = result.viewer.coinbaseAnalytics.graphsForUser.edges.map(
    (edge) => edge.node
  );

  return (
    <Modal visible={visible} onRequestClose={onRequestClose}>
      <ModalHeader title="Add to graph" />
      <ModalBody>
        <VStack gap={2}>
          <TextBody spacingStart={3} as="p">
            {title ||
              `Select a graph to add the ${searchResultTypesMap[entity]} to.`}
          </TextBody>

          <VStack gap={0}>
            {graphs.length !== 0 ? (
              graphs.map((item) => {
                return (
                  <ListCell
                    key={item.id}
                    title={item.name}
                    description={Moment(item.lastUpdated).format(
                      'MMM DD, YYYY'
                    )}
                    selected={value?.id === item?.id}
                    onPress={() => {
                      selectGraph(item);
                    }}
                  />
                );
              })
            ) : (
              <ListCell title="You haven’t created any graphs yet." disabled />
            )}
          </VStack>
        </VStack>
        {graphs.length !== 0 && (
          <NextPageCursorNavigator
            useCDS
            currentPage={state.currentPage ? state.currentPage : 1}
            nextPageCursor={nextPageCursor}
            onClick={navigateToPage}
          />
        )}
      </ModalBody>
      <ModalFooter
        primaryAction={
          <Button
            onPress={onAddToGraph}
            disabled={graphs?.length === 0 || value === null}
          >
            Add
          </Button>
        }
        secondaryAction={
          <Button
            variant="secondary"
            onPress={() => {
              onNewGraph();
              onRequestClose();
            }}
            disabled={graphCreationStatus !== null}
          >
            {secondaryActionButtonTitle || 'Add to a new graph'}
          </Button>
        }
      />
    </Modal>
  );
};

export const GraphsModalWrapper = (props: IGraphsModalProps) => {
  return (
    <ErrorBoundary>
      <Suspense fallback={<GraphModalFallback />}>
        <GraphsModal {...props} />
      </Suspense>
    </ErrorBoundary>
  );
};

export const GraphModalFallback = () => {
  return (
    <Modal visible>
      <ModalHeader>Add to graph</ModalHeader>
      <ModalBody>
        <ListCellFallback title description />
        <ListCellFallback title description />
        <ListCellFallback title description />
        <ListCellFallback title description />
        <ListCellFallback title description />
      </ModalBody>
      <ModalFooter
        primaryAction={<Button>Add</Button>}
        secondaryAction={<Button>Add to a new graph</Button>}
      />
    </Modal>
  );
};
