import btcLogo from '../../assets/images/btc.svg';
import bchLogo from '../../assets/images/bch.svg';
import dogeLogo from '../../assets/images/doge.svg';
import ethLogo from '../../assets/images/eth.svg';
import etcLogo from '../../assets/images/etc.svg';
import ltcLogo from '../../assets/images/ltc.svg';
import zecLogo from '../../assets/images/zec.svg';
import dashLogo from '../../assets/images/dash.svg';
import usdtLogo from '../../assets/images/usdt.svg';
import eosLogo from '../../assets/images/eos.svg';
import xlmLogo from '../../assets/images/xlm.svg';
import xrpLogo from '../../assets/images/xrp.svg';
import cgldLogo from '../../assets/images/cgld.svg';
import filLogo from '../../assets/images/fil.svg';
import xflowLogo from '../../assets/images/logo-xflow.svg';

export const ICONS = {
  btc: btcLogo,
  bch: bchLogo,
  doge: dogeLogo,
  eth: ethLogo,
  etc: etcLogo,
  ltc: ltcLogo,
  zec: zecLogo,
  dash: dashLogo,
  usdt: usdtLogo,
  eos: eosLogo,
  xlm: xlmLogo,
  xrp: xrpLogo,
  cgld: cgldLogo,
  fil: filLogo,
};

export const Logo = xflowLogo;
