/**
 * @generated SignedSource<<aa42e459ae6b0477c26d66f53488a0ef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type GraphsModal_GraphsListQuery$variables = {
  after: string;
  tickerSymbol?: any | null;
};
export type GraphsModal_GraphsListQueryVariables = GraphsModal_GraphsListQuery$variables;
export type GraphsModal_GraphsListQuery$data = {
  readonly viewer: {
    readonly coinbaseAnalytics: {
      readonly graphsForUser: {
        readonly edges: ReadonlyArray<{
          readonly cursor: string;
          readonly node: {
            readonly id: string;
            readonly chain: string;
            readonly lastUpdated: any | null;
            readonly name: string;
            readonly wallets: ReadonlyArray<string>;
            readonly categories: ReadonlyArray<string>;
            readonly shared: boolean | null;
            readonly description: string | null;
          } | null;
        }>;
        readonly pageInfo: {
          readonly endCursor: string;
          readonly hasNextPage: boolean;
        };
      } | null;
    };
  };
};
export type GraphsModal_GraphsListQueryResponse = GraphsModal_GraphsListQuery$data;
export type GraphsModal_GraphsListQuery = {
  variables: GraphsModal_GraphsListQueryVariables;
  response: GraphsModal_GraphsListQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tickerSymbol"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Viewer",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CoinbaseAnalytics",
        "kind": "LinkedField",
        "name": "coinbaseAnalytics",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "after",
                "variableName": "after"
              },
              {
                "kind": "Variable",
                "name": "tickerSymbol",
                "variableName": "tickerSymbol"
              }
            ],
            "concreteType": "CoinbaseAnalyticsGraphConnection",
            "kind": "LinkedField",
            "name": "graphsForUser",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CoinbaseAnalyticsGraphEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CoinbaseAnalyticsGraph",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "chain",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastUpdated",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "wallets",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "categories",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "shared",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "description",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GraphsModal_GraphsListQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GraphsModal_GraphsListQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "250dcc7a6c23200c1aa3e0719bdeea1b",
    "id": null,
    "metadata": {},
    "name": "GraphsModal_GraphsListQuery",
    "operationKind": "query",
    "text": "query GraphsModal_GraphsListQuery(\n  $after: String!\n  $tickerSymbol: TickerSymbol\n) {\n  viewer {\n    coinbaseAnalytics {\n      graphsForUser(after: $after, tickerSymbol: $tickerSymbol) {\n        edges {\n          cursor\n          node {\n            id\n            chain\n            lastUpdated\n            name\n            wallets\n            categories\n            shared\n            description\n          }\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2b44085d2ffa47f2af28e8a39e6254ab";

export default node;
