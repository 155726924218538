import { useToggler } from '@cbhq/cds-web';
import { Button } from '@cbhq/cds-web/buttons';
import { SearchInput, TextInput, NativeTextArea } from '@cbhq/cds-web/controls';
import { SelectOption } from '@cbhq/cds-web/controls/SelectOption';
import { VStack, HStack } from '@cbhq/cds-web/layout';
import {
  Modal,
  ModalHeader,
  ModalBody,
  PopoverMenu,
  PopoverTrigger,
  ModalFooter,
} from '@cbhq/cds-web/overlays';
import { useToast } from '@cbhq/cds-web/overlays/useToast';
import { TextBody, TextLabel1, TextCaption } from '@cbhq/cds-web/typography';
import React, {
  useState,
  useRef,
  useContext,
  useEffect,
  useMemo,
  CSSProperties,
  useCallback,
} from 'react';
import { OverlayProps } from '.';
import { PageDataContext } from '../../util/PageDataContext';

const SendGraphModal = ({
  visible,
  onRequestClose,
  name,
  id,
  chain,
}: OverlayProps) => {
  const toast = useToast();

  const [options, setOptions] = useState(null);
  const [isVisible, { toggleOn: openMenu, toggleOff: closeMenu }] =
    useToggler(false);
  const [selectedValue, setSelectedValue] = useState(undefined);
  const [searchValue, setSearchValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(null);
  const [message, setMessage] = useState('');

  const popoverMenuRef = useRef(null);

  const [loadingStatus, setLoadingStatus] = useState<
    'loading' | 'error' | 'success'
  >(null);

  const { userConfigs } = useContext(PageDataContext);

  const loadUsersList = async () => {
    const response = await fetch(`/api/internal/users?asset=${chain}`, {
      method: 'GET',
      credentials: 'include',
    });
    const data = await response.json();

    const sortedUsers = data.result.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    );

    setOptions(sortedUsers);
    setFilteredOptions(sortedUsers);
  };

  useEffect(() => {
    loadUsersList();
  }, []);

  const customNativeTextAreaCSS = useMemo(() => {
    return {
      resize: 'none',
    } as CSSProperties;
  }, []);

  const filterOptions = useMemo(
    () =>
      !searchValue
        ? options
        : options.filter(
            (option) =>
              searchValue &&
              typeof searchValue === 'string' &&
              option.name.toLowerCase().includes(searchValue.toLowerCase())
          ),
    [searchValue]
  );

  useEffect(() => {
    setFilteredOptions(filterOptions);
  }, [filterOptions]);

  const renderOptions = useMemo(
    () =>
      filteredOptions &&
      filteredOptions.map((option) => (
        <SelectOption value={option.name} key={option.id} title={option.name} />
      )),
    [filteredOptions]
  );

  const handleSearchInputPress = useCallback(() => {
    openMenu();
  }, [openMenu]);

  const handleClear = useCallback(() => {
    setSelectedValue(undefined);
    setSearchValue(undefined);
  }, [setSelectedValue]);

  const handleMenuChange = useCallback(
    (newValue) => {
      setSelectedValue(newValue);
      setSearchValue(newValue);
    },
    [setSearchValue, setSelectedValue]
  );

  const handleBlur = useCallback((event) => {
    popoverMenuRef.current.handlePopoverMenuBlur(event);
  }, []);

  const handleKeyDown = useCallback(
    (event) => {
      if (!isVisible) {
        openMenu();
      }
      if (selectedValue) {
        setSelectedValue(undefined);
      }
      if (event.key === 'ArrowDown' || event.key === 'Enter') {
        popoverMenuRef.current.focusSelectOption();
      }
    },
    [selectedValue, setSelectedValue, openMenu, isVisible]
  );

  const sendGraph = async () => {
    setLoadingStatus('loading');
    try {
      const formData = new FormData();
      formData.append('graph_name', name);
      formData.append('from', userConfigs.name);
      formData.append('message', message);
      formData.append('to_email', '');
      formData.append(
        'to_user',
        options.find((user) => user.name === selectedValue).id
      );
      formData.append('authenticity_token', window.$.rails.csrfToken());

      await fetch(`/${chain}/graph/${id}/send_to`, {
        method: 'POST',
        body: formData,
      });

      setLoadingStatus('success');
      toast.show(`A copy of this graph has been sent to ${selectedValue}.`);
    } catch (error) {
      setLoadingStatus('error');
      toast.show(`Failed to send a copy of this graph.`);
    } finally {
      onRequestClose();
      window.location.reload();
    }
  };

  return (
    <Modal visible={visible} onRequestClose={onRequestClose}>
      <ModalHeader title="Send graph" />
      <ModalBody>
        <VStack gap={1}>
          <TextBody as="p">
            Send a copy of {name} to another user on Coinbase Tracer.
          </TextBody>
          <VStack gap={2}>
            <PopoverMenu
              visible={isVisible}
              openMenu={openMenu}
              closeMenu={closeMenu}
              onChange={handleMenuChange}
              value={selectedValue}
              width="100%"
              flush
              searchEnabled
              ref={popoverMenuRef}
            >
              <PopoverTrigger>
                <VStack gap={1} width="100%">
                  <TextLabel1 as="p">Recipient</TextLabel1>
                  <SearchInput
                    borderRadius={8}
                    onChangeText={setSearchValue}
                    value={
                      selectedValue
                        ? selectedValue
                        : searchValue
                        ? searchValue
                        : ''
                    }
                    width="100%"
                    onClear={handleClear}
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}
                    onPress={handleSearchInputPress}
                    placeholder="Search"
                  />
                </VStack>
              </PopoverTrigger>
              {filteredOptions && filteredOptions.length > 0 ? (
                renderOptions
              ) : (
                <HStack spacing={3}>
                  <TextCaption as="p">No options were found. </TextCaption>
                </HStack>
              )}
            </PopoverMenu>

            <TextInput
              inputNode={
                <NativeTextArea
                  style={customNativeTextAreaCSS}
                  rows={4}
                  name="message"
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                  value={message}
                />
              }
              label="Message (OPTIONAL)"
              type="input"
              name="message"
            />
          </VStack>
        </VStack>
      </ModalBody>
      <ModalFooter
        primaryAction={
          <Button
            onPress={() => {
              sendGraph();
            }}
            loading={loadingStatus === 'loading'}
            disabled={selectedValue === undefined || loadingStatus === 'error'}
          >
            Send
          </Button>
        }
        secondaryAction={
          <Button variant="secondary" onPress={onRequestClose}>
            Cancel
          </Button>
        }
      />
    </Modal>
  );
};

export default SendGraphModal;
