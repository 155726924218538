import React, { useEffect } from 'react';
import { VStack } from '@cbhq/cds-web/layout';
import PortalList, { List, listItemType } from './PortalList';
import { useLocalStorage } from 'usehooks-ts';
import {
  ALL_NETWORKS,
  DEFAULT_NETWORK,
  NetworkFilter,
} from '../../components/filters/NetworkFilter';

const exploreList: (name: string, symbol: string, chainClass: any) => List = (
  name,
  symbol,
  chainClass
) => {
  const blocksTerm = !['fil'].includes(symbol) ? 'Blocks' : 'Tipsets';
  const showAddresses = !['AccountBased'].includes(chainClass);
  const transactionsTerm = !['fil'].includes(symbol)
    ? 'Transactions'
    : 'Messages';
  const showStatistics = !['AccountBased'].includes(chainClass);
  const showOperations = ['AccountBased'].includes(chainClass);
  const operationsTerm = !['fil'].includes(symbol)
    ? 'Operations'
    : 'Transactions';
  let explore = [];

  if (showStatistics) {
    explore.push({
      id: 'id',
      route: `${symbol}/statistics`,
      type: listItemType.CELL,
      title: `${name} Statistics`,
      iconName: 'chartBar',
      detail:
        'Summary data about wallets, blocks, transactions, addresses, entities and more',
    });
  }
  explore = explore.concat([
    {
      id: 'id',
      route: `${symbol}/blocks`,
      type: listItemType.CELL,
      title: `Recent ${name} ${blocksTerm}`,
      iconName: 'blockchain',
      detail: `${blocksTerm} in chronological order`,
    },
    {
      id: 'id',
      route: `${symbol}/wallets`,
      type: listItemType.CELL,
      title: `${name} Wallets`,
      iconName: 'wallet',
      detail: 'Wallets active in the last 24 hours',
    },
    {
      id: 'id',
      route: `${symbol}/transactions`,
      type: listItemType.CELL,
      title: `${name} ${transactionsTerm}`,
      iconName: 'wireTransfer',
      detail: `${transactionsTerm} made in the last 24 hours`,
    },
  ]);

  if (showOperations) {
    explore.push({
      id: 'id',
      route: `${symbol}/operations`,
      type: listItemType.CELL,
      title: `${name} ${operationsTerm}`,
      iconName: 'wireTransfer',
      detail: `${operationsTerm} used in the last 24 hours`,
    });
  }

  if (showAddresses) {
    explore.push({
      id: 'id',
      route: `${symbol}/addresses`,
      type: listItemType.CELL,
      title: `${name} Addresses`,
      iconName: 'identityCard',
      detail: 'Addresses used in the last 24 hours',
    });
  }

  return explore;
};

const buterinList: (name: string, symbol: string) => List = (name, symbol) => {
  return [
    {
      id: 'id',
      type: listItemType.SECTION,
      label: 'Ethereum-specific categories',
    },
    {
      id: 'id',
      route: `${symbol}/uncles`,
      type: listItemType.CELL,
      title: `${name} Uncles`,
      iconName: 'fork',
      detail: 'Uncles found in the last 24 hours',
    },
    {
      id: 'id',
      route: `${symbol}/contracts`,
      type: listItemType.CELL,
      title: `${name} Contracts`,
      iconName: 'smartContract',
      detail: 'Contracts created in the last 24 hours',
    },
    {
      id: 'id',
      route: `${symbol}/tokens`,
      type: listItemType.CELL,
      title: `${name} Tokens List`,
      iconName: 'currencies',
      detail: `Top 500 ERC-20 Tokens by Market Cap`,
    },
    {
      id: 'id',
      route: `${symbol}/tokens/transactions`,
      type: listItemType.CELL,
      title: `${name} ERC20 Transactions`,
      iconName: 'nft',
      detail: 'ERC20 Transactions made in the last 24 hours',
    },
  ];
};

const Explore = ({
  chainConfigs,
}: {
  chainConfigs: { configs: any; chains: any[] };
}) => {
  const [localStorage, setLocalStorage] = useLocalStorage(
    'currentChain',
    (undefined || ALL_NETWORKS) ?? DEFAULT_NETWORK
  );

  useEffect(() => {
    if (localStorage === ALL_NETWORKS) {
      setLocalStorage(DEFAULT_NETWORK);
    }
  }, []);

  return (
    <VStack gap={3} spacingTop={3} spacingHorizontal={0}>
      <NetworkFilter
        showAllNetworks={false}
        chainConfigs={chainConfigs}
        chain={localStorage === ALL_NETWORKS ? DEFAULT_NETWORK : localStorage}
        onChange={setLocalStorage}
      />

      <hr className="portal-page-divider" />
      <PortalList
        list={exploreList(
          chainConfigs.configs[
            localStorage === ALL_NETWORKS ? DEFAULT_NETWORK : localStorage
          ].name,
          localStorage,
          chainConfigs.configs[localStorage]?.class
        )}
      />
      {chainConfigs.configs[localStorage]?.class === 'Buterin' && (
        <PortalList
          list={buterinList(
            chainConfigs.configs[
              localStorage === ALL_NETWORKS ? DEFAULT_NETWORK : localStorage
            ].name,
            localStorage
          )}
        />
      )}
    </VStack>
  );
};

export default Explore;
