import React from 'react';
import { Link, useLocation } from 'react-router-dom';

export const ChainLink: React.FC<{ to: string; children: React.ReactNode }> = ({
  to,
  children,
}) => {
  const location = useLocation();
  const chain = location.pathname.split('/')[1];
  const toLocation = `/${chain}${to}`;

  const destination = to.split('/')[1];
  const allowedSections = ['blocks', 'transactions'];
  if (allowedSections.includes(destination)) {
    return <Link to={toLocation}>{children}</Link>;
  } else {
    return <a href={toLocation}>{children}</a>;
  }
};
