import { useToggler } from '@cbhq/cds-web';
import { SearchInput } from '@cbhq/cds-web/controls/SearchInput';
import { Box, Divider, HStack, Spacer, VStack } from '@cbhq/cds-web/layout';
import { PopoverMenu, PopoverTrigger } from '@cbhq/cds-web/overlays';
import { graphql, useLazyLoadQuery } from '@cbhq/data-layer';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useWindowWidth } from '@react-hook/window-size';
import { TextLabel1, TextLegal } from '@cbhq/cds-web/typography';
import { Pressable } from '@cbhq/cds-web/system';
import { Icon } from '@cbhq/cds-web/icons';
import { ICONS } from '../../util/Icons';
import { MultichainSearchInput_MultichainSearchQuery } from './__generated__/MultichainSearchInput_MultichainSearchQuery.graphql';
import { abbreviateHash } from '../../util/StringUtils';
import { SelectOption } from '@cbhq/cds-web/controls';

export const multichainSearchQuery = graphql`
  query MultichainSearchInput_MultichainSearchQuery($query: String!) {
    viewer {
      coinbaseAnalytics {
        multichainSearch(query: $query) {
          autocomplete {
            asset
            names
            addresses {
              acceptable
              minLength
              triggered
              matches
            }
          }
        }
      }
    }
  }
`;

const MultichainSearchInput = ({ setSearchValue, searchValue, queryArgs }) => {
  const popoverMenuRef = useRef(null);
  const path = window.location.pathname;

  const [isVisible, { toggleOn: openMenu, toggleOff: closeMenu }] =
    useToggler(false);
  const placeholder = 'Search for an address, id, hash, or name';

  const [selectedValue, setSelectedValue] = useState(undefined);

  useEffect(() => {
    if (path.includes('/portal/search')) {
      const urlParams = new URLSearchParams(window.location.search);
      const searchQuery = urlParams.get('query');

      setSearchValue(searchQuery);
    }
  }, []);

  /* Data Fetching */
  const data = useLazyLoadQuery<MultichainSearchInput_MultichainSearchQuery>(
    multichainSearchQuery,
    queryArgs.variables,
    queryArgs.options
  );

  /* Populating PopoverMenu */
  const multichainData = useMemo(
    () =>
      data?.viewer.coinbaseAnalytics.multichainSearch?.autocomplete.length > 0
        ? data.viewer.coinbaseAnalytics.multichainSearch?.autocomplete
        : [],

    [data]
  );

  const cleanedData = useMemo(() => {
    const names = multichainData.map((chainData) =>
      chainData.names.map((name) => ({
        asset: chainData.asset,
        isAddress: false,
        label: name,
      }))
    );

    const addresses = multichainData.map((chainData) =>
      chainData.addresses.matches?.map((address) => ({
        asset: chainData.asset,
        isAddress: true,
        label: address,
      }))
    );

    return [...names, ...addresses].flat(2).filter((item) => item);
  }, [multichainData]);

  const renderAutocompleteOptions = useMemo(
    () =>
      cleanedData && [
        cleanedData.map((chainData, index) => (
          <>
            <SelectOption
              onPress={() => {
                window.location.href = chainData?.isAddress
                  ? `/${chainData?.asset}/addresses/${chainData?.label}`
                  : `/${chainData?.asset}/metadata?search=${chainData?.label}`;
              }}
              value={chainData?.label}
              key={index}
              title={
                <TextLegal as="p" color="foregroundMuted" mono={true}>
                  {chainData?.isAddress
                    ? abbreviateHash(chainData?.label)
                    : chainData?.label}
                </TextLegal>
              }
              intermediary={
                <TextLegal as="p" color="foregroundMuted">
                  {chainData?.isAddress ? 'ADDRESS' : 'WALLET'}
                </TextLegal>
              }
              compact
              media={
                <img className="chainlogo-md" src={ICONS[chainData?.asset]} />
              }
            />
          </>
        )),
      ],

    [cleanedData]
  );

  /* PopoverMenu Actions */
  const handleKeyDown = useCallback(
    (event) => {
      if (!isVisible && searchValue.trim().length > 2) {
        openMenu();
      }

      if (selectedValue) {
        setSelectedValue(undefined);
      }

      if (event.key === 'ArrowDown') {
        popoverMenuRef.current.focusSelectOption();
      }

      if (event.key === 'Enter') {
        setSelectedValue(searchValue);
        window.location.href = `/portal/search?query=${searchValue}`;
      }
    },
    [
      searchValue,
      setSearchValue,
      selectedValue,
      setSelectedValue,
      openMenu,
      isVisible,
    ]
  );

  const handleClear = useCallback(() => {
    setSelectedValue(undefined);
    setSearchValue(undefined);
  }, [setSelectedValue]);

  const handleMenuChange = useCallback(
    (newValue) => {
      setSelectedValue(newValue);
      setSearchValue(newValue);
    },
    [setSearchValue, setSelectedValue]
  );

  const handleSearchInputPress = useCallback(() => {
    openMenu();
  }, [openMenu]);

  const handleBlur = useCallback((event) => {
    popoverMenuRef.current.handlePopoverMenuBlur(event);
  }, []);

  // Prevent navbar from crashing in case search fails
  // if (data.viewer.coinbaseAnalytics.multichainSearch == null) return <></>;

  return (
    <PopoverMenu
      visible={isVisible}
      openMenu={openMenu}
      closeMenu={closeMenu}
      onChange={handleMenuChange}
      value={selectedValue}
      searchEnabled
      ref={popoverMenuRef}
    >
      <PopoverTrigger>
        <SearchInput
          compact
          width={0.3 * useWindowWidth()}
          placeholder={placeholder}
          onChangeText={setSearchValue}
          value={selectedValue ? selectedValue : searchValue ? searchValue : ''}
          onClear={handleClear}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          onPress={handleSearchInputPress}
        />
      </PopoverTrigger>
      {searchValue.trim().length > 0 ? (
        <VStack position="relative" gap={0.5}>
          {multichainData && renderAutocompleteOptions}
          <SeeAllResults key="see-all-results" searchValue={searchValue} />
        </VStack>
      ) : (
        <AdvancedFilterOption key="advanced-filter-option" />
      )}
    </PopoverMenu>
  );
};

const AdvancedFilterOption = () => {
  return (
    <Pressable
      onPress={() => {
        // Is redirected to bitcoin advanced search
        window.location.href = `/btc/search/advanced_search`;
      }}
      noScaleOnPress
      backgroundColor="background"
    >
      <HStack
        alignItems="center"
        spacingVertical={1}
        spacingHorizontal={2}
        gap={1}
      >
        <span>
          <TextLabel1 as="span">
            Looking for more powerful filters? Try
          </TextLabel1>{' '}
          <TextLabel1 as="span" color="primary">
            advanced search.
          </TextLabel1>
        </span>

        <Spacer />
        <Icon name="caretRight" size="xs" color="foregroundMuted" />
      </HStack>
    </Pressable>
  );
};

const SeeAllResults = ({ searchValue }) => {
  return (
    <Pressable
      style={{
        position: 'sticky',
        width: '100%',
        bottom: 0,
        left: 0,
        right: 0,
      }}
      onPress={() => {
        window.location.href = `/portal/search?query=${searchValue}`;
      }}
      noScaleOnPress
      backgroundColor="background"
    >
      <Divider key="see-all-results-divider" />
      <HStack
        alignItems="center"
        spacingVertical={1}
        spacingHorizontal={2}
        gap={1}
      >
        <TextLabel1 as="span">See all results </TextLabel1> <Spacer />
        <Icon name="caretRight" size="xs" color="foregroundMuted" />
      </HStack>
    </Pressable>
  );
};

export default MultichainSearchInput;
