import React, { useState } from 'react';
import Moment from 'moment';
import { graphql } from '@cbhq/data-layer';
import { useFragment } from 'react-relay';
import { useLocation } from 'react-router-dom';
import { DemixerTooltip } from './DemixerTooltip';
import { addHoursToDate } from '../util/TimeFormatter';

const transactionInputsFragment = graphql`
  fragment DemixToolInputsFragment on CoinbaseAnalyticsTransaction {
    hash
    time
    demixerType
    operations {
      inputs {
        address
      }
    }
  }
`;
export const DemixTool = ({ transactionReference }) => {
  const data = useFragment(transactionInputsFragment, transactionReference);
  const txHash = data.hash;
  const txTime = data.time;
  const location = useLocation();
  const chain = location.pathname.split('/')[1];
  // BTC has only 1 operation and we dont demix other chains, so it is what it is
  const addresses = data.operations[0].inputs.map((input) => input.address);
  const url = `/${chain}/transactions/${txHash}/demix_sharedsend`;

  const demixerType = data.demixerType;
  let demixModal, target, demixUrl;

  if (['sharedsend', 'wasabi'].includes(demixerType)) {
    demixModal = <DemixSharedSend addresses={addresses} url={url} />;
    target = '.demix-sharedsend-parameters';
  } else if (demixerType === 'joinmarket') {
    demixModal = <DemixDefault chain={chain} txHash={txHash} />;
    target = '.demix-parameters';
  } else if (demixerType === 'wallet_demix') {
    demixModal = (
      <DemixWalletParameters chain={chain} txHash={txHash} txTime={txTime} />
    );
    target = '.demix-wallet-parameters';
  } else {
    demixUrl = `/${chain}/transactions/${txHash}/demix_general`;
  }

  return (
    <>
      <DemixerTooltip demixerType={demixerType} />
      <a
        data-effect="solid"
        data-toggle="modal"
        data-target={target}
        data-tip="necessary for react-tooltip"
        data-for="tool-demixer"
        data-place="top"
        target="_blank"
        className="btn btn-block btn-basic"
        href={demixUrl}
        rel="noreferrer"
      >
        Demix Transaction
      </a>
      {demixModal}
    </>
  );
};

const DemixDefault = ({ chain, txHash }) => {
  const [selectedAnalysisType, setSelectedAnalysisType] = useState('fast');
  return (
    <div className="modal fade demix-parameters">
      <div className="modal-dialog modal-sm" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 className="modal-title">Demixing Type</h4>
          </div>
          <div className="modal-body">
            <form id="demix-parameters">
              <div className="form-group row">
                <div className="col-xs-12">
                  <select
                    className="form-control input-sm"
                    name="mode"
                    id="analysis_type"
                    onChange={(e) => {
                      setSelectedAnalysisType(e.target.value);
                    }}
                  >
                    <option
                      selected={selectedAnalysisType === 'fast'}
                      value="fast"
                    >
                      Fast analysis
                    </option>
                    <option
                      selected={selectedAnalysisType === 'normal'}
                      value="normal"
                    >
                      Thorough analysis
                    </option>
                  </select>
                </div>
              </div>
            </form>
            <div className="small text-justify help-text" id="help-text"></div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <a
              target="_blank"
              href={`/${chain}/transactions/${txHash}/demix?mode=${selectedAnalysisType}`}
              type="button"
              className="btn btn-primary"
              rel="noreferrer"
            >
              Demix!
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const DemixSharedSend = ({ addresses, url }) => (
  <div className="modal fade demix-sharedsend-parameters">
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 className="modal-title">Inputs to follow</h4>
        </div>
        <div className="modal-body">
          <form id="demix-sharedsend-parameters" method="get" action={url}>
            <input type="hidden" name="tab" value="demixed" />
            <fieldset>
              {addresses.map((address, index) => (
                <div key={address}>
                  <input
                    type="checkbox"
                    name={`input${index}`}
                    id={`input${index}`}
                    value={address}
                  />
                  <label htmlFor={`input${index}`} className="monospace small">
                    &nbsp;{address}
                  </label>
                  <br />
                </div>
              ))}
            </fieldset>
          </form>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Close
          </button>
          <button
            type="submit"
            className="btn btn-primary"
            form="demix-sharedsend-parameters"
          >
            Demix!
          </button>
        </div>
      </div>
    </div>
  </div>
);

const DemixWalletParameters = ({ chain, txHash, txTime }) => {
  const demixUrl = `/${chain}/transactions/${txHash}/demix_general`;
  const txDate: Date = new Date(txTime);
  const format = 'DD/MM/YYYY HH:mm:ss';
  const timeFrames = {
    fmindate: addHoursToDate(txDate, -6).format(format),
    fmaxdate: addHoursToDate(txDate, 18).format(format),
    wmindate: addHoursToDate(txDate, 0).format(format),
    wmaxdate: addHoursToDate(txDate, 24).format(format),
  };
  return (
    <div className="modal fade demix-wallet-parameters">
      <div className="modal-dialog modal-md" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 className="modal-title">Timeframe</h4>
          </div>
          <div className="modal-body">
            <form id="demix-wallet-parameters" method="get" action={demixUrl}>
              <input type="hidden" name="tab" value="demixed" />
              <div className="form-group row">
                <label className="col-md-3 col-form-label form-label">
                  Funding from:
                </label>
                <div className="col-md-4">
                  <div className="input-group input-group-sm datetimepicker">
                    <input
                      type="text"
                      className="form-control"
                      name="fmindate"
                      value={timeFrames.fmindate}
                    />
                    <span className="input-group-addon">
                      <span className="fa fa-calendar pointer"></span>
                    </span>
                  </div>
                </div>
                <label className="col-md-1 col-form-label form-label">
                  to:
                </label>
                <div className="col-md-4">
                  <div className="input-group input-group-sm datetimepicker">
                    <input
                      type="text"
                      className="form-control"
                      name="fmaxdate"
                      value={timeFrames.fmaxdate}
                    />
                    <span className="input-group-addon">
                      <span className="fa fa-calendar pointer"></span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-md-3 col-form-label form-label">
                  Withdrawal from:
                </label>
                <div className="col-md-4">
                  <div className="input-group input-group-sm datetimepicker">
                    <input
                      type="text"
                      className="form-control"
                      name="wmindate"
                      value={timeFrames.wmindate}
                    />
                    <span className="input-group-addon">
                      <span className="fa fa-calendar pointer"></span>
                    </span>
                  </div>
                </div>
                <label className="col-md-1 col-form-label form-label">
                  to:
                </label>
                <div className="col-md-4">
                  <div className="input-group input-group-sm datetimepicker">
                    <input
                      type="text"
                      className="form-control"
                      name="wmaxdate"
                      value={timeFrames.wmaxdate}
                    />
                    <span className="input-group-addon">
                      <span className="fa fa-calendar pointer"></span>
                    </span>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              form="demix-wallet-parameters"
            >
              Demix!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
