import { useToast } from '@cbhq/cds-web/overlays/useToast';
import { bookmarksService } from '../../services';

const useBookmark = () => {
  const toast = useToast();

  const bookmark = async (
    chain: string,
    ref: string,
    name?: string
  ): Promise<void> => {
    const response = await bookmarksService.bookmarkItem(chain, ref, name);
    if (response.status == 'ok') {
      toast.show('Bookmarked', {
        action: {
          label: 'View',
          onPress: () => (window.location.href = `/${chain}/watchlists`),
        },
      });
    } else {
      if (response.message.includes('Item already watched')) {
        toast.show('Already bookmarked');
      } else {
        toast.show('Failed to bookmark');
      }
    }
  };

  return bookmark;
};

export default useBookmark;
