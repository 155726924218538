import { useToggler } from '@cbhq/cds-web';
import { SearchInput } from '@cbhq/cds-web/controls/SearchInput';
import { SelectOption } from '@cbhq/cds-web/controls/SelectOption';
import { Divider } from '@cbhq/cds-web/layout';
import {
  PopoverMenu,
  PopoverTrigger,
  SectionTitle,
} from '@cbhq/cds-web/overlays';
import { graphql, useLazyLoadQuery } from '@cbhq/data-layer';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { SearchInputMenu_AutocompleteSearchQuery } from './__generated__/SearchInputMenu_AutocompleteSearchQuery.graphql';
import { useWindowWidth } from '@react-hook/window-size';

export const autocompleteSearchQuery = graphql`
  query SearchInputMenu_AutocompleteSearchQuery(
    $query: String!
    $tickerSymbol: TickerSymbol!
  ) {
    viewer {
      coinbaseAnalytics {
        autocompleteSearch(query: $query, tickerSymbol: $tickerSymbol) {
          names
          addresses {
            acceptable
            minLength
            triggered
            matches
          }
        }
      }
    }
  }
`;

const SearchInputMenu = ({
  tickerSymbol,
  setSearchValue,
  searchValue,
  queryArgs,
}) => {
  const popoverMenuRef = useRef(null);
  const [isVisible, { toggleOn: openMenu, toggleOff: closeMenu }] =
    useToggler(false);
  const placeholder =
    'Search ' + tickerSymbol.toUpperCase() + ' for addresses and wallets';

  const [selectedValue, setSelectedValue] = useState(undefined);

  const data = useLazyLoadQuery<SearchInputMenu_AutocompleteSearchQuery>(
    autocompleteSearchQuery,
    queryArgs.variables,
    queryArgs.options
  );

  const nameOptions = useMemo(
    () =>
      data?.viewer.coinbaseAnalytics.autocompleteSearch?.names.length > 0
        ? data.viewer.coinbaseAnalytics.autocompleteSearch?.names
        : [],
    [data]
  );

  const addressOptions = useMemo(
    () =>
      data?.viewer.coinbaseAnalytics.autocompleteSearch?.addresses.matches
        .length > 0
        ? data.viewer.coinbaseAnalytics.autocompleteSearch?.addresses.matches
        : [],
    [data]
  );

  const renderNameOptions = useMemo(
    () =>
      nameOptions &&
      nameOptions.map((option, key) => (
        <SelectOption
          key={key}
          compact
          title={option}
          value={option}
          //@ts-ignore unsupported prop by CDS
          to={`/${tickerSymbol}/metadata?search=${option}`}
        />
      )),
    [nameOptions]
  );

  const renderAddressOptions = useMemo(
    () =>
      addressOptions &&
      addressOptions.map((option, key) => (
        <SelectOption
          key={key}
          compact
          title={option}
          value={option}
          //@ts-ignore unsupported prop by CDS
          to={`/${tickerSymbol}/addresses/${option}`}
        />
      )),
    [addressOptions]
  );

  const nameResultText = useMemo(
    () => (nameOptions.length == 1 ? 'result' : 'results'),
    [nameOptions]
  );

  const addressResultText = useMemo(
    () => (addressOptions.length == 1 ? 'result' : 'results'),
    [addressOptions]
  );

  const handleKeyDown = useCallback(
    (event) => {
      if (!isVisible && searchValue.trim().length > 2) {
        openMenu();
      }

      if (selectedValue) {
        setSelectedValue(undefined);
      }
      if (event.key === 'ArrowDown') {
        popoverMenuRef.current.focusSelectOption();
      }
      if (event.key === 'Enter') {
        window.location.href = `/${tickerSymbol}/search?q=${searchValue}`;
      }
    },
    [selectedValue, setSelectedValue, openMenu, isVisible, searchValue]
  );

  const handleClear = useCallback(() => {
    setSelectedValue(undefined);
    setSearchValue(undefined);
  }, [setSelectedValue]);

  const handleMenuChange = useCallback(
    (newValue) => {
      setSelectedValue(newValue);
      setSearchValue(newValue);
    },
    [setSearchValue, setSelectedValue]
  );

  const handleSearchInputPress = useCallback(() => {
    openMenu();
  }, [openMenu]);

  const handleBlur = useCallback((event) => {
    popoverMenuRef.current.handlePopoverMenuBlur(event);
  }, []);

  // if (data.viewer.coinbaseAnalytics.autocompleteSearch == null) return <></>;

  return (
    <PopoverMenu
      visible={isVisible}
      openMenu={openMenu}
      closeMenu={closeMenu}
      onChange={handleMenuChange}
      value={selectedValue}
      searchEnabled
      ref={popoverMenuRef}
    >
      <PopoverTrigger>
        <SearchInput
          compact
          width={0.3 * useWindowWidth()}
          height={44}
          //@ts-ignore unsupported prop by CDS
          borderRadius={8}
          placeholder={placeholder}
          onChangeText={setSearchValue}
          value={selectedValue ? selectedValue : searchValue ? searchValue : ''}
          onClear={handleClear}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          onPress={handleSearchInputPress}
        />
      </PopoverTrigger>

      {searchValue.trim().length > 2 && [
        <SectionTitle
          key="search-input-menu-section-title-list-of-wallets"
          text={
            tickerSymbol +
            ' Wallets (' +
            nameOptions.length +
            ' ' +
            nameResultText +
            ' found)'
          }
        />,
        nameOptions && nameOptions.length > 0 && renderNameOptions,
        nameOptions && addressOptions && <Divider direction="horizontal" />,

        addressOptions && (
          <SectionTitle
            key="search-input-menu-section-title-list-of-addresses"
            text={
              tickerSymbol +
              ' Addresses (' +
              addressOptions.length +
              ' ' +
              addressResultText +
              ' found)'
            }
          />
        ),
        addressOptions && addressOptions.length > 0 && renderAddressOptions,
      ]}
    </PopoverMenu>
  );
};

export default SearchInputMenu;
