/**
 * @generated SignedSource<<5307fd4c519fa94be24f7f4ad7273248>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SearchInputMenu_AutocompleteSearch$data = {
  readonly names: ReadonlyArray<string | null> | null;
  readonly addresses: {
    readonly acceptable: boolean | null;
    readonly minLength: number | null;
    readonly triggered: boolean | null;
    readonly matches: ReadonlyArray<string | null> | null;
  } | null;
  readonly " $fragmentType": "SearchInputMenu_AutocompleteSearch";
};
export type SearchInputMenu_AutocompleteSearch = SearchInputMenu_AutocompleteSearch$data;
export type SearchInputMenu_AutocompleteSearch$key = {
  readonly " $data"?: SearchInputMenu_AutocompleteSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SearchInputMenu_AutocompleteSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SearchInputMenu_AutocompleteSearch",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "names",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CoinbaseAnalyticsAutocompleteSearchAddress",
      "kind": "LinkedField",
      "name": "addresses",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "acceptable",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "minLength",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "triggered",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "matches",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CoinbaseAnalyticsAutocompleteSearch",
  "abstractKey": null
};

(node as any).hash = "fd0b994addfc7a4079256c6c8d469941";

export default node;
