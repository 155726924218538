import React, { useEffect } from 'react';
import { ICONS, Logo } from '../util/Icons';
import InitUserLeap from '../util/InitUserLeap';

/* eslint react/jsx-key: 0 */

// The top navigation bar that persists across all pages.
const TopNavBar = ({
  chain,
  chainConfigs,
  userConfigs,
  userleapEnvId,
  flags = { search: true },
}) => {
  // console.log(chainConfigs)
  // console.log(userConfigs)
  // console.log(flags)

  const chainClass = chainConfigs.configs[chain].class;

  const showSearch = userConfigs.show_search && flags.search;

  // Explorer Menu
  const blocksTerm = !['fil'].includes(chain) ? 'Blocks' : 'Tipsets';

  const showAddresses = !['AccountBased'].includes(chainClass);

  const transactionsTerm = !['fil'].includes(chain)
    ? 'Transactions'
    : 'Messages';

  const showOperations = ['AccountBased'].includes(chainClass);
  const operationsTerm = !['fil'].includes(chain)
    ? 'Operations'
    : 'Transactions';

  const showStatistics = !['AccountBased'].includes(chainClass);

  // Tools Menu
  const showWebSearch = !['AccountBased', 'Usdt'].includes(chainClass);
  const showAdvancedSearch = !['AccountBased'].includes(chainClass);
  const showMetadataImporter =
    userConfigs.permissions.tags &&
    !['AccountBased', 'Usdt'].includes(chainClass);

  // Cases Menu
  const casesTerm = userConfigs.license.type === 'gov' ? 'Cases' : 'Clients';

  // Setup jQuery binding for search etc
  useEffect(() => {
    if (showSearch) {
      xflow.bootstrapComponents();
    }
  });

  return (
    <>
      <InitUserLeap userleapEnvId={userleapEnvId} userId={userConfigs.id} />
      <nav className="navbar navbar-fixed-top navbar-default" role="navigation">
        <div className="container-fluid">
          {/*mobile view toggler*/}
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#collapse"
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar" />
              <span className="icon-bar" />
              <span className="icon-bar" />
            </button>
          </div>

          {/*Navbar*/}
          <div className="collapse navbar-collapse" id="collapse">
            {/*Navbar Left*/}
            <ul className="nav navbar-nav">
              {/*Chain Dropdown Menu*/}
              <li className="dropdown">
                <a
                  href="#"
                  className="navbar-brand dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img className="chainlogo-sm" src={ICONS[chain]} />
                  <span className="sr-only">Toggle Dropdown</span>
                </a>
                <ul className="dropdown-menu">
                  {chainConfigs.chains.map(
                    (c) =>
                      chainConfigs.configs[c].enabled && (
                        <li key={c}>
                          <a href={`/${c}`}>
                            <img className="chainlogo-sm" src={ICONS[c]} />{' '}
                            {chainConfigs.configs[c].name}
                          </a>
                        </li>
                      )
                  )}
                </ul>
              </li>

              {/*CA logo*/}
              <li>
                <a href={`/${chain}`} className="navbar-brand">
                  <img style={{ height: '28px', width: 'auto' }} src={Logo} />
                </a>
              </li>

              {/*Search Bar*/}
              {showSearch && (
                <li>
                  <form
                    className="navbar-form navbar-right"
                    role="search"
                    action={`/${chain}/search`}
                    method="get"
                    id="navBarSearchForm"
                  >
                    <div className="input-group">
                      <div className="input-group-btn">
                        <button className="btn btn-default" type="submit">
                          <i className="glyphicon glyphicon-search"></i>
                        </button>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        name="q"
                        id="q"
                        autoComplete="off"
                        data-autocomplete-path={`/${chain}/search/autocomplete`}
                      />
                    </div>
                  </form>
                </li>
              )}
            </ul>

            {/*Navbar Right*/}
            <ul className="nav navbar-nav navbar-right">
              {/*Explorer Dropdown Menu*/}
              <li className="dropdown">
                <a
                  href="#"
                  className="dropdown-toggle"
                  data-toggle="dropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Explorer <span className="caret"></span>
                </a>
                <ul className="dropdown-menu">
                  {showStatistics && (
                    <li>
                      <a href={`/${chain}/statistics`}>Statistics</a>
                    </li>
                  )}
                  <li>
                    <a href={`/${chain}/blocks`}>{blocksTerm}</a>
                  </li>
                  <li>
                    <a href={`/${chain}/wallets`}>Wallets</a>
                  </li>
                  {showAddresses && (
                    <li>
                      <a href={`/${chain}/addresses`}>Addresses</a>
                    </li>
                  )}
                  <li>
                    <a href={`/${chain}/transactions`}>{transactionsTerm}</a>
                  </li>
                  {showOperations && (
                    <li>
                      <a href={`/${chain}/operations`}>{operationsTerm}</a>
                    </li>
                  )}

                  {chainClass === 'Buterin' && [
                    <li
                      key="buterin-list-item-1"
                      role="separator"
                      className="divider"
                    />,
                    <li key="buterin-list-item-2">
                      <a href={`/${chain}/uncles`}>Uncles</a>
                    </li>,
                    <li key="buterin-list-item-3">
                      <a href={`/${chain}/contracts`}>Contracts</a>
                    </li>,
                    <li key="buterin-list-item-4">
                      <a href={`/${chain}/tokens`}>Tokens List</a>
                    </li>,
                    <li key="buterin-list-item-5">
                      <a href={`/${chain}/tokens/transactions`}>
                        ERC20 Transactions
                      </a>
                    </li>,
                  ]}
                </ul>
              </li>

              {/*Tools Dropdown Menu*/}
              <li className="dropdown">
                <a
                  href="#"
                  className="dropdown-toggle"
                  data-toggle="dropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Tools <span className="caret"></span>
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a href={`/${chain}/graph/new`} id="newGraphLink">
                      New graph
                    </a>
                  </li>
                  <li>
                    <a href={`/${chain}/graphs`}>My graphs</a>
                  </li>
                  <li role="separator" className="divider" />
                  <li>
                    <a href={`/${chain}/custom_wallets`}>Custom Wallets</a>
                  </li>
                  <li role="separator" className="divider" />
                  <li>
                    <a href={`/${chain}/batch_risks/new`}>Batch Risk Score</a>
                  </li>
                  {showAdvancedSearch ||
                    showWebSearch ||
                    (showMetadataImporter && (
                      <li role="separator" className="divider" />
                    ))}
                  {showAdvancedSearch && (
                    <li>
                      <a href={`/${chain}/search/advanced_search`}>
                        Advanced search
                      </a>
                    </li>
                  )}
                  {showWebSearch && (
                    <li>
                      <a href={`/${chain}/crawler_jobs/new`}>Web search</a>
                    </li>
                  )}
                  {showMetadataImporter && (
                    <li>
                      <a href={`/${chain}/metadata_bulk_importer/new`}>
                        Import metadata
                      </a>
                    </li>
                  )}
                </ul>
              </li>

              {/*Cases Dropdown Menu*/}
              <li className="dropdown">
                <a
                  href="#"
                  className="dropdown-toggle"
                  data-toggle="dropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {casesTerm} <span className="caret" />
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a href={`/${chain}/cases`}>{casesTerm}</a>
                  </li>
                  <li>
                    <a href={`/${chain}/watchlists`}>Bookmarks</a>
                  </li>
                </ul>
              </li>

              {/*Profile Dropdown Menu*/}
              <li className="dropdown" id="logged-in-as">
                <a
                  href="#"
                  className="dropdown-toggle"
                  data-toggle="dropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <b>{userConfigs.name}</b> <span className="caret" />{' '}
                  {userConfigs.running_jobs > 0 && (
                    <span className="label label-default label-as-badge label-primary">
                      {userConfigs.running_jobs}
                    </span>
                  )}
                </a>

                <ul className="dropdown-menu">
                  <li>
                    <a href="/users/edit">Account</a>
                  </li>
                  <li>
                    <a href={`/groups/${userConfigs.group.id}`}>Group</a>
                  </li>
                  <li>
                    <a href={`/licenses/${userConfigs.license.id}`}>License</a>
                  </li>
                  <li>
                    {userConfigs.policies.api_key_index ? (
                      <a href="/api_keys">API Keys</a>
                    ) : (
                      <a
                        style={{ cursor: 'not-allowed' }}
                        title="Upgrade your license"
                      >
                        <span className="text-muted">API keys</span>
                      </a>
                    )}
                  </li>

                  <li role="separator" className="divider" />
                  <li>
                    <a href={`/${chain}/jobs`}>
                      Background Jobs{' '}
                      {userConfigs.running_jobs > 0 && (
                        <span className="label label-default label-as-badge label-primary">
                          {userConfigs.running_jobs}
                        </span>
                      )}
                    </a>
                  </li>

                  <li role="separator" className="divider" />
                  <li>
                    <a href={`/${chain}/clustering_addresses`}>
                      Addresses Clustering
                    </a>
                    {chainClass === 'Nakamoto' && [
                      <a href={`/${chain}/clustering_force`}>
                        Forced Clustering
                      </a>,
                      <a href={`/${chain}/clustering_extract`}>
                        Cluster Extraction
                      </a>,
                    ]}
                  </li>

                  {userConfigs.permissions.admin && [
                    <li role="separator" className="divider" />,
                    <li className="dropdown-header">Administration</li>,
                    userConfigs.policies.user_index && (
                      <li>
                        <a href="/users">Users</a>
                      </li>
                    ),
                    userConfigs.policies.group_index && (
                      <li>
                        <a href="/groups">Groups</a>
                      </li>
                    ),
                    userConfigs.policies.license_index && (
                      <li>
                        <a href="/licenses">Licenses</a>
                      </li>
                    ),
                    userConfigs.policies.sidekiq_index && (
                      <li>
                        <a href={`/${chain}/sidekiq`}>Sidekiq Jobs</a>
                      </li>
                    ),
                    <li>
                      <a
                        href={userConfigs.crawler_external_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Crawler
                      </a>
                    </li>,
                  ]}

                  <li role="separator" className="divider" />
                  <li>
                    <a
                      rel="nofollow"
                      data-method="delete"
                      href="/users/sign_out"
                    >
                      Log out
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default TopNavBar;
