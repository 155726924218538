import React, { Suspense, useState } from 'react';
import { graphql, useFragment, useLazyLoadQuery } from '@cbhq/data-layer';
import { Bar } from 'react-chartjs-2';
import WalletCategory from './WalletCategory';
import { ErrorBoundary, DefaultErrorMessage } from './ErrorBoundary';
import { LoadingBubbles } from './LoadingBubbles';
import { FundsExposure_ExposureTableFragment$key } from './__generated__/FundsExposure_ExposureTableFragment.graphql';
import { GetCategoryColor } from '../util/CategoryUtils';
import { FundsExposure_ExposureQuery } from './__generated__/FundsExposure_ExposureQuery.graphql';

const chartHeight = 120;

const exposureQuery = graphql`
  query FundsExposure_ExposureQuery(
    $type: String!
    $tickerSymbol: TickerSymbol!
    $hash: String!
    $direction: String!
    $hops: String
    $threshold: Float
  ) {
    viewer {
      coinbaseAnalytics {
        exposure(
          type: $type
          hash: $hash
          tickerSymbol: $tickerSymbol
          direction: $direction
          hops: $hops
          threshold: $threshold
        ) {
          ...FundsExposure_ExposureTableFragment
          chartData {
            label
            value
          }
        }
      }
    }
  }
`;

const exposureTableFragment = graphql`
  fragment FundsExposure_ExposureTableFragment on CoinbaseAnalyticsExposure {
    links {
      type
      walletName
      categories
      country
      linkFactor
      mixed
      numTx
      pathLength
      path
    }
  }
`;

const options = (onClick: (event: any, element: any) => void) => {
  return {
    onClick: onClick,
    tooltips: { displayColors: false },
    scales: {
      yAxes: { display: false, ticks: { beginAtZero: true, min: 0, max: 19 } },
      xAxes: { grid: { display: false }, ticks: { autoSkip: false } },
    },
    responsive: false,
    plugins: {
      legend: { display: false },
    },
  };
};

const ExposureDropdown: React.FC<{
  selectedName: string;
  items: DropdownItem[];
  style?: Record<string, unknown>;
}> = (props) => {
  return (
    <span className="dropdown">
      <button
        className="btn dropdown-toggle"
        data-toggle="dropdown"
        role="button"
        aria-haspopup="true"
        aria-expanded="false"
        style={{
          backgroundColor: '#FFFFFF',
          borderColor: '#CCCCCC',
          borderWidth: '1px',
          borderRadius: '4px',
          ...props.style,
        }}
      >
        {props.selectedName} <span className="caret"></span>
      </button>
      <ul className="dropdown-menu">
        {props.items.map((item) => {
          return (
            <li onClick={item.onClick} key={item.name}>
              <a>{item.name}</a>
            </li>
          );
        })}
      </ul>
    </span>
  );
};

const FundsExposureTable: React.FC<{
  fragmentRef: FundsExposure_ExposureTableFragment$key;
  chain: string;
  selectedCategory: string;
}> = (props) => {
  const tableFragment = useFragment(exposureTableFragment, props.fragmentRef);
  const links = tableFragment.links;
  return (
    links.length > 0 && (
      <table id="known-entities-funds-table" className="table">
        <thead>
          <tr>
            <th>Wallet</th>
            <th>Category</th>
            {/* TODO Brett: add in country when we have the flags ready */}
            <th>Link Factor</th>
            <th>No. Tx</th>
            <th>Path Len.</th>
          </tr>
        </thead>
        <tbody>
          {links
            .filter((link) => {
              return (
                !props.selectedCategory ||
                link.categories.includes(props.selectedCategory)
              );
            })
            .map((link) => {
              // TODO Brett: get the right class name here (link_category_classes) and figure out why the categories have no spacing
              return (
                <tr key={link.walletName} className="categorized">
                  {/* TODO Brett: Make this a link */}
                  <td width="35%">{link.walletName}</td>
                  <td width="35%">
                    {link.categories.map((category, index) => (
                      <React.Fragment
                        key={`funds-exposure-link-categories-${index + 1}`}
                      >
                        <WalletCategory
                          key={category}
                          category={category}
                          mixed={link.mixed}
                        />
                        <span> </span>
                      </React.Fragment>
                    ))}
                  </td>
                  {/* TODO Brett: add the flag here when ready */}
                  {/* <td></td> */}
                  <td>{link.linkFactor}</td>
                  {/* TODO Brett: add in the right link here */}
                  <td>
                    <a href={`/${props.chain}/links`}>{link.numTx}</a>
                  </td>
                  {/* TODO Brett: add the "add to graph link" */}
                  <td>{link.pathLength} &nbsp; </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    )
  );
};

const FundsExposureSettingsBar: React.FC<{
  setDirection: Function;
  hops: string;
  setHops: Function;
  threshold: string;
  setThreshold: Function;
}> = (props) => {
  return (
    <table
      className="table"
      style={{
        marginBottom: '0px',
        borderBottom: '2px solid #DDDDDD',
      }}
    >
      <tbody>
        <tr>
          <td>
            <ul className="nav nav-pills">
              <li className="">
                <a
                  data-toggle="tab"
                  onClick={() => {
                    props.setDirection('received');
                  }}
                  aria-expanded="true"
                >
                  <b>Incoming</b>
                </a>
              </li>
              <li className="active">
                <a
                  data-toggle="tab"
                  onClick={() => {
                    props.setDirection('sent');
                  }}
                  aria-expanded="false"
                >
                  <b>Outgoing</b>
                </a>
              </li>
            </ul>
          </td>
          <td>
            <span>Hops &nbsp;</span>
            <ExposureDropdown
              selectedName={props.hops}
              /* TODO Brett: is there a better way to set this fixed size (and have it
                 determined by the width of the "until known entity..." text) */
              style={{ width: '200px', textAlign: 'right' }}
              items={[
                {
                  name: 'Until known entity is found',
                  onClick: () => {
                    props.setHops('Until known entity is found');
                  },
                },
                {
                  name: '1',
                  onClick: () => {
                    props.setHops('1');
                  },
                },
                {
                  name: '2',
                  onClick: () => {
                    props.setHops('2');
                  },
                },
                {
                  name: '3',
                  onClick: () => {
                    props.setHops('3');
                  },
                },
                {
                  name: '4',
                  onClick: () => {
                    props.setHops('4');
                  },
                },
                {
                  name: '5',
                  onClick: () => {
                    props.setHops('5');
                  },
                },
              ]}
            ></ExposureDropdown>
          </td>
          <td style={{ display: 'flex', alignItems: 'center' }}>
            <span>Threshold &nbsp;</span>
            <span>
              <input
                className="form-control"
                type="number"
                value={props.threshold}
                name="threshold"
                min="0.01"
                max="1"
                step="0.01"
                onChange={(event) => {
                  props.setThreshold(event.target.value);
                }}
              />
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const FundsExposureContent: React.FC<{
  chain: string;
  type: string;
  hash: string;
  direction: string;
  hops: string;
  threshold: number;
  settingsChild: React.ReactNode;
}> = (props) => {
  const [selectedCategory, setSelectedCategory] = useState('');

  const result = useLazyLoadQuery<FundsExposure_ExposureQuery>(exposureQuery, {
    type: props.type,
    tickerSymbol: props.chain,
    hash: props.hash,
    direction: props.direction,
    hops: props.hops,
    threshold: props.threshold,
  });

  const labels = [];
  const data = [];
  const backgroundColor = [];
  for (const node of result.viewer.coinbaseAnalytics.exposure.chartData) {
    labels.push(node.label);
    data.push(node.value);
    backgroundColor.push(GetCategoryColor(node.label));
  }

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: props.type,
        data: data,
        backgroundColor: backgroundColor,
      },
    ],
  };

  const barClick = (_, element) => {
    if (element.length > 0) {
      const label = chartData.labels[element[0].index];
      setSelectedCategory((prevState) => {
        if (label === prevState) {
          return '';
        }
        return label;
      });
    }
  };
  const barChartOptions = options(barClick);
  if (result.viewer.coinbaseAnalytics.exposure.chartData.length <= 0) {
    return (
      <React.Fragment>
        <div className="modal-body">
          Address exposure information is not available for these parameters.
        </div>
        {props.settingsChild}
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Bar
          data={chartData}
          options={barChartOptions}
          height={chartHeight}
          width={600}
        />
        {props.settingsChild}
        <FundsExposureTable
          fragmentRef={result.viewer.coinbaseAnalytics.exposure}
          chain={props.chain}
          selectedCategory={selectedCategory}
        />
      </React.Fragment>
    );
  }
};

const FundsExposure: React.FC<{
  chain: string;
  type: string;
  exposureId: string;
}> = (props) => {
  const [hops, setHops] = useState('5');
  const [threshold, setThreshold] = useState('0.1');
  const [direction, setDirection] = useState('sent');
  const settingsBar = (
    <FundsExposureSettingsBar
      setDirection={setDirection}
      hops={hops}
      setHops={setHops}
      threshold={threshold}
      setThreshold={setThreshold}
    />
  );

  return (
    <ErrorBoundary
      errorChild={
        <React.Fragment>
          <div style={{ height: `${chartHeight}px`, textAlign: 'center' }}>
            <DefaultErrorMessage />
          </div>
          {settingsBar}
        </React.Fragment>
      }
    >
      <Suspense
        fallback={
          <React.Fragment>
            <div
              style={{
                height: `${chartHeight}px`,
                textAlign: 'center',
                verticalAlign: 'center',
              }}
            >
              <LoadingBubbles inline={true} />
            </div>
            {settingsBar}
            <LoadingBubbles />
          </React.Fragment>
        }
      >
        <FundsExposureContent
          chain={props.chain}
          type={props.type}
          hash={props.exposureId}
          direction={direction}
          hops={hops}
          threshold={+threshold}
          settingsChild={settingsBar}
        />
      </Suspense>
    </ErrorBoundary>
  );
};

interface DropdownItem {
  name: string;
  onClick: () => void;
}

export default FundsExposure;
